<template>
  <core-dialog
    id="Upload_claim_file"
    :visible="visible"
    @update:visible="close"
    :title="$t('claim.add_file')"
    @close="close"
    :use_default_breakpoints="false"
    :lg_width="30"
    :xl_width="40"
    :md_width="50"
    :xs_width="90"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
  <div class="p-6 w-full">
    <div class="flex-col flex">
      <core-file-uploader
        @update:file="(val) => addFile(val)"
        @update:urls="setImageData()"
        class="w-full"
        title="claim.add_file"
      />
    </div>
    <div
      class="border-2 border-gray-200 mt-3 rounded-md p-2"
      v-bind:key="'proff_' + index"
      v-for="(proof, index) in proof_urls"
    >
      <embed
        :src="dataUrls[index]"
        class="w-full h-96 mt-3"
        v-if="proof.base64.type == 'application/pdf'"
        type="application/pdf"
      />
      <img
        class="max-h-96 mx-auto mt-3"
        :src="dataUrls[index]"
        v-if="proof.base64.type !== 'application/pdf'"
      />
      <core-input
        class="mt-3"
        inputType="textarea"
        placeholder=""
        :label="$t('description')"
        @change="(val) => updateDescription(val, index)"
      />
      <core-button
        class="w-full mt-2"
        type="delete_outline"
        @click="removeFile(index)"
        >{{ $t("claim.delete_file") }}</core-button
      >
    </div>
    </div>
    <div
      slot="footer"
      style="width: 100%"
      class="dialog-footer flex justify-end mt-4"
    >
      <slot name="buttons-prefix" />
      <core-button
        type="coolrunner"
        @click="uploadFiles()"
        class="ml-2"
        :disabled="formOkay"
        >{{ $t("claim.upload_file") }}
      </core-button>
    </div>
  </core-dialog>
</template>

<script>
import _ from "lodash";
import FormData from "@/mixins/FormData";
export default {
  mixins: [FormData],
  name: "FileModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    token: {
        type: String,
        default: ""
    }
  },
  data:() => ({
        proof_urls: [],
        dataUrls: []
    }),
    computed: {
    formOkay: function () {
      return (
        this.proof_urls.length == 0
      );
    },
  },
  methods: {
    close: function () {
      this.$emit("update:visible", false);
      this.proof_urls = []
      this.dataUrls = []
    },
    addFile: function (file) {
      let index = this.proof_urls.length;
      const proofUrls = this.proof_urls;
      const newProofs = [...proofUrls];
      if (file.type == "application/pdf") {
        newProofs.splice(index, 1, {
          ...newProofs[index],
          base64: file,
          description: "",
          type: "receipt",
        });
      } else {
        newProofs.splice(index, 1, {
          ...newProofs[index],
          base64: file,
          description: "",
          type: "shipment",
        });
      }
        this.proof_urls = newProofs
    },
    removeFile: function (i) {
      const index = i;
      const urls = this.dataUrls;
      const proofUrls = this.proof_urls;
      const newProofs = [...proofUrls];
      newProofs.splice(index, 1);
      urls.splice(index, 1);

      this.proof_urls = newProofs
    },
    setImageData: function () {
      let array = this.dataUrls;
      _.forEach(this.proof_urls, function (file) {
        if (_.isString(file.base64)) {
          array.push(`data:${this.mimeType};base64,${file}`);
        }

        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          if (_.includes(array, e.target.result)) {
            return;
          } else {
            array.push(e.target.result);
          }
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file.base64);
      });
    },
    updateDescription: function (description, i) {
      const index = i;

      const proofUrls = this.proof_urls || [];
      const newProofs = [...proofUrls];
      newProofs.splice(index, 1, { ...newProofs[index], description });

      this.proof_urls = newProofs
    },
    uploadFiles: function () {
      const id = Date.now();
      this.$notification({
        id: id,
        title: this.$t("uploading_files"),
        text: this.$t("uploading_files_text"),
        duration: -1,
      });
      let self = this;

      _.forEach(this.proof_urls, function (file) {

          let fileToUpload = {
              base64: file.base64,
              description: file.description,
              type: file.type
          }

        self.createFormData(fileToUpload);

        self.$upload(
        `/claims/${self.token}/files`,
        self.formData,
        {
          failSilently: true,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          self.$notification.close(id);
          self.$notification({
            title: self.$t("file_uploaded"),
            text: response.message,
            duration: 2500,
          });
        })
        .catch(e => {
          const errors =
            e.response.data.errors ?? e.response.data.response_data;
            self.$notification.close(id);
            self.$notification({
            title: self.$t("file_failed"),
            text: self.$_.join(self.$_.map(errors), "</br>"),
            type: "error",
            duration: 2500,
          });
          self.$refs.wizard.loading = false;
        });

      })
    },
  },
};
</script>

<style lang="scss">
.el-dialog__wrapper .el-dialog__body {
  display: block;
}
</style>