import _ from "lodash";

export default {
  data: () => ({
    formData: null
  }),
  methods: {
    createFormData: function(
      obj,
      subKeyStr = "",
      clear = false,
      smartfix = false
    ) {
      if (!this.formData || clear) this.formData = new FormData();

      if (smartfix) {
        for (let i in obj) {
          let value = obj[i];
          let subKeyStrTrans = subKeyStr ? subKeyStr + "[" + i + "]" : i;
          if (typeof value === "object" && !(value instanceof File)) {
            if (_.isEmpty(value)) {
              this.formData.append(subKeyStrTrans, []);
            } else this.createFormData(value, subKeyStrTrans, false, true);
          } else {
            this.formData.append(subKeyStrTrans, value);
          }
        }
      } else {
        for (let i in obj) {
          let value = obj[i];
          let subKeyStrTrans = subKeyStr ? subKeyStr + "[" + i + "]" : i;
          if (
            value instanceof File ||
            typeof value === "string" ||
            typeof value === "number"
          ) {
            this.formData.append(subKeyStrTrans, value);
          } else if (typeof value === "object") {
            this.createFormData(value, subKeyStrTrans);
          }
        }
      }
    }
  }
};
