<template>
  <core-view>
    <core-view-header :name="name" :count="totalItems" />
    <core-list-new-table
      :items="items"
      :headers="headers"
      :pageSize.sync="options.itemsPerPage"
      :currentPage.sync="options.page"
      :totalItems="totalItems"
      :loading="loading"
      :orderBy.sync="options.orderBy"
      :orderDir.sync="options.orderDir"
      :entityName="name"
      :emptyListIcon="icon"
      :rowActions="rowActions"
      :show-create-new="true"
      @create-new-clicked="editItem"
      :createNewLabel="
        $t('setup_entity', { entity: $tc('gateway', 1).toLowerCase() })
      "
    />
    <dialog-gateway
      v-if="!!gateway"
      :gateway="gateway"
      :visible="!!gateway"
      @update:visible="gateway = null"
      @saved="getDataFromApi"
    />
  </core-view>
</template>

<script>
import List from "@/mixins/List";
import DialogGateway from "./components/DialogGateway";
import { checkServiceAccess } from "@/utils/tools";
export default {
  beforeRouteEnter(to, from, next) {
    checkServiceAccess("lights", from, next);
  },
  components: { DialogGateway },
  mixins: [List],
  data: () => ({
    endpoint: "casambi/gateways",
    name: "gateway",
    icon: ["far", "router"],
    headers: [
      {
        label: "email",
        key: "email"
      },
      {
        label: "number_of_units",
        key: "units_count"
      }
    ],
    gateway: null
  }),
  computed: {
    rowActions: function() {
      return [
        {
          label: this.$t("edit"),
          icon: ["far", "edit"],
          action: this.editItem
        },

        {
          label: this.$t("test_entity", {
            entity: this.$tc("gateway", 1).toLowerCase()
          }),
          icon: ["far", "lightbulb-on"],
          action: this.identify
        },
        {
          label: this.$t("delete"),
          icon: ["far", "trash"],
          action: this.deleteItem
        }
      ];
    }
  },
  mounted: function() {
    this.getDataFromApi();
  },
  methods: {
    editItem: function(item = {}) {
      this.gateway = item;
    },
    identify: function(entity) {
      this.$get(`${this.endpoint}/${entity.id}/identify`).then(() => null);
    }
  }
};
</script>
