<template>
  <div
    style="padding:0px; background-color:white; border-radius:0px 0px 10px 10px;"
    class="cr-table"
    id="cr-table"
  >
    <core-list-content :loading="loading" style="padding:0">
      <div :class="['core-list', { navigateable: !!pushPath }]">
        <div
          class="core-list__header actions p-4"
          v-if="showHeaderActions"
          :loading="loading"
        >
          <core-filter-table-row
            :wmsSelect="wmsSelect"
            :list="list"
            v-bind:key="viewType"
            v-if="filterable"
            :query="query"
            :viewType="viewType"
            :interval="interval"
            style="padding:0 15px 0 0"
            @update:query="val => $emit('update:query', val)"
            @set-filters="val => $emit('set-filters', val)"
            @set-cleaned-filters="val => $emit('set-cleaned-filters', val)"
          >
            <template slot="actions">
              <core-list-actions
                v-if="selection"
                :open.sync="bulkActionsVisible"
                :actions="bulkActions"
                :disabled="!selected.length"
              >
                <template v-slot:activator="">
                  <core-button
                    :disabled="!selected.length"
                    style="margin-left: 15px; height: 42px;"
                    >{{
                      $tc("selected_with_count", selected.length, {
                        count: `${selectAll ? "~ " : ""} ${$n(selectedCount)}`
                      })
                    }}
                    <i class="el-icon-arrow-down" />
                  </core-button>
                </template>
              </core-list-actions>
            </template>
          </core-filter-table-row>
          <core-button
            type="coolrunner"
            @click="$emit('create-new-clicked')"
            v-if="showCreateNew"
          >
            {{ $t(createNewLabel) }}
          </core-button>
        </div>

        <div v-if="$slots['list-title']" class="core-list__title mb-2 mx-4">
          <slot name="list-title" />
        </div>

        <core-list-empty
          v-if="!loading && !totalItems"
          :emptyListIcon="emptyListIcon"
          :emptyListText="emptyListText"
          :entityName="entityName"
          style="height:500px"
        />
        <div class="w-full bg-white h-14" v-if="loading && !totalItems"></div>
        <el-table
          ref="table"
          v-if="totalItems"
          :data="items"
          sortable="custom"
          :default-sort="{ prop: orderBy, order: 'descending' }"
          :value="selected"
          :spanMethod="spanMethod"
          :cellClassName="cellClassName"
          @select="handleSelection"
          @select-all="$emit('update:selectAll', selectBoo), toggle()"
          @sort-change="
            ({ column, order }) => {
              $emit('update:orderBy', column.columnKey);
              $emit('update:orderDir', getOrder(order));
            }
          "
          row-key="id"
          size="medium"
          style="
      width: 100%;
      border-collapse: separate;
      border-spacing:10em;"
          :cell-style="{ padding: '5px', paddingLeft: '10px', height: '60px' }"
          :row-class-name="
            ({ row }) => [
              'tableRows opacity-100 transition duration-350 ease-in-out relative',
              { deleted: isInactive(row) }
            ]
          "
          class="overflow-visible"
          @row-click="handleRowClick"
          :row-style="animateRowIn"
        >
          <template v-if="selection">
            <el-table-column
              type="selection"
              :reserve-selection="true"
              width="55"
              :selectable="row => row.selectable !== false"
            >
            </el-table-column>
          </template>

          <el-table-column
            v-for="label in headers"
            v-bind:key="label.key"
            :prop="label.key"
            :label="$tc(label.label, label.label_count || 1)"
            :sortable="label.orderable !== false"
            :column-key="label.orderableKey || label.key"
            :min-width="label.minWidth"
            class="el-column"
            scope="scope"
          >
            <template slot="header" scope="scope">
              <slot
                :name="`column.${label.key}.header`"
                v-bind="{ item: scope.row }"
              >
                {{ $tc(label.label, label.label_count || 1) }}
              </slot>
            </template>
            <template scope="scope">
              <slot :name="`column.${label.key}`" v-bind="{ item: scope.row }">
                {{ getValue(scope.row, label) }}
              </slot>
            </template>
          </el-table-column>
          <el-table-column v-if="rowActions.length !== 0" width="100">
            <template slot-scope="scope">
              <core-list-actions
                :open.sync="actionsVisible[scope.$index]"
                :actions="rowActions"
                :entity="scope.row"
                :positionFixed="false"
              >
              </core-list-actions>
            </template>
          </el-table-column>
          <template slot="empty">
            <div class="noData"></div>
          </template>
        </el-table>
      </div>
      <el-pagination
        v-if="paginate && totalItems"
        class="core-list__pagination"
        :current-page="currentPage"
        :total="totalItems"
        :page-size="pageSize"
        :pageSizes="[10, 20, 30]"
        :disabled="loading"
        style="background-color:white;
      border-radius: 9px;
      padding:7px 0;"
        @size-change="s => $emit('update:pageSize', s)"
        @current-change="c => $emit('update:currentPage', c)"
      />
    </core-list-content>
  </div>
</template>

<script>
import { convertUTCToLocal } from "../../../utils/date";
import { URL_PREFIX } from "@/utils/constants";
export default {
  name: "NewTable",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    wmsSelect: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 1
    },
    showHeaderActions: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: () => true
    },
    viewType: {
      type: String,
      default: ""
    },
    orderBy: {
      type: String,
      default: ""
    },
    orderDir: {
      type: String,
      default: ""
    },
    query: {
      type: String,
      default: null
    },
    interval: {
      type: String,
      default: null
    },
    showCreateNew: {
      type: Boolean,
      default: false
    },
    createNewLabel: {
      type: String,
      default: "create_new"
    },
    totalItems: {
      type: Number,
      default: 0
    },
    paginate: {
      type: Boolean,
      default: true
    },
    currentPage: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    bulkActions: {
      type: Array,
      default: () => []
    },
    checkbox: {
      type: Boolean,
      default: true
    },
    rowActions: {
      type: Array,
      default: () => []
    },
    pageSize: {
      type: Number,
      default: 0
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    pushPath: {
      type: String,
      default: null
    },
    selection: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    excluded: {
      type: Array,
      default: () => []
    },
    selectionKey: {
      type: String,
      default: "id"
    },
    emptyListText: {
      type: String,
      default: ""
    },
    emptyListIcon: {
      type: Array,
      default: () => ["fad", "align-slash"]
    },
    entityName: {
      type: String,
      default: "element"
    },
    spanMethod: {
      type: Function,
      default: undefined
    },
    cellClassName: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      page: 0,
      bulkActionsVisible: false,
      actionsVisible: {},
      selectBoo: true
    };
  },
  created: function() {
    this.page = this.currentPage;
  },
  computed: {
    selectedCount: function() {
      return this.selectAll
        ? this.totalItems - this.excluded.length
        : this.selected.length;
    },
    URL_PREFIX: () => URL_PREFIX,
    selectable: function() {
      if (this.checkbox) return true;
      let hasBulkactions = !!(this.bulkActions && this.bulkActions.length);
      let screenSizeOK =
        !this.$store.state.breakpoint.isSmall &&
        !this.$store.state.breakpoint.isMedium &&
        !this.$store.state.breakpoint.noMatch;
      return hasBulkactions && screenSizeOK;
    }
  },
  watch: {
    items(n) {
      this.items = n;
      if (this.selectAll && this.$refs)
        this.$nextTick(function () {
          this.$refs?.table?.toggleAllSelection();
      })
    }
  },
  methods: {
    isInactive: function(item) {
      return item.cancel_at || item.deleted_at || item.active === false;
    },
    getCarrier(val) {
      return (
        (this.$store.state.carriers && this.$store.state.carriers[val]) || val
      );
    },
    handleSelection(val, item) {
      const selector = item[this.selectionKey];
      const isSelected = this.$_.find(val, [this.selectionKey, selector]);

      this.$emit("checkbox-change", { value: !!isSelected }, item);
    },
    handleRowClick(row, column, event) {
      this.$emit("rowclicked", row);
      if (this.pushPath) {
        let x = document.getElementsByClassName("tableRows");
        x.forEach((item, index) => {
          let totalTime = 30 * this.items.length;
          item.setAttribute("id", "row" + index);
          const delay = totalTime - 30 * index;
          document.getElementById(item.id).animate(
            [
              // keyframes
              { transform: `translate3d(0, 0px, 0)`, opacity: 1 },
              {
                opacity: 1
              },
              { transform: `translate3d(0, -15px, 0)`, opacity: 0 }
            ],
            {
              // timing options
              duration: 250,
              easing: "ease-in-out",
              fill: "forwards",
              delay: delay
            }
          );
        });
        if (this.pushPath == "tools_smartcheckout_checkout") {
          this.$router.push({
            name: "tools_smartcheckout_checkout",
            params: { smartcheckout_id: row.id }
          });
        } else if (this.pushPath == "tools_smartcheckout_shippingmethod") {
          this.$router.push({
            name: "tools_smartcheckout_shippingmethod",
            params: {
              smartcheckout_id: this.$route.params.smartcheckout_id,
              shipping_method_id: row?.id || "null"
            }
          });

        } else if (this.pushPath == "returns_details") {
          this.$router.push({
            name: this.pushPath,
            params: { id: row.name }
          });
        }
        else {
          this.$router.push({
            name: this.pushPath,
            params: { id: row.shipment_id ?? row.id }
          });
        }
      }
    },
    updateQuery: function(query) {
      this.$emit("update:query", query);
    },
    animateRowIn(row) {
      const delay = 0.05 * row.rowIndex;
      return {
        animation: "tableRow 0.30s ease-in-out backwards",
        animationDelay: `${delay}s`
      };
    },
    sleep: function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    toggle: function() {
      this.selectBoo = !this.selectBoo;
    },
    getOrder: function(order) {
      if (order == "ascending") {
        return "asc";
      } else {
        return "desc";
      }
    },
    getValue: function(item, header) {
      let val = this.$_.get(item, header.key);
      switch (header.type) {
        case "date":
        case "datetime":
        case "time": {
          let localTime = this.$moment(val).local();
          if (this.$moment().diff(localTime, "days") > 7) {
            val = this.$moment(localTime).format("DD-MM-YYYY");
          } else {
            val = this.$moment(localTime).fromNow();
          }
          break;
        }
        case "price":
          val = this.$n(val, "currency", "da-DK");
          break;
        case "status":
          val = this.$t(val);
          break;
        case "warehouse":
          val = this.$t(val);
          break;
        default:
          break;
      }

      return val || "-";
    }
  }
};
</script>

<style lang="scss">
@keyframes tableRow {
  0% {
    transform: translate3d(0, 10, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.cr-table {
  .el-table {
    .el-table__body-wrapper {
      overflow: visible;
    }
    tr {
      margin: 20px;
      border-bottom: 1px solid #f5f8fa;
      &.deleted {
        background: #f8fafc;
        color: #cacdcf;
      }
    }
    tr > td {
      transition: 200ms ease-in-out;
    }

    td {
      transition: 300ms ease-in-out;
    }
    &__header {
      .cell:first-of-type {
        padding-left: 20px;
        padding-right: 0px;
      }
      .cell {
        color: #c9d0de;
        font-weight: 400;
        transition: 300ms ease-in-out;
        white-space: nowrap;
        overflow: visible;
        text-overflow: ellipsis;
        &:hover {
          color: #9297a0;
          transition: 300ms ease-in-out;
          opacity: 1;
        }
      }
    }

    &__body {
      overflow: visible;
      .el-table__row {
        .cell {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          .small {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .overflow {
            width: 100%;
            overflow: visible;
          }
          .carrier-image {
            text-align: center;
            vertical-align: middle;
            .el-image__inner {
              vertical-align: inherit;
              height: 24px;
              width: auto;
            }
          }
        }
      }
    }
  }

  .navigateable {
    tr:hover > td {
      cursor: pointer;
      transition: 300ms ease-in-out;
    }
    .tableRows {
      &:hover {
        transition: 350ms ease-in-out;
        box-shadow: 0px 0px 6px 1px #d6d6d6;
        border: 7px solid #ffffff;
        //  transform: scaleX(1.009) scaleY(1.009);
        z-index: 1;
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  table tr td {
    width: 25%;
  }
}
</style>
