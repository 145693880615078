<template>
  <core-dialog
    :visible="visible"
    :title="$t('return_shipment')"
    id="return_shipments_dialog"
    @close="$emit('close-returns-dialog')"
    @opened="$emit('open-returns-dialog')"
    :close-on-click-modal="!loading && step === 1"
    :close-on-press-escape="!loading && step == 1"
    :use_default_breakpoints="false"
    :lg_width="50"
    :xl_width="50"
    :md_width="50"
    :xs_width="90"
  >
    <el-col
      :span="24"
      style="min-height: 256px; max-height: 512px"
      id="return_shipments_dialog__container"
    >
      <step1
        v-if="step === 1"
        :available-products="availableProducts"
        :selected-product="selectedProduct"
        @select:product="product => (selectedProduct = product)"
      />
      <step2 v-else-if="step === 2" :step="step"  :shipment.sync="returnShipment" />
      <step3 v-else-if="step === 3" :order="order" />
    </el-col>
    <div slot="footer" class="dialog-footer flex justify-between">
      <template v-if="step === 1">
        <core-button
          type="default_outline"
          @click="$emit('close-returns-dialog')"
          >{{ $t("cancel") }}</core-button
        >
        <core-button
          type="coolrunner"
          :loading="loading"
          :disabled="!selectedProduct"
          @click="createReturnShipment"
          >{{ $t("next") }}
        </core-button>
      </template>
      <template v-else-if="step === 2">
        <core-button type="default" @click="step = 1">{{
          $t("back")
        }}</core-button>
        <core-button
          type="coolrunner"
          :loading="loading"
          @click="createReturnOrder"
          >{{ $t("create") }}
        </core-button>
      </template>
      <template v-else>
        <core-button type="default" @click="$emit('close-returns-dialog')">{{
          $t("close")
        }}</core-button>
      </template>
    </div>
  </core-dialog>
</template>

<script>
import Step1 from "@/views/Labels/components/ReturnShipmentDialog/step1";
import Step2 from "@/views/Labels/components/ReturnShipmentDialog/step2";
import Step3 from "@/views/Labels/components/ReturnShipmentDialog/step3";

import Loader from "@/mixins/Loader";
import Payment from "@/mixins/Payment";
import { URL_PREFIX } from "@/utils/constants";

export default {
  components: { Step1, Step2, Step3 },
  mixins: [Loader, Payment],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    shipment: {
      type: Object,
      default: () => {}
    }
  },
  watch: {},
  computed: {
    packageNumber: function() {
      if (this.shipment) return this.shipment.package_number || null;
      else return null;
    },
    countryFrom: function() {
      if (this.shipment) return this.shipment.receiver.country || null;
      if (this.shipment) return this.shipment.receiver.country || null;
      else return null;
    }
  },
  data: () => ({
    selectedProduct: null,
    availableProducts: [],
    returnShipment: {
      carrier: null,
      carrier_product: null,
      carrier_service: null,
      weight: null,
      receiver: {
        name: null,
        street1: null,
        zip_code: null,
        city: null,
        country: null
      },
      sender: {
        name: null,
        street1: null,
        zip_code: null,
        city: null,
        country: null
      },
      reference: null
    },
    step: 1,
    order: null,
    defaultLoaderTarget: "#return_shipments_dialog__container"
  }),
  mounted() {
    this.$on("close-returns-dialog", () => {
      this.loading = false;
      this.step = 1;
      this.selectedProduct = null;
    });

    this.$on("open-returns-dialog", () => {
      this.fetchProducts();
    });
  },
  methods: {
    fetchProducts() {
      this.loading = true;
      this.$get("/prices", {
        country_from: this.shipment.receiver.country,
        country_to: this.shipment.sender.country,
        sender_country: this.shipment.receiver.country,
        receiver_country: this.shipment.sender.country,
        sender_street1: this.shipment.receiver.street1,
        receiver_street1: this.shipment.sender.street1,
        sender_zip_code: this.shipment.receiver.zip_code,
        receiver_zip_code: this.shipment.sender.zip_code,
        sender_city: this.shipment.receiver.city,
        receiver_city: this.shipment.sender.city,
        weight: this.shipment.weight,
        height: this.shipment.height,
        length: this.shipment.length,
        width: this.shipment.width,
        receiver_type: this.shipment.sender.type,
        q: "return"
      }).then(response => {
        this.loading = false;
        this.availableProducts = response;
      });
    },
    createReturnOrder() {
      if (this.returnShipment.order_lines) {
        let sorted_order_lines = this.$_.remove(this.returnShipment.order_lines, function(n) {
          return n.qty !== '0';
        });
        this.returnShipment = {...this.returnShipment, order_lines: sorted_order_lines}
      }
      this.loading = true;
      this.$post(`shipments`, {
        shipment: this.returnShipment,
        final_url: this.landing_page.order_success
      }).then(response => {
        this.step = 3;
        this.order = response.shipment;
        this.$notification({
            title: this.$t("created"),
            text: this.$t("entity_saved_correctly", {
              entity: this.$t("return_shipment").toLocaleLowerCase()
            })
          });

          this.loading = false;
          this.$emit("refresh-data");
      });
    },
    handlePayment(response) {
      if (response.type !== "invoice" && response.type !== "balance") {
        let payment_window = this.popup(response.payment_url);
        this.$on(`payment-${payment_window}:success`, () => {
          this.processReturnShipment(response);
        });
      } else {
        this.processReturnShipment(response);
      }
    },
    createReturnShipment() {
      const [carrier, product, service] = this.selectedProduct.split(/_/, 3);
      this.loading = true;
      this.$post(`shipments/${this.shipment.package_number}/return`, {
        carrier: carrier,
        product: product,
        service: service || null
      }).then(response => {
        this.returnShipment = {...response, order_lines: this.shipment.order_lines};
        this.loading = false;
        this.step = 2;
      });
    },
    processReturnShipment(order_response) {
      this.$get(
        `${URL_PREFIX}/business-panel/order/${order_response.order.id}/process`
      )
        .then(response => {
          this.step = 3;
          this.order = response;

          if (order_response.type === "balance") {
            this.$store.dispatch("loadPaymentInfo");
            this.$notification({
              title: this.$t("balance"),
              duration: -1,
              text:
                this.$t("entity_drawn_from_balance", {
                  entity: this.$n(
                    this.$store.state.account.business
                      ? response.shipment.price.excl_tax
                      : response.shipment.price.incl_tax,
                    "currency",
                    "da-DK"
                  )
                }) + ` (#${response.order.id})`,
              data: {
                actions: [
                  {
                    label: this.$t("go_to_entity", {
                      entity: this.$t("balance")
                    }),
                    click: () => {
                      this.$router.push({ name: "account_settings_payment" });
                    }
                  }
                ]
              }
            });
          }

          this.$notification({
            title: this.$t("created"),
            text: this.$t("entity_saved_correctly", {
              entity: this.$t("return_shipment").toLocaleLowerCase()
            })
          });

          this.loading = false;
          this.$emit("refresh-data");
        })
        .catch(e => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#return_shipments_dialog {
  min-height: 365px;
}
</style>
