import axios from "axios";
import print from "print-js";

export default {
  name: "PdfDownloader",
  data: () => ({
    downloadingPDF: false,
  }),
  created: function() {},
  methods: {
    downloadPDF: function(url) {
      this.downloadingPDF = true;

      axios
        .get(url, { responseType: "blob" })
        .then((r) => {
          print({
            printable: URL.createObjectURL(r.data),
            type: "pdf",
            onPrintDialogClose: () => {
              this.downloadingPDF = null;
              this.printDialogClosed();
            },
            onPdfOpen: () => {
              this.downloadingPDF = null;
              this.printDialogClosed();
            },
            onError: () => {
              this.downloadingPDF = null;
              this.printDialogClosed();
            },
          });
        })
        .catch((e) => {
          const blob = new Blob([e.response.data]);

          blob.text().then((data) => {
            const { message, details } = JSON.parse(data);
            if (message) this.addAlert({ title: message, color: "red" });
          });

          this.downloadingPDF = null;
          this.printDialogClosed();
        });
    },
    printDialogClosed: function() {},
  },
};
