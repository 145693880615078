<template>
  <widget-base
    :title="cTitle"
    :subtitle="sub"
    :shadow="shadow"
    :titlePosition="titlePosition"
    :showNoData="showNoData"
    :initialFilters="data.filters"
    :interval="interval"
    :filteringEnabled="this.filteringEnabled"
    @set-filters="val => (filters = val)"
    @set-cleaned-filters="val => (cleanedFilters = val)"
    @set-interval="val => (interval = val)"
    showIntervalSelector
    class="chart-wrapper"
  >
  <div class="failText" v-if="showNoData">
      {{$t('chart_error')}}
    </div>
    <chart-radar
      v-if="tooltipsOptions"
      :chart-id="uniqueID"
      :chart-data="dataCollection"
      :options="{
        legend: false,
        fullCornerRadius: false,
        resonsive: true,
        maintainAspectRatio: false,
        tooltips: tooltipsOptions(title)
      }"
    />
  </widget-base>
</template>

<script>
import ChartWidget from "../../mixins/ChartWidget";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "RadarChart",
  mixins: [ChartWidget],
  props: {},
  data: () => ({
    baseID: "radar-chart"
  }),
  computed: {
    plugins: () => [ChartDataLabels]
  },
  methods: {
    /*  fillData() {
      this.loading = true;
      this.$get(this.endpoint, {
        date_from: this.dateFrom,
        date_to: this.dateTo
      }).then(r => {
        this.dataCollection = {
          labels: this.labels,
          datasets: [
            {
              data: r,
              backgroundColor: [
                "#157EFB",
                "#328DFA",
                "#4B9BFA",
                "#64A8FA",
                "#7DB6FA",
                "#96C3FA",
                "#AFD1FA",
                "#C8DEFA",
                "#E1ECFA",
                "#F5F7FA"
              ]
            }
          ]
        };
        this.loading = false;
      });
    }*/
  }
};
</script>
