<template>
  <core-view :style="{ '--delay': delay }">
    <div class="bg-white rounded-md py-10" v-if="!$store.state.account.enabled_services.transfer_enabled">
      <div class="grid grid-cols-2">
        <div class="col-span-1 px-[10%]">
            <p class="text-2xl font-semibold">{{ $t('transfer.current_profile')}}</p>
            <p class="text-lg mt-3">{{ $t('transfer.current_profile')}}: <span class="font-bold">{{$t('transfer.droppoint')}}</span></p>
            <p class="text-sm mt-2">{{ $t('transfer.description_1')}}</p>
            <p class="text-sm mt-2">{{ $t('transfer.description_2')}}</p>
            <p class="text-sm mt-2 font-semibold">{{ $t('transfer.description_4')}}</p>
            <div class="flex mt-5">
                <core-checkbox
                    :key="`input_0`"
                    class="mt-0.5"
                    :value="acceptedStart"
                    @input="val => acceptedStart = val"
                />
                <p class="pl-3 cursor-pointer" @click="acceptedStart = !acceptedStart">{{$t('transfer.accept_terms')}}</p>
            </div>
            <div v-if="!available">
            <div class="mt-3 mb-3">
              <p v-if="missing_subscription" class="text-red-500 text-sm">{{$t('transfer.need_card')}}</p>
              <p v-if="autofill_missing" class="text-red-500 text-sm">{{$t('transfer.auto_fill')}}</p>
              <p v-if="autofill_threshold" class="text-red-500 text-sm">{{$t('transfer.auto_fill_amount')}}</p>
            </div>
            <core-button
              v-if="missing_subscription"
              type="coolrunner"
              @click="addCard"
            >{{ $t("add_entity", { entity: $t("card").toLowerCase() }) }}
            </core-button>
            <core-button
              v-if="autofill_missing || autofill_threshold"
              class="mt-3"
              type="coolrunner"
              @click="refillVisible = true"
            >{{ $t("change_entity", { entity: $t("refill").toLowerCase() }) }}
            </core-button>
            </div>
            <core-button
                class="mt-5"
                :type="'coolrunner'"
                :disabled="!acceptedStart || !available"
                @click="startTransfer()"
                >{{$t('transfer.change_profile')}}
            </core-button>
        </div>
        <div class="col-span-1 px-[10%] border-l border-coolrunner-900">
            <p class="text-lg mt-3 font-bold">{{$t('transfer.see_video')}}</p>
            <div class="w-full h-auto max-h-[477px]">
                <div style="padding:300px 0 0 0;position:relative; border-radius: 0.375rem;">
                <iframe :src="`https://player.vimeo.com/video/822975823?h=812f0542e3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Case Lakrids by Bülow.mp4"></iframe>
                </div>
            </div>
            <p class="text-lg mt-3 font-bold">{{$t('transfer.advantages')}}</p>
            <ul class="mt-3 mb-3 list-inside list-disc text-left">
                <li>{{$t('transfer.order')}}</li>
                <li>{{$t('transfer.avoid')}}</li>
                <li>{{$t('transfer.price_pr_pallet')}}</li>
                <li>{{$t('transfer.countries')}}</li>
                <li>{{$t('transfer.pickup_at')}} {{localPickupTime}}, {{localPickupCity}}</li>
            </ul>
            <p class="font-semibold my-5">{{$t('transfer.auto_fill')}}</p>
            <p>{{$t('transfer.pickup_by')}}</p>
            <div class="h-20 w-32">
                <el-image
                    :src="
                        `${URL_PREFIX}/images/carriers/dfm.png?w=500&context=business_panel`
                    "
                    class="carrier-image"
                    :fit="'contain'"
                    ondragstart="return false"
                    >
                </el-image>
            </div>
        </div>
      </div>
    </div>
    <div v-if="$store.state.account.enabled_services.transfer_enabled" class="relative">
      <core-view-header :name="'pickup_shipment'" :count="items.length" :description="'page_descriptions.pickup'" :route-name="$route.name" route="labels" show-view-name />
      <core-button type="dark" class="absolute top-[40px] right-8" @click="createPalletvisible = true">
        {{ $t("order_pickup") }}
      </core-button>
      <div>
        <div v-for="group in groupedPallets" :key="group.label" class="">
          <div class="flex items-center justify-between bg-gray-200 p-5">
            <div class="text-lg font-semibold">{{ group.label }}</div>
          </div>
          <div class="flex flex-col gap-4">
            <div v-for="pallet in group.items" :key="pallet.id" class="min-h-36 grid w-full grid-cols-14 grid-rows-2 gap-2 rounded-md bg-white" :class="{ 'opacity-60': pallet.state !== 'opened' }">
              <div class="col-span-2 px-3 py-4 text-left">
                <p class="font-semibold capitalize">
                  {{ pallet.type.title }}
                </p>
                <p class="mt-3 text-xs">
                  {{ $t("pickup_type") }}
                </p>
              </div>
              <div class="col-span-2 px-3 py-4 text-left">
                <p class="font-semibold capitalize">
                  {{ pallet.consignment_number }}
                </p>
                <p class="mt-3 text-xs">
                  {{ $t("consignment_number") }}
                </p>
              </div>
              <div class="px-3 py-4 text-left" :class="{'col-span-1' : group.id !== 'today', 'col-span-2' : group.id == 'today'}">
                <p class="font-semibold">
                  {{ pallet.shipments }}
                </p>
                <p class="mt-3 text-xs">
                  {{ $t("number_of_packages") }}
                </p>
              </div>
              <div class="col-span-3 px-3 py-4 text-left">
                <p class="font-semibold">
                  {{ pallet.carrier.title }}
                </p>
                <p class="mt-3 text-xs">
                  {{ $t("carrier") }}
                </p>
              </div>
              <div v-if="group.id !== 'today'" class="relative col-span-2 py-3 pl-3 text-left">
                <p class="font-semibold">
                  {{ $t(pallet.pickup_booked_for) }}
                </p>
                <p class="mt-3 text-xs">
                  {{ $t("pickup_date") }}
                </p>
              </div>
              <div class="relative py-3 pl-3 text-left" :class="{'col-span-1' : group.id !== 'today', 'col-span-2' : group.id == 'today'}">
                <p class="font-semibold">
                  {{ $t(pallet.state) }}
                </p>
                <p class="mt-3 text-xs">
                  {{ $t("status") }}
                </p>
              </div>
              <div class="relative col-span-3 py-3 pl-3 text-left">
                <p v-if="carriers.length !== 0" class="font-semibold">
                  {{ calcCountdown(pallet.carrier.slug, 'calc') }}
                </p>
                <p v-if="carriers.length !== 0" class="mt-3 text-xs">{{ $t("cutoff") }} {{ $moment(calcCountdown(pallet.carrier.slug, 'raw')).format("HH:mm") }}</p>
              </div>
              <div class="col-span-14 my-4 flex flex-row justify-end gap-2 pr-5">
                <core-button v-if="pallet.shipments !== 0" type="white" @click="getPallet(pallet.id)">
                  {{ $t("see", { entity: $t("packages") }) }}
                </core-button>
                <core-button type="dark" :loading="downloadingPDF" :disabled="!pallet.pdf.available" @click="printLabel(pallet.id)">
                  {{ $t("print") }} <span class="lowercase">{{ $tc("label", 1) }}</span>
                </core-button>
                <core-button type="delete" :disabled="!!pallet.shipments || pallet.state !== 'opened'" @click="selectPalletToCancel(pallet.id)">
                  {{ $t("cancel") }} <span class="lowercase">{{ pallet.type.title }}</span>
                </core-button>

                <core-button type="dark" :disabled="!pallet.shipments || pallet.state !== 'opened'" @click="selectPalletToClose(pallet.id)">
                  {{ $t("close") }} <span class="lowercase">{{ pallet.type.title }}</span>
                </core-button>
              </div>
              <core-list-new-table
              class="col-span-14"
              v-if="selectedPallet == pallet.id && shownPallet.shipments"
              :items="shownPallet.shipments"
              :headers="headers"
              :loading="loading"
              :order-by.sync="options.orderBy"
              :order-dir.sync="options.orderDir"
              :current-page.sync="options.page"
              :selected="selected"
              :show-header-actions="false"
              :excluded="excluded"
              :selection-key="selectionKey"
              :total-items="shownPallet.shipments.length"
              :select-all.sync="selectAll"
              :entity-name="name"
              :empty-list-icon="icon"
              :page-size="options.itemsPerPage"
              :paginate="false"
              push-path="labels_details"
              @checkbox-change="handleSelection"
            >
              <template v-slot:[`column.receiver.name`]="{ item }">
                <core-list-cell-templates-receiver-with-flag :name="item.receiver.name" :country-code="item.receiver.country" />
              </template>
              <template v-slot:[`column.package_number`]="{ item }">
                <div style="cursor: copy" @click.stop="$clipboard(item.package_number)">
                  {{ item.package_number }}
                </div>
              </template>

              <template v-slot:[`column.labelless_code`]="{ item }">
                <div style="cursor: copy" @click.stop="$clipboard(item.labelless_code)">
                  {{ item.labelless_code }}
                </div>
              </template>

              <template v-slot:[`column.measurement`]="{ item }">
                <div>
                  {{ item.height + "cm x " + item.width + "cm x " + item.length + "cm" }}
                </div>
              </template>

              <template v-slot:[`column.carrier`]="{ item }">
                <core-list-cell-templates-carrier-with-image :carrier="item.carrier" :url="URL_PREFIX" />
              </template>
            </core-list-new-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <core-dialog id="create-pallet" :visible="createPalletvisible" :title="$t('create_pickup')" :use_default_breakpoints="false" :lg_width="40" :xl_width="50" :md_width="70" :xs_width="90" :destroy-on-close="true" :close-on-click-modal="false" @update:visible="createPalletvisible = false, createStep = 1">
      <div v-if="createStep == 1" class="w-full">
        <div class="mt-3 px-5">
          <p v-if="Object.keys(carriers).length > 1" class="mb-1 mt-3">
            {{ $t("select") }} <span class="lowercase">{{ $t("carrier") }}</span>
          </p>
          <core-select v-if="Object.keys(carriers).length > 1" class="w-full max-w-full" :options="carriers" :value="pallet.carrier" label-key="title" value-key="slug" @input="(val) => setCarrierAndPallets(val)" />
        </div>
        <div class="mt-3 px-5">
          <p class="mb-1 mt-3">
            {{ $t("select") }} <span class="lowercase">{{ $t("type") }}</span>
          </p>
          <core-select class="w-full max-w-full" :options="palletTypes" :value="pallet.type" label-key="label" value-key="key" @input="(val) => (pallet.type = val)" />
        </div>
        <div class="grid w-full grid-cols-2 gap-5">
          <core-date-picker-tailwind class="col-span-1 mt-3 pl-5" label="select_pickupdate" :calender-only="true" :model-value="$moment(new Date())._d" @update:modelValue="setDate" />
          <div v-if="carrierSupportsComments" class="col-span-1 pr-5">
            <p class=" text-sm mt-3">{{$t('transfer.send_message')}}</p>
            <p class=" text-xs italic mt-1 break-normal">{{$t('transfer.ex_messages')}}</p>
            <core-input
              class="mt-5"
              inputType="textarea"
              placeholder=""
              :label="$t('message')"
              v-model="pallet.comment"
            />
            <!--<div class="flex w-full pt-3 text-left text-sm">
            <core-checkbox
              style="margin-right: 10px break-normal"
              :value="rememberPickupComment"
              @change="val => rememberPickupComment = val"
            />{{$t('transfer.remember_message')}}</div> -->
            <!-- <div class="flex w-full pt-6 text-base font-semibold">
            <core-checkbox
              style="margin-right: 10px"
              :value="pallet.palletSwap"
              @change="val => pallet.palletSwap = val"
            />Ønsker du et pallebyt (12 kr.)</div>
            <p class="whitespace-pre-line text-sm break-normal mt-1">Med pallebyt giver vi dig en ny palle, når vi henter din levering. På den måde sikre
              du, at du altid har en palle til din næste levering.</p>
            <p class="whitespace-pre-line text-sm break-normal mt-3">Pallebyt koster 12 kr. og vil blive lagt over din afhentningspris</p>
            -->
          </div>
          <div v-if="dateError" class="text-red-500 pb-3">{{$t('transfer.date_error')}}</div>
        </div>
      </div>
      <div v-if="createStep == 2" class="w-full p-5">
        <p class="text-center font-bold mb-5">{{$t('transfer.before_continuing')}}</p>
        <p class="text-center mb-3">{{$t('transfer.beware')}}</p>
        <div class="w-full bg-gray-200 rounded-md p-5 flex mb-3">
          <div class="mt-2 mr-2 h-8 w-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
              <path d="M223.68,66.15,135.68,18a15.88,15.88,0,0,0-15.36,0l-88,48.17a16,16,0,0,0-8.32,14v95.64a16,16,0,0,0,8.32,14l88,48.17a15.88,15.88,0,0,0,15.36,0l88-48.17a16,16,0,0,0,8.32-14V80.18A16,16,0,0,0,223.68,66.15ZM128,32l80.34,44-29.77,16.3-80.35-44ZM128,120,47.66,76l33.9-18.56,80.34,44ZM40,90l80,43.78v85.79L40,175.82Zm176,85.78h0l-80,43.79V133.82l32-17.51V152a8,8,0,0,0,16,0V107.55L216,90v85.77Z"></path>
            </svg>
          </div>
          <div>
            <p>{{$t('transfer.packages')}}</p>
            <p class="text-gray-500 break-normal">{{$t('transfer.packages_description')}}</p>
          </div>
        </div>
        <div class="w-full bg-gray-200 rounded-md p-5 flex mb-3">
          <div class="mt-2 mr-2 h-8 w-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
              <path d="M128,152a12,12,0,1,1,12-12A12,12,0,0,1,128,152Zm104-24A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128ZM40,128v.33a135.93,135.93,0,0,1,176,0V128a88,88,0,0,0-176,0Zm67.5,85.58L90.45,168H49.63A88.35,88.35,0,0,0,107.5,213.58ZM128,216c.83,0,1.66,0,2.49,0l20.07-53.57a16.07,16.07,0,0,1,15-10.39h47.12c.38-1.31.72-2.64,1-4a120,120,0,0,0-171.4,0c.31,1.34.65,2.67,1,4H90.45a16.08,16.08,0,0,1,15,10.4l20,53.56C126.31,216,127.15,216,128,216Zm78.37-48H165.55l-17.09,45.59A88.34,88.34,0,0,0,206.37,168Z"></path>
            </svg>
          </div>
          <div>
            <p>{{$t('transfer.tires')}}</p>
            <p class="text-gray-500 break-normal">{{$t('transfer.tires_description')}}</p>
          </div>
        </div>
        <div class="w-full bg-gray-200 rounded-md p-5 flex mb-3">
          <div class="mt-2 mr-2 h-8 w-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
              <path d="M120,136V96a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,24a12,12,0,1,0,12,12A12,12,0,0,0,128,160ZM96,16h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM200,56V224a24,24,0,0,1-24,24H80a24,24,0,0,1-24-24V56A24,24,0,0,1,80,32h96A24,24,0,0,1,200,56Zm-16,0a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8V224a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8Z"></path>
            </svg>
          </div>
          <div>
            <p>{{$t('transfer.batteries')}}</p>
            <p class="text-gray-500 break-normal">{{$t('transfer.batteries_description')}}</p>
          </div>
        </div>
        <div class="w-full bg-gray-200 rounded-md p-5 flex mb-3">
          <div class="mt-2 mr-2 h-8 w-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
              <path d="M48.07,104H207.93a16,16,0,0,0,15.72-19.38C216.22,49.5,176,24,128,24S39.78,49.5,32.35,84.62A16,16,0,0,0,48.07,104ZM128,40c39.82,0,74.21,20.61,79.93,48H48.07L48,87.93C53.79,60.61,88.18,40,128,40ZM229.26,152.48l-41.13,15L151,152.57a8,8,0,0,0-5.94,0l-37,14.81L71,152.57a8,8,0,0,0-5.7-.09l-44,16a8,8,0,0,0,5.47,15L40,178.69V184a40,40,0,0,0,40,40h96a40,40,0,0,0,40-40v-9.67l18.73-6.81a8,8,0,1,0-5.47-15ZM200,184a24,24,0,0,1-24,24H80a24,24,0,0,1-24-24V172.88l11.87-4.32L105,183.43a8,8,0,0,0,5.94,0l37-14.81,37,14.81a8,8,0,0,0,5.7.09l9.27-3.37ZM16,128a8,8,0,0,1,8-8H232a8,8,0,0,1,0,16H24A8,8,0,0,1,16,128Z"></path>
            </svg>
          </div>
          <div>
            <p>{{$t('transfer.foods')}}</p>
            <p class="text-gray-500 break-normal">{{$t('transfer.foods_description')}}</p>
          </div>
        </div>
        <div class="w-full bg-gray-200 rounded-md p-5 flex mb-3">
          <div class="mt-2 mr-2 h-8 w-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
              <path d="M224,152V96a8,8,0,0,0-8-8H168V40a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8v64h0v56a8,8,0,0,0,8,8H88v48a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V152Zm-68.69,56L48,100.69V59.31L196.69,208Zm-96-160h41.38L208,155.31v41.38ZM208,132.69,179.31,104H208Zm-56-56L123.31,48H152ZM48,123.31,76.69,152H48Zm56,56L132.69,208H104Z"></path>
            </svg>
          </div>
          <div>
            <p>{{$t('transfer.bound')}}</p>
            <p class="text-gray-500 break-normal">{{$t('transfer.bound_description')}}</p>
          </div>
        </div>
        <div class="max-w-md mx-auto flex">
          <div class="mt-5 mr-2 h-8 w-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#6B7280" viewBox="0 0 256 256">
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
            </svg>
          </div>
          <div>
            <p class="text-gray-500 break-normal pb-10 pt-3">{{$t('transfer.length_warning')}}</p>
          </div>
        </div>
      </div>
      <div slot="footer" style="width: 100%" class="dialog-footer mt-2 flex justify-end">
        <core-button v-if="createStep == 1" type="coolrunner" class="ml-2" :disabled="formOkay" :loading="loading" @click="createStep = 2">
          {{ $t("order_pickup") }}
        </core-button>
        <core-button v-if="createStep == 2" type="coolrunner" class="ml-2" :disabled="orderDisabled" :loading="loading" @click="orderPallet()">
          {{ $t("accept") }}
        </core-button>
      </div>
    </core-dialog>
    <core-dialog id="create-pallet" :visible="closePalletVisible" :title="$t('close_pickup')" :use_default_breakpoints="false" :lg_width="40" :xl_width="50" :md_width="70" :xs_width="90" :destroy-on-close="true" :close-on-click-modal="false" @update:visible="closePalletVisible = false">
      <div class="w-full">
        <div class="grid w-full grid-cols-2 justify-items-stretch gap-5 pb-10">
          <div class="col-span-2 w-full text-center">
            <p class="mt-3 mb-3 text-center text-xl">
              {{ $t("pickup_you_sure") }}
            </p>
            <p class="text-center">
              {{ $t("pickup_warning") }}
            </p>
          </div>
          <div class="mt-2 w-[100px] cursor-pointer justify-self-end rounded-md bg-[#004062] py-2 px-3 font-semibold text-white transition-all duration-300 hover:bg-[#0a5075]" @click="closePallet()">
            {{ $t("yes") }}
          </div>
          <div class="mt-2 w-[100px] cursor-pointer justify-self-start rounded-md border border-[#004062] bg-white py-2 px-3 font-semibold text-[#004062] transition-all duration-300 hover:bg-[#004062] hover:text-white" @click="closePalletVisible = false">
            {{ $t("no") }}
          </div>
        </div>
      </div>
    </core-dialog>
    <core-dialog id="cancel-pallet" :visible="cancelPalletVisible" :title="$t('cancel')" :use_default_breakpoints="false" :lg_width="40" :xl_width="50" :md_width="70" :xs_width="90" :destroy-on-close="true" :close-on-click-modal="false" @update:visible="cancelPalletVisible = false">
      <div class="w-full">
        <div class="grid w-full grid-cols-2 justify-items-stretch gap-5 pb-10">
          <div class="col-span-2 w-full text-center">
            <p class="mt-3 mb-3 text-center text-xl">
              {{ $t("pickup_cancel_you_sure") }}
            </p>
          </div>
          <div class="mt-2 w-[100px] cursor-pointer justify-self-end rounded-md bg-[#004062] py-2 px-3 font-semibold text-white transition-all duration-300 hover:bg-[#0a5075]" @click="cancelPallet()">
            {{ $t("yes") }}
          </div>
          <div class="mt-2 w-[100px] cursor-pointer justify-self-start rounded-md border border-[#004062] bg-white py-2 px-3 font-semibold text-[#004062] transition-all duration-300 hover:bg-[#004062] hover:text-white" @click="cancelPalletVisible = false">
            {{ $t("no") }}
          </div>
        </div>
      </div>
    </core-dialog>
    <core-dialog id="create-pallet" :visible="refillVisible" :title="$t('autofill_saldo')" :use_default_breakpoints="false" :lg_width="40" :xl_width="50" :md_width="70" :xs_width="90" :destroy-on-close="true" :close-on-click-modal="false" @update:visible="refillVisible = false">
      <div class="grid grid-cols-12 gap-5 p-10">
        <div class="col-span-12 sm:col-span-4">
          <core-number
            :label="$t('minimum_amount')"
            style="margin-top: 2px; margin-right: 10px;"
            :min="50"
            v-model="autofill.autofill_threshold"
          />
        </div>
        <div class="col-span-12 sm:col-span-4">
          <core-number
            :label="$t('amount_to_fill')"
            style="margin-top: 2px; margin-right: 10px;"
            :min="50"
            v-model="autofill.autofill_amount"
          />
        </div>
        <div class="col-span-12 sm:col-span-4 flex items-center">
          <core-switch
            :label="$t('enabled')"
            v-model="autofill.autofill"
            :active-value="1"
            :inactive-value="0"
          />
        </div>
        <div class="col-span-12">
          <core-button
            type="coolrunner"
            :disabled="!autofill.autofill_threshold || !autofill.autofill_amount || !autofill.autofill"
            @click="updateAutofill"
            >{{ $t("save") }}
          </core-button>
        </div>
      </div>
    </core-dialog>
  </core-view>
</template>

<script>
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";
import { URL_PREFIX, API_PREFIX } from "@/utils/constants";
import PdfDownloader from "@/mixins/PdfDownloader";
import Payment from "@/mixins/Payment";
import _ from "lodash";
import moment from "moment";

export default {
  name: "OpenPallets",
  mixins: [List, PdfDownloader, Payment],
  data: () => ({
    icon: ["fal", "box-alt"],
    trackPackage: null,
    selectionKey: "id",
    createPalletvisible: false,
    cancelPalletVisible: false,
    closePalletVisible: false,
    refillVisible: false,
    selectedPallet: null,
    shownPallet: {},
    palletToClose: null,
    palletToCancel: null,
    acceptedStart: false,
    available: false,
    paymentEndpoint: "account/payment",
    autofill_missing: false,
    autofill_threshold: false,
    missing_subscription: false,
    localPickupTime: null,
    localPickupCity: null,
    carriers: [],
    createStep: 1,
    rememberPickupComment: false,
    carrierSupportsComments: false,
    dateError: false,
    pallet: {
      type: "",
      carrier: "dfm",
      pickup_date: null,
      comment: null
    },
    autofill: {
      autofill_threshold: null,
      autofill_amount: null,
      autofill: false
    },
    palletTypes: [],
    headers: [
      {
        label: "date",
        key: "created_at",
        type: "date",
        minWidth: "11",
      },
      {
        label: "receiver",
        key: "receiver.name",
        orderableKey: "receiver_name",
        minWidth: "11",
      },
      {
        label: "carrier",
        key: "carrier",
        type: "carrier_image",
        minWidth: "11",
      },
      {
        label: "labelless_code",
        key: "labelless_code",
        minWidth: "10",
      },
      {
        label: "package_number",
        key: "package_number",
        minWidth: "12",
      },
      {
        label: "reference",
        key: "reference",
        minWidth: "11",
      },
      {
        label: "measurement",
        key: "measurement",
        minWidth: "11",
      },
    ],
  }),
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    API_PREFIX: () => API_PREFIX,
    delay: function () {
      return this.items.length * 0.03;
    },
    formOkay: function () {
      if (!this.pallet.type || !this.pallet.pickup_date) {
        return true;
      } else {
        return false;
      }
    },
    groupedPallets: function () {
      let grouped = [
        {
          label: `${this.$tc("todays_shipment", 99)} | ${moment().format("DD/MM/YYYY")} | ${this.$t("pickup")} ${this.localPickupTime}, ${this.localPickupCity}`,
          items: [],
          index: 0,
          id: 'today'
        },
        {
          label: this.$tc("future_shipment", 99),
          items: [],
          index: 1,
          id: 'future'
        },
        {
          label: `${this.$tc("previous_shipment", 99)}`,
          items: [],
          index: 2,
          id: 'past'
        }
      ];

      _.each(this.items, (pallet) => {
        if (moment().diff(pallet.pickup_booked_for, 'days') >= 1) {
          grouped[2].items.push(pallet); 
        } else if (moment().diff(pallet.pickup_booked_for, 'days') < 0) {
          grouped[1].items.push(pallet);
        } else {
          if (moment().diff(pallet.pickup_booked_for, 'hours') < 0) {
            grouped[1].items.push(pallet);
          } else {
            grouped[0].items.push(pallet);
          }
        }
      });

      grouped = _.filter(grouped, (group) => {
        if (group.items.length == 0) {
          return false;
        } else {
          return true;
        }
      });

      return grouped;
    }
  },
  created() {
    if (this.$store.state.breakpoint.isSmall || this.$store.state.breakpoint.noMatch) {
      this.headers = [
        {
          label: "date",
          key: "created_at",
          type: "date",
        },
        {
          label: "receiver",
          key: "receiver.name",
          orderableKey: "receiver_name",
        },
        {
          label: "package_number",
          key: "package_number",
        },
      ];
    } else if (this.$store.state.account?.level == "vip") {
      this.headers = [
        {
          label: "date",
          key: "created_at",
          type: "date",
          minWidth: "11",
        },
        {
          label: "receiver",
          key: "receiver.name",
          orderableKey: "receiver_name",
          minWidth: "11",
        },
        {
          label: "carrier",
          key: "carrier",
          type: "carrier_image",
          minWidth: "11",
        },
        {
          label: "package_number",
          key: "package_number",
          minWidth: "12",
        },
        {
          label: "reference",
          key: "reference",
          minWidth: "11",
        },
        {
          label: "measurement",
          key: "measurement",
          minWidth: "11",
        },
      ];
    }
  },
  mounted() {
    if (this.$route?.query?.state == 'intro') {
      this.setTempData()
    } else {
      this.getPickupTimes();
      this.checkAvailability();
      if (this.$store.state.account.enabled_services.transfer_enabled) {
        this.selectAll = true;
        this.getCreate();
        this.getPallets();
        this.pallet.pickup_date = this.$moment().format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    addCard: function() {
      this.$put(`${this.paymentEndpoint}/card`, {
        final_url: window.location.origin
      })
        .then(response => {
          let payment_window = this.popup(response.redirect_url);
          this.$on(`payment-${payment_window}:success`, () => {
            this.checkAvailability();
          });
        })
        .catch(e => {
          this.$notification({
            title: this.$t("error"),
            text: `${this.$t(e)}`,
            type: "warning"
          });
        });
    },
    getPickupTimes: function () {
      this.$get(`/transfer/pickup-times`)
          .then((response) => {
            Object.entries(response.pickup_times).forEach((entry) => {
              const [key, value] = entry;
              if (key == this.$store.state.account.shipping_address.zip_code) {
                this.localPickupTime = value.time;
                this.localPickupCity = value.city;
              }
            });
            if (!this.localPickupTime) {
              this.localPickupTime = response.default;
            }
          })
          .catch((e) => {})
          .finally(() => {
            this.loading = false;
          });
    },
    checkAvailability: function () {
      this.autofill_missing = false;
      this.autofill_threshold = false;
      this.missing_subscription = false;
      this.loading = true;
      this.$get(`/transfer/availability`)
          .then((response) => {
            this.available = response.available;
            if (this.$_.includes(response.errors, 'autofill_missing')) {
              this.autofill_missing = true;
            }
            if (this.$_.includes(response.errors, 'autofill_threshold')) {
              this.autofill_threshold = true;
            }
            if (this.$_.includes(response.errors, 'missing_subscription')) {
              this.missing_subscription = true;
            }
          })
          .catch((e) => {})
          .finally(() => {
            this.loading = false;
          });
    },
    updateAutofill: function() {
      this.loading = true;
      this.$put(`${this.paymentEndpoint}/balance/autofill`, this.autofill)
        .then(response => {
          this.$notification({
            title: this.$t("saved_correctly")
          });
        })
        .catch(e => {
          this.$notification({
            title: this.$t("error"),
            text: `${this.$t(e)}`,
            type: "warning"
          });
        })
        .finally(() => {this.loading = false, this.refillVisible = false, this.checkAvailability();});
    },
    startTransfer: function () {
      this.$post(
        `/delivery-methods`,
        {delivery_method: 'dfm', customer_id: this.$store.state.account.id},
        {
          failSilently: true
        }
      ).then((response) => {
        this.$notification({
          title: this.$t("success"),
          type: "success"
        });
        this.$store.dispatch("loadAccount");

      })
      .catch(e => {
        this.$notification({
          title: this.$t("error"),
          text: e.response.data.status,
          type: "error"
        });
      })
      .finally(() => {
      });
    },
    setCarrierAndPallets: function (val) {
      this.pallet.carrier = val;
      let containerArray = [];
      this.carrierSupportsComments = this.$_.includes(this.carriers[val].features, 'comment');
      Object.entries(this.carriers[val].supported_container_types).forEach((entry) => {
        const [key, value] = entry;
        containerArray.push({ key: key, label: value });
      });
      this.palletTypes = containerArray;
    },
    convertUTCToLocal: function (val, type) {
      return convertUTCToLocal(val, type);
    },
    getPallet: function (id) {
      if (this.$route?.query?.state == 'intro') {
        this.selectedPallet = 1020;
        this.shownPallet = {
          shipments: [{
            carrier: "bring",
            created_at: "2023-05-16T12:00:23.000000Z",
            height: 10,
            id: 47051666,
            length: 10,
            package_number: "00340434698571755728",
            receiver: {
              attention: null,
              city: "Testby",
              country: "DK",
              email: "test@homerunner.com",
              name: "Peter Test",
              notify_email: null,
              notify_sms: null,
              phone: "12345678",
              state: null,
              street1: "Testgade 1",
              street2: "",
              zip_code: "9000"
            },
            reference: "123456",
            width: 10
          }],
          state: "opened"
        }
        this.loading = false;
      } else {
        if (this.selectedPallet == id) {
        this.selectedPallet = null;
        } else {
          this.loading = true;
          this.selectedPallet = id;
          this.$get(`/transfer/containers/${id}`)
            .then((response) => {
              this.shownPallet = response;
            })
            .catch((e) => {})
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    closePallet: function () {
      this.createPalletvisible = false;
      this.loading = true;
      this.$put(`/transfer/containers/${this.palletToClose}/close`, {}, { failSilently: true })
        .then((response) => {
          this.$notification({
            title: this.$t("Afhentning er lukket"),
            text: ``,
            duration: 2000,
          });
          this.closePalletVisible = false;
          this.getPallets();
        })
        .catch((e) => {
          this.$notification({
            title: this.$t("error"),
            text: e.response.data.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    cancelPallet: function () {
      this.cancelPalletVisible = false;
      this.loading = true;
      this.$del(`/transfer/containers/${this.palletToCancel}`, {}, { failSilently: true })
        .then((response) => {
          this.$notification({
            title: this.$t("Afhentning er slettet"),
            text: '',
            duration: 2000,
          });
          this.getPallets();

          this.cancelPalletVisible = false;
        })
        .catch((e) => {
          this.$notification({
            title: this.$t("error"),
            text: e.response.data.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    selectPalletToClose(id) {
      this.closePalletVisible = true;
      this.palletToClose = id;
    },
    selectPalletToCancel(id) {
      this.cancelPalletVisible = true;
      this.palletToCancel = id;
    },
    orderPallet: function () {
      this.loading = true;
      const id = Date.now();
      this.$post(`/transfer/containers`, this.pallet)
        .then((r) => {
          this.$notification({
            id: id,
            title: this.$t("Afhentning oprettet"),
            text: '',
            duration: 2000,
          });
          this.createPalletvisible = false;
          this.loading = false;
          this.createStep = 1;
          this.getPallets();
          if (r?.pdf?.available && !r?.pdf?.prints_using_print_service) {
            this.printLabel(r.id)
            };
        })
        .catch((e) => {
          this.$notification({
            id: id,
            title: this.$t("Fejl"),
            text: e,
            duration: 1000,
          });
          this.loading = false;
          this.createStep = 1;
        })
        .finally(() => {
          this.loading = false;
          this.pallet.pickup_date = null;
          this.createStep = 1;
        });
    },
    getPdfUrl: function (id) {
      return `${URL_PREFIX}/business-panel/transfer/containers/${id}/pdf?access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`;
    },
    printLabel: function (id) {
      this.downloadPDF(this.getPdfUrl(id));
    },
    getCreate: function () {
      this.loading = true;

      this.$get(`/transfer/describe`)
        .then((response) => {
          let containerArray = [];
          let carrier = null;
          let comments = false;
          this.$_.forIn(response.carriers, function (value, key) {
            let first = false;
            if (!first && !Array.isArray(value.supported_container_types)) {
              carrier = key;
              comments = value.features.includes("comment");
              Object.entries(value.supported_container_types).forEach((entry) => {
                const [key, value] = entry;
                containerArray.push({ key: key, label: value });
                first = true;
              });
            }
          });
          this.carrierSupportsComments = comments
          this.palletTypes = containerArray;
          this.carriers = response.carriers;
          this.pallet.carrier = carrier;
          this.loading = false;
        })
        .catch((e) => {});
    },
    getPallets: function () {
      this.$get(`/transfer/containers`, { state: "opened,not_closed" })
        .then((response) => {
          this.items = response.data;
        })
        .catch((e) => {});
    },
    setDate(val) {
      this.dateError = false
      if (this.$moment(val).diff(this.$moment(), 'days') >= 7) {
        this.dateError = true
      } else {
        this.pallet.pickup_date = this.$moment(val).format("YYYY-MM-DD");
      }
    },
    calcCountdown: function (val, key) {
      let carrier = this.carriers[val]
      if (!carrier) {
        return 'Unknown'
      } else {
        if (key == 'calc') {
          let date = this.$moment(carrier.next_cutoff).format(`YYYY-MM-DD HH:mm`);
          return `Cutoff ${this.$moment().to(date)}`;
        }
        if (key == 'raw') {
          return carrier.next_cutoff
        }
      }
    },
    setTempData: function () {
      this.available = true;
      this.carrierSupportsComments = true;
      this.palletTypes = [
        {key: 'Pallet', label: 'Palle'},
        {key: 'HALF_PALLET', label: 'Halvpalle'},
        {key: 'QUARTER_PALLET', label: 'Kvartpalle'},
        {key: 'BAG', label: 'Sæk'},
        {key: 'BOX', label: 'Kasse'},
      ]
      this.localPickupTime = '13:00';
      this.localPickupCity = 'Testby';
      this.carriers = {dfm: {next_cutoff: `${this.$moment().format("YYYY-MM-DD")} 13:00:00`}}
      this.items = [
        {
          arrived_at: null,
          carrier: {slug: "dfm", title: "Danske Fragtmænd"},
          closed_at: null,
          comment: "Du skal gå ind af port 3 og taste koden 1252",
          consignment_number: "EC123456",
          id: 1020,
          opened_at: "2023-05-16T13:23:45.000000Z",
          pdf: {available: true, prints_using_print_service: false},
          picked_up_at: null,
          pickup_booked_for: this.$moment().format("YYYY-MM-DD"),
          shipments: 5,
          state: "opened",
          type: {slug: "HALF_PALLET", title: "halvpalle"}
        }
      ]
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.tracking-button:hover ~ .tracking-modal {
  opacity: 1;
  z-index: 50;
}
</style>
