<template>
  <div>
    <p class="small content">
      <span
        style="margin-right:4px;"
        :class="`flag-icon flag-icon-${countryCode.toLowerCase()}`"
      />
      {{ name }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ReceiverWithFlagCell",
  props: {
    name: {
      type: String,
      default: ""
    },
    countryCode: {
      type: String,
      default: ""
    }
  }
};
</script>
