import qs from "qs";
import axios from "axios";
import List from "./List";
import { exportIconsMap } from "../utils/constants";
import EventBus from "@/plugins/eventbus";

export default {
  mixins: [List],
  data: () => ({
    viewType: "",
    filters: {},
    cleanedFilters: {},
    exportType: "",
  }),
  mounted() {
    this.init();
  },
  watch: {
    cleanedFilters(val, old) {
      const opts = { ...this.options };
      opts.page = 1;

      this.options = opts;
    },
    options: {
      handler: function(val) {
        this.$router.push({
          path: this.$route.path,
          query: { filters: this.cleanedFilters, page: this.options.page }
        });
      },
      deep: true
    }
  },
  computed: {
    exportActions: function() {
      let exportOptions = this.$_.omitBy(
        this.$store.state.exportOptions,
        (e, key) => key == "document"
      );
      return this.$_.map(exportOptions, (eo, key) => {
        const isGdprAction = this.$_.includes(key, "gdpr");
        const isDocumentAction = this.$_.includes(key, "document");
        const isPrintAction = this.$_.includes(key, "printer");
        const labelKey = isDocumentAction
          ? "generate_type_as"
          : isGdprAction
          ? eo
          : isPrintAction
          ? "print_type"
          : "export_type_as";

        return {
          disabled: !this.selected.length,
          key,
          label: this.$t(labelKey, {
            type: this.$tc("label", 99).toLowerCase(),
            as: eo
          }),
          icon: [
            "far",
            `${exportIconsMap[key.replace(/:[\s\S]*$/, "")] || "file-download"}`
          ],
          action: () =>
            isDocumentAction
              ? this.createDocument(this.exportType, key)
              : isGdprAction
              ? this.DeleteUserdata(this.exportType, key)
              : isPrintAction
              ? EventBus.$emit("SHOW_PRINT_DIALOG", printer =>
                  this.bulkPrint(this.exportType, key, printer)
                )
              : this.bulkExport(this.exportType, key)
        };
      });
    }
  },
  methods: {
    init() {
      this.$on("refresh-data", () => {
        this.getDataFromApi();
      });

      this.$on("set-cleaned-filters", function(val) {
        this.cleanedFilters = val;
      });
      const { filters } = this.$route.query;

      if (!filters) this.cleanedFilters = {};

      if (filters) this.cleanedFilters = filters;

      this.getDataFromApi();
    },
    getDataFromApi() {
      this.itemsLoaded = false
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();

      let endpoint = `/views/paginate${this.search ? "?query=" + this.search : ""}`

      if (this.viewType == 'ticket') {
        endpoint = `ticket/views/paginate${this.search ? "?query=" + this.search : ""}`
      }

      this.loading = true;
      this.$post(
        endpoint,
        {
          filters: this.cleanedFilters,
          type: this.viewType,
          ...this.params
        },
        undefined,
        { cancelToken: this.cancelToken.token }
      )
        .then(response => {
          this.items = response.data;
          this.totalItems = response.total;
          this.loading = false;
          this.itemsLoaded = true
        })
        .catch(e => {
          if (e !== "cancelled") {
            this.loading = false
            this.itemsLoaded = true
          }
        });
    },
    bulkPrint: function(entity, format, printer) {
      if (!this.selected) return;
      const id = Date.now();

      this.$notification({
        id: id,
        title: this.$t("print_started"),
        text: this.$t("bulk_print_should_start", {
          entity: this.$tc(entity, 99).toLowerCase()
        }),
        duration: -1
      });

      let requestObject = {
        include: this.selected,
        exclude: this.excluded,
        type: this.viewType,
        export_options: { printer }
      };

      if (this.selectAll) {
        requestObject = {
          ...requestObject,
          include: [],
          filters: this.cleanedFilters
        };
      }
      this.$post(
        `/views/export?format=${format}${
          this.search ? "&query=" + this.search : ""
        }`,
        requestObject,
        {
          rawResponse: true,
          failSilently: true
        }
      )
        .catch(e => {
          const { data } = e.response;
          this.$readJsonFromBlob(data).then(r => {
            this.$notification({
              title: this.$t("print_failed"),
              duration: 2500,
              type: "error"
            });
          });
        })
        .finally(() => {
          this.$notification.close(id);
        });
    },
    bulkExport: function(entity, format, exportOptions) {
      if (!this.selected) return;
      const id = Date.now();

      this.$notification({
        id: id,
        title: this.$t("export_started"),
        text: this.$t("starting_bulk_export", {
          entity: this.$tc(entity, 99).toLowerCase()
        }),
        duration: -1
      });

      let requestObject = {
        include: this.selected,
        exclude: this.excluded,
        type: this.viewType,
        export_options: exportOptions
      };

      if (this.selectAll) {
        requestObject = {
          ...requestObject,
          include: [],
          filters: this.cleanedFilters
        };
      }
      this.$post(
        `/views/export?format=${format}${
          this.search ? "&query=" + this.search : ""
        }`,
        requestObject,
        {
          rawResponse: true,
          failSilently: true
        },
        { responseType: "blob", timeout: 2 * 60 * 1000 }
      )
        .then(response => {
          this.$notification.close(id);
          const contentDisposition = response.headers["content-disposition"];
          if (contentDisposition) {
            const suggestedFileName = contentDisposition.match(/filename=["']?(?<filename>[^;"'\n\r]+)["']?/).groups.filename;
            let effectiveFileName =
              suggestedFileName === undefined
                ? `${this.$moment().format("yyyy-MM-dd HH:mm:ss")}.${format}`
                : suggestedFileName;

            // Let the user save the file.
            effectiveFileName = effectiveFileName.replace(/["']/g, "");
            this.$fileDownload(response.data, effectiveFileName);
          } else {
            const { data } = response;
            this.$readJsonFromBlob(data).then(r => {
              this.$notification({
                title: this.$t("export_started"),
                text: r.message,
                duration: 2500
              });
            });
          }
        })
        .catch(e => {
          this.$notification.close(id);
          console.log(e, e.response);
          const { data } = e.response;
          this.$readJsonFromBlob(data).then(r => {
            this.$notification({
              title: this.$t("export_failed"),
              duration: 2500,
              type: "error"
            });
          });
        });
    },
    DeleteUserdata: function(entity, format, exportOptions) {
      if (!this.selected) return;
      const id = Date.now();

      this.$notification({
        id: id,
        title: this.$t("delete_started"),
        duration: -1
      });

      let requestObject = {
        include: this.selected,
        exclude: this.excluded,
        type: this.viewType
      };

      if (this.selectAll) {
        requestObject = {
          ...requestObject,
          include: [],
          filters: this.cleanedFilters
        };
      }
      this.$post(
        `/views/export?format=${format}${
          this.search ? "&query=" + this.search : ""
        }`,
        requestObject,
        {
          rawResponse: true,
          failSilently: true
        },
        { responseType: "blob", timeout: 2 * 60 * 1000 }
      )
        .then(response => {
          this.$notification.close(id);
          this.$notification({
            title: this.$t("receiver_data_deleted"),
            duration: 2500
          });
        })
        .catch(e => {
          this.$notification.close(id);
          const { data } = e.response;
          this.$readJsonFromBlob(data).then(r => {
            this.$notification({
              title: this.$t("receiver_data_delete_failed"),
              duration: 2500,
              type: "error"
            });
          });
        });
    },
    createDocument: function(entity, format, exportOptions) {
      let newFormat = format.split(":");
      if (!this.selected) return;
      const id = Date.now();

      this.$notification({
        id: id,
        title: this.$t("generating_document"),
        text: this.$t("generating_document_text", {
          entity: this.$tc(entity, 99).toLowerCase()
        }),
        duration: -1
      });

      let requestObject = {
        include: this.selected,
        exclude: this.excluded,
        type: this.viewType,
        export_options: exportOptions
      };

      if (this.selectAll) {
        requestObject = {
          ...requestObject,
          include: [],
          filters: this.cleanedFilters
        };
      }
      this.$post(
        `/documents/${newFormat[1]}`,
        requestObject,
        {
          rawResponse: true,
          failSilently: true
        },
        { responseType: "blob", timeout: 2 * 60 * 1000 }
      )
        .then(response => {
          this.$notification.close(id);

          const contentDisposition = response.headers["content-disposition"];
          if (contentDisposition) {
            const suggestedFileName = contentDisposition.split("filename=")[1];
            let effectiveFileName =
              suggestedFileName === undefined
                ? `${this.$moment().format("yyyy-MM-dd HH:mm:ss")}.${format}`
                : suggestedFileName;

            // Let the user save the file.
            effectiveFileName = effectiveFileName.replace(/["']/g, "");
            this.$fileDownload(response.data, effectiveFileName);
          } else {
            const { data } = response;
            this.$readJsonFromBlob(data).then(r => {
              this.$notification({
                title: this.$t("document_generated"),
                text: r.message,
                duration: 2500
              });
            });
            this.$router.push("/documents");
          }
        })
        .catch(e => {
          this.$notification.close(id);
          const { data } = e.response;
          this.$readJsonFromBlob(data).then(r => {
            this.$notification({
              title: this.$t("document_failed"),
              duration: 2500,
              type: "error"
            });
          });
        });
    }
  }
};
