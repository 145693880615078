<template>
  <div id="return_shipment_order_container">
    <div class="wrapper">
      <row>
        <column :xs="12" style="margin: auto">
          <core-carrier-logo :carrier="shipment.carrier" />
        </column>
        <column :xs="12">
          <row :gutter="12">
            <column :xs="12" :xl="7">
              <el-card style="height: 100%;">
                <h3>{{ $t("order_summary") }}</h3>
                <p>
                  <b>{{ $tc("order") }}: </b>
                  <i>{{ $t("pending") }}</i>
                </p>
                <p>
                  <b>{{ $t("order_date") }}:</b>
                  {{ convertUTCToLocal(undefined, "datetime") }}
                </p>
                <p>
                  <b>{{ $t("order_total") }}:</b>
                  <span v-if="$store.state.account.business">
                    {{ $n(shipment.price.excl_tax, "currency", "da-DK") }}
                  </span>

                  <span v-else>
                    {{ $n(shipment.price.incl_tax, "currency", "da-DK") }}
                  </span>
                </p>
              </el-card>
            </column>
            <column :xs="12" :xl="5">
              <el-card style="height: 100%;">
                <h3>{{ $t("entity_address", { entity: $t("sender") }) }}</h3>
                <p>{{ shipment.sender.name }}</p>
                <p>{{ shipment.sender.street1 }}</p>
                <p>
                  {{ shipment.sender.zip_code }}
                  {{ shipment.sender.city }}
                </p>
              </el-card>
            </column>
            <column :xs="12" :xl="7">
              <el-card style="height: 100%;">
                <h3>{{ $t("label_information") }}</h3>
                <p>
                  <b>{{ $t("product") }}:</b> {{ shipment.title }}
                </p>
                <p>
                  <b>{{ $t("package_number") }}: </b> <i>{{ $t("pending") }}</i>
                </p>
                <core-input
                  :label="$t('reference')"
                  style="font-size: 13px;"
                  v-model="shipment.reference"
                  :value="shipment.reference"
                />
              </el-card>
            </column>
            <column :xs="12" :xl="5">
              <el-card style="height: 100%;">
                <h3>{{ $t("entity_address", { entity: $t("receiver") }) }}</h3>
                <p>{{ shipment.receiver.name }}</p>
                <p>{{ shipment.receiver.street1 }}</p>
                <p>
                  {{ shipment.receiver.zip_code }}
                  {{ shipment.receiver.city }}
                </p>
                <p>{{ shipment.receiver.email }}</p>
                <p>{{ shipment.receiver.phone }}</p>
              </el-card>
            </column>
          </row>
        </column>
        <column v-if="shipmentCopy.order_lines.length !== 0" :xs="12" style="margin: auto; padding-left: 12px; padding-right: 12px;">
          <el-card style="height: 100%;">
            <h3>{{ $t("products") }}</h3>
            <div v-for="orderLine, index in shipmentCopy.order_lines" :key="`${orderLine.item_number}_${index}`">
              <div class="grid grid-cols-6 mt-3 gap-1 md:border-0 border md:rounded-none rounded-md p-4 md:p-0">
                <div class="col-span-1 flex justify-center items-center">
                  <div  class="w-[220px] h-[220px] md:w-[45px] md:h-[45px]" :style="`
                      background-size: contain;
                      background-repeat: no-repeat;
                      background-position: center;
                      background-image: url(${getImage(orderLine)})`"
                  />
                </div>
                <div class="col-span-4 md:pb-0 flex flex-col justify-center mr-2">
                  <p style="white-space: break-spaces">{{ getProductTitle(orderLine) }}</p>
                  <p class="text-gray-400 text-xs">{{ orderLine.item_number }}</p>
                </div>
                <div class="col-span-1 relative flex flex-col justify-center border border-gray-200 rounded-md">
                  <label :for="'amount' + index" class="md:hidden pr-3">{{$t("amount")}}</label>
                    <select
                    :id="'qty' + index"
                    name="qty"
                    :value="parseInt(orderLine.qty)"
                    @input="editOrder_lines($event.target.value, index, parseInt(orderLine.qty))"
                    class="border border-blue-500 focus:outline-none rounded-md focus:border-blue-500 focus:shadow-sm h-10 w-full text-sm"
                  >
                    <option value="0" selected>0</option>
                    <option v-bind:key="'number_' + index" v-for="element, index in setQty(orderLine.qty)">
                      {{ index + 1 }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </el-card>
        </column>
      </row>
    </div>
  </div>
</template>

<script>
import { convertUTCToLocal } from "@/utils/date";
import { URL_PREFIX } from "@/utils/constants";

export default {
  name: "Step2",
  props: {
    step: {
      type: Number,
      default: 2
    },
    shipment: {
      type: Object,
      default: () => {
        return {
          title: null,
          carrier: null,
          order: {
            id: null,
            sales_price: null
          },
          shipment: {
            package_number: null,
            carrier: "pdk",
            receiver: {
              name: null,
              company: null,
              street1: null,
              zip_code: null,
              city: null
            },
            price: {
              incl_tax: 0,
              excl_tax: 0
            }
          }
        };
      }
    }
  },
  data() {
    return {
      shipmentCopy: {
        order_lines: []
      },
    }
  },
  watch: {
    step: function (n,o) {
      if (n === 3) {
        this.shipmentCopy = { order_lines: []}
      }
    }
  },
  mounted() {
    this.shipmentCopy = this.$_.cloneDeep(this.shipment)
  },
  methods: {
    getImage: function (val) {
      if (val.image_url) {
        return val.image_url
      } else {
        return 'https://coolrunner-assets.s3.eu-central-1.amazonaws.com/public/img/shop_placeholder.png'
      }
    },
    getProductTitle: function (product){
      return product.title || this.$_.get(product, 'customs[0].description') || this.$_.get(product, 'customs.description')
    },
    convertUTCToLocal: function(val, type) {
      return convertUTCToLocal(val, type);
    },
    setQty: function (val) {
      let value = parseInt(val)
      return value
    },
    editOrder_lines: function(val, index, amount) {
      this.shipment.order_lines[index].qty = val
      if (this.shipment.order_lines[index].customs) {
        this.shipment.order_lines[index].customs[0].weight = (this.shipment.order_lines[index].customs[0].weight / amount) * val
        this.shipment.order_lines[index].customs[0].total_price = (this.shipment.order_lines[index].customs[0].total_price / amount) * val
      }
    }
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX
  }
};
</script>

<style scoped></style>
