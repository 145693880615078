<template>
  <div>
    <div class="grid grid-cols-2 justify-items-center">
      <core-date-picker-tailwind
        class="mb-4 col-span-1"
        label="select_date_from"
        :calenderOnly="true"
        :modelValue="$moment(new Date()).add(-7, 'days')._d"
        @update:modelValue="setDateFrom"/>
      <core-date-picker-tailwind
        class="mb-4 col-span-1"
        label="select_date_to"
        :calenderOnly="true"
        @update:modelValue="setDateTo"/>
    </div>
    <core-button
      type="coolrunner"
      :disabled="
          !dateFrom ||
          !dateTo
      "
      @click="
        $emit('update-filter', {
          start: dateFrom,
          end: dateTo
        })
      "
      >{{ $t(value ? "update" : "add") }}</core-button
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: null
    }
  },
  data: () => ({
    dateFrom: null,
    dateTo: null
  }),
  created: function() {
    if (this.value && this.$_.isObject(this.value))
      this.calendarData = {
        dateRange: { start: this.value.start, end: this.value.end }
      };
  },
  methods: {
    setDateTo(val) {
       this.dateTo = this.$moment(val).format('YYYY-MM-DD')
    },
    setDateFrom(val) {
       this.dateFrom = this.$moment(val).format('YYYY-MM-DD')
    }
  },
};
</script>
