<template>
  <el-input
    class="core-search core-clipboard"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="value"
    :disabled="disabled"
  >
    <template v-slot:prefix v-if="!hidePrefix">
      <slot name="prefix">
        <button @click="copyToClipboard">
          <font-awesome-icon :icon="prefix_icon" fixed-width />
        </button>
      </slot>
    </template>

    <template v-slot:append>
      <slot name="append">
        <el-button @click="copyToClipboard" class="core-clipboard-button">
          <font-awesome-icon :icon="append_icon" fixed-width />
        </el-button>
      </slot>
    </template>
  </el-input>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "ClipboardInput",
  props: {
    value: {
      type: String,
      default: ""
    },
    prefix_icon: {
      type: Array,
      default: () => ["far", "link"]
    },
    append_icon: {
      type: Array,
      default: () => ["far", "copy"]
    },
    hidePrefix: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: String,
      default: ""
    }
  },
  methods: {
    copyToClipboard() {
      this.$clipboard(this.value);
      this.$notification({
        title: this.$t("copied"),
        text: this.$t("entity_copied_to_clipboard", {
          entity: this.$t("link")
        }),
        type: "success"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.core-clipboard {
  &:disabled {
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: inherit;
    }
  }

  &.loading {
    svg {
      animation: fa-spin 2s infinite linear;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      animation-name: fa-spin;
    }
  }
}

.core-clipboard {
  width: 100%;
  &.core-search {
    width: 100%;
  }

  &::v-deep {
    input {
      height: 4em;
    }

    .el-input__prefix {
      left: 10px;
    }

    .el-input__prefix,
    .el-input-group__append {
      button {
        transition: color 0.5s ease;

        &:hover,
        &.loading {
          color: #157efb;
        }
      }
    }

    .el-input__inner,
    .el-input-group__append {
      border-color: #bec1c4;
      &.el-input__inner {
        background-color: #ebeef2;
      }
    }
  }
}
</style>
