<template>
    <div class="w-64 text-left">
        <label for="datepicker" class="text-gray-700">{{$t(label)}}</label>
        <div class="flex items-center" v-if="!calenderOnly">
        <div class="relative">
            <input type="hidden" name="date" ref="date">
            <core-input id="datepicker"
                        type="text"
                        readonly
                        v-model="datepickerValue"
                        @clicked="showDatepicker = !showDatepicker"
                        @keydown.escape="showDatepicker = false"
                        :placeholder="$t(label)"
                        :hideValidate="hideValidate"
            />
            <div class="absolute top-2 right-0 px-3 py-2">
                <svg class="h-6 w-6 text-gray-400"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                </svg>
            </div>
            <div
                class="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0 z-50"
                style="width: 17rem"
                v-if="showDatepicker"
                v-click-outside="() => showDatepicker = false">
                <div class="flex justify-between items-center mb-2">
                    <div>
                         <select class="text-lg font-bold text-gray-800 border-0 focus:outline-none" style="background-image: none; padding-right: 0; padding-left: 0;" v-model="month" @change="val => getNoOfDays()"><option v-for="(value, index) in MONTH_NAMES" :value="index" :key="`month_` + index">{{ value }}</option></select>
                         <select class="ml-1 text-lg text-gray-600 font-normal border-0 focus:outline-none" style="background-image: none; padding-right: 0; padding-left: 0;" v-model="year" @change="val => getNoOfDays()"><option v-for="(value, index) in years" :key="`year_` + index">{{ value }}</option></select>
                    </div>
                    <div>
                        <button
                            type="button"
                            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                            @click="() => getNoOfDays(true)">
                            <svg class="h-6 w-6 text-gray-500 inline-flex"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
                            </svg>
                        </button>
                        <button
                            type="button"
                            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                            @click="() => getNoOfDays(false)">
                            <svg class="h-6 w-6 text-gray-500 inline-flex"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="flex flex-wrap mb-3 -mx-1">
                    <template v-for="(day, index) in DAYS">
                        <div style="width: 14.26%" class="px-1" :key="index">
                            <div
                                class="text-gray-800 font-medium text-center text-xs">{{day}}</div>
                        </div>
                    </template>
                </div>
                <div class="flex flex-wrap -mx-1">
                    <template v-for="blankday, index in blankdays">
                        <div
                            v-bind:key="'dateBlank' + index"
                            style="width: 14.28%"
                            class="text-center border p-1 border-transparent text-sm"
                        ></div>
                    </template>
                    <template v-for="(date, dateIndex) in no_of_days">
                        <div style="width: 14.28%" class="px-1 mb-1" v-bind:key="dateIndex">
                            <div
                                @click="getDateValue(date)"
                                class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100"
                                :class="{'bg-blue-500 text-white': isToday(date) == true, 'text-gray-700 hover:bg-blue-200': isToday(date) == false }"
                            >{{date}}</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        </div>
        <div class="flex items-center" v-if="calenderOnly">
        <div class="relative h-72">
            <input type="hidden" name="date" ref="date">
            <div
                class="bg-white rounded-lg shadow p-4 absolute top-0 left-0 z-50"
                style="width: 17rem">
                <div class="flex justify-between items-center mb-2">
                    <div>
                         <select class="text-lg font-bold text-gray-800 border-0 focus:outline-none" style="background-image: none; padding-right: 0; padding-left: 0;" v-model="month" @change="val => getNoOfDays()"><option v-for="(value, index) in MONTH_NAMES" :value="index" :key="`month_` + index">{{ value }}</option></select>
                         <select class="ml-1 text-lg text-gray-600 font-normal border-0 focus:outline-none" style="background-image: none; padding-right: 0; padding-left: 0;" v-model="year" @change="val => getNoOfDays()"><option v-for="(value, index) in years" :key="`year_` + index">{{ value }}</option></select>
                    </div>
                    <div>
                        <button
                            type="button"
                            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                            @click="() => getNoOfDays(true)">
                            <svg class="h-6 w-6 text-gray-500 inline-flex"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
                            </svg>
                        </button>
                        <button
                            type="button"
                            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                            @click="() => getNoOfDays(false)">
                            <svg class="h-6 w-6 text-gray-500 inline-flex"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="flex flex-wrap mb-3 -mx-1">
                    <template v-for="(day, index) in DAYS">
                        <div style="width: 14.26%" class="px-1" :key="index">
                            <div
                                class="text-gray-800 font-medium text-center text-xs">{{day}}</div>
                        </div>
                    </template>
                </div>
                <div class="flex flex-wrap -mx-1">
                    <template v-for="blankday, index in blankdays">
                        <div
                            v-bind:key="'dateBlank' + index"
                            style="width: 14.28%"
                            class="text-center border p-1 border-transparent text-sm"
                        ></div>
                    </template>
                    <template v-for="(date, dateIndex) in no_of_days">
                        <div style="width: 14.28%" class="px-1 mb-1" v-bind:key="dateIndex">
                            <div
                                @click="getDateValue(date)"
                                class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100"
                                :class="{'bg-blue-500 text-white': isToday(date) == true, 'text-gray-700 hover:bg-blue-200': isToday(date) == false, 'bg-blue-300 text-white': isSelected(date) == true }"
                            >{{date}}</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    inheritAttrs: false,
    name: "DatePicker",
    emits: ['update:modelValue'],
    props: {
        label: {
            type: String,
            default: 'select_date'
        },
        modelValue: {
            type: Date,
            default: () => new Date()
        },
        hideValidate: {
            type: Boolean,
            default: false
        },
        calenderOnly: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showDatepicker: false,
        datepickerValue: '',
        month: '',
        year: '',
        no_of_days: [],
        blankdays: [],
        markedDate: null
    }),
    mounted: function() {
        this.initDate()
        this.getNoOfDays()
        this.setMarkedDate()
    },
    computed: {
        MONTH_NAMES: () => moment.months(),
        DAYS: () => moment.weekdaysShort(true),
        years: function() {
            let max = new Date().getFullYear()
            let min = max - 9
            let years = []

            for (let i = max+10; i >= min; i--) {
                years.push(i)
            }
            return years.reverse()

        }
    },
    methods: {
        getDatePickerValue: function(date) {
          return this.isToday(date.getDate()) ? this.$t('today') :  moment(date).format('LL')
        },
        initDate() {
            this.month = this.modelValue.getMonth();
            this.year = this.modelValue.getFullYear();
            this.datepickerValue = this.getDatePickerValue(new Date(this.year, this.month, this.modelValue.getDate()));
        },
        setMarkedDate() {
            this.markedDate = new Date(2000, 1, 1);

        },
        isToday(date) {
            const today = new Date();
            const d = new Date(this.year, this.month, date);
            return today.toDateString() === d.toDateString() ? true : false;
        },
        isSelected(date) {
            const d = new Date(this.year, this.month, date);
            return this.markedDate.toDateString() === d.toDateString() ? true : false;
        },
        getDateValue(date) {
            this.markedDate = new Date(this.year, this.month, date);
            this.isSelected(date)
            let selectedDate = new Date(this.year, this.month, date);
            this.$emit('update:modelValue', selectedDate)
            this.datepickerValue = this.getDatePickerValue(selectedDate);
            this.$refs.date.value = selectedDate.getFullYear() +"-"+ ('0'+ selectedDate.getMonth()).slice(-2) +"-"+ ('0' + selectedDate.getDate()).slice(-2);
            this.showDatepicker = false;
        },
        getNoOfDays(increment) {
            if(increment !== undefined)
                if (increment) {
                    if(this.month == 0){
                        this.month = 11
                        this.year--
                    }
                    else this.month--
                }
                else {
                    if(this.month == 11){
                        this.month = 0
                        this.year++
                    }
                    else this.month++
                }
                
            let daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
            // find where to start calendar day of week
            let dayOfWeek = moment(new Date(this.year, this.month)).weekday();
            let blankdaysArray = [];
            for ( let i=1; i <= dayOfWeek; i++) {
                blankdaysArray.push(i);
            }
            let daysArray = [];
            for ( let i=1; i <= daysInMonth; i++) {
                daysArray.push(i);
            }
            this.blankdays = blankdaysArray;
            this.no_of_days = daysArray;
        },
    }
}
</script>
<style scoped>
</style>