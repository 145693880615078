<template>
  <div class="wrapper form">
    <div
      style="display: flex; flex-direction: column; align-items: center; word-break: normal;"
    >
      {{ $t("download_module_desc") }}
      <core-button
        class="m-6"
        type="default_outline"
        @click="window.open(downloadUrl, '_blank')"
        >{{ $t("download_module") }}</core-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newShop: {
      type: Object,
      default: () => ({ data: {} })
    },
    errors: {
      type: Object,
      default: () => ({ data: {} })
    }
  },

  computed: {
    window: () => window,
    shopTypes: function() {
      return this.$store.state.shopSettings;
    },
    downloadUrl: function() {
      return this.shopTypes[this.newShop.type].download_url;
    }
  }
};
</script>
