<template>
  <core-dialog
    class="new-shop-dialog"
    :visible="visible"
    @update:visible="$emit('update:visible')"
    append-to-body
    :title="$t('create_new_shop.title')"
    :close-on-click-modal="false"
  >
    <page-1
      v-if="page === 1"
      :name.sync="newShop.data.name"
      :type.sync="newShop.type"
      @page-ok="setPageStatus"
    />
    <page-2 v-if="page === 2" :newShop="newShop" @page-ok="setPageStatus" />
    <page-3
      v-if="page === 3"
      :newShop="newShop"
      :errors="errors"
      @update:newShop="val => (newShop = val)"
      @page-ok="setPageStatus"
    />
    <page-4
      v-if="page === 4"
      :newShop="newShop"
      @shop-activated="() => $emit('shop-activated')"
    />
    <template v-slot:footer>
      <core-button type="default" v-if="page === 3" @click="prevPage"
        >{{ $t("back") }}
      </core-button>
      <core-button
        type="coolrunner"
        :loading="creating"
        @click="buttonMethod"
        :disabled="(page !== 4 && !nextPage) || !pageOk[page]"
        >{{ buttonText }}
      </core-button>
    </template>
  </core-dialog>
</template>

<script>
import Page1 from "./Page_1";
import Page2 from "./Page_2";
import Page3 from "./Page_3";
import Page4 from "./Page_4";

export default {
  components: { Page1, Page2, Page3, Page4 },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    page: 1,
    pageOk: { 2: true, 4: true },
    newShop: {
      type: "",
      data: {
        name: ""
      }
    },
    errors: {
      data: {}
    },
    pageHistory: [],
    creating: false
  }),
  computed: {
    buttonText: function() {
      switch (this.nextPage) {
        case 2:
        case 3:
          return this.$t("next");
        case 4:
          return this.$t("create");
        default:
          return this.$t("finish");
      }
    },
    buttonMethod: function() {
      switch (this.nextPage) {
        case 2:
          return this.pageForward;
        case 3:
          return this.pageForward;
        case 4:
          return this.createShop;
        default:
          return () => this.$emit("update:visible");
      }
    },
    nextPage: function() {
      if (this.page == 1) {
        if (
          !this.newShop.type ||
          !this.$_.isEmpty(this.$_.omit(this.fields, "name"))
        )
          return 3;

        if (this.newShop.type && this.shopTypes[this.newShop.type].download_url)
          return 2;
        return 4;
      }

      if (this.page == 2 || this.page == 3) return 4;

      return null;
    },
    shopTypes: function() {
      return this.$store.state.shopSettings;
    },
    fields: function() {
      if (!this.newShop.type) return {};
      return this.shopTypes[this.newShop.type].fields || {};
    }
  },
  methods: {
    pageForward: function(page) {
      if (!this.pageOk[this.page]) return;

      const ph = [...this.pageHistory];
      ph.push(this.page);
      this.pageHistory = ph;
      this.page = page || this.nextPage;
    },
    prevPage: function() {
      const ph = [...this.pageHistory];
      this.page = ph.pop();
      this.pageHistory = ph;
    },
    setPageStatus: function(status) {
      const pageOk = { ...this.pageOk };
      pageOk[this.page] = status;
      this.pageOk = pageOk;
    },
    createShop: function() {
      this.creating = true;
      this.errors = {};
      this.$post("shops", this.newShop, { failSilently: true })
        .then(r => {
          this.newShop = r;
          this.pageForward(4);
          this.$emit("shop-created");
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.$notification({
            title: this.$t("shops.error_creating"),
            text: this.$_.join(this.$_.map(response.data.errors), "</br>"),
            type: "error"
          });
        })
        .finally(() => {
          this.creating = false;
        });
    }
  }
};
</script>
