<template>
  <div class="w-full">
    <div class="max-w-full md:max-w-[50%] flex">
        <div class="w-[60%]">
            <core-input
                :label="$t('name')"
                v-model="user.name"
                disabled
            />
            <core-input
                class="mt-3"
                :label="$t('email')"
                v-model="user.email"
                disabled
            />
            <core-input
                class="mt-3"
                :label="$t('phone')"
                v-model="user.phone"
                disabled
            />
        </div>
        <!--<div class="w-[40%] pl-3">
            <div class="border-gray-200 border rounded-md p-5 w-full">
                <img class="w-full h-auto" src="https://a.storyblok.com/f/120110/add31a48d2/t2mas7q6l-ugjl9ly7l-c975104fc9c5-512.jpeg" alt="">
            </div>
        </div>-->
    </div>
    <div class="max-w-full md:max-w-[50%] mt-20">
        <p class="text-lg mb-3">{{$t('organization_and_role')}}</p>
        <div v-for="role, index in roles" :key="'role_' + index" class="bg-gray-100 grid grid-cols-2 px-5 py-4 rounded-md mb-3">
            <p>{{ role.account.name }}</p>
            <div>
              <p v-for="roleName, index in role.userRoles" :key="'name '+ index" class="text-center">{{ roleName }}</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  data: () => ({
    name: 'Profile',
    user: {
    },
    roles: [],
    loading: false,
  }),
  created: function() {
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#account_info--wrapper"
    };
    this.account = { ...this.$store.state.account };
    this.user = { ...this.$store.state.accountRes[0].user };
    this.roles = this.$store.state.accountRes
  },
  watch: {
    "this.$store.state.account": function(val) {
      this.account = val;
    }
  },
  computed: {
    rowActions: function (item) {
        let actions = [
        {
          label: "Rediger",
          action: this.editItem
        },
        {
          label: "Slet",
          action: this.deleteItem
        },
      ];
      return actions;
    }
  },
  methods: {
    editItem: function () {
        this.editVisible = true
    },
    deleteItem: function () {
        this.editVisible = true
    }
  }
};
</script>

<style scoped></style>