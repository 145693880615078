<template>
  <el-time-picker
    class="core-time-picker"
    v-bind="$attrs"
    v-on="$listeners"
    popper-class="core-time-picker-popper"
    size="large"
  />
</template>

<script>
export default {
  inheritAttrs: false
};
</script>

<style lang="scss">
.core-time-picker-popper {
  .el-time-panel__btn.el-time-panel__btn.cancel {
    display: none;
  }
}
</style>
