<template>
  <div class="p-5">
    <row :gutter="10">
      <column
        :xs="6"
        :columns="12"
        v-for="(weight, index) in weightIntervals"
        v-bind:key="weight.value"
      >
        <core-card
          :selected="index === selectedIndex"
          @click="selectWeight(weight, index)"
        >
          <span style="font-weight: bold"> {{ weight.label }}</span>
        </core-card>
      </column>
    </row>
  </div>
</template>

<script>
export default {
  name: "CreateShipmentWeight",
  data: () => ({}),
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    if (this.selectedIndex !== null) this.$emit("set:nextStep", "products");
  },
  computed: {
    weightIntervals: function() {
      return this.$store.state.product_dimensions.weights;
    },
    selectedIndex: function() {
      if (!this.value.weight) return null;
      return this.weightIntervals.findIndex(w => w.value >= this.value.weight);
    }
  },
  methods: {
    selectWeight: function(val) {
      this.$emit("update:value", { ...this.value, weight: val.value });
      this.$emit("set:nextStep", "products");
    }
  }
};
</script>
