import Vue from "vue";
import VueI18n from "vue-i18n";
import countries from "i18n-iso-countries";
import moment from "moment";
import VueNumerals from "vue-numerals";
import "moment/locale/en-gb";
import "moment/locale/de";
import "moment/locale/da";

const getBrowserLocale = function(options = {}) {
  const defaultOptions = { countryCodeOnly: true };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;
  if (!navigatorLocale) {
    return undefined;
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
};

const numberFormats = {
  "da-DK": {
    currency: {
      style: "currency",
      currency: "DKK"
    },
    weight: {
      style: "decimal",
      unit: "gram"
    },
    dimension: {
      style: "decimal",
      unit: "centimeter"
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
};

const dateTimeFormats = {
  "da-DK": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  }
};

Vue.use(VueI18n);

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/da.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));

const messages = {
  en: require("../locales/en.json"),
  da: require("../locales/da.json"),
  de: require("../locales/de.json"),
  sv: require("../locales/sv.json")
};

const userLang = getBrowserLocale() || process.env.VUE_APP_I18N_LOCALE;
moment.locale(userLang);
moment.updateLocale("da", {
  longDateFormat: {
    L: "DD/MM/YYYY",
    l: "D/M-YYYY"
  }
});

Vue.use(VueNumerals, {
  locale: userLang == "da" ? "da-dk" : "en"
});

export default new VueI18n({
  silentTranslationWarn: true,
  locale: userLang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
  numberFormats,
  dateTimeFormats
});
