<template>
  <div>
    {{ label }}
    <el-switch v-bind="$attrs" v-on="$listeners" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
