<template>
  <router-link
    :to="{ name: entry.index, query: entry.query }"
    v-slot="{ href, navigate, isExactActive }"
    @click.native="$emit('entry-click')"
  >
    <a :href="disabled ? '' : href" @click="navigate">
      <li
        role="menuitem"
        class="el-menu-item"
        :class="[
          {
            'is-active': active(isExactActive) || claimsActive,
            'opacity-50 italic cursor-default hover:bg-transparent active:bg-auto': disabled
          }
        ]"
      >
        <div>
          <font-awesome-icon v-if="entry.icon" :icon="entry.icon" fixed-width />
          <span>{{ `${label} ${disabled ? "(TBA)" : ""}` }}</span>
        </div>
      </li>
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ""
    },
    keyProp: {
      type: String,
      default: "index"
    }
  },
  data() {
    return {
      claimsActive: false
    }
  },
  watch:{
      $route (to, from){
        if (to.name == 'labels_details' && from.name == 'claims' && this.entry.index == 'claims') {
          this.claimsActive = true
        }
        if (from.name == 'labels_details' && this.entry.index == 'claims') {
          this.claimsActive = false
        }
      }
    },
  computed: {
    disabled: function() {
      const { route } = this.$router.resolve({
        name: this.entry.index
      });

      return route.meta.disabled;
    }
  },
  methods: {
    active: function(ia) {
      if (this.entry.index === "home") {
        return this.$route.path === "/";
      }
      if (this.entry.index === "labels") {
        return this.$route.name === "labels" || this.$route.name === "labels_details";
      }
      if (this.entry.index === "account_settings") {
        return this.$route.name === "account_settings_info" || this.$route.name === "account_settings_label";
      }
      if (this.entry.index === "returns") {
        return this.$route.name === "returns" || this.$route.name === "returns_portal_id" || this.$route.name === "returns_portal_new";
      }
      if (this.entry.index === "returns_statistics") {
        return this.$route.name === "returns_statistics" || this.$route.name === "returns_details";
      }
      if (this.entry.index === "pickup") {
        return this.$route.name === "pickup";
      }
      if (this.entry.index === "pickup_pallets") {
        return this.$route.name === "pickup_pallets";
      }

      if (this.$route.name.includes(this.entry.index)) {
        return true
      }
      return this.entry.index === this.$route.name || ia;
    }
  }
};
</script>
