<template>
  <core-view :style="{ '--delay': delay}">
    <core-view-header
      :name="'wms_orders'"
      :count="totalItems"
      :routeName="$route.name"
      route="wmsorders"
      showViewName
    >
    </core-view-header>
    <core-list-new-table
    :items="items"
    :headers="headers"
    :wmsSelect="true"
    :list="list"
    :loading="loading"
    :orderBy.sync="options.orderBy"
    :orderDir.sync="options.orderDir"
    :currentPage.sync="options.page"
    :query.sync="search"
    :selected="selected"
    :excluded="excluded"
    :selectionKey="selectionKey"
    :totalItems="totalItems"
    :rowActions="rowActions"
    :bulkActions="bulkActions"
    @checkbox-change="handleSelection"
    @set-cleaned-filters="val => $emit('set-cleaned-filters', val)"
    @set-filters="val => $emit('set-filters', val)"
    :selectAll.sync="selectAll"
    :entityName="name"
    :emptyListIcon="icon"
    :pageSize="options.itemsPerPage"
    pushPath="wmsorders_details"
    >
    <template v-slot:[`column.carrier`]="{ item }">
        <core-list-cell-templates-carrier-with-image
          :carrier="item.data.carrier"
          :url="URL_PREFIX"
        />
      </template>
    <template v-slot:[`column.receiver.name`]="{ item }">
        <core-list-cell-templates-receiver-with-flag
          :name="item.data.receiver.name"
          :countryCode="item.data.receiver.country"
        />
      </template>
    </core-list-new-table>
  </core-view>
</template>

<script>
import I18n from "@/plugins/i18n";
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";
import { URL_PREFIX } from "../../utils/constants";

export default {
  mixins: [List],
  name: "WMSOrdersList",
  data: (vm) => ({
    viewType: "shipments",
    endpoint: "wms/orders",
    exportType: "label",
    name: "shipment",
    icon: ["fal", "box-alt"],
    trackPackage: null,
    selectionKey: "id",
    headers: [
      {
        label: "date",
        key: "created_at",
        type: "date",
        minWidth: "11" },
      {
        label: "receiver",
        key: "receiver.name",
        minWidth: "11"
      },
      {
        label: "order_number",
        key: "customer_order_number",
        minWidth: "11"
      },
      {
        label: "carrier",
        key: "carrier",
        orderableKey: "carrier",
        type: "carrier_image",
        minWidth: "11"
      },
      {
        label: "status",
        key: "status",
        minWidth: "10",
        type: "status"
      },
      {
        label: "warehouse",
        key: "warehouse",
        minWidth: "12",
        type: "warehouse"
      }
    ],
    list: [
      {
        label: vm.$t('passed-validation'),
        value: "passed-validation"
      },
      {
        label: vm.$t('failed-validation'),
        value: "failed-validation"
      },
      {
        label: vm.$t('shipment-created'),
        value: "shipment-created"
      },
      {
        label: vm.$t('shipment-failed'),
        value: "shipment-failed"
      },
      {
        label: vm.$t('sent-to-wms'),
        value: "sent-to-wms"
      },
      {
        label: vm.$t('rejected-by-wms'),
        value: "rejected-by-wms"
      },
      {
        label: vm.$t('accepted-by-wms'),
        value: "accepted-by-wms"
      },
      {
        label: vm.$t('imported-to-wms'),
        value: "imported-to-wms"
      },
      {
        label: vm.$t('cancelled'),
        value: "cancelled"
      },
      {
        label: vm.$t('failed'),
        value: "failed"
      },
      {
        label: vm.$t('duplicate-detected'),
        value: "duplicate-detected"
      },
      {
        label: vm.$t('rebook-failed'),
        value: "rebook-failed"
      },
      {
        label: vm.$t('rebooked'),
        value: "rebooked"
      },
      {
        label: vm.$t('packed'),
        value: "packed"
      },
      {
        label: vm.$t('picked'),
        value: "picked"
      },
      {
        label: vm.$t('unknown-item-number'),
        value: "unknown-item-number"
      },
      {
        label: vm.$t('missing-stock'),
        value: "missing-stock"
      },
      {
        label: vm.$t('warehouse-found'),
        value: "warehouse-found"
      },
      {
        label: vm.$t('warehouse-not-found'),
        value: "warehouse-not-found"
      },
    ]
  }),
  created() {
      this.getDataFromApi()

      if(this.$store.state.breakpoint.isSmall || this.$store.state.breakpoint.noMatch) {
        this.headers = [
      {
        label: "receiver",
        key: "data.receiver.name",
        minWidth: "11"
      },
      {
        label: "order_number",
        key: "order_number",
        minWidth: "11"
      },
      {
        label: "status",
        key: "status",
        minWidth: "10",
        type: "status"
      },
    ]
      }
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    rowActions: function(entity) {
      let actions = [
        {
          label: I18n.t("cancel_entity", {
            entity: I18n.tc(this.name).toLowerCase()
          }),
          icon: ["far", "radar"],
          action: item => {
            this.cancelShipment(item);
          },
        }
      ];
      return actions;
    },
    bulkActions: function() {
      return this.exportActions;
    },
    delay: function() {
        return this.items.length * 0.030
    }
  },
  methods: {
    convertUTCToLocal: function(val, type) {
      return convertUTCToLocal(val, type);
    },
    cancelShipment: function(item) {
      this.$confirm(
        this.$t("confirm_cancellation", {
          type: this.$tc(this.name).toLowerCase()
        })
      ).then(() => {
        this.loading = true;
        this.$del(`/${this.endpoint}/${item.id}`)
          .then(() => {
            this.getDataFromApi();
            this.$notification({
              title: this.$t("success"),
              text: `${this.$tc(this.name)} ${this.$t(
                "has_been_cancelled"
              ).toLowerCase()}`,
              type: "success"
            });
          })
          .catch(e => {
            this.$notification({
              title: this.$t("not_cancelled"),
              text: `${this.$tc(this.name)} ${this.$t(
                "has_not_been_cancelled"
              ).toLowerCase()}`,
              type: "error"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  }
};
</script>