<template>
  <div
    class="core-phone-input border flex border-gray-200 focus:outline-none rounded-md focus:border-coolrunner-500 focus:shadow-sm w-full"
  >
    <core-select-country
      v-if="showCountry"
      :labelKey="'phone_prefix'"
      :valueKey="'phone_prefix'"
      :value.sync="phone_prefix"
      @change="val => $emit('update:phone_prefix', val)"
      :disabled="disabled"
      :bordered="false"
      :label="$t('country')"
      class="border-r rounded-r-none w-40 flex"
    >
      <template v-slot:option="{ option }">
        <span
          style="margin-right:4px;"
          :class="`flag-icon flag-icon-${option.iso.toLowerCase()}`"
        />

        +{{ option.phone_prefix }}
      </template>
    </core-select-country>

    <core-input
      :bordered="false"
      :value="phone"
      :label="phoneLabel"
      @input="val => $emit('update:phone', val)"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    phone_prefix: {
      type: [String, Number],
      default: "45"
    },
    phone: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showCountry: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    phoneLabel: function() {
      return this.label || this.$t("phone");
    }
  }
};
</script>

<style>
.core-phone-input .core-select__country .el-input input {
  @apply rounded-r-none;
}
</style>
