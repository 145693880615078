<template>
  <core-input v-bind="$attrs" :error="error" type="number" v-on="$listeners" />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    error: {
      type: Boolean,
      default: false
    },
  }
};
</script>

<style scoped></style>
