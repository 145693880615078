<template>
  <div
    :class="[
      class_override,
      ekstra_class,
      error ? 'border-red-500' : 'border-gray-200',
      { border: bordered, 'h-14': bordered }
    ]"
    class="rounded-md items-center"
  >
    <el-select
      :class="[
        'flex rounded-md h-full',
        {'w-full items-stretch': full }
      ]"
      v-observe-visibility="visibilityChanged"
      v-bind="$attrs"
      v-on="$listeners"
      :clearable="clearable"
      :disabled="disabled"
      :value-key="valueKey"
      :popper-append-to-body="false"
      :popper-options="{ boundariesElement: 'body', gpuAcceleration: false }"
      :loading="loading"
      :placeholder="label"
      :loading-text="$t('loading')"
      :value="value"
      :multiple="multiple"
      @change="val => $emit('update:value', val)"
      ref="select"
    >
      <template v-for="(index, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
      <el-option
        v-for="item in options"
        :key="getKey(item)"
        :label="getLabel(item)"
        :value="$_.isString(item) || valueIsObject ? item : item[valueKey]"
      >
        <slot name="option" v-bind:option="item" />
      </el-option>
      <infinite-loading
        v-if="paginate"
        @infinite="infinity => $emit('bottom-reached', infinity)"
        force-use-infinite-wrapper=".el-select-dropdown__wrap"
      >
        <template slot="spinner">
          <p class="el-select-dropdown__empty">
            {{ $t("loading") }}
          </p>
        </template>
        <template slot="no-results"><p /></template>
        <template slot="no-more"><p /></template>
      </infinite-loading>
    </el-select>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    InfiniteLoading
  },

  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    value: {},
    labelKey: {
      type: [String, Array, Function],
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    labelFormat: {
      type: String,
      default: null
    },
    listItemKey: {
      type: String,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    class_override: {
      type: String,
      default: "core-select"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    valueIsObject: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    paginate: {
      type: Boolean,
      default: false
    },
    query: {
      type: String,
      default: ""
    },
    defaultToFirst: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: true
    },
    ekstra_class: {
      type: String,
      default: ""
    },
    full: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    options: function(val, old) {
      if (!old?.length && this.defaultToFirst && !this.value) {
        const item = val[0];
        this.$emit(
          "input",
          this.$_.isString(item) || this.valueIsObject
            ? item
            : item[this.valueKey]
        );
      }
    }
  },

  methods: {
    visibilityChanged: function(visible) {
      if (!visible) return;
      if (this.defaultToFirst && !this.value && this.options?.length) {
        const item = this.options[0];
        this.$emit(
          "input",
          this.$_.isString(item) || this.valueIsObject
            ? item
            : item[this.valueKey]
        );
      }
    },
    getLabel(item) {
      if (typeof this.labelKey === "function") {
        return this.labelKey(item);
      } else if (Array.isArray(this.labelKey)) {
        const labelValues = this.$_.map(this.labelKey, lk => item[lk] || lk);
        return this.$_.join(labelValues, " ");
      } else {
        return item[this.labelKey];
      }
    },
    getKey(item) {
      return (
        (this.listItemKey ? item[this.listItemKey] : "") +
        " " +
        (this.$_.isString(item) ? item : item[this.valueKey])
      );
    }
  }
};
</script>

<style lang="scss">
.el-select-dropdown.el-popper {
  margin-top: 0px !important;
  margin-left: -15.6px;
  .popper__arrow {
    display: none;
  }
}
</style>
