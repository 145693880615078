<template>
  <row :gutter="20" class="inner-box row">
    <column
      v-for="product in availableProducts"
      v-bind:key="product.carrier_product_service"
      :xs="12"
    >
      <el-card
        style="cursor: pointer;width:100%"
        :body-style="{}"
        shadow="hover"
        @click.native="$emit('select:product', product.carrier_product_service)"
        :class="{
          selected: product.carrier_product_service === selectedProduct
        }"
      >
        <el-col :span="6" style="height:54px">
          <core-carrier-logo :carrier="product.carrier" />
        </el-col>
        <el-col :span="18">
          <div class="">
            <p class="small" style="">
              <b>{{ $t("title") }}:</b> {{ product.title }}
            </p>
            <p class="small">
              <b>{{ $t("price") }}:</b>
              <span v-if="$store.state.account.business">
                {{
                  $n(product.sales_price_excl_vat, "currency", "da-DK")
                }}</span
              >
              <span v-else>
                {{
                  $n(product.sales_price_incl_vat, "currency", "da-DK")
                }}</span
              >
            </p>
          </div>
        </el-col>
      </el-card>
    </column>
  </row>
</template>

<script>
import { URL_PREFIX } from "@/utils/constants";

export default {
  name: "CreateReturnLabelDialogStep1",
  props: {
    availableProducts: {
      type: Array
    },
    selectedProduct: {
      type: String,
      default: null
    }
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  &.selected {
    border: 1px solid #157efb;
  }
}
</style>
