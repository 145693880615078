<template>
  <div id="main-view" class="w-full" :style="{ '--delay': delay }">
    <div
      class="w-full bg-white h-14 rounded-md flex justify-between px-6 mb-4 shadow-sm"
    >
      <p
        class="group text-left self-center text-lg font-semibold cursor-pointer"
        @click="navigate"
      >
        <font-awesome-icon
          :icon="['far', 'long-arrow-left']"
          class="mr-2 group-hover:animate-bounce-left"
          id="arrow"
        />
        {{ $tc("order", 1) }} {{ $route.params.id }}
      </p>

      <core-list-actions
        :open.sync="actionsVisible"
        :actions="rowActions"
        :entity="entity"
      >
        <template v-slot:activator="">
          <div class="group hidden">
            <p class="text-right text-xs cursor-pointer pt-5">
              <font-awesome-icon
                :icon="['far', 'cog']"
                class="mr-2 group-hover:animate-bounce-once"
              />
              {{ $t("functions") }}
            </p>
          </div>
        </template>
      </core-list-actions>
    </div>

    <div
      class="w-full bg-white h-14 rounded-md flex justify-between px-6 mb-4 shadow-sm hover:shadow transition duration-500 ease-in-out"
      v-if="deleted"
      :style="{ '--i': 2 }"
    >
      <p class="text-left self-center text-lg font-semibold">
        {{ $t("package_deleted") }}
      </p>
    </div>
    <div class="w-full md:flex" v-if="!$_.isEmpty(entity)">
      <div class="w-full md:w-2/3 mr-4">
        <transition appear leave name="detail-animation" mode="in-out">
          <div
            class="bg-white rounded-md shadow-sm w-full pb-5 mb-4 hover:shadow transition duration-500 ease-in-out"
            :style="{ '--i': 1 }"
            v-if="show"
          >
            <h4 class="px-6 pt-5 w-full font-medium text-lg">
              <font-awesome-icon :icon="['far', 'dolly']" class="mr-2" />
              {{ $t("order_info") }}
            </h4>
            <div class="w-full grid grid-cols-3">
              <div class="col-span-1 px-6 pt-5">
                <p class="w-full text-sm">{{ $t("warehouse") }}</p>
                <p class="pt-2">{{ $t(entity.info.warehouse) }}</p>
              </div>
              <div class="col-span-2 md:col-span-1 px-6 pt-5">
                <p class="w-full text-sm">{{ $t("order_number") }}</p>
                <p class="pt-2">{{ entity.info.shop_order_number }}</p>
              </div>
              <div class="col-span-3 md:col-span-1 px-6 pt-5">
                <p class="w-full text-sm">Status</p>
                <p class="pt-2">{{ $t(entity.info.status) }}</p>
              </div>
            </div>
          </div>
        </transition>
        <transition appear name="detail-animation" mode="out-in" v-if="show">
          <div
            class="bg-white rounded-md shadow-sm w-full pb-5 mb-4 hover:shadow transition duration-500 ease-in-out"
            :style="{ '--i': 3 }"
            v-if="entity.data.order_lines.length !==0"
          >
            <h4 class="px-6 pt-5 w-full font-medium text-lg">
              <font-awesome-icon :icon="['far', 'box-open']" class="mr-2" />
              {{ $t("products") }}
            </h4>
            <div
              class="w-full grid grid-cols-3"
              v-for="(product, index) in entity.data.order_lines"
              v-bind:key="index"
            >
              <div class="col-span-2 pl-6 pt-3">
                <p class="text-sm pb-1">{{ $t("item_number") }}</p>
                <core-input
                  readonly
                  :value="product.item_number"
                  class="w-full"
                >
                </core-input>
              </div>
              <div class="col-span-1 px-6 pt-3">
                <p class="text-sm pb-1">{{ $t("number_of_units") }}</p>
                <core-input readonly :value="product.qty" class="w-full">
                </core-input>
              </div>
              <div class="col-span-2 pl-6 pt-3 pb-6">
                <p class="text-sm pb-1">{{ $t("item_description") }}</p>
                <core-input
                  v-if="product.customs"
                  readonly
                  :value="product.customs.description"
                  class="w-full"
                >
                </core-input>
                <core-input
                  v-if="!product.customs"
                  readonly
                  value=""
                  class="w-full"
                >
                </core-input>
              </div>
              <div class="col-span-1 px-6 pt-3 pb-6">
                <p class="text-sm pb-1">{{ $t("number_packed") }}</p>
                <core-input readonly :value="entity.info.order_lines[product.item_number].qty_packed || 0" class="w-full">
                </core-input>
              </div>
            </div>
          </div>
        </transition>
         <transition appear name="detail-animation" mode="out-in">
          <div
            class="bg-white rounded-md shadow-sm w-full pb-5 mb-4 hover:shadow transition duration-500 ease-in-out"
            :style="{ '--i': 4 }"
            v-if="show"
          >
            <h4 class="px-6 pt-5 w-full font-medium text-lg">
              <font-awesome-icon :icon="['far', 'cube']" class="mr-2" />
              {{ $t("shipments") }}
            </h4>
            <div v-for="shipment in entity.info.shipments"
                 v-bind:key="shipment.index">
            <div class="w-full grid grid-cols-3">
              <div class="col-span-2 px-6 py-3">
                <p class="text-sm pb-1">{{ $t("package_number") }}</p>
                  <core-input
                    class="w-full"
                    readonly
                    :value="shipment.package_number"
                  >
                  </core-input>
              </div>
              <div class="col-span-1 px-6 py-3">
                <p class="text-sm pb-1">{{ $t("carrier") }}</p>
                <div>
                  <img
                    :src="
                      `${URL_PREFIX}/images/carriers/${shipment.carrier}.png?w=500&context=business_panel`
                    "
                    class="w-32"
                  />
                </div>
              </div>
              <div class="col-span-2 px-6 py-3">
                <p class="text-sm pb-1">{{ $t("carrier_service") }}</p>
                  <core-input
                    class="w-full"
                    readonly
                    :value="shipment.title"
                  >
                  </core-input>
              </div>
              <div class="col-span-1 px-6 py-3 mt-8">
                <core-button type="coolrunner"
                @click="goToShipment(shipment.id)">
                  {{$t('go_to_shipment')}}
                </core-button>
              </div>
            </div>
          </div>
          </div>
        </transition>
        <transition appear name="detail-animation" mode="out-in" v-if="show">
          <div
            class="bg-white rounded-md shadow-sm w-full pb-5 hover:shadow transition duration-500 ease-in-out"
            :style="{ '--i': 6 }"
          >
            <h4 class="px-6 pt-5 w-full font-medium text-lg">
              <font-awesome-icon :icon="['far', 'route']" class="mr-2" />
              {{ $t("history") }}
            </h4>
            <core-tracking :history="entity.info.history" />
          </div>
        </transition>
      </div>
      <div class="w-full mt-4 md:mt-0 md:w-1/3">
        <transition appear name="detail-animation" mode="out-in" v-if="show">
          <div
            class="bg-white rounded-md shadow-sm w-full pb-7 hover:shadow transition duration-500 ease-in-out"
            :style="{ '--i': 2 }"
          >
            <h4 class="px-7 pt-5 text-lg">
              <font-awesome-icon :icon="['far', 'user']" class="mr-2" />
              {{ $t("receiver") }}
            </h4>
            <core-contact
              class="w-5/6 m-auto mt-3"
              :contactSwitch="false"
              :entity="entity.data.receiver"
              readonly
              :showCountry="false"
            />
          </div>
        </transition>
        <transition appear name="detail-animation" mode="out-in" v-if="show">
          <div
            class="bg-white rounded-md shadow-sm w-full pb-7 mt-4 hover:shadow transition duration-500 ease-in-out"
            :style="{ '--i': 5 }"
          >
            <h4 class="px-7 pt-5 text-lg">
              <font-awesome-icon :icon="['far', 'user']" class="mr-2" />
              {{ $t("sender") }}
            </h4>
            <core-contact
              class="w-5/6 m-auto mt-3"
              :contactSwitch="false"
              :entity="entity.data.sender"
              readonly
              :showCountry="false"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { URL_PREFIX } from "@/utils/constants";
import moment from "moment";
import I18n from "@/plugins/i18n";
import EventBus from "@/plugins/eventbus";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from) vm.from = from;
      // access to component instance via `vm`
    });
  },

  name: "OrderDetails",
  data: () => ({
    from: "",
    show: false,
    images: [],
    entity: {},
    loading: false,
    deleted: false,
    actionsVisible: false,
    trackPackage: null,
    delay: 0,
    modals: {
      returnShipmentDialog: {
        visible: false,
        shipment: null
      },
      jsonDialog: {
        visible: false,
        orderlines: null
      },
      galleryDialog: {
        visible: false,
        images: []
      }
    }
  }),

  created: function() {
    this.fetchData();
    this.show = true;
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    moment: () => moment,
    rowActions: function(entity) {
      let actions = [
        {
          label: I18n.t("download_label"),
          icon: ["far", "file-download"],
          link: item =>
            `${URL_PREFIX}/business-panel/shipments/${item.package_number}/label?access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`
        },
        {
          label: I18n.t("cancel_entity", {
            entity: I18n.tc(this.name).toLowerCase()
          }),
          icon: ["far", "radar"],
          action: item => {
            this.cancelShipment(item);
          },
          hidden: item => !item.cancellable
        }
      ];

      if (this.$store.state?.printServices?.length) {
        actions.splice(0, 0, {
          label: I18n.t("print_entity", {
            entity: I18n.tc("label", 1).toLowerCase()
          }),
          icon: ["far", "print"],
          action: item => {
            EventBus.$emit("SHOW_PRINT_DIALOG", printer =>
              this.printLabel(printer, item)
            );
          }
        });
      }
      if (this.$store.state?.account?.business)
        actions.splice(2, 0, {
          label: I18n.t("create_entity", {
            entity: I18n.t("return_shipment").toLowerCase()
          }),
          icon: ["far", "undo"],
          action: item => {
            this.modals.returnShipmentDialog.visible = true;
            this.modals.returnShipmentDialog.shipment = item;
          }
        });
      return actions;
    }
  },
  methods: {
    navigate: function() {
      this.show = false;
      document.getElementById('arrow').animate(
            [
              { transform: "translateX(0)", opacity: 1 },
              {
                opacity: 1
              },
              { transform: 'translateX(-100%)', opacity: 0 }
            ],
            {
              duration: 250,
              easing: "ease-in-out",
              fill: "forwards",
            }
          );
      if (this.from.name) this.$router.go(-1);
      else this.$router.push({ name: "wmsorders" });
    },
    fetchData() {
      this.loading = true;
      this.$get(`wms/orders/${this.$route.params.id}`).then(r => {
        if (r == "OK") {
          this.deleted = true;
          this.delay = 0.4;
        } else {
          this.entity = r;
          this.delay = 1.2;
          this.loading = false;
        }
      });
    },
    checkData: function(val) {
      if (val == null) {
        return this.$t("no_data");
      } else {
        return val;
      }
    },
    formatTime: function(val) {
      return moment(val).format("YYYY-MM-DD HH:mm a");
    },
    cancelShipment: function(item) {
      this.$confirm(
        this.$t("confirm_cancellation", {
          type: this.$tc(this.package_number).toLowerCase()
        })
      ).then(() => {
        this.loading = true;
        this.$del(`/${this.endpoint}/${item.id}`)
          .then(() => {
            this.getDataFromApi();
            this.$notification({
              title: this.$t("success"),
              text: `${this.$tc(this.package_number)} ${this.$t(
                "has_been_cancelled"
              ).toLowerCase()}`,
              type: "success"
            });
          })
          .catch(e => {
            this.$notification({
              title: this.$t("not_cancelled"),
              text: `${this.$tc(this.package_number)} ${this.$t(
                "has_not_been_cancelled"
              ).toLowerCase()}`,
              type: "error"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    goToShipment: function (val) {
      this.show = false;
      this.$router.push({ name: 'labels_details', params: { id: val } });
    }
  }
};
</script>
 