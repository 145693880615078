<template>
  <div
    class="view-header flex flex-col flex-grow-0 bg-white rounded-t-lg p-4 md:p-8 md:pb-4 shadow-sm"
  >
    <div style="display: flex;">
      <slot name="title">
        <router-link
          :to="backTo ? backTo : { name: $route.name.replace(/_view/, '') }"
          style="text-decoration: none;"
          @click.native="backTo ? $emit('back') : $emit('navigate')"
        >
          <h1>
            <font-awesome-icon
              v-if="backTo"
              :icon="['far', 'long-arrow-left']"
              fixed-width
            />
            {{ $tc(name, 99) }}
            <span style="font-weight: normal;">{{ title }}</span>
          </h1>
        </router-link>
      </slot>
      <div style="display: flex; margin-left: auto;" v-if="!!help">
        <core-button
          type="text"
          @click="showHelp = true"
          :icon="['fal', 'question-circle']"
          >{{ $t("help") }}</core-button
        >
      </div>
    </div>

    <h2 v-if="count !== undefined">
      {{ $t("n_found", { count: $n(count) }) }}
    </h2>
    <slot name="description">
      <div
        class="small"
        style="white-space: pre-line;text-align: left; margin-top: 1em; max-width: 70%"
        v-html="$t(description)"
      >
      </div>
    </slot>

    <template>
      <div class="view-header_actions" v-if="actions && actions.length">
        <core-button
          :type="action.buttonType || 'coolrunner'"
          :key="action.label"
          :loading="action.loading"
          :disabled="action.disabled"
          v-for="action in actions"
          @click="action.action" >
          {{ action.label }}
          </core-button
        >
      </div>
    </template>
    <slot name="lower" />
    <core-dialog-view-header-help
      :help="help"
      :visible="showHelp"
      @close="showHelp = false"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    route: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: undefined
    },
    description: {
      type: String,
      default: ""
    },
    actions: {
      type: Array,
      default: () => []
    },
    showViewName: {
      type: Boolean,
      default: false
    },
    help: {
      type: Object,
      default: undefined
    },
    backTo: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    showHelp: false
  }),
  computed: {
    title: function() {
      let title = "";

      if (this.showViewName && this.$route.params.view_id) {
        const view = this.$_.find(this.$store.state.availableViews, [
          "id",
          parseInt(this.$route.params.view_id)
        ]);

        title += ` (${view.name})`;
      }

      return title;
    }
  }
};
</script>

<style lang="scss">
.view-header_actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
