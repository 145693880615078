var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full",class:{ dense: _vm.dense }},[(_vm.inputType === 'input')?_c('input',{ref:"input",staticClass:"focus:outline-none focus:border-coolrunner-500 focus:shadow-sm w-full p-3 peer",class:[
      { 'pt-6': !!_vm.label, border: _vm.bordered, 'rounded-md': _vm.rounded, },
      _vm.inputClass, _vm.error ? 'border-red-500' : 'border-gray-200'
    ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder || _vm.label,"autofocus":!!_vm.autofocus,"type":_vm.type,"disabled":_vm.disabled,"readonly":_vm.readonly,"onkeypress":_vm.inputFilter,"onwheel":"this.blur()","onpaste":_vm.disablePaste,"name":_vm.name,"pattern":_vm.pattern,"required":_vm.required},domProps:{"value":_vm.checkValue(_vm.value)},on:{"input":val => {
        _vm.$emit('input', val.target.value);
        _vm.$emit('change', val.target.value);
      },"blur":() => {
      _vm.$emit('blur');
    },"click":function($event){return _vm.$emit('clicked')}}}):_c('textarea',{ref:"input",staticClass:"focus:outline-none focus:border-coolrunner-500 focus:shadow-sm w-full p-3 peer",class:[
      { 'has-label': !!_vm.label, border: _vm.bordered, 'rounded-md': _vm.rounded },
      _vm.inputClass, _vm.error ? 'border-red-500' : 'border-gray-200'
    ],attrs:{"id":_vm.id,"rows":"3","placeholder":_vm.placeholder,"autofocus":!!_vm.autofocus,"type":_vm.type,"disabled":_vm.disabled,"readonly":_vm.readonly,"name":_vm.name},domProps:{"value":_vm.checkValue(_vm.value)},on:{"input":val => {
        _vm.$emit('input', val.target.value);
        _vm.$emit('change', val.target.value);
      }}}),_vm._v(" "),(_vm.label)?_c('label',{staticClass:"absolute top-3 left-0 px-3 h-full flex pointer-events-none transform origin-left transition-all duration-100 ease-in-out leading-none text-gray-500 peer-focus:scale-75 peer-focus:-translate-y-2.5 peer-focus:translate-x-1 peer-focus:text-gray-500",class:{
    'scale-75 -translate-y-2.5 translate-x-1 text-gray-500': _vm.inputValue,
    'translate-y-2.5' : !_vm.inputValue,
    'scale-75 -translate-y-1 translate-x-1 text-gray-500': _vm.inputValue && _vm.dense,
    'translate-y-1' : !_vm.inputValue && _vm.dense
  },attrs:{"for":_vm.id}},[(_vm.labelIcon)?_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":_vm.labelIcon}}):_vm._e(),_vm._v(_vm._s(_vm.label)+" "),_c('div',[(!!_vm.helperText)?_c('el-tooltip',{staticClass:"pointer-events-auto cursor-help",attrs:{"effect":"light","content":_vm.helperText,"placement":"top-start"}},[_c('span',{staticClass:"el-icon-question input-description",staticStyle:{"margin-left":".25em"}})]):_vm._e()],1)],1):_vm._e(),(_vm.suffix)?_c('p',{staticClass:"absolute top-1 right-0 p-3 text-gray-400"},[_vm._v(_vm._s(_vm.suffix))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }