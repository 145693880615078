<template>
  <div
    @click="$emit('click')"
    :class="[
      { 'border-coolrunner-500': selected },
      'border rounded-md hover:shadow-md cursor-pointer flex flex-col mb-5 items-center p-5 transition duration-300 ease-in-out'
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
};
</script>
