<template>
  <div
    id="productslist"
    style="height:100%;"
    class="p-5 overflow-scroll w-full"
  >
    <core-products-list
      :options="products"
      @select="selectProduct"
      :column_span="12"
      labelKey="title"
      bindKey="uid"
      :selectedIndex="selectedIndex"
    >
      <slot slot-scope="prop">
        <div class="w-full flex items-center justify-between">
          <el-col :span="6" class="mr-3">
            <core-carrier-logo :carrier="prop.item.carrier" />
          </el-col>
          <el-col :span="18">
            <div>
              <p class="small">
                <b>{{ $t("title") }}:</b> {{ prop.item.title }}
              </p>
              <p class="small">
                <b>{{ $t("price") }}:</b>
                <span v-if="$store.state.account.business">
                  {{
                    $n(prop.item.sales_price_excl_vat, "currency", "da-DK")
                  }}</span
                >
                <span v-else>
                  {{
                    $n(prop.item.sales_price_incl_vat, "currency", "da-DK")
                  }}</span
                >
              </p>
              <p class="small" v-if="prop.item.delivery_eta">
                <b>{{ $t("expected_eta") }}:</b>
                {{ getEtaString(prop.item) }}
              </p>
              <p class="small">
                <span v-if="prop.item.nearest_dropoff_name">{{prop.item.nearest_dropoff_name}} <br></span>
                <span v-if="prop.item.nearest_dropoff_address">{{prop.item.nearest_dropoff_address}} <br></span>
                <span v-if="prop.item.nearest_dropoff_distance"> {{prop.item.nearest_dropoff_distance}} </span>
              </p>
            </div>
          </el-col>
        </div>
      </slot>
    </core-products-list>
  </div>
</template>

<script>
import Loader from "@/mixins/Loader";
export default {
  mixins: [Loader],
  name: "CreateShipmentProducts",
  data: () => ({
    products: null,
    defaultLoaderTarget: "#productslist",
    clickedIndex: null
  }),
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.fetchProducts().finally(() => {
      this.setNextStep(this.value.carrier_service, this.value.price?.insurance);
    });
  },
  computed: {
    selectedIndex: function() {
      if (this.clickedIndex) return this.clickedIndex;
      let idx = null;

      if (!this.products) idx = null;
      else if (
        this.value.carrier &&
        this.value.carrier_service &&
        this.value.carrier_product
      ) {
        idx = this.products.findIndex(product => {
          return product.carrier_product_service === this.selectedCPS;
        });
      }
      if (idx === -1) idx = null;
      return idx;
    },
    selectedCPS: function() {
      if (
        this.value.carrier &&
        this.value.carrier_service &&
        this.value.carrier_product
      ) {
        return `${this.value.carrier}_${this.value.carrier_product}_${this.value.carrier_service}`;
      }
      return null;
    }
  },
  methods: {
    getEtaString: function(item) {
      const deta = item?.delivery_eta;

      const etaSpanRaw = deta?.split("{")[1].split("}")[0];
      const etaSpan = this.$t("delivery_span")[etaSpanRaw];

      return `${
        deta ? deta.replace(`{${etaSpanRaw}}`, etaSpan) : this.$t("unknown")
      }`;
    },
    validate: function() {
      const selected_product = this.products[this.selected_index];
      const [carrier, product] = selected_product.carrier_product_service.split(
        "_",
        3
      );

      if (carrier && product) this.unlock();
      else this.lock();
    },
    fetchProducts: function() {
      this.loading = true;
      return this.$get("prices", {
        country_from: this.value.sender.country,
        country_to: this.value.receiver.country,
        sender_country: this.value.sender.country,
        receiver_country: this.value.receiver.country,
        sender_street1: this.value.sender.street1,
        receiver_street1: this.value.receiver.street1,
        sender_zip_code: this.value.sender.zip_code,
        receiver_zip_code: this.value.receiver.zip_code,
        sender_city: this.value.sender.city,
        receiver_city: this.value.receiver.city,
        weight: this.value.weight,
        height: this.value.height,
        length: this.value.length,
        width: this.value.width,
        receiver_type: this.value.receiver.type
      })
        .then(response => {
          this.products = response;
        })
        .catch(error => {
          const response = error.response.data;
          if (response.errors) {
            this.$_.values(response.errors).forEach(err => {
              this.$notification({
                title: response.message,
                duration: -1,
                text: err[0]
              });
            });
          } else {
            this.$notification({
              title: response.message,
              duration: 5
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectProduct: function(idx) {
      const selectedProduct = this.products[idx];
      const cps = selectedProduct.carrier_product_service.split("_");
      const [carrier, product] = cps;

      cps.splice(0, 2);
      const service = cps.join("_");
      this.$emit("update:value", {
        ...this.value,
        carrier,
        carrier_product: product,
        carrier_service: service,
        price: {
          excl_tax: selectedProduct.sales_price_excl_vat,
          incl_tax: selectedProduct.sales_price_incl_vat,
          title: selectedProduct.title,
          insurance: selectedProduct.has_insurance
        }
      });
      this.clickedIndex = idx;
      this.setNextStep(service, selectedProduct.has_insurance);
    },
    setNextStep: function(service, insurance) {
      let step = null;
      if (this.selectedIndex == null) return null;

      if (service == "droppoint") step = "droppoint";
      else step = "insurance";

      this.$emit("set:nextStep", step);
    }
  }
};
</script>
