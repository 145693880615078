<template>
  <div>
    <div class="px-6 mt-4" style="display: flex; width: 100%">
      <div
        v-if="loading"
        style="text-align: center; width: 100%; height: 77px; top: -13px;"
        v-loading="loading"
        :element-loading-text="$t('looking_for_tracking')"
      />

      <el-timeline
        v-else-if="data && data.events.length > 0"
        style="width: 100%;"
      >
        <el-timeline-item
          v-for="event, index in data.events"
          :key="'event' + index"
          placement="bottom"
        >
          <core-card>
            <div class="w-full">
              <h4 class="mb-2">
                {{ event.timestamp || event.created_at }}
              </h4>
              <p class="text-sm">{{ $t(event.status) }}</p>
              <p v-html="$t(event.title || event.event || event.description)"></p>
              <p v-if="event.location">
                <font-awesome-icon
                  :icon="['fas', 'map-marker-alt']"
                  fixed-width
                />
                {{ event.location }}
              </p>
            </div>
          </core-card>
        </el-timeline-item>
      </el-timeline>
      <div v-else class="mb-5" style="text-align: center; width: 100%;">
        {{ $t("no_tracking_events") }}
      </div>
    </div>
    <template>
      <a
        class="el-button el-button--secondary outline transition duration-300 ease-in-out"
        v-if="data && data.external_carrier"
        type="primary"
        :href="data.link"
        target="_blank"
        >{{ $t("go_to_carrier_website") }}
      </a>
    </template>
  </div>
</template>
<script>
export default {
  name: "TrackingModal",
  props: {
    packageNumber: {
      type: String,
      default: null
    },
    history: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    loading: true,
    data: null
  }),
  watch: {
    packageNumber: function(val) {
      if (val) this.fetchTracking();
      else {
        this.loading = true;
        this.data = null;
      }
    },
    history: function(val) {
      if (val) {
        this.data = { ...this.data, events: this.history };
        this.loading = false;
      } else {
        this.loading = true;
        this.data = null;
      }
    }
  },
  mounted() {
    if (this.history) {
      this.data = { ...this.data, events: this.history };
      this.loading = false;
    } else {
      this.fetchTracking();
    }
  },
  methods: {
    fetchTracking: function() {
      this.$get(`shipments/${this.packageNumber}/track`, undefined, {
        failSilently: true
      })
        .then(r => {
          this.data = r;
          this.$emit("latest_status_update", {status: r.events[0].subcode, code:r.events[0].code});
        })
        .catch(e => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
