export default {
  data: () => ({
    payment_window: {
      height: 500,
      width: 500
    },
    landing_page: {
      subscribed: `${window.location.origin}/subscribed`,
      order_success: `${window.location.origin}/order/success`
    }
  }),
  methods: {
    popup: function(url, name = null) {
      let height = this.payment_window.height;
      let width = this.payment_window.width;

      name =
        name ??
        Math.random()
          .toString(36)
          .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);

      let popup = window.open(
        url,
        "Payment",
        `toolbar=no,location=no,directories=no,status=no,menubar=no,
				scrollbars=no,resizable=no,copyhistory=no,width=${width},
                height=${height},top=${window.top.outerHeight / 2 +
          window.top.screenY -
          height / 2},
                left=${window.top.outerWidth / 2 +
                  window.top.screenX -
                  width / 2}`
      );

      // Listen for popup close event
      let timer = setInterval(() => {
        if (popup.closed) {
          {
            clearInterval(timer);
            if (this.loading || false) this.loading = false;
          }
        }
      }, 1000);

      let eventHandler = e => {
        if (e.data === "payment:success") {
          if (this.loading || false) this.loading = false;

          this.$emit(`payment-${name}:success`);
        }

        if (e.data === "payment:close") {
          if (this.loading || false) this.loading = false;

          this.$emit(`payment-${name}:close`);
          window.removeEventListener("message", eventHandler);
        }
      };

      window.addEventListener("message", eventHandler);

      return name;
    }
  },
  watch: {}
};
