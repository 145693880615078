var render = function render(){var _vm=this,_c=_vm._self._c;return _c('widget-base',{staticClass:"chart-wrapper",attrs:{"title":_vm.cTitle,"subtitle":_vm.sub,"shadow":_vm.shadow,"titlePosition":_vm.titlePosition,"showNoData":_vm.showNoData,"initialFilters":_vm.data.filters,"interval":_vm.interval,"filteringEnabled":_vm.filteringEnabled,"showIntervalSelector":"","hasDescription":_vm.hasDescription},on:{"set-filters":val => (_vm.filters = val),"set-cleaned-filters":val => (_vm.cleanedFilters = val),"set-interval":val => (_vm.interval = val)}},[_c('div',[(_vm.showNoData)?_c('div',{staticClass:"failText relative"},[_c('span',{staticClass:"abolute top-1/2 left-1/2 transform mt-28 translate-x-1/2 translate-y-1/2"},[_vm._v(_vm._s(_vm.$t('chart_error')))])]):_vm._e(),_c(_vm.breakpoint.innerWidth < 1 ? 'chart-horizontal-bar' : 'chart-bar',{tag:"component",attrs:{"chart-id":_vm.uniqueID,"chart-data":_vm.dataCollection,"options":{
      legend: false,
      animation: {
        easing: 'easeInOutElastic'
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: _vm.tooltipsOptions(_vm.title),
      minBarLength: 10,
      scales: _vm.scalesOptions()
    }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }