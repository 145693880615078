<template>
  <div
    class="
      shadow-md
      rounded
      bg-white
      p-4
      hover:shadow-lg
      cursor-pointer
      transition
      duration-300
      ease-in-out
      h-64
      pb-10
    "
    @click="type == 'return' && $emit('openedit', data)"
  >
    <div class="grid grid-cols-2 relative pb-4" v-if="type == 'return'">
      <div
        class="h-11 w-11 rounded-full bg-gray-100 shadow-inner"
        style="padding-left: 10px; padding-top: 13px"
      >
        <div :class="`flag-icon-${data.country.toLowerCase()} h-6 w-6`" />
      </div>
      <core-list-actions
        trigger="hover"
        :actions="cardActions"
        :entity="data"
        class="absolute top-0 right-0 rounded-full"
      >
      </core-list-actions>
    </div>
    <div class="grid grid-cols-2 relative pb-4" v-else>
      <div
        v-if="type == 'product'"
        class="h-11 w-11 rounded-full bg-gray-100 shadow-inner"
        style="padding-left: 10px; padding-top: 13px"
      >
        <div class="h-6 w-6">
          <font-awesome-icon :icon="['far', `${icon}`]" />
        </div>
      </div>
      <div v-if="type == 'carrier'" class="relative h-11">
         <el-image
      :src="
        `${URL_PREFIX}/images/carriers/${ carrier }.png?w=500&context=business_panel`
      "
      class="carrier-image absolute top-5 left-0"
      :fit="'contain'"
      ondragstart="return false"
    >
      <div slot="error" class="image-slot">{{ carrier }}</div>
    </el-image>
      </div>
      <core-toggle
      :enabled="enabled"
       @enabled="enableService" />
    </div>
    <div class="h-42 flex flex-wrap content-end overflow-hidden">
      <h3 class="text-lg w-full">
        {{ headline }}
        <span class="fa-layers fa-fw" v-if="privatePortal">
          <font-awesome-icon class="text-2xl" :icon="['fal', 'circle']" />
          <font-awesome-icon class="text-xs mx-auto" :icon="['far', 'lock']" />
        </span>
      </h3>
      <p class="text-sm w-full text-gray-500">{{ subLine }}</p>
      <p class="text-sm line-clamp-3 w-full mb-2 text-gray-500">
        {{ description }}
      </p>
      <p class="text-sm w-full text-gray-500" v-if="parseFloat(price) > 0">
        {{ $n(price) }} {{ currency }}
      </p>
      <p class=" text-sm w-full text-gray-500" v-else>
        {{ $t("free") }}
      </p>
    </div>
  </div>
</template>

<script>
import I18n from "@/plugins/i18n";
import { URL_PREFIX } from "@/utils/constants";
export default {
  name: "productCard",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "default",
    },
    headline: {
      type: String,
      default: "",
    },
    subLine: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: "",
    },
    privatePortal: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    carrier: {
      type: String,
      default: ""
    }
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    cardActions() {
      return [
        {
          label: I18n.t("edit"),
          icon: ["far", "edit"],
          action: (entity) => {
            this.$emit("openedit", entity);
          },
        },
        {
          label: I18n.t("share"),
          icon: ["far", "share"],
          action: (entity) => {
            this.$emit("openshare", entity);
          },
        },
        {
          label: I18n.t("delete"),
          icon: ["far", "trash"],
          action: (entity) => {
            this.$emit("opendelete", entity);
          },
        },
      ];
    },
  },
  methods: {
    enableService: function(val) {
      this.$emit("serviceEnabled", { enabled: val, data: this.data});
    }
  }
};
</script>

<style lang="scss">
input:checked ~ .dot {
  transform: translateX(100%);
}
input:checked ~ .block {
  background-color: #157efb;
}
</style>