<template>
  <el-dialog
    class=""
    :visible="visible"
    @update:visible="$emit('close')"
    append-to-body
    width="730px"
    :title="title"
    :close-on-click-modal="false"
  >
    <template>
      <div class="flex w-full">
        <pre>{{ formatted }}</pre>
      </div>
    </template>
    <template v-slot:footer>
      <core-button type="default_outline" @click="$clipboard(formatted)">
        {{ $t("copy") }}
      </core-button>

      <core-button type="coolrunner" @click="$emit('close')" class="ml-2"
        >{{ $t("close") }}
      </core-button>
    </template>
  </el-dialog>
</template>
<script>
import unescapeJs from "unescape-js";

export default {
  name: "JsonModal",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    json: {
      type: String,
      default: ""
    }
  },
  data: () => ({}),
  computed: {
    formatted: function() {
      try {
        let unescaped = unescapeJs(this.json);

        if (
          unescaped.charAt(0) === '"' &&
          unescaped.charAt(unescaped.length - 1) === '"'
        ) {
          unescaped = unescaped.substr(1, unescaped.length - 2);
        }

        const parsed = JSON.parse(unescaped);

        return JSON.stringify(parsed, null, 4);
      } catch {
        return this.json;
      }
    }
  }
};
</script>
