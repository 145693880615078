<template>
  <core-view :style="{ '--delay': delay}">
    <core-view-header
      :name="name"
      :count="totalItems"
      :description="'page_descriptions.orders'"
    />
    <core-list-new-table
      class="rounded-b-sm"
      :items="items"
      :headers="headers"
      :page-size.sync="options.itemsPerPage"
      :current-page.sync="options.page"
      :total-items="totalItems"
      :loading="loading"
      :order-by.sync="options.orderBy"
      :order-dir.sync="options.orderDir"
      :query.sync="search"
      :selected="selected"
      selectable
      expandable
      :entity-name="name"
      :empty-list-icon="icon"
      @checkbox-change="handleSelection"
      @all-selected-change="handleSelection"
    >
      <template v-slot:column.order_lines="{ item }">
        <p class="small content">
          {{ item.order_lines.length }}
        </p>
      </template>
      <template v-slot:expanded-content="{ item }">
        <div class="inner-table">
          <row
            v-for="orderLine in item.order_lines"
            :key="orderLine.id"
            :columns="24"
            :gutter="20"
            class="inner-box row"
            style="max-width: 100%"
          >
            <column :xs="24" :md="8" :lg="4" class="info-box">
              <h4>{{ $t("type") }}</h4>

              {{ $tc(orderLine.orderable_type) }}
            </column>
            <column :xs="24" :md="8" :lg="4" class="info-box">
              <h4>{{ $t("product") }}</h4>

              {{ $tc(orderLine.orderable.title) || "-" }}
            </column>

            <column :xs="24" :md="8" :lg="4" class="info-box">
              <h4>{{ $t("price_excl_tax") }}</h4>

              {{ $n(orderLine.sales_price_excl_vat, "currency", "da-DK") }}
            </column>
            <column :xs="24" :md="8" :lg="4" class="info-box">
              <h4>{{ $t("price_incl_tax") }}</h4>

              {{ $n(orderLine.sales_price_incl_vat, "currency", "da-DK") }}
            </column>
            <column :xs="24" :md="8" :lg="4" class="info-box">
              <h4>{{ $t("created") }}</h4>

              {{ convertUTCToLocal(orderLine.created_at) }}
            </column>
            <column
              v-if="orderLine.orderable_type === 'shipment'"
              :xs="24"
              :md="8"
              :lg="4"
              class="info-box"
            >
              <h4>{{ $t("options") }}</h4>

              <router-link
                :to="{
                  name: 'labels',
                  query: {
                    filter: {
                      package_number: orderLine.orderable.package_number
                    }
                  }
                }"
                >{{ $t("see_label_information") }}</router-link
              >
            </column>
          </row>
        </div>
      </template>
    </core-list-new-table>
  </core-view>
</template>

<script>
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";

export default {
  mixins: [List],
  data: () => ({
    endpoint: "orders",
    name: "order",
    icon: ["far", "receipt"],
    headers: [
      {
        label: "order_number",
        key: "id"
      },
      {
        label: "created",
        key: "created_at",
        type: "datetime"
      },
      {
        label: "orderlines",
        key: "order_lines",
        orderable: false
      },
      {
        label: "price",
        key: "sales_price",
        type: "price",
        orderable: false
      }
    ]
  }),
  computed: {
    delay: function() {
      if(this.totalItems > 20){
      return 1.2
      } else {
        return this.items.length * 0.050
      }
    }
  },
  mounted: function() {
    this.getDataFromApi();
  },
  methods: {
    convertUTCToLocal: function(val) {
      return convertUTCToLocal(val, "datetime");
    },
    formatPriceString: function(orderline) {
      return `${this.$n(orderline.sales_price_excl_vat, "currency", "da-DK")}/${this.$n(orderline.sales_price_incl_vat, "currency", "da-DK")}`
    }
  }
};
</script>
