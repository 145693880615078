<template>
  <div>
    <core-list-new-table
      :items="rows"
      :headers="headers"
      :pageSize="options.itemsPerPage"
      :currentPage.sync="options.page"
      :totalItems="totalItems"
      :loading="loading"
      :orderBy.sync="options.orderBy"
      :orderDir.sync="options.orderDir"
      :query.sync="search"
      :selected="selected"
      :excluded="excluded"
      :selectionKey="selectionKey"
      @checkbox-change="handleSelection"
      :rowActions="rowActions"
      :bulkActions="bulkActions"
      :selectAll.sync="selectAll"
      :entityName="name"
      @set-cleaned-filters="val => $emit('set-cleaned-filters', val)"
      @set-filters="val => $emit('set-filters', val)"
      :emptyListText="
        type === 'failed'
          ? $t('no_failed_shipments')
          : $t('empty_list', { entity: $tc(name, 99).toLowerCase() })
      "
      :spanMethod="spanMethod"
      :cellClassName="cellClassName"
    >
      <template v-slot:[`column.data.carrier`]="{ item }">
        <div v-if="item.type !== 'extra'">
          {{ item.carrier }}
        </div>
        <div v-else class="w-full">
          {{ item.data }}
        </div>
      </template>
    </core-list-new-table>
    <edit-dialog
      :visible.sync="showEditDialog"
      :shipment="shipmentToEdit"
      :state="type"
      :saving="saving"
      @update:visible="
        val => {
          if (!val) shipmentToEdit = null;
        }
      "
      @save="save"
    />
  </div>
</template>
<script>
import EditDialog from "./EditDialog";
import { convertUTCToLocal } from "@/utils/date";

import List from "@/mixins/List";
import axios from "axios";
import I18n from "@/plugins/i18n";
import EventBus from "@/plugins/eventbus";

export default {
  mixins: [List],
  components: { EditDialog },
  name: "LabelsList",
  props: {
    listIcon: {
      type: Array,
      default: null
    },
    listEndpoint: {
      type: String,
      default: ""
    },
    listName: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    showEditDialog: false,
    shipmentToEdit: null,
    selectionKey: "id"
  }),
  created: function() {
    this.icon = this.listIcon;
    this.endpoint = this.listEndpoint;
    this.name = this.listName;
  },
  mounted: function() {
    this.getDataFromApi();
  },
  watch: {
    type: function(val) {
      if (val !== "importer") this.getDataFromApi();
    }
  },
  computed: {
    rows: function() {
      if (this.type !== "failed") return this.items;

      const errors = this.$_.map(this.items, i => {
        return { data: i.last_error, type: "extra", selectable: false };
      });

      const zipLock = (a, b) =>
        a.length ? [a[0], ...zipLock(b, a.slice(1))] : b;

      return zipLock(this.items, errors);
    },
    rowActions: function() {
      switch (this.type) {
        case "awaiting":
          return [
            {
              label: I18n.tc("produce_shipment", 1),
              action: item => this.process(item),
              icon: ["far", "box-check"]
            },
            {
              label: I18n.t("edit"),
              action: item => this.editShipment(item),
              icon: ["far", "edit"]
            },
            {
              label: I18n.t("delete"),
              action: item => this.delete(item),
              icon: ["far", "trash"]
            }
          ];
        case "failed":
          return [
            {
              label: I18n.t("restore"),
              action: item => this.restore(item),
              icon: ["far", "reply"]
            },
            {
              label: I18n.t("edit"),
              action: item => this.editShipment(item),
              icon: ["far", "edit"]
            },
            {
              label: I18n.t("retry"),
              action: item => this.process(item),
              icon: ["far", "repeat-alt"]
            },
            {
              label: I18n.t("delete"),
              action: item => this.delete(item),
              icon: ["far", "trash"]
            }
          ];

        default:
          return [];
      }
    },
    bulkActions: function() {
      switch (this.type) {
        case "awaiting":
          return [
            {
              label: I18n.tc("produce_shipment", 99),
              action: () => this.process(),
              icon: ["far", "box-check"]
            },
            {
              label: I18n.t("edit"),
              action: () => this.editShipment(),
              icon: ["far", "edit"]
            },
            {
              label: I18n.t("delete"),
              action: () => this.delete(),
              icon: ["far", "trash"]
            }
          ];
        case "failed":
          return [
            {
              label: I18n.t("restore"),
              action: () => this.restore(),
              icon: ["far", "reply"]
            },
            {
              label: I18n.t("edit"),
              action: () => this.editShipment(),
              icon: ["far", "edit"]
            },
            {
              label: I18n.t("retry"),
              action: () => this.process(),
              icon: ["far", "repeat-alt"]
            },
            {
              label: I18n.t("delete"),
              action: () => this.delete(),
              icon: ["far", "trash"]
            }
          ];
        default:
          return [];
      }
    },
    selectedCount: function() {
      return this.selectAll
        ? this.totalItems - this.excluded.length
        : this.selected.length;
    },
    headers: function() {
      if (
        this.$store.state.breakpoint.isSmall ||
        this.$store.state.breakpoint.noMatch
      ) {
        return this.type === "done"
          ? [
              { label: "date", key: "created_at", type: "date" },
              {
                label: "receiver",
                key: "shipment.receiver.name",
                orderableKey: "receiver_name"
              },
              {
                label: "carrier",
                key: "shipment.carrier",
                orderableKey: "carrier",
                type: "carrier_image"
              },
              {
                label: "labelless_code",
                key: "shipment.labelless_code"
              },

              {
                label: "package_number",
                key: "shipment.package_number"
              }
            ]
          : [
              {
                label: "carrier",
                key: "data.carrier",
                orderableKey: "carrier",
                type: "carrier_image"
              },
              {
                label: "receiver",
                key: "data.receiver_name",
                orderableKey: "receiver_name"
              },
              {
                label: "email",
                key: "data.receiver_email",
                orderableKey: "receiver_email"
              }
            ];
      } else {
        return this.type === "done"
          ? [
              { label: "date", key: "created_at", type: "date" },
              {
                label: "receiver",
                key: "shipment.receiver.name",
                orderableKey: "receiver_name"
              },
              {
                label: "carrier",
                key: "shipment.carrier",
                orderableKey: "carrier",
                type: "carrier_image"
              },
              {
                label: "labelless_code",
                key: "shipment.labelless_code"
              },

              {
                label: "package_number",
                key: "shipment.package_number"
              },
              {
                label: "reference",
                key: "shipment.reference",
                orderableKey: "reference"
              },
              { label: "price", key: "price", orderableKey: "price" }
            ]
          : [
              {
                label: "carrier",
                key: "data.carrier",
                orderableKey: "carrier",
                type: "carrier_image"
              },
              {
                label: "receiver",
                key: "data.receiver_name",
                orderableKey: "receiver_name"
              },
              {
                label: `${I18n.t("street")} 1`,
                localized: true,
                key: "data.receiver_street1",
                orderableKey: "receiver_street1"
              },
              {
                label: `${I18n.t("street")} 2`,
                localized: true,
                key: "data.receiver_street2",
                orderableKey: "receiver_street2"
              },
              {
                label: "zip_code",
                key: "data.receiver_zip_code",
                orderableKey: "receiver_zip_code"
              },
              {
                label: "city",
                key: "data.receiver_city",
                orderableKey: "receiver_city"
              },
              {
                label: "country",
                key: "data.receiver_country",
                orderableKey: "receiver_country"
              },
              {
                label: "email",
                key: "data.receiver_email",
                orderableKey: "receiver_email"
              },
              {
                label: "phone",
                key: "data.receiver_phone",
                orderableKey: "receiver_phone"
              }
            ];
      }
    }
  },
  methods: {
    convertUTCToLocal: convertUTCToLocal,

    editShipment(shipment = undefined) {
      this.shipmentToEdit = shipment;
      this.showEditDialog = true;
    },
    buildUrl: function(entity, path) {
      let url = this.endpoint;

      if (path) url += `/${path}`;

      if (!this.$_.isEmpty(entity)) url += `?include=${entity.id}`;
      else if (this.selectAll)
        url += `?exclude=${this.$_.join(this.excluded, ",")}`;
      else url += `?include=${this.$_.join(this.selected, ",")}`;

      url += `&state=${this.type}&q=${this.search}`;

      return url;
    },
    getDataFromApi() {
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();

      this.loading = true;

      this.$get(
        `/${this.endpoint}?state=${this.type}&q=${this.search}`,
        {
          ...this.params,
          cancelToken: this.cancelToken.token
        },
        { mutateLocation: false, useDev: this.useDev }
      )
        .then(response => {
          this.items = response.data;
          this.totalItems = response.total;
          this.loading = false;
        })
        .catch(e => {
          if (e !== "cancelled") this.loading = false;
        });
    },
    save: function(entity) {
      let httpMethod = this.$put;
      this.saving = true;
      if (this.$_.isEmpty(this.shipmentToEdit)) httpMethod = this.$patch;

      const url = this.buildUrl(this.shipmentToEdit);

      httpMethod(url, entity)
        .then(this.getDataFromApi)
        .then(() => {
          this.shipmentToEdit = null;
          this.showEditDialog = false;
        })
        .finally(() => (this.saving = false));
    },
    process: function(entity) {
      this.getPrices(entity);
    },
    getPrices: function(entity) {
      const url = this.buildUrl(entity, "prices");
      this.loading = true;
      this.$get(url, undefined)
        .then(r => {
          this.$confirm(
            this.$t("confirm_produce_shipment_description", {
              excl_vat: this.$n(r.excl_vat, "currency", "da-DK"),
              incl_vat: this.$n(r.incl_vat, "currency", "da-DK")
            }),
            this.$tc("confirm_produce_shipment", 99),
            { dangerouslyUseHTMLString: true }
          ).then(() => {
            this.doProcess(entity);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    doProcess: function(entity) {
      const url = this.buildUrl(entity, "process");
      this.loading = true;
      this.$post(url, undefined, { failSilently: true })
        .then(r => {
          this.$notification({
            title: this.$t("request send"),
            text: this.$t("generating_shipments"),
            type: "success"
          });
        })
        .then(this.getDataFromApi)
        .catch(e => {
          const data = e.response.data;

          if (data.type !== "INSUFFICIENT_BALANCE") {
            const errors = data.errors || data.response_data;
            this.$notification({
              title: I18n.t("error"),
              text: errors
                ? this.$_.join(this.$_.map(errors), "</br>")
                : e.response.message,
              type: "error"
            });
          } else {
            EventBus.$emit("SHOW_FILL_BALANCE", {
              required: data.additional_data.required_balance,
              balance: data.additional_data.current_balance,
              success: () => this.process(entity)
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    delete: function(entity) {
      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$tc(this.name, entity ? 1 : 99).toLowerCase()
        })
      ).then(() => {
        const url = this.buildUrl(entity);
        this.loading = true;

        this.$del(url)
          .then(r => {
            this.$notification({
              title: this.$t("success"),
              text: `${this.$tc(this.name, entity ? 1 : 99)} ${this.$t(
                "has_been_deleted"
              ).toLowerCase()}`,
              type: "success"
            });
          })
          .then(this.getDataFromApi)

          .catch(e => {
            this.$notification({
              title: this.$t("not_deleted"),
              text: `${this.$tc(this.name, entity ? 1 : 99)} ${this.$t(
                "has_not_been_deleted"
              ).toLowerCase()}`,
              type: "error"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    restore: function(entity) {
      this.$confirm(
        this.$t("confirm_restore", {
          type: this.$tc(this.name, entity ? 1 : 99).toLowerCase()
        })
      ).then(() => {
        const url = this.buildUrl(entity, "restore");
        this.loading = true;

        this.$put(url)
          .then(r => {
            this.$notification({
              title: this.$t("success"),
              text: `${this.$tc(this.name, entity ? 1 : 99)} ${this.$t(
                "has_been_restored"
              ).toLowerCase()}`,
              type: "success"
            });
          })
          .then(this.getDataFromApi)
          .catch(e => {
            this.$notification({
              title: this.$t("not_restored"),
              text: `${this.$tc(this.name, entity ? 1 : 99)} ${this.$t(
                "has_not_been_restored"
              ).toLowerCase()}`,
              type: "error"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    spanMethod: function({ row, columnIndex }) {
      if (row.type === "extra") {
        if (columnIndex === 1) return [1, this.headers.length + 2];

        return [0, 0];
      }
      return [1, 1];
    },
    cellClassName: function({ row, rowIndex }) {
      if (row.type === "extra") return "!bg-gray-300 !h-2";
    }
  }
};
</script>
