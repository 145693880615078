<template>
  <core-select
    class="core-select__country"
    v-bind="$attrs"
    :options="options"
    :autocomplete="autocomplete"
    :filterable="true"
    :value="selectedValue"
    :label-key="labelKey"
    :value-key="valueKey"
    :label="label"
    :disabled="disabled"
    list-item-key="iso"
    :class="[{ 'is-disabled': disabled }]"
    v-on="$listeners"
  >
    <template v-slot:prefix>
      <span :class="`flag-icon flag-icon-${selectedFlag.toLowerCase()}`" />
    </template>
    <template v-slot:option="{ option }">
      <slot name="option" :option="option">
        <span
          style="margin-right:4px;"
          :class="`flag-icon flag-icon-${getOptionFlag(option)}`"
        />

        {{ option[labelKey] }}
      </slot>
    </template>
  </core-select>
</template>

<script>
export default {
  name: "CountrySelect",
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dataOverride: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Boolean, String, Number],
      default: null
    },
    autocomplete: {
      type: String,
      default: "on"
    },
    endpoint: {
      type: String,
      default: "countries"
    },
    labelKey: {
      type: String,
      default: "full_name"
    },
    valueKey: {
      type: String,
      default: "iso"
    },
    valuePrefix: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "select"
    },
    showAllOption: {
      type: Boolean,
      default: false
    },
    excludeAllNonEU: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    countries: []
  }),
  computed: {
    options: function() {
      let opts =
        this.dataOverride.length > 0 ? this.dataOverride : this.countries;

      if (this.showAllOption)
        return [{ full_name: this.$t("all"), iso: null }, ...opts];
      return opts;
    },
    selectedFlag: function() {
      if (this.value && typeof this.value !== "boolean") {
        if (this.valueKey === "iso") {
          return this.value || "zz";
        }
        const country = this.$_.find(this.countries, [
          this.valueKey,
          this.value
        ]);
        if (country) return country.iso || "zz";
      }

      return "zz";
    },
    selectedValue: function() {
      if (typeof this.value == "boolean") return null;
      else return this.value;
    }
  },
  mounted: function() {
    if(this.dataOverride.length) return
    else if (!this.$store?.state?.countries || this.endpoint !== "countries")
      this.fetchCountries();
    else if (!this.dataOverride.length) {
      this.countries = this.$_.filter(this.$store.state.countries, function(o) {
        return o.active;
      });
      if (this.excludeAllNonEU) {
        this.countries = this.$_.filter(this.countries, function(o) {
          return o.trade_union === "EU";
        });
      }
    }
  },
  methods: {
    getOptionFlag: function(opt) {
      if (opt.iso) return opt.iso.toLowerCase();
      else return "dk";
    },
    fetchCountries: function() {
      this.$get(
        `/${this.endpoint}`,
        {
          ...this.params
        },
        { mutateLocation: true, useDev: this.useDev }
      )
        .then(response => {
          this.countries = response;
          if (this.excludeAllNonEU) {
            this.countries = this.$_.filter(this.countries, function(o) {
              return o.trade_union === "EU";
            });
          }
          this.loading = false;
        })
        .catch(e => {
          if (e !== "cancelled") this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
