<template>
  <div class="icon icon--order-success svg flex justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
      <g fill="none" stroke="#157efb" stroke-width="2">
        <circle
          cx="77"
          cy="77"
          r="72"
          style="stroke-dasharray:480px, 480px; stroke-dashoffset: 960px;"
        />
        <polyline
          class="st0"
          stroke="#157efb"
          stroke-width="10"
          points="43.5,77.8 63.7,97.9 112.2,49.4 "
          style="stroke-dasharray:100px, 100px; stroke-dashoffset: 200px;"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AnimationCheckMark"
};
</script>

<style lang="scss" scoped>
.icon-check {
  color: #157efb;
  font-size: 4em;
  border: 2px solid #157efb;
  padding: 12px;
  border-radius: 6em;
}
@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 200px;
  }
}

@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 200px;
  }
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 0px;
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }

  100% {
    stroke-dashoffset: 960px;
  }
}

@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }

  100% {
    stroke-dashoffset: 960px;
  }
}

.inlinesvg .svg svg {
  display: inline;
}
.icon--order-success svg polyline {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}
</style>
