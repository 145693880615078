<template>
  <div class="testerdiv">
    <core-dialog
      v-for="(document, index) in pending"
      v-bind:key="index"
      :visible="visible"
      :show-close="false"
      :title="document.readable_type"
      width="60"
    >
      <div class="w-full h-screen">
        <iframe
          id="pdf"
          :title="document.readable_type"
          :src="`${getDocumentUrl(document)}`"
          style="overflow:hidden;height:100%;width:100%"
          frameborder="1"
          scrolling="auto"
        ></iframe>
      </div>
      <template slot="footer">
        <div class="w-full relative px-4 py-6  text-left">
          <div class="flex pr-[70px] mt-3 ">
          <core-checkbox class="" v-model="accepted"></core-checkbox>
          <p class="text-sm pl-3">{{
            $t("i_hereby_accept", {
              entity: document.readable_type.toLowerCase()
            })
          }}</p>
          </div>
        </div>
        <core-button
            @click="acceptTerms(document)"
            class="absolute bottom-3.5 right-4"
            type="coolrunner"
            :disabled="!accepted"
            >{{ $t("save") }}</core-button
          >
      </template>
    </core-dialog>
  </div>
</template>

<script>
export default {
  name: "LegalDocumentView",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    accepted: false,
    loading: false
  }),
  created: function() {},
  computed: {
    pending: function() {
      return this.$store.state?.account?.pending_documents;
    }
  },
  methods: {
    acceptTerms: async function(document) {
      this.loading = true;
      await this.$post(`/account/legal-document/${document.id}/accept`)
        .then(r => {
          this.$store.dispatch("setPendingLegalDocuments", r);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDocumentUrl: function(document) {
      if (this.$i18n.locale !== "da") {
        if (document.urls[this.$i18n.locale]) {
          return document.urls[this.$i18n.locale];
        } else {
          return document.urls.da;
        }
      } else {
        return document.urls.da;
      }
    }
  }
};
</script>
