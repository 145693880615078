<template>
    <div>
        <input class="hidden" ref="uploader" type="file" @change="uploadFile" accept="image/*,.pdf" :multiple="true" :value="inputvalue">
        <div>
            <div class="text-center">
                    <core-button class="w-full mt-2" type="coolrunner_outline" @click="$refs.uploader.click()">{{$t(title)}}</core-button>
            </div>
        </div>
    </div>

</template>

<script>
import _ from "lodash";

export default {
    name: "FileUploader",
    props: {
       title: {
           type: String,
           default: ""
       }
    },
    data: () => ({
        fileTooLarge: false,
        inputvalue: null
    }),
    methods: {
        uploadFile (event) {
            let maxFileSizeInMB = 10
            _.forEach(event.target.files, (file, key) => {
                if(file.size > maxFileSizeInMB*1048576) {
                    this.fileTooLarge = true
                }
                else {
                    this.fileTooLarge = false
                    this.$emit('update:file', file)
                    this.$emit('update:urls')
                    this.inputvalue = null
                }
            });
        },
    }
}
</script>

<style scoped>

</style>