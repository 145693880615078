<template>
  <div class="wrapper form">
    <div class="description">
      {{ $t("create_new_shop.description") }}
    </div>

    <core-input
      :label="$t('name')"
      :value="name"
      @input="val => $emit('update:name', val)"
    />
    <div class="grid-container" style="margin-top: 10px;">
      <row :gutter="20" :columns="24">
        <column
          :xs="12"
          :sm="8"
          v-for="(shopType, key) in shopTypes"
          :key="key"
          @click.native="$emit('update:type', key)"
        >
          <div class="shop-type" :class="{ selected: type == key }">
            <img :src="shopType.image" />
            <p>{{ shopType.title }}</p>
          </div>
        </column>
      </row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    }
  },
  watch: {
    pageOk: function(val, oldVal) {
      if (val !== oldVal) {
        this.$emit("page-ok", val);
      }
    }
  },
  computed: {
    pageOk: function() {
      return !!(this.name && this.type);
    },
    shopTypes: function() {
      return this.$store.state.shopSettings;
    }
  }
};
</script>
