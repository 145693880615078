import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element-ui";
import "./plugins/calendar.js";
import "./plugins/line-clamp.js";
import "./plugins/notify.js";
import vuetify from "./plugins/vuetify";
import "./plugins/charts.js";
import "./plugins/widgets.js";
import "./plugins/clipboard.js";
import "./plugins/grid.js";
import "./plugins/youtube.js";
import "./plugins/gtag.js";
import "./plugins/date-time-picker.js";
import "./plugins/fontawesome.js";
import "./plugins/observe-visibility.js";
import "./plugins/cookie.js";

import "chartjs-plugin-style";
import echo_instance from "./plugins/laravel-echo";

//import "./components/charts/custom/CoolRunnerBar.js";
import "./components/charts/custom/CustomEasing.js";
import "./plugins/core-components";
import data from "./plugins/data";
import _ from "lodash";
import moment from "moment";
import "flag-icon-css/css/flag-icon.css";

import "./styles/main.scss";

import i18n from "./plugins/i18n";
//import "./registerServiceWorker";
import fileDownload from "js-file-download";

Vue.config.productionTip = false;

let app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  beforeCreate: function() {
    Vue.prototype.$get = data.fetch;
    Vue.prototype.$post = data.post;
    Vue.prototype.$put = data.put;
    Vue.prototype.$del = data.del;
    Vue.prototype.$patch = data.patch;
    Vue.prototype.$upload = data.upload;
    Vue.prototype.$_ = _;
    Vue.prototype.$moment = moment;
    Vue.prototype.$axios = data.http;
    Vue.prototype.$echo = echo_instance;
    Vue.prototype.$fileDownload = fileDownload;
    Vue.prototype.$readJsonFromBlob = data.readJsonFromBlob;
  },
  render: (h) => h(App),
}).$mount("#app");

const favicon = document.querySelector("link[rel=icon]");
const title = document.querySelector("title");
const hostname = window.location.hostname;
const hostsplit = hostname.split(".");

if (hostsplit[1] === "homerunner") {
  title.innerHTML = "Homerunner";
  favicon.href = "/img/icons/favicon_homerunner.png";
} else {
  favicon.href = "/img/icons/favicon_coolrunner.png";
}
