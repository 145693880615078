<template>
  <div class="progress-bar" :class="{ completed: done, failed }">
    <div class="bar">
      <div class="bar-inner" :style="`width: ${percentage}%`" />
    </div>
    <div class="info">
      <p class="percentage">
        {{ percentage === undefined ? "-" : `${percentage}%` }}
      </p>
      <p class="time-left">
        {{ timeLeft === undefined ? "-" : formatedTimeLeft }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: undefined
    },
    timeLeft: {
      type: Number,
      default: undefined
    },
    failed: {
      type: Boolean,
      default: () => false
    },
    done: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    formatedTimeLeft: function() {
      const duration = this.timeLeft;
      if (this.failed) {
        return this.$t("action_failed", { action: this.$t("upload") });
      }
      if (this.done && duration === 0)
        return this.$t("action_completed", {
          action: this.$t("upload")
        });
      // Hours, minutes and seconds
      var hrs = Math.floor(duration / 3600);
      var mins = Math.floor((duration % 3600) / 60);
      var secs = Math.floor(duration % 60);

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = "";

      if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }

      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;

      let suffix = this.$t("abrreviations.seconds");
      if (hrs > 0) suffix = this.$t("hours");
      else if (mins > 0) suffix = this.$t("abrreviations.minutes");

      ret += " " + suffix;

      return this.$t("time_left", { time: ret });
    }
  }
};
</script>

<style lang="scss">
.progress-bar {
  .bar {
    display: block;
    width: 100%;
    background-color: #ebeef2;
    text-align: left;
    line-height: 0;
    border-radius: 6px;
    overflow: hidden;
    &-inner {
      height: 12px;
      background-color: #157efb;
      display: inline-block;
      transition: width 250ms linear;
      transition: background-color 250ms linear;
    }
  }
  &.completed {
    .bar-inner {
      background-color: #75dc69;
    }
  }
  &.failed {
    .bar-inner {
      background-color: #e54d42;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    p {
      color: #157efb;
      font-size: 14px;
    }
  }
}
</style>
