<template>
  <widget-simple height="550px" v-bind="$attrs" />
</template>

<script>
export default {
  inheritAttrs: false,
  name: "KPIWidget"
};
</script>
