<template>
  <el-submenu v-if="entry.type === 'group'" :index="entry.index">
    <template slot="title">
      <font-awesome-icon v-if="entry.icon" :icon="entry.icon" fixed-width />
      <span>{{ getLabel(entry) }}</span>
    </template>
    <core-menu-entry
      v-for="(subEntry, subIndex) in entry.entries"
      v-bind:key="subIndex + '.' + subEntry[keyProp]"
      :entry="subEntry"
      :label="getLabel(subEntry)"
      v-on="$listeners"
      :keyProp="keyProp"
      nested
    />
  </el-submenu>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      default: () => ({})
    },
    keyProp: {
      type: String,
      default: "index"
    },
    labelProp: {
      type: String,
      default: "label"
    },
    labelLocalized: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getLabel: function(entry) {
      return this.labelLocalized
        ? this.$tc(entry[this.labelProp], 99)
        : entry[this.labelProp];
    }
  }
};
</script>
