var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter__table-row"},[_c('div',{staticClass:"flex flex-wrap w-full"},[_c('core-search',{attrs:{"query":_vm.query,"size":"small"},on:{"update:query":val => _vm.$emit('update:query', val)}}),(_vm.wmsSelect)?_c('core-select',{ref:"select",attrs:{"ekstra_class":"h-10 border-2 ml-4","bordered":false,"full":false,"value":"Vælg status","options":_vm.list,"query":_vm.query},on:{"change":val => _vm.$emit('update:query', val)}}):_vm._e(),(_vm.supportsFilters)?_c('core-button',{staticClass:"mx-2",attrs:{"icon":['far', 'filter']},on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}},[_c('p',{staticClass:"inline-flex"},[_vm._v(_vm._s(_vm.$t("to_filter")))]),(_vm.numberOfFilters)?_c('core-badge',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.numberOfFilters)+" ")]):_vm._e()],1):_vm._e(),(_vm.supportsFilters && _vm.$_.isEmpty(_vm.view) && _vm.numberOfFilters)?_c('core-button',{attrs:{"icon":['far', 'save'],"type":"default"},on:{"click":function($event){_vm.showSaveFilter = !_vm.showSaveFilter}}},[(
          !this.$store.state.breakpoint.isSmall &&
            !this.$store.state.breakpoint.isMedium &&
            !this.$store.state.breakpoint.noMatch
        )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("save_entity", { type: _vm.$tc("view").toLowerCase() }))+" ")]):_vm._e()]):(_vm.supportsFilters && !_vm.$_.isEmpty(_vm.view))?_c('div',{staticClass:"saved-view__actions ml-2"},[_c('el-popover',{attrs:{"placement":"bottom-end","width":"260","trigger":"click","popper-options":{
          boundariesElement: 'body',
          gpuAcceleration: true,
          positionFixed: true,
          preventOverflow: true
        },"append-to-body":false,"popper-class":"action-popup available-views"}},[_c('core-button',{staticClass:"h-full",attrs:{"slot":"reference","type":"default","icon":['far', 'filter']},slot:"reference"},[_c('span',{staticClass:"hidden md:block"},[_vm._v(" "+_vm._s(_vm.$tc("view"))+" ")])]),_c('el-col',{staticClass:"action-popup__menu"},[_c('el-row',{staticClass:"action-popup__menu-item",nativeOn:{"click":function($event){return (() => {
                _vm.dialogType = 'create';
                _vm.showSaveFilter = !_vm.showSaveFilter;
              }).apply(null, arguments)}}},[_c('div',{staticClass:"action-popup__menu-item__wrapper"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("create_entity", { entity: _vm.$tc("view").toLowerCase() }))+" ")])])]),_c('el-row',{staticClass:"action-popup__menu-item",nativeOn:{"click":function($event){return (() => {
                _vm.dialogType = 'update';
                _vm.showSaveFilter = !_vm.showSaveFilter;
              }).apply(null, arguments)}}},[_c('div',{staticClass:"action-popup__menu-item__wrapper"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("update_entity", { entity: _vm.$tc("view").toLowerCase() }))+" ")])])]),_c('el-row',{staticClass:"action-popup__menu-item",nativeOn:{"click":function($event){return _vm.deleteFilter(_vm.view)}}},[_c('div',{staticClass:"action-popup__menu-item__wrapper"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("delete_entity", { entity: _vm.$tc("view").toLowerCase() }))+" ")])])])],1)],1)],1):_vm._e(),(_vm.filteredAvailableViews.length !== 0)?_c('div',{staticClass:"saved-views"},[(_vm.supportsFilters && _vm.availableViews.length)?_c('el-popover',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
          () => {
            _vm.open = false;
          }
        ),expression:"\n          () => {\n            open = false;\n          }\n        "}],attrs:{"placement":"bottom-end","width":"260","trigger":"manual","value":_vm.open,"popper-options":{
          boundariesElement: 'body',
          gpuAcceleration: true,
          positionFixed: true,
          preventOverflow: true
        },"append-to-body":false,"popper-class":"action-popup available-views"}},[_c('core-button',{staticClass:"h-full",attrs:{"slot":"reference","icon":['far', 'bookmark']},on:{"click":function($event){_vm.open = !_vm.open}},slot:"reference"},[_c('span',{staticClass:"hidden md:block"},[_vm._v(" "+_vm._s(_vm.$t("saved", { type: _vm.$tc("view", 99).toLowerCase() }))+" ")])]),_c('el-col',{staticClass:"action-popup__menu"},[_c('core-search',{attrs:{"query":_vm.availableViewsFilter,"rounded":false,"bordered":false},on:{"update:query":val => (_vm.availableViewsFilter = val)}}),_vm._l((_vm.filteredAvailableViews),function(av){return _c('el-row',{key:av.id,staticClass:"action-popup__menu-item",class:{ 'is-active': _vm.view.id === av.id }},[_c('div',{staticClass:"action-popup__menu-item__wrapper",on:{"click":function($event){_vm.open = !_vm.open}}},[_c('div',{on:{"click":() => _vm.setActiveView(av.id)}},[_vm._v(" "+_vm._s(_vm.$t(av.name))+" ")])])])}),(!_vm.filteredAvailableViews || !_vm.filteredAvailableViews.length)?_c('el-row',{staticClass:"action-popup__menu-item"},[_c('div',{staticClass:"action-popup__menu-item__wrapper"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("no_views_found"))+" ")])])]):_vm._e()],2)],1):_vm._e()],1):_vm._e(),_vm._t("actions")],2),_c('core-dialog',{attrs:{"title":this.$t(`${_vm.dialogType}_entity`, {
        entity: _vm.$tc('view').toLowerCase()
      }),"visible":_vm.showSaveFilter,"xs_width":90,"lg_width":30},on:{"update:visible":function($event){_vm.showSaveFilter=$event}}},[_c('div',{staticClass:"form wrapper"},[(_vm.dialogType === 'create')?_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t("save_view_description"))+" ")]):_vm._e(),_c('core-input',{attrs:{"label":_vm.$t('name')},model:{value:(_vm.viewName),callback:function ($$v) {_vm.viewName=$$v},expression:"viewName"}})],1),_c('span',{staticClass:"dialog-footer flex",attrs:{"slot":"footer"},slot:"footer"},[_c('core-button',{staticClass:"mr-auto",attrs:{"type":"default_outline"},on:{"click":function($event){_vm.showSaveFilter = false}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),(_vm.dialogType === 'update')?_c('core-button',{attrs:{"type":"coolrunner_outline","disabled":!_vm.viewName.length,"loading":_vm.saving},on:{"click":function($event){return _vm.saveFilter(true)}}},[_vm._v(_vm._s(_vm.$t("update")))]):_c('core-button',{attrs:{"type":"coolrunner","disabled":!_vm.viewName.length,"loading":_vm.saving},on:{"click":function($event){!_vm.disabled && _vm.saveFilter()}}},[_vm._v(_vm._s(_vm.$t("save_new_entity", { type: _vm.$tc("view").toLowerCase() })))])],1)]),_c('core-filter-modal',{attrs:{"visible":_vm.showFilter,"filters":_vm.filters,"showIntervalSelector":false,"numberOfFilters":_vm.numberOfFilters,"viewType":_vm.viewType},on:{"confirm":_vm.confirmFilters,"update-filter":_vm.updateFilter,"remove-filter":_vm.removeFilter}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }