<template>
  <core-dialog
    destroy-on-close
    :title="this.$t(`edit`)"
    :visible="visible"
    @update:visible="close"
    :xs_width="90"
    :lg_width="30"
  >
    <div class="form wrapper">
      <div class="grid-container">
        <row :gutter="20">
          <template v-for="key in Object.keys(fieldsToAutoGenerate)">
            <el-divider content-position="left" :key="key">{{
              $t(key)
            }}</el-divider>

            <column
              v-for="subkey in Object.keys(fieldsToAutoGenerate[key])"
              :key="subkey"
              :xs="fieldsToAutoGenerate[key][subkey].width"
            >
              <div style="display: flex; align-items: center;">
                <core-checkbox
                  v-if="isBulk"
                  style="margin-right: 10px"
                  :value="shouldFieldBeUpdated(subkey)"
                  @change="val => toggleFieldBeUpdated(subkey, val)"
                />
                <component
                  v-if="subkey !== 'cps'"
                  :is="fieldsToAutoGenerate[key][subkey].component"
                  v-model="entity[subkey]"
                  :options="fieldsToAutoGenerate[key][subkey].options"
                  :label="fieldsToAutoGenerate[key][subkey].label"
                  @change="val => setFieldBeUpdated(subkey)"
                />
                <core-select-cps
                  v-else
                  :valueIsObject="false"
                  :value="
                    $_.join(
                      [
                        entity.carrier,
                        entity.carrier_product,
                        entity.carrier_service
                      ],
                      '_'
                    )
                  "
                  @change="val => setFieldBeUpdated(subkey)"
                  @update:value="splitCps"
                />
              </div>
            </column>
          </template>

          <div v-if="entity.order_lines" class="h-auto mt-2 mx-2 w-full text-left border border-gray-300 rounded-md" >
            <pre>{{ formatted }}</pre>
          </div>
        </row>
      </div>
    </div>
    <span slot="footer" class="dialog-footer flex">
      <core-button type="default_outline" class="mr-auto" @click="close">{{
        $t("close")
      }}</core-button>
      <core-button
        :disabled="!this.fieldsToUpdate.length"
        :loading="saving"
        type="coolrunner"
        @click="save"
        >{{ state === "failed" ? $t("save_restore") : $t("save") }}</core-button
      >
    </span>
  </core-dialog>
</template>
<script>
import I18n from "@/plugins/i18n";
import unescapeJs from "unescape-js";
export default {
  name: "ShipmentEditDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    shipment: {
      type: Object,
      default: null
    },
    state: {
      type: String,
      default: ""
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    fieldsToUpdate: [],
    emptyEntity: {
      receiver_name: "",
      receiver_attention: "",
      receiver_street1: "",
      receiver_street2: "",
      receiver_zip_code: "",
      receiver_city: "",
      receiver_country: "",
      receiver_phone: "",
      receiver_email: "",
      receiver_notify: "",
      receiver_notify_sms: "",
      receiver_notify_email: "",
      sender_name: "",
      sender_attention: "",
      sender_street1: "",
      sender_street2: "",
      sender_zip_code: "",
      sender_city: "",
      sender_country: "",
      sender_phone: "",
      sender_email: "",
      servicepoint_id: "",
      carrier: "",
      carrier_product: "",
      carrier_service: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      reference: "",
      description: "",
      comment: "",
      label_format: "",
      order_lines: []
    },
    entity: {},
    fieldsToAutoGenerate: {
      receiver: {
        receiver_name: {
          width: 12,
          label: I18n.t("name"),
          component: "core-text"
        },
        receiver_attention: {
          width: 12,
          label: I18n.t("attention"),
          component: "core-text"
        },
        receiver_street1: {
          width: 12,
          label: `${I18n.t("street")} 1`,
          component: "core-text"
        },
        receiver_street2: {
          width: 12,
          label: `${I18n.t("street")} 2`,
          component: "core-text"
        },
        receiver_zip_code: {
          width: 4,
          label: I18n.t("abrreviations.zip_code"),
          component: "core-text"
        },
        receiver_city: {
          width: 8,
          label: I18n.t("city"),
          component: "core-text"
        },
        receiver_country: {
          width: 12,
          label: I18n.t("country"),
          component: "core-select-country"
        },
        receiver_phone: {
          width: 12,
          label: I18n.t("phone"),
          component: "core-text"
        },
        receiver_email: {
          width: 12,
          label: I18n.t("email"),
          component: "core-text"
        }
      },
      sender: {
        sender_name: {
          width: 12,
          label: I18n.t("name"),
          component: "core-text"
        },
        sender_attention: {
          width: 12,
          label: I18n.t("attention"),
          component: "core-text"
        },
        sender_street1: {
          width: 12,
          label: `${I18n.t("street")} 1`,
          component: "core-text"
        },
        sender_street2: {
          width: 12,
          label: `${I18n.t("street")} 2`,
          component: "core-text"
        },
        sender_zip_code: {
          width: 4,
          label: I18n.t("zip_code"),
          component: "core-text"
        },
        sender_city: {
          width: 8,
          label: I18n.t("city"),
          component: "core-text"
        },
        sender_country: {
          width: 12,
          label: I18n.t("country"),
          component: "core-select-country"
        },
        sender_phone: {
          width: 12,
          label: I18n.t("phone"),
          component: "core-text"
        },
        sender_email: {
          width: 12,
          label: I18n.t("email"),
          component: "core-text"
        }
      },
      package_information: {
        weight: {
          width: 6,
          label: I18n.t("weight"),
          component: "core-number"
        },
        height: {
          width: 6,
          label: I18n.t("height"),
          component: "core-number"
        },
        length: {
          width: 6,
          label: I18n.t("length"),
          component: "core-number"
        },

        width: {
          width: 6,
          label: I18n.t("width"),
          component: "core-number"
        },
        reference: {
          width: 12,
          label: I18n.t("reference"),
          component: "core-textarea"
        },
        description: {
          width: 12,
          label: I18n.t("description"),
          component: "core-textarea"
        },
        comment: {
          width: 12,
          label: I18n.t("comment"),
          component: "core-textarea"
        },
        cps: {
          width: 12,
          label: I18n.t("carrier_service")
        },
        label_format: {
          width: 12,
          label: I18n.t("label_size"),
          options: [
            {
              label: "A4",
              value: "A4"
            },
            {
              label: "LabelPrint",
              value: "LabelPrint"
            }
          ],
          component: "core-select"
        },
      }
    }
  }),
  watch: {
    shipment: function(val) {
      this.entity = val ? { ...val.data } : {};
    }
  },
  computed: {
    isBulk: function() {
      return this.$_.isEmpty(this.shipment);
    },
    formatted: function() {
      try {
        let unescaped = unescapeJs(this.entity.order_lines);

        if (
          unescaped.charAt(0) === '"' &&
          unescaped.charAt(unescaped.length - 1) === '"'
        ) {
          unescaped = unescaped.substr(1, unescaped.length - 2);
        }

        const parsed = JSON.parse(unescaped);

        return JSON.stringify(parsed, null, 4);
      } catch {
        return this.json;
      }
    }
  },
  methods: {
    shouldFieldBeUpdated: function(field) {
      return this.fieldsToUpdate.includes(field);
    },
    setFieldBeUpdated: function(field) {
      const fields = [...this.fieldsToUpdate];
      if (fields.indexOf(field) == -1) {
        fields.push(field);
        this.fieldsToUpdate = fields;
      }
    },
    toggleFieldBeUpdated: function(field, val) {
      const fields = [...this.fieldsToUpdate];
      const index = fields.indexOf(field);
      if (!val) {
        fields.splice(index, 1);
      } else {
        fields.push(field);
      }

      this.fieldsToUpdate = fields;
    },

    splitCps: function(cps) {
      const splits = cps.split("_");
      this.entity.carrier = splits.shift();
      this.entity.carrier_product = splits.shift() || "";
      this.entity.carrier_service = splits.join("_");
    },

    close: function() {
      this.fieldsToUpdate = [];
      this.$emit("update:visible", false);
    },
    save: function() {
      const entity = { ...this.entity };

      let summary = "";
      const receiver_changes = [];
      const sender_changes = [];
      const package_info_changes = [];
      this.$_.each(this.fieldsToUpdate, field => {
        const prefix = field.split("_")[0];
        switch (prefix) {
          case "receiver":
            receiver_changes.push(field);
            break;
          case "sender":
            sender_changes.push(field);
            break;
          default:
            package_info_changes.push(field);
            break;
        }
      });

      if (receiver_changes.length > 0) {
        summary += `<p>${this.$t("receiver")}:</p>`;
        summary += `<ul style="margin-left: 20px">`;

        this.$_.each(receiver_changes, c => {
          summary += `<li style="font-size: 12px">${
            this.fieldsToAutoGenerate.receiver[c].label
          }: ${entity[c] || ""}</li>`;
        });
        summary += `</ul>`;
      }

      if (sender_changes.length > 0) {
        summary += `<p>${this.$t("sender")}:</p>`;
        summary += `<ul style="margin-left: 20px">`;

        this.$_.each(sender_changes, c => {
          summary += `<li style="font-size: 12px">${
            this.fieldsToAutoGenerate.sender[c].label
          }: ${entity[c] || ""}</li>`;
        });
        summary += `</ul>`;
      }

      if (package_info_changes.length > 0) {
        summary += `<p>${this.$t("package_information")}:</p>`;
        summary += `<ul style="margin-left: 20px">`;

        this.$_.each(package_info_changes, c => {
          let val = entity[c];
          if (c === "cps") {
            val = this.$_.join(
              [entity.carrier, entity.carrier_product, entity.carrier_service],
              "_"
            );
          }

          summary += `<li style="font-size: 12px">${
            this.fieldsToAutoGenerate.package_information[c].label
          }: ${val || ""}</li>`;
        });
        summary += `</ul>`;
      }

      this.$confirm(summary, this.$t("confirm_changes"), {
        dangerouslyUseHTMLString: true
      }).then(() => {
        if (this.isBulk)
          this.$_.each(Object.keys(entity), key => {
            if (this.fieldsToUpdate.includes(key)) return;
          });
        this.$emit("save", entity);
      });
    }
  }
};
</script>
