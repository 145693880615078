<template>
  <core-view>
    <core-view-header
      :name="name"
      :count="totalItems"
      :description="'page_descriptions.invoices'"
    />
    <core-list-new-table
      :items="items"
      :headers="headers"
      :pageSize.sync="options.itemsPerPage"
      :currentPage.sync="options.page"
      :totalItems="totalItems"
      :loading="loading"
      :orderBy.sync="options.orderBy"
      :orderDir.sync="options.orderDir"
      :query.sync="search"
      :selected="selected"
      :excluded="excluded"
      :selectionKey="selectionKey"
      @checkbox-change="handleSelection"
      :selectAll.sync="selectAll"
      :rowActions="rowActions"
      :bulkActions="bulkActions"
      :entityName="name"
      :emptyListIcon="icon"
    />
  </core-view>
</template>

<script>
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";
import { URL_PREFIX } from "../../utils/constants";
export default {
  mixins: [List],
  data: () => ({
    endpoint: "invoices",
    name: "invoice",
    icon: ["fal", "file-invoice-dollar"],
    selectionKey: "number",
    viewType: "invoice",
    headers: [
      {
        label: "invoice_number",
        key: "number"
      },
      {
        label: "amount",
        key: "amount",
        type: "price"
      },
      { label: "type", key: "invoice_type" },
      { label: "created", key: "created_at", type: "datetime" }
    ]
  }),
  mounted: function() {
    this.getDataFromApi();
  },
  computed: {
    rowActions: function() {
      return [
        {
          label: this.$t("download_entity", {
            entity: this.$t("pdf").toLowerCase()
          }),
          icon: ["far", "file-download"],
          link: item =>
            item.url + "?access_token=" + this.$store.state.auth.access_token + '&account_id=' + this.$store.state.account_id
        },
        {
          label: this.$t("download_entity", {
            entity: this.$t("specification").toLowerCase()
          }),
          icon: ["far", "file-download"],
          link: item =>
            item.specification_url +
            "?access_token=" +
            this.$store.state.auth.access_token + '&account_id=' + this.$store.state.account_id
        }
      ];
    },
    bulkActions: function() {
      return [
        {
          label: this.$t("download_entity", {
            entity: this.$t("pdf").toLowerCase()
          }),
          icon: ["far", "file-download"],
          link: () => this.bulkPdfLink
        },
        {
          label: this.$t("download_entity", {
            entity: this.$t("specification").toLowerCase()
          }),
          icon: ["far", "file-download"],
          link: () => this.bulkSpecificationLink
        }
      ];
    },
    bulkPdfLink: function() {
      if (!this.selected) return;
      return `${URL_PREFIX}/business-panel/invoices/bulk?type=pdf&invoice_numbers=${this.$_.join(
        this.selected,
        ","
      )}&access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`;
    },
    bulkSpecificationLink: function() {
      if (!this.selected) return;
      return `${URL_PREFIX}/business-panel/invoices/bulk?type=specification&invoice_numbers=${this.$_.join(
        this.selected,
        ","
      )}&access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`;
    }
  },
  methods: {
    convertUTCToLocal: function(val) {
      return convertUTCToLocal(val, "date");
    },
  }
};
</script>
