<template>
  <core-view>
    <div v-if="!canView">
      {{$t('page_restricted')}}
    </div>
    <div v-if="canView">
    <core-view-header
      :name="'api_key'"
      :description="'page_descriptions.api_keys'"
    >
      <template v-slot:description>
        <i18n path="page_descriptions.api_keys" tag="p">
          <template v-slot:link>
            <a href="https://docs.coolrunner.dk" target="_blank">{{
              $t("api_documentation")
            }}</a>
          </template>
        </i18n>
      </template>
    </core-view-header>
    <el-container
      class="bg-white flex-grow-0 shadow-sm"
      style="padding: 0px 30px 30px; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
    >
      <el-col class="form" style="padding:0 !important">
        <el-row
          class="grid-content"
          type="flex"
          size="small"
          align="middle"
          style="margin-bottom: 10px"
        >
          <core-input
            readonly
            :value="integration.username"
            :label="$t('username')"
          />
        </el-row>
        <h5 style="text-align:left; margin-top:20px">Token</h5>
        <el-row
          class="grid-content"
          type="flex"
          size="small"
          align="middle"
          style="margin-bottom: 10px"
        >
          <core-clipboard-input
            :label="$tc('api_key', 1)"
            :value="integration.token"
            ref="copyToClipboard"
            diabled="disabled"
          >
          </core-clipboard-input>
          <el-button
                @click="updateToken"
                class="reload-url h-12 ml-2"
                :class="{ loading: loading }"
                :disabled="loading"
              >
                <font-awesome-icon :icon="['far', 'sync']" />
          </el-button>
        </el-row>
        <br />
        <el-row
          v-if="integration.token"
          class="grid-content"
          type="flex"
          size="small"
          align="middle"
        >
          <h5>Basic authorization token</h5>
        </el-row>
        <el-row
          v-if="integration.token"
          class="grid-content"
          type="flex"
          size="small"
          align="middle"
          style="margin-bottom: 10px"
        >
          <core-clipboard-input
            :label="$tc('api_key', 1)"
            :value="convertToBase64(integration.username, integration.token)"
            disabled="disabled"
            ref="copyToClipboard"
          >
            <template v-slot:append>
              <el-button
                @click.native="copyToClipboard"
                class="reload-url"
                :class="{ loading: loading }"
                :disabled="loading"
              >
                <font-awesome-icon :icon="['far', 'copy']" />
              </el-button>
            </template>
          </core-clipboard-input>
        </el-row>
      </el-col>
    </el-container>
  </div>
  </core-view>
</template>

<script>
import { convertUTCToLocal } from "@/utils/date";
import pageAccess from "@/mixins/PageAccess";

export default {
  name: "APIKeys",
  mixins:[pageAccess],
  data: () => ({
    loading: false
  }),
  computed: {
    integration: function() {
      return this.$store.state.account.integration || {};
    }
  },
  methods: {
    convertUTCToLocal: function(val) {
      return convertUTCToLocal(val, "datetime");
    },
    convertToBase64: function(username, token) {
      let base = btoa(`${username}:${token}`);
      let converted = `basic ${base}`;
      return converted;
    },
    updateToken: function(val) {
      this.$confirm(
        this.$t("generate_token_confirm"),
        this.$t("generate_token")
      ).then(() => {
        this.loading = true;
        this.$put(`/account/integration-token/update`)
          .then(this.$store.dispatch("loadAccount"))
          .then(() => {
            this.$notification({
              title: this.$t("token_generation_success"),
              duration: 2500
            });
          })
          .catch(e => {
            this.$notification({
              title: this.$t("token_generation_failed"),
              duration: 2500,
              type: "error"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    copyToClipboard() {
      this.$refs.copyToClipboard.copyToClipboard();
    }
  }
};
</script>

<style lang="scss">
.reload-url.loading {
  svg {
    animation: fa-spin 2s infinite linear;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: fa-spin;
  }
}
</style>
