var render = function render(){var _vm=this,_c=_vm._self._c;return _c('core-select',{ref:"select",attrs:{"filterable":"","remote":"","remote-method":_vm.setQuery,"loading":_vm.loading,"options":_vm.list,"labelKey":_vm.labelKey,"valueKey":_vm.valueKey,"value":_vm.value[_vm.valueKey],"paginate":_vm.paginate,"query":_vm.search,"label":_vm.placeholder},on:{"update:value":val =>
      _vm.$emit(
        'update:value',
        _vm.$_.find(_vm.list, i => i[_vm.valueKey] === val)
      ),"bottom-reached":inf => {
      _vm.infinity = inf;
      _vm.options = { ..._vm.options, page: _vm.options.page + 1 };
      _vm.remoteMethod();
    }}})
}
var staticRenderFns = []

export { render, staticRenderFns }