import axios from "axios";
export default {
  data() {
    return {
      useDev: false,
      valid: true,
      endpoint: "",
      items: [],
      itemsLoaded: false,
      totalItems: 0,
      options: {
        itemsPerPage: 20,
        page: 1,
        orderBy: "created_at",
        orderDir: "desc"
      },
      loading: true,
      search: "",
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialog: false,
      name: "",
      cancelToken: null,
      selected: [],
      excluded: [],
      saving: false,
      paginate: true,
      selectionKey: null,
      selectAll: false,
      reloadOnDelete: false,
      mutateLocation: true
    };
  },
  computed: {
    params: function() {
      return {
        limit: this.options.itemsPerPage,
        page: this.options.page,
        order_by: this.options.orderBy || "created_at",
        order_dir: this.options.orderDir || "desc",
        q: this.search
      };
    }
  },
  watch: {
    params: {
      handler(val, oldVal) {
        if (val.q !== oldVal.q) this.options = { ...this.options, page: 1 };
        else this.getDataFromApi();
      },
      deep: true
    },
    items: function(val) {
      if (this.selectAll)
        this.handleSelection(
          this.selectAll,
          this.$_.filter(
            val,
            v => !this.$_.includes(this.excluded, v[this.selectionKey])
          )
        );
    },
    selectAll: function(val) {
      this.handleSelection(val, this.items);
    }
  },
  created() {
    const { q, limit, page, order_by, order_dir } = this.$route.query;

    if (limit) {
      this.options.itemsPerPage = parseInt(limit);
    }
    if (page) {
      this.options.page = parseInt(page);
    }
    if (order_by) {
      this.options.orderBy = order_by;
    }
    if (order_dir) {
      this.options.orderDir = order_dir;
    }
    if (q) {
      this.search = q;
    }
  },
  methods: {
    getSelectedItems: function() {
      const items = { ...this.items };
      return this.$_.pullAt(items, this.selected);
    },
    getJoinedPropFromSelected: function(prop) {
      return this.$_.join(this.$_.map(this.getSelectedItems(), prop), ",");
    },
    getDataFromApi() {
      this.itemsLoaded = false
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();

      this.loading = true;

      this.$get(
        `/${this.endpoint}`,
        {
          paginate: this.paginate ? 1 : 0,
          ...this.params,
          cancelToken: this.cancelToken.token
        },
        { mutateLocation: this.mutateLocation, useDev: this.useDev }
      )
        .then(response => {
          this.items = response.data;
          this.totalItems = response.total;
          this.loading = false;
          this.itemsLoaded = true;
          this.afterFetch(response);
        })
        .catch(e => {
          if (e !== "cancelled") {
            this.loading = false;
            this.itemsLoaded = true;
          } 
        });
    },
    setSort: function(queryInfo) {
      if (queryInfo.type !== "sort") return;

      const opts = { ...this.options };

      opts.orderBy = queryInfo.sort.prop;
      opts.orderDir = queryInfo.sort.order;

      this.options = opts;
    },
    deleteItem(item) {
      const index = this.$_.findIndex(this.items, c => c.id === item.id);

      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$tc(this.name, 1).toLowerCase()
        })
      ).then(() => {
        if (index >= 0) {
          this.loading = true;
          this.$del(`/${this.endpoint}/${item.id}`, undefined, {
            useDev: this.useDev
          })
            .then(() => {
              if (this.reloadOnDelete) return this.getDataFromApi();

              return Promise.resolve();
            })
            .then(() => {
              if (!this.reloadOnDelete) {
                let items = [...this.items];
                items.splice(index, 1);
                this.items = items;
              }
              this.$notification({
                title: this.$t("success"),
                text: `${this.$tc(this.name)} ${this.$t(
                  "has_been_deleted"
                ).toLowerCase()}`,
                type: "success"
              });
            })
            .catch(e => {
              this.$notification({
                title: this.$t("not_deleted"),
                text: `${this.$tc(this.name)} ${this.$t(
                  "has_not_been_deleted"
                ).toLowerCase()}`,
                type: "error"
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    editItem(item = {}) {
      this.editedIndex = this.$_.findIndex(this.items, c => c.id === item.id);
      if (this.editedIndex !== -1) {
        this.editedItem = { ...item };
      } else {
        this.editedItem = { ...this.defaultItem };
      }
      this.dialog = true;
    },
    save() {
      let httpMethod = this.$post;
      if (this.editedItem.id) {
        httpMethod = this.$put;
      }
      this.saving = true;
      let url = this.endpoint;

      if (this.editedItem.id) {
        url += `/${this.editedItem.id}`;
      }

      httpMethod(url, this.editedItem, { useDev: this.useDev })
        .then(() => {
          this.getDataFromApi();
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });

          this.close();
          this.afterSave();
        })
        .catch(() => {
          this.$notification({
            title: this.$t("not_saved"),
            text: `${this.$tc(this.name)} ${this.$t(
              "has_not_been_saved"
            ).toLowerCase()}`,
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    close() {
      this.dialog = false;
      this.afterClose();
    },
    afterClose() {
      this.$refs.form && this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    afterFetch() {},
    afterSave() {},
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    handleSelection(val, item) {
      if (this.$_.isArray(val)) {
        this.selected = val.map(({ id }) => id);
        this.excluded = [];
        return;
      }
      if (this.$_.isBoolean(val)) {
        if (val)
          this.selected = this.selectionKey
            ? this.$_.union(this.selected, this.$_.map(item, this.selectionKey))
            : this.$_.range(this.items.length);
        else {
          this.selected = [];
          this.excluded = [];
        }
        return;
      }

      const selectionValue = this.selectionKey
        ? item[this.selectionKey]
        : val.index;

      const selected = [...this.selected];
      const excluded = [...this.excluded];

      const selectedArrayIndex = this.$_.indexOf(selected, selectionValue);
      const excludedArrayIndex = this.$_.indexOf(excluded, selectionValue);
      if (selectedArrayIndex == -1) {
        selected.push(selectionValue);
        this.selected = selected;
        if (this.selectAll && excludedArrayIndex > -1) {
          excluded.splice(excludedArrayIndex, 1);
        }
      } else {
        selected.splice(selectedArrayIndex, 1);
        this.selectAll && excluded.push(selectionValue);
      }

      if (this.selectAll) this.excluded = excluded;
      this.selected = selected;
    }
  }
};
