import I18n from "@/plugins/i18n";

export default {
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmLeavePage()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  data: () => ({
    askBeforeLeave: false,
    confirmLeavePageText: I18n.t("confirm_leave.default")
  }),

  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },

  methods: {
    confirmLeave() {
      return window.confirm(this.confirmLeavePageText);
    },

    confirmLeavePage() {
      return this.askBeforeLeave && !this.confirmLeave();
    },

    beforeWindowUnload(e) {
      if (this.askBeforeLeave) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    }
  }
};
