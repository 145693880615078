<template>
  <button
    :disabled="isDiabled"
    class="rounded-md p-3 py-2.5 font-medium text-sm	flex flex-grow-0 items-center justify-center hover:shadow-md whitespace-nowrap focus:border-none disabled:cursor-default disabled:shadow-none disabled:bg-btn-grey-disabled disabled:text-disabled disabled:border-0 transition duration-300 ease-in-out h-[42px]"
    :class="buttonClass"

    @click="$emit('click')"
  >
    <font-awesome-icon v-if="icon && !loading" :icon="icon" fixed-width class="font-medium text-base" />
    <div
      :class="[
        {
          'ml-1': !!icon && $slots.default,
          'mr-1': !!suffixIcon && $slots.default,
        },
        ...iconClass,
      ]"
    >
      <template v-if="loading">
        <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width spin />
      </template>
      <template v-else>
        <slot />
      </template>
    </div>
    <font-awesome-icon v-if="suffixIcon && !loading" :icon="suffixIcon" fixed-width class="font-medium text-base" />
  </button>
</template>

<script>
const types = {
  default: "bg-btn-grey-500 hover:bg-btn-grey-400 text-default",
  coolrunner: "bg-coolrunner-500 hover:bg-coolrunner-400 text-white hover:shadow-sm-coolrunner",
  coolrunner_outline: "border border-solid border-coolrunner-500 text-coolrunner-500 hover:shadow-sm-coolrunner",
  default_outline: "border-solid border border-btn-grey-600  text-default hover:shadow-sm",
  default_add: "bg-gray-500 hover:bg-gray-400 text-white hover:shadow-md",
  delete: "bg-red-500 hover:bg-red-400 hover:shadow-sm-red text-white",
  delete_outline: "border border-solid border-red-500  text-red-500 hover:shadow-sm-red",
  dark: "text-white bg-[#004062] hover:bg-[#0a5075] cursor-pointer transition-all duration-300 font-semibold",
  white: "text-[#004062] bg-white hover:bg-[#f5f5f5] cursor-pointer transition-all duration-300 font-semibold",
};
export default {
  props: {
    type: {
      type: String,
      default: "default",
      validator: function(value) {
        return Object.keys(types).includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    extraClasses: {
      type: String,
      default: "",
    },
    icon: {
      type: Array,
      default: undefined,
    },
    suffixIcon: {
      type: Array,
      default: undefined,
    },
    iconClass: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass: function() {
      return `${types[this.type]} ${this.extraClasses}`;
    },
    isDiabled: function() {
      return this.disabled || this.loading;
    },
  },
};
</script>
