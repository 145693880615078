<template>
  <core-view>
    <div v-if="!canView">
      {{$t('page_restricted')}}
    </div>
    <div v-if="canView">
    <core-view-header :name="name" :count="totalItems">
      <template v-slot:description>
        <i18n
          path="page_descriptions.checkouts"
          tag="div"
          class="small"
          style="white-space: pre-line;text-align: left; margin-top: 1em; max-width: 70%"
        >
          <template v-slot:link>
            <router-link class="underline" :to="{ name: 'integrations_shops' }">{{
                $t("my_shops")
              }}
            </router-link>
          </template>
        </i18n>
      </template>
    </core-view-header>
    <core-list-new-table
      :items="items"
      :headers="headers"
      :pageSize="options.itemsPerPage"
      :currentPage.sync="options.page"
      :totalItems="totalItems"
      :loading="loading"
      :orderBy.sync="options.orderBy"
      :orderDir.sync="options.orderDir"
      :query.sync="search"
      :selected="selected"
      @checkbox-change="handleSelection"
      :selectAll.sync="selectAll"
      :rowActions="rowActions"
      :entityName="name"
      :show-create-new="true"
      :checkbox="false"
      :selection="false"
      @create-new-clicked="editItem()"
      pushPath="tools_smartcheckout_checkout"
    >
      <template v-slot:[`column.shops`]="{ item }">
        <div class="flex">
          <div
            :key="shop"
            v-for="shop in item.shops"
            class="bg-coolrunner-500 rounded-full px-2 py-1 text-white w-auto mr-2"
          >
            {{ shop }}
          </div>
        </div>
      </template>
      <template v-slot:[`column.no_of_shipping_methods`]="{ item }">
        <div>
          {{ item.shipping_methods.length }}
        </div>
      </template>
    </core-list-new-table>
    <core-dialog
      :title="
        this.$t(`create_entity`, {
          entity: $tc(name).toLowerCase()
        })
      "
      :visible.sync="dialog"
      :xs_width="90"
      :lg_width="30"
    >
      <div class="form wrapper">
        <row :columns="24" :gutter="20">
          <column :xs="24">
            <core-input :label="$t('name')" v-model="editedItem.name" />
          </column>
          <column :xs="24">
            <core-textarea
              :label="$t('description')"
              v-model="editedItem.description"
            />
          </column>
        </row>
      </div>
      <span slot="footer" class="dialog-footer flex">
        <core-button
          class="mr-auto"
          type="default_outline"
          @click="
            () => {
              dialog = false;
              editedItem = defaultItem;
            }
          "
        >{{ $t("cancel") }}</core-button
        >
        <core-button
          :disabled="!editedItem.name.length || !editedItem.description.length"
          :loading="saving"
          type="coolrunner"
          @click="
            !(!editedItem.name.length || !editedItem.description.length) &&
              saveItem()
          "
        >{{
            $t("save_new_entity", { type: $tc(name).toLowerCase() })
          }}</core-button
        >
      </span>
    </core-dialog>
  </div>
  </core-view>
</template>

<script>
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";
import pageAccess from "@/mixins/PageAccess";

export default {
  name: "SmartCheckouts",
  mixins: [List,pageAccess],
  data: () => ({
    endpoint: "smartcheckouts",
    name: "checkout",
    mutateLocation: true,
    headers: [
      {
        label: "name",
        key: "name",
        orderable: false,
        minWidth: "80"
      },
      {
        label: "shop",
        label_count: 99,
        key: "shops",
        orderable: false
      },
      {
        label: "shipping_method",
        label_count: 99,
        key: "no_of_shipping_methods",
        minWidth: "20",
        orderable: false
      }
    ],
    showCreate: false,
    editedItem: {
      name: "",
      description: "",
      shipping_methods: []
    },
    defaultItem: {
      name: "",
      description: "",
      shipping_methods: []
    },
    reloadOnDelete: true
  }),
  watch:{
    itemsLoaded: function(val) {
      if (val && this.$route.query.state == 'intro') {
        this.items = [{
          created_at: "2021-02-10T08:45:13.000000Z",
          description: "BF",
          id: 'test1',
          label: null,
          name: "Test Checkout",
          shipping_methods: ['TestShop'],
          shops: ['TestShop'],
          updated_at: "2022-07-04T11:44:57.000000Z"
        }]
        this.totalItems = 1
      }
    },
  },
  mounted: function() {
    if (this.$route.query.state) {
      this.mutateLocation = false;
    }
    this.getDataFromApi();
  },
  computed: {
    rowActions: function() {
      return [
        {
          label: this.$t("edit"),
          icon: ["far", "edit"],
          action: this.editItem
        },
        {
          label: this.$t("duplicate"),
          icon: ["far", "clone"],
          action: this.duplicateItem
        },
        {
          label: this.$t("delete"),
          icon: ["far", "trash"],
          action: this.deleteItem
        }
      ];
    }
  },
  methods: {
    editItem: function(item) {
      if (item) {
        this.loading = true;
        this.$router.push({
          name: "tools_smartcheckout_checkout",
          params: { smartcheckout_id: item.id }
        });
      } else this.dialog = true;
    },
    saveItem: function() {
      this.saving = true;
      this.$post(this.endpoint, this.editedItem)
        .then(r => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });

          this.$router.push({
            name: "tools_smartcheckout_checkout",
            params: { smartcheckout_id: r.id }
          });
        })
        .catch(() => {
          this.$notification({
            title: this.$t("not_saved"),
            text: `${this.$tc(this.name)} ${this.$t(
              "has_not_been_saved"
            ).toLowerCase()}`,
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    duplicateItem: function(item) {
      this.saving = true;
      this.$put(`/${this.endpoint}/${item.id}/duplicate`)
        .then(r => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });

          this.$router.push({
            name: "tools_smartcheckout_checkout",
            params: { smartcheckout_id: r.id }
          });
        })
        .catch(() => {
          this.$notification({
            title: this.$t("not_saved"),
            text: `${this.$tc(this.name)} ${this.$t(
              "has_not_been_saved"
            ).toLowerCase()}`,
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    convertUTCToLocal: function(val) {
      return convertUTCToLocal(val, "date");
    }
  }
};
</script>
