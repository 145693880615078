<template>
  <core-select
    class="checkbox"
    v-bind="$attrs"
    v-on="$listeners"
    :options="options"
    :labelKey="labelKey"
    :valueKey="valueKey"
    :value="value"
    clearable
    filterable
    :remote-method="fetchOptions"
    :loading="loading"
    :valueIsObject="valueIsObject"
    multiple
  >
    <template v-slot:option="{ option }">
      <div
        @click.exact="
          $emit(isOptionSelected(option) ? 'remove' : 'add', option)
        "
      >
        <core-checkbox
          @input="$emit(isOptionSelected(option) ? 'remove' : 'add', option)"
          style="margin-right: 8px;"
          :value="isOptionSelected(option)"
        />
        {{ option[labelKey] }}
        <div class="append">
          <slot name="option.append" v-bind:option="option" />
        </div>
      </div>
    </template>
  </core-select>
</template>
<script>
export default {
  name: "CheckboxSelect",
  inheritAttrs: false,
  props: {
    value: {},
    labelKey: {
      type: [String, Array, Function],
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    endpoint: {
      type: String,
      default: ""
    },
    valueIsObject: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: true,
    options: []
  }),
  created: function() {
    this.fetchOptions();
  },
  methods: {
    isOptionSelected: function(option) {
      return this.valueIsObject
        ? !!this.$_.find(this.value, [this.valueKey, option[this.valueKey]])
        : this.$_.includes(this.value, option);
    },

    fetchOptions: function(q) {
      this.loading = true;
      this.$get(this.endpoint, { q })
        .then(r => {
          this.options = r;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.core-select.checkbox {
  .el-select-dropdown__list {
    padding: 0;
  }
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
    height: 49.5px;
    padding: 4px;
    > div {
      width: 100%;
      height: 100%;
      padding: 12.5px;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }
    &:hover,
    &.hover {
      background-color: #fff;
      > div {
        background-color: #f5f7fa;
      }
    }

    .append {
      margin-left: auto;
    }
  }
}
</style>
