<template>
  <div id="app" class="relative" @dragover.prevent @drop.prevent>
    <router-view />
    <core-dialog-create-shipment
      v-if="showCreateShipment.visible"
      :duplicate="showCreateShipment.duplicate"
      @update:visible="
        (val) => (showCreateShipment = { visible: val, duplicate: null })
      "
    />
    <core-dialog-print
      :visible="!!printDialogAction"
      @update:visible="printDialogAction = null"
      @print="
        (alias) => (printDialogAction ? printDialogAction(alias) : () => null)
      "
    />
    <notifications width="450px">
      <template slot="body" slot-scope="props">
        <div class="vue-notification" :class="props.item.type">
          <div class="wrapper">
            <p class="title">
              {{ props.item.title }}
            </p>
            <div class="content" v-html="props.item.text" />

            <div
              v-if="props.item.data && props.item.data.actions"
              class="actions"
            >
              <core-button
                v-for="(action, index) in props.item.data.actions"
                :key="action.label"
                :type="index === 1 ? 'default_outline' : 'coolrunner'"
                @click="action.click"
                >{{ action.label }}</core-button
              >
            </div>
          </div>
          <a class="close" @click="props.close">
            <font-awesome-icon :icon="['far', 'times-circle']" />
          </a>
        </div>
      </template>
    </notifications>
    <core-dialog-fill-balance
      v-if="!!fillBalance"
      :visible="!!fillBalance"
      :balance="fillBalance.balance"
      :required-balance="fillBalance.required"
      @update:visible="fillBalance = null"
      @success="
        () => {
          fillBalance.success();
          fillBalance = null;
        }
      "
    />
  </div>
</template>

<script>
import Loader from "@/mixins/Loader";
import EventBus from "@/plugins/eventbus";
import { importerStates } from "@/utils/constants";
import { destroyEcho } from "./plugins/laravel-echo";
import { editScrollbarOnWindows } from "@/utils/scrollbar";
export default {
  mixins: [Loader],
  data: () => ({
    defaultLoaderTarget: "#app",
    showCreateShipment: {
      visible: false,
      duplicate: null,
    },
    printDialogAction: null,
    fillBalance: null,
    showLabelDetails: false,
    labelDetails: {},
    importSocketChannel: "",
  }),
  computed: {
    importerStates: () => importerStates,
    importCounts: function() {
      return this.$store.state.importCounts;
    },
  },
  watch: {
    "$store.state.initialLoaded": {
      handler: function(val, old) {
        if (val === old) return;

        this.loading = !val;

        if (!val && old) this.setupImporterSockets();
      },
      immediate: true,
    },
    "$store.state.account.id": function(val, old) {
      if (!val && old) this.destroyImporterSockets(old);
    },
    "importCounts.done": function(val, old) {
      const key = "imported_done";
      this.$notification.close(key);
      if (val)
        this.$notification({
          id: key,
          title: this.$tc("new_shipment_generated", val),
          text: this.$tc("n_shipments_has_been_generated", val, { count: val }),
          type: "success",
          data: {
            actions: [
              {
                label: this.$t("show"),
                click: () =>
                  this.$router
                    .push({
                      name: "labels",
                      query: {
                        filter: { order_context: "imported_orders" },
                      },
                    })
                    .catch((err) => {}),
              },
            ],
          },
        });
    },
    "importCounts.awaiting": function(val, old) {
      const key = "imported_awaiting";
      this.$notification.close(key);
      if (val)
        this.$notification({
          id: key,
          title: this.$tc("new_shipment_imported", val),
          text: this.$tc("n_shipments_has_been_imported", val, { count: val }),
          type: "success",
          data: {
            actions: [
              {
                label: this.$t("show"),
                click: () =>
                  this.$router
                    .push({
                      name: "labels_import",
                      query: { state: "awaiting" },
                    })
                    .catch((err) => {}),
              },
            ],
          },
        });
    },
    "importCounts.failed": function(val, old) {
      const key = "imported_failed";
      this.$notification.close(key);
      if (val)
        this.$notification({
          id: key,
          title: this.$tc("new_shipment_failed", val),
          text: this.$tc("n_shipments_has_failed", val, { count: val }),
          type: "error",
          data: {
            actions: [
              {
                label: this.$t("show"),
                click: () =>
                  this.$router
                    .push({
                      name: "labels_import",
                      query: { state: "failed" },
                    })
                    .catch((err) => {}),
              },
            ],
          },
        });
    },
  },
  created: function() {
    editScrollbarOnWindows();
    this.loaderOptions = {
      ...this.loaderOptions,
      text: `${this.$t("fetching_data")}`,
    };

    EventBus.$on("SHOW_CREATE_SHIPMENT", (duplicate) => {
      this.showCreateShipment = {
        visible: true,
        duplicate,
      };
    });
    EventBus.$on("SHOW_LABEL_DETAILS", (row) => {
      this.showLabelDetails = true;
      this.labelDetails = row;
    });
    EventBus.$on("SHOW_PRINT_DIALOG", (act) => {
      this.printDialogAction = act;
    });
    EventBus.$on("SHOW_FILL_BALANCE", (fb) => {
      this.fillBalance = fb;
    });
  },
  destroyed: function() {
    this.destroyImporterSockets();
  },
  methods: {
    setupImporterSockets: function() {
      if (!this.$store?.state?.account?.id) return;

      this.importSocketChannel = `order_import.${this.$store.state.account.id}`;
      this.$_.each(importerStates, (label, key) => {
        this.$echo()
          .private(this.importSocketChannel)
          .listen(`.${key}`, (e) => {
            this.$store.dispatch("incrementImportStateCount", key);
          });
      });
    },
    destroyImporterSockets: function() {
      if(!this.$echo()) return;
      this.$_.each(importerStates, (label, key) => {
        this.$echo()
          .private(this.importSocketChannel)
          .stopListening(`.${key}`);
      });

      this.importSocketChannel = "";
      destroyEcho();
    },
  },
};
</script>
