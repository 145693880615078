<template>
  <core-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="options"
    labelKey="name"
    :label="$tc('checkout', 1)"
    valueKey="id"
    clearable
    remote
    filterable
    :remote-method="fetchOptions"
    :loading="loading"
    valueIsObject
  />
</template>
<script>
export default {
  name: "CheckoutSelect",
  inheritAttrs: false,
  data: () => ({
    loading: true,
    options: []
  }),
  created: function() {
    this.fetchOptions();
  },
  methods: {
    fetchOptions: function(q) {
      this.loading = true;
      this.$get("smartcheckouts/select-list", { q })
        .then(r => {
          this.options = r;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
