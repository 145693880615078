<template>
  <div class="w-full">
    <div class="absolute -top-28 right-4 md:right-8">
      <core-button
        type="coolrunner"
        @click="editVisible = true"
        >{{ $t('create_entity', { entity: $tc('user', 1).toLowerCase() }) }}
      </core-button>
    </div>
    <core-list-new-table
        :selection="false"
        :items="users"
        :headers="headers"
        :loading="loading"
        :orderBy.sync="orderBy"
        :orderDir.sync="orderDir"
        :currentPage.sync="page"
        :show-create-new="true"
        :totalItems="totalItems"
        :entityName="name"
        :pageSize="itemsPerPage"
        :showHeaderActions="false"
        :rowActions="rowActions"
        :paginate="false"
    >
      <template v-slot:column.role="{ item }">
        <div v-for="role in item.roles" :key="role.name">
          <p class="text-sm">
            {{role.name}}
          </p>
        </div>
      </template>
      <template v-slot:column.created_at="{ item }">
        <p class="text-sm">
          {{$moment(item.created_at).format('DD-MM-YYYY')}}
        </p>
      </template>
    </core-list-new-table>
    <core-dialog
        id="edit_user"
        :visible="editVisible"
        @update:visible="editVisible = false"
        :title="$t('edit_entity', { entity: $tc('user', 1).toLowerCase() })"
        :use_default_breakpoints="false"
        :lg_width="30"
        :xl_width="40"
        :md_width="50"
        :xs_width="90"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        >
        <div class="p-5 w-full">
          <core-input
            class="mt-3"
            :label="$t('name')"
            v-model="user.name"
          />
          <core-input
            class="mt-3"
            :label="$t('email')"
            v-model="user.email"
          />
          <core-select
            class="mt-2 w-full max-w-full"
            :options="roles"
            v-model="user.roles"
            :label="$t('select_entity', { entity: $tc('role', 1).toLowerCase() })"
            labelKey="name"
            valueKey="id"
            @input="val => (this.user.roles = val)"
          />
        </div>
        <div
        slot="footer"
        style="width: 100%"
        class="dialog-footer flex justify-end mt-4"
        >
        <slot name="buttons-prefix" />
        <core-button
            v-if="passwordEditable"
            type="coolrunner"
            @click="createUser()"
            class="ml-2"
            :disabled="formOkay"
            >{{ $t("create") }}
        </core-button>
        <core-button
            v-if="!passwordEditable"
            type="coolrunner"
            @click="updateUser()"
            class="ml-2"
            :disabled="updateFormOkay"
            >{{ $t("update") }}
        </core-button>
        </div>
    </core-dialog>
  </div>
</template>

<script>
import { URL_PREFIX } from "@/utils/constants";
export default {
  name: "Users",
  data: () => ({
    name: 'Users',
    editVisible: false,
    passwordEditable: true,
    selectedUser: null,
    roles: [],
    users: [],
    user: {
        name: null,
        roles: null,
        email: null,
    },
    headers: [
        {
        label: "name",
        key: "name",
        minWidth: "11"
      },
      {
        label: "role",
        key: "role",
        minWidth: "11"
      },
      {
        label: "phone",
        key: "phone",
        minWidth: "11"
      },
      {
        label: "email",
        key: "email",
        minWidth: "11"
      },
      {
        label: "created_at",
        key: "created_at",
        minWidth: "11"
      }
    ],
    loading: false,
    orderBy: 'name',
    orderDir: 'asc',
    page: 1,
    totalItems: 3,
    itemsPerPage: 100,
  }),
  created: function() {
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#account_info--wrapper"
    };
    this.account = { ...this.$store.state.account };
    this.getRoles()
    this.getUsers()
  },
  watch: {
    "this.$store.state.account": function(val) {
      this.account = val;
    }
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    rowActions: function (item) {
        let actions = [
        {
          label: "Rediger",
          action: this.editItem
        },
        {
          label: "Slet",
          action: item => {
            this.deleteItem(item)
          },
          hidden: item => item.id == this.$store.state.account_id
        },
      ];
      return actions;
    },
    formOkay: function () {
      if (
        !this.user.email ||
        !this.user.roles ||
        !this.user.name
      ) {
        return true
      } else {
        return false
      }
    },
    updateFormOkay: function () {
      if (
        !this.user.email ||
        !this.user.roles ||
        !this.user.name
      ) {
        return true
      } else {
        return false
      }
    }

  },
  methods: {
    getRoles: function () {
      this.$get(
        `${URL_PREFIX}/auth-service/roles`,
        {},
        {
          failSilently: true
        }
      ).then(response => {
        let roleArray = [...response.data]
        this.$_.remove(roleArray, (role) => !role.id == 3 || !role.id == 1);
        this.roles = roleArray
      });
    },
    getUsers: function () {
      this.loading = true
      this.$get(
        `${URL_PREFIX}/auth-service/accounts/${this.$store.state.auth_id}/users`,
        {},
        {
          failSilently: true
        }
      ).then(response => {
        this.users = response.data
        this.loading = false
      });
    },
    createUser: function () {
      this.passwordEditable = true
      const id = Date.now();
      this.loading = true
      this.editVisible = false
      this.user.roles = [this.user.roles]
      this.$notification({
        id: id,
        title: this.$t("creating_entity", {entity: this.$t("user")}),
        duration: -1
      });
      this.$post(
        `${URL_PREFIX}/auth-service/accounts/${this.$store.state.auth_id}/users`,
        this.user,
        {
          failSilently: true
        }
      ).then(response => {
        this.$notification.close(id);
        this.$notification({
        title: this.$t("success"),
        duration: 2500
      });
        this.user.email = null
        this.user.name = null
        this.user.roles = null
        this.loading = false
        this.getUsers()
      }).catch((err) => {
        this.$notification.close(id);
        this.$notification({
        title: this.$t("error"),
        type: "error",
        duration: 2500
      });
      })
    },
    editItem: function (item) {
      this.passwordEditable = false
      this.user.email = item.email
      this.user.roles = item.roles[0].id
      this.selectedUser = item.id
      this.editVisible = true
    },
    updateUser: function () {
      const id = Date.now();
      this.user.roles = [this.user.roles]
      this.$notification({
        id: id,
        title: this.$t("updating_entity", {entity: this.$t("user")}),
        duration: -1
      });
      this.$put(
        `${URL_PREFIX}/auth-service/accounts/${this.$store.state.auth_id}/users/${this.selectedUser}`,
        this.user,
        {
          failSilently: true
        }
      ).then(response => {
        this.$notification.close(id);
        this.$notification({
        title: this.$t("success"),
        duration: 2500
      });
        this.user.email = null
        this.user.roles = null
        this.loading = false
        this.getUsers()
      }).catch((err) => {
        this.$notification.close(id);
        this.$notification({
        title: this.$t("error"),
        type: "error",
        duration: 2500
      });
      })
    },
    deleteItem: function (item) {
      this.loading = true
      this.$del(
      `${URL_PREFIX}/auth-service/accounts/${this.$store.state.auth_id}/users/${item.id}`,
      {},
      {
        failSilently: true
      }
      ).then(response => {
        this.loading = false
        this.getUsers()
      });
    }
  }
};
</script>

<style scoped></style>