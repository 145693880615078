<template>
  <div class="wrapper">
    <div class="title">{{ title }}</div>
    <div class="description" v-if="description">{{ description }}</div>


    <div class="text">
      <template v-if="values.length">
        <el-row
          type="flex"
          align="middle"
          v-for="(val, index) in values"
          v-bind:key="index"
          :gutter="20"
        >
          <el-col :span="22">
            <div class="grid grid-cols-6 gap-3">
              <core-select
                class="w-full items-stretch max-w-full col-span-2"
                :options="selectValues"
                labelKey="label"
                valueKey="value"
                style="width: 100%"
                :defaultToFirst="true"
                @input="val => handleExclude(val, index)"
                v-model="values[index].inverse"
              />
              <core-input
                class="col-span-4"
                v-bind:key="'input_' + index"
                :label="title"
                :type="type"
                :value="val.value"
                @input="v => handleInput(v, index)"
                :autofocus="index === values.length - 1"
              />
            </div>
          </el-col>
          <el-col :span="2">
            <core-filter-modal-filter-clear
              @click="$emit('remove-filter', index)"
            />
          </el-col>
        </el-row>
        <core-button @click="$emit('update-filter', {value:'', inverse:false})">
          {{ $t("add_new") }}
        </core-button>
      </template>

      <el-row type="flex" align="middle" v-bind:key="0" v-else :gutter="20">
        <el-col :span="22">
          <div class="grid grid-cols-6 gap-3">
            <core-select
              class="w-full items-stretch max-w-full col-span-2"
              :options="selectValues"
              labelKey="label"
              valueKey="value"
              style="width: 100%"
              :defaultToFirst="true"
              @input="val => handleExclude(val, -1)"
              v-model="inverse"
            />
            <core-input
              class="col-span-4"
              v-bind:key="'input_0'"
              :label="title"
              :type="type"
              @input="val => handleNewInput(val)"
              :autofocus="true"
            />
          </div>
        </el-col>
        <el-col :span="2">
          <core-filter-modal-filter-clear disabled />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import mixin from "./typeMixin";
export default {
  mixins: [mixin],
  props: {
    type: {
      type: String,
      default: "text"
    }
  },
  data: () => ({
    inverse: false
  }),
  created() {
    this.handleNewInput = _.debounce(this.handleNewInput, 500);
  },
  computed: {
    selectValues: function () {
      return [
      {
        label: this.$t('include'),
        value: false
      },
      {
        label: this.$t('exclude'),
        value: true
      }
    ]
    }
  },
  methods: {
    handleNewInput: function(val) {
      let data = {
        value: this.type === "number" ? parseFloat(val) : val,
        inverse: this.inverse
      }
      if (val)
        this.$emit(
          "update-filter",
          data
        );
    },
    handleExclude: function (val, index) {
      if (index == -1) {
        this.inverse = val
      } else {
        let data = {
        value: this.values[index].value,
        inverse: val
      }
      this.$emit(
          "update-filter",
          data,
          index
        );
      }
      
    },
    handleInput: function(val, index) {
      let data = {
        value: this.type === "number" ? parseFloat(val) : val,
        inverse: this.values[index].inverse
      }
      if (this.values.length === 1) {
        if (val)
          this.$emit(
            "update-filter",
            data,
            index
          );
        else this.$emit("remove-filter", index);
      } else
        this.$emit(
          "update-filter",
          data,
          index
        );
    }
  }
};
</script>
