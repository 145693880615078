<template>
  <core-view>
    <core-view-header :name="name" style="padding-bottom: 0;" />

    <el-container class="bg-white account-container">
      <div
        class="light_group__form"
        style="margin-top: 10px; width: 100%; justify-content: left"
      >
        <div v-if="group" class="form">
          <div class="grid-container" style="margin: -12px -12px;">
            <row :columns="24" :gutter="10" style="">
              <column :xs="12">
                <core-text v-model="group.name" :label="$t('name')" />
                <core-number
                  v-model="group.priority"
                  :min="1"
                  step="1"
                  :label="$t('priority')"
                />

                <core-textarea
                  v-model="group.description"
                  :label="$t('description')"
                />
              </column>
              <column :xs="12">
                <core-select-checkbox
                  :placeholder="unitsSelectTitle"
                  :value="group.units"
                  value-key="address"
                  label-key="name"
                  value-is-object
                  :endpoint="`/${endpoint}/${group.id}/units/unused?ids=`"
                  @add="addUnit"
                  @remove="removeUnit"
                >
                  <template v-slot:option.append="{ option }">
                    <core-button
                      class="light_button"
                      type="coolrunner"
                      style="padding: 6px 8px;"
                      :suffix-icon="['far', 'lightbulb-on']"
                      @click.native.stop="() => identify(option)"
                      >{{ $t("test") }}
                    </core-button>
                  </template>
                </core-select-checkbox>
              </column>
            </row>
          </div>
          <div class="rules-list">
            <h3 style="margin-bottom: 10px; margin-top: 14px;">
              {{ $t("set_of_rules") }}
            </h3>
            <div
              v-for="(rule, index) in group.rules"
              :key="index"
              class="rule-wrapper"
            >
              <row :columns="24" :gutter="30">
                <div class="rule-form-wrapper">
                  <column :xs="24" :sm="24">
                    <core-text
                      v-model="rule.name"
                      required
                      :label="$t('name')"
                    />
                  </column>
                </div>
                <div class="filter">
                  <div v-if="!$_.isEmpty(rule.filters)">
                    <p>{{ $t("shown_if") }}:</p>
                    <core-filter-modal-type-active
                      :filters="rule.filters"
                      :view="description"
                      :clearable="false"
                    />
                  </div>
                  <div class="flex justify-end">
                    <core-button
                      type="default_outline"
                      class="mr-2"
                      @click="deleteRule(index)"
                      >{{
                        $t("delete_entity", {
                          entity: $t("set_of_rules").toLowerCase()
                        })
                      }}</core-button
                    >
                    <core-button
                      v-if="$_.isEmpty(rule.filters)"
                      type="coolrunner"
                      @click="
                        () => {
                          ruleIndex = index;
                          showFilter = true;
                        }
                      "
                      >{{
                        $t("add_entity", {
                          entity: $tc("filter", 1).toLowerCase()
                        })
                      }}</core-button
                    >
                    <core-button
                      v-else
                      type="coolrunner"
                      @click="
                        () => {
                          ruleIndex = index;
                          showFilter = true;
                        }
                      "
                      >{{ $t("edit") }}</core-button
                    >
                  </div>
                </div>
              </row>
            </div>

            <core-list-empty
              v-if="$_.isEmpty(group.rules)"
              :empty-list-icon="['fad', 'align-slash']"
              entity-name="set_of_rules"
            />
          </div>

          <div class="flex">
            <div class="mr-2">
              <core-button @click="addRule">{{
                $t("add_entity", { entity: $t("set_of_rules").toLowerCase() })
              }}</core-button>
            </div>
            <div>
              <core-button type="coolrunner" :loading="saving" @click="save">{{
                $t("save")
              }}</core-button>
            </div>
          </div>
        </div>
      </div>
    </el-container>
    <core-filter-modal
      :view-type="viewType"
      :visible="showFilter"
      :filters="filters"
      :number-of-filters="numberOfFilters"
      @confirm="confirmFilters"
      @update-filter="updateFilter"
      @remove-filter="removeFilter"
    />
  </core-view>
</template>

<script>
import Filter from "@/mixins/Filter";
import { set } from "lodash/fp";
import data from "@/plugins/data";
import { checkServiceAccess } from "@/utils/tools";
export default {
  beforeRouteEnter(to, from, next) {
    const hasAccess = checkServiceAccess("lights");
    if (!hasAccess) next(from);

    if (to.params.group_id && to.params.group_id !== "null")
      data.fetch(`casambi/groups/${to.params.group_id}`).then(r => {
        next(vm => (vm.group = { rules: [], units: [], ...r }));
      });
    else
      next(
        vm =>
          (vm.group = {
            name: "",
            priority: 1,
            description: "",
            units: [],
            rules: []
          })
      );
  },
  name: "LightGroupEditor",
  mixins: [Filter],
  props: {
    viewType: {
      type: String,
      default: "lightGroup"
    }
  },
  data: () => ({
    name: "light_group",
    endpoint: "casambi/groups",
    headers: [
      {
        label: "name",
        key: "name"
      }
    ],
    ruleIndex: null,
    saving: false,
    group: {
      name: "",
      priority: 1,
      description: "",
      units: [],
      rules: []
    }
  }),
  computed: {
    unitsSelectTitle: function() {
      return `${this.$t("select_entity_with_n", {
        entity: this.$tc("light", 99).toLowerCase(),
        count: this.group.units.length
      })}`;
    }
  },
  watch: {
    ruleIndex: function(val) {
      if (val !== null)
        this.filters = !this.$_.isArray(this.group.rules[val].filters)
          ? this.group.rules[val].filters
          : {};
    }
  },
  methods: {
    confirmFilters: function() {
      this.group = set(
        `rules[${this.ruleIndex}].filters`,
        this.cleanedFilters,
        this.group
      );
      this.ruleIndex = null;
      this.showFilter = false;
      this.filters = {};
    },
    deleteRule: function(ruleIndex) {
      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$t("set_of_rules").toLowerCase()
        })
      ).then(() => {
        let rules = [...this.group.rules];
        rules.splice(ruleIndex, 1);
        this.group = set(`rules`, rules, this.group);
      });
    },
    addRule: function() {
      let rules = [...this.group.rules];
      rules.push({ filters: {} });
      this.group = set(`rules`, rules, this.group);
    },
    addUnit: function(unit) {
      this.group.units = [...this.group.units, unit];
    },
    removeUnit: function(unit) {
      const index = this.$_.findIndex(this.group.units, ["id", unit.id]);
      let units = [...this.group.units];
      units.splice(index, 1);
      this.group = set(`units`, units, this.group);
    },
    save: function() {
      let httpMethod = this.$post;
      const isCreate = !this.group.id;

      if (!isCreate) {
        httpMethod = this.$put;
      }
      this.saving = true;
      let url = `${this.endpoint}`;

      if (!isCreate) {
        url += `/${this.group.id}`;
      }

      httpMethod(url, this.group, { failSilently: true })
        .then(r => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
          this.group = r;

          if (isCreate)
            this.$router.replace({
              name: "tools_lights_group",
              params: {
                group_id: r.id
              }
            });
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.$notification({
            title: this.group.id
              ? this.$t("error_updating_entity", {
                  entity: this.$tc(this.name, 1).toLowerCase()
                })
              : this.$t("not_saved"),
            text: this.$_.join(this.$_.map(response.data.errors), "</br>"),
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    identify: function(unit) {
      this.$get(`casambi/units/${unit.id}/identify`, undefined, {
        failSilently: true
      }).then(() => null);
    }
  }
};
</script>

<style lang="scss">
.light_group__form {
  .el-select {
    width: 100%;
    max-width: 100%;
    display: block;
  }
  .form {
    max-width: 692px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    .core-input,
    .core-select {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .units {
    padding-bottom: 100%;
    border-color: #d2d5d9;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;

    .unit {
      border-color: #d2d5d9;
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      div {
        &:first-of-type {
          margin-right: 5px;
        }
        &:last-of-type {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }
  .rules-list {
    margin-bottom: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .rule-wrapper {
    padding: 15px 30px 30px;
    border: 1px solid #d2d5d9;
    border-radius: 8px;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
    .rule-form-wrapper {
      width: calc(100% + 30px);
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }
    .filter {
      width: 100%;
      margin-top: 10px;
      p {
        font-weight: 500;
        margin-bottom: 10px;
      }
      > div {
        border-top: 2px solid #d2d5d9;
        padding-top: 20px;
      }
      .active-filters {
        text-align: left;
        .title {
          font-size: 15px;
        }
        .tag {
          &:hover {
            color: initial;
            cursor: initial;
          }
          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
