import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

const requireComponent = require.context("../components/core", true, /\.vue$/);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(
      fileName
        .replace(/index/, "")
        .replace(/^\.\//, "")
        .replace(/\.\w+$/, "")
    )
  );

  Vue.component(
    `Core${componentName}`,
    componentConfig.default || componentConfig
  );
});
