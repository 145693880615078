<template>
    <div class="w-full">
        <h4>{{ $t("account.pickup") }}</h4>
        <div class="w-full inline-block">
            <core-select
                v-model="selected_delivery_method"
                style="width:343px; max-width: calc(100% - 40px); float: left; margin-top: 10px;"
                :disabled="!account"
                :label="$t('account.pickup')"
                :options="delivery_methods"
                value-key="key"
                label-key="label"
            />
        </div>
        <div class="flex mt-3">
            <core-checkbox
                :key="`input_0`"
                class="mt-0.5"
                :value="acceptedChange"
                @input="v => acceptedChange = val"
            />
            <p class="pl-3 cursor-pointer" @click="acceptedChange = !acceptedChange">{{$t('transfer.accept_terms')}}</p>
        </div>
        <core-button
            class="mt-3"
            :type="'coolrunner'"
            :disabled="!acceptedChange"
            @click="save"
            >{{ $t('save') }}
        </core-button>
    </div>
</template>

<script>
import { URL_PREFIX, API_PREFIX } from "@/utils/constants";
export default {
  name: "Transfer",
  data: () => ({
    endpoint: "account",
    mode: 'unset',
    delivery_methods: [],
    selected_delivery_method: 'dfm',
    pickupKey: null,
    acceptedChange: false,
  }),
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    account: function() {
      return this.$store.state.account;
    } ,
  },
  created: function() {
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#account_info--wrapper"
    };

    this.$get(
        `/delivery-methods`,
        {},
        {
          failSilently: true
        }
      ).then(response => {
        let carrierArray = []
        Object.entries(response).forEach(entry => {
        const [key, value] = entry;
        carrierArray.push({ key: key, label: value });
      });
      this.delivery_methods = carrierArray;
      });

  },
  mounted() {
    if (this.account.enabled_services.transfer_delivery_method) {
        this.selected_delivery_method = this.account.enabled_services.transfer_delivery_method
        this.mode = 'set'
    }
  },

  methods: {
    save() {
      this.$post(
        `/delivery-methods`,
        {delivery_method: this.selected_delivery_method, customer_id: this.account.id},
        {
          failSilently: true
        }
      ).then((response) => {
        this.$notification({
          title: this.$t("success"),
          type: "success"
        });
        this.$store.dispatch("loadAccount");

      })
      .catch(e => {
        this.$notification({
          title: this.$t("error"),
          text: e.response.data.status,
          type: "error"
        });
      })
      .finally(() => {
      });
    }
  }
};
</script>

<style scoped></style>