var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
    () => {
      _vm.open ? _vm.$emit('update:open', false) : () => null;
    }
  ),expression:"\n    () => {\n      open ? $emit('update:open', false) : () => null;\n    }\n  "}],attrs:{"placement":"bottom-end","trigger":_vm.trigger,"value":_vm.open,"popper-options":{
    boundariesElement: 'body',
    gpuAcceleration: true,
    positionFixed: _vm.positionFixed,
    preventOverflow: true
  },"append-to-body":false,"popper-class":"action-popup"}},[_c('template',{slot:"reference"},[_c('div',{staticClass:"action-popup__enabler",class:[{ enabled: _vm.open }, !_vm.$slots.activator ? _vm.class_override : ''],on:{"click":function($event){$event.stopPropagation();return (() => {
          if (_vm.disabled) {
            return;
          }
          _vm.$emit('update:open', !_vm.open);
        }).apply(null, arguments)}}},[_vm._t("activator",function(){return [_c('i',{staticClass:"el-icon-more"})]})],2)]),_c('el-col',{staticClass:"action-popup__menu"},_vm._l((_vm.filteredActions),function(action){return _c('el-row',{key:action.key || action.label,staticClass:"action-popup__menu-item",class:{ disabled: action.disabled }},[(action.action)?_c('div',{staticClass:"action-popup__menu-item__wrapper",on:{"click":() => {
            if (action.disabled) return;
            _vm.$emit('update:open', false);
            action.action(_vm.entity);
          }}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","word-break":"break-word"}},[(action.icon)?_c('font-awesome-icon',{attrs:{"icon":action.icon,"fixed-width":""}}):(action.icon_html)?_c('div',{domProps:{"innerHTML":_vm._s(action.icon_html)}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(action.label)+" ")])],1)]):(action.link)?_c('a',{staticClass:"action-popup__menu-item__wrapper",attrs:{"href":action.disabled ? '' : action.link(_vm.entity),"target":"_blank"},on:{"click":() => {
            if (action.disabled) return;
            _vm.$emit('update:open', false);
          }}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","word-break":"break-word"}},[(action.icon)?_c('font-awesome-icon',{attrs:{"icon":action.icon,"fixed-width":""}}):(action.icon_html)?_c('div',{domProps:{"innerHTML":_vm._s(action.icon_html)}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(action.label)+" ")])],1)]):_vm._e()])}),1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }