import data from "@/plugins/data";
import axios from "axios";
import _ from "lodash";
import store from "@/store";
import { URL_PREFIX } from "@/utils/constants";

let cancelToken = null;

export function renameKeyName(obj, oldName, newName) {
  const { [oldName]: value, ...remainingObj } = obj;
  return {
    ...remainingObj,
    [newName]: value
  };
}

export function checkServiceAccess(key, from, next) {
  const services = store.state.account.enabled_services;

  if (services[key]) {
    if (next) next();
    return true;
  } else {
    if (next) {
      next(from);
      return false;
    }
  }
}

export const autoCity = (
  val,
  countryISO,
  handleChange = () => null,
  toggleCityField = () => null
) => {
  const country = _.find(store.state.countries, c => c.iso === countryISO);

  if (!country) {
    handleChange("zip_code", val);
    return;
  }

  const regex = RegExp(country.zip_format);
  if (!regex.test(val)) {
    handleChange("zip_code", val);
    return;
  }

  toggleCityField(false);

  if (cancelToken) {
    cancelToken.cancel("cancelled");
  }
  cancelToken = axios.CancelToken.source();
  data
    .fetch(
      `autocity?zip_code=${val}&country_code=${countryISO}&limit=1`,
      {
        cancelToken: cancelToken.token
      },
      { failSilently: true }
    )
    .then(res => {
      if (res.autocity && res.autocity.length) {
        handleChange("city", res.autocity[0]?.city);
        handleChange("zip_code", val);
      } else {
        handleChange("city", "");
        handleChange("zip_code", val);
      }
      toggleCityField(true);
    })
    .catch(e => {
      if (e !== "cancelled") toggleCityField(true);
    });
};

export function getCarrierImageUrl(carrier, width = 128, context) {
  let url = `${URL_PREFIX}/images/carriers/${carrier}.png?w=${width}`;
  if (!context) return url;

  url += `&context=${context}`;

  return url;
}
