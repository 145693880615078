var render = function render(){var _vm=this,_c=_vm._self._c;return _c('widget-base',{staticClass:"chart-wrapper",attrs:{"title":_vm.cTitle,"subtitle":_vm.sub,"shadow":_vm.shadow,"titlePosition":_vm.titlePosition,"showNoData":_vm.showNoData,"initialFilters":_vm.data.filters,"interval":_vm.interval,"filteringEnabled":this.filteringEnabled,"showIntervalSelector":"","hasDescription":_vm.hasDescription},on:{"set-filters":val => (_vm.filters = val),"set-cleaned-filters":val => (_vm.cleanedFilters = val),"set-interval":val => (_vm.interval = val)}},[(_vm.showNoData)?_c('div',{staticClass:"failText"},[_vm._v(" "+_vm._s(_vm.$t('chart_error'))+" ")]):_c('chart-line',{tag:"component",attrs:{"chart-id":_vm.uniqueID,"chart-data":_vm.dataCollection,"options":{
      legend: false,
      animation: {
        easing: 'easeInOutElastic'
      },
      responsive: true,
      fullCornerRadius: false,
      maintainAspectRatio: false,
      tooltips: _vm.tooltipsOptions(_vm.title),
      scales: _vm.scalesOptions()
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }