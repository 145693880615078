import Echo from "laravel-echo";
import { URL_ECHO } from "@/utils/constants";
import store from "@/store";
import _ from "lodash";
window.io = require("socket.io-client");
let echo = null;

const getEcho = () => {
  const token = _.get(store, "state.auth.access_token");
  if (echo) return echo;
  if (!token) return null;
  else {
    echo = new Echo({
      broadcaster: "socket.io",
      host: `${URL_ECHO}`,
      encrypted: true,
      secure: true,
      rejectUnauthorized: false,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Context: "customer"
        }
      }
    });
    return echo;
  }
};

export const destroyEcho = () => {
  echo = null;
};

export default getEcho;
