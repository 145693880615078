<template>
  <core-view id="shops">
    <div v-if="!canView">
      {{$t('page_restricted')}}
    </div>
    <div v-if="canView">
    <core-view-header name="my_shops" :actions="headerActions">
      <template v-slot:description>
        <i18n path="page_descriptions.shops" tag="p">
          <template v-slot:link>
            <router-link :to="{ name: 'tools_smartcheckout' }">{{
              $tc("shipping_method", 99).toLowerCase()
            }}</router-link>
          </template>
        </i18n>
      </template>
    </core-view-header>

    <div style="margin-top: 16px;">
      <div id="shops_container" class="grid grid-cols-12 gap-5">
        <div
          class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"
          v-for="shop in shops"
          v-bind:key="shop.shop_url"
        >
          <shop :shop="shop" @click="() => edit(shop)" />
        </div>
      </div>
    </div>
    <new-shop-dialog
      v-if="showDialog"
      :visible.sync="showDialog"
      @shop-created="() => $store.dispatch('loadShops', true)"
      @shop-activated="() => $store.dispatch('loadShops', true)"
    />
    <edit-dialog
      v-if="showEditDialog"
      :visible.sync="showEditDialog"
      :shop="shop"
      @shop-updated="
        () => {
          $store.dispatch('loadShops', true);
          showEditDialog = false;
          shop = null;
        }
      "
      @close="
        () => {
          shop = null;
          showEditDialog = false;
        }
      "
    />
  </div>
  </core-view>
</template>

<script>
import NewShopDialog from "./components/newShopDialog";
import EditDialog from "./components/editDialog";
import Shop from "./components/Shop";
import pageAccess from "@/mixins/PageAccess";
export default {
  name: "Shops",
  mixins:[pageAccess],
  components: { NewShopDialog, EditDialog, Shop },
  data: () => ({
    loading: false,
    showDialog: false,
    showEditDialog: false,
    shop: null
  }),
  created: function() {
    this.$store.dispatch("loadShops", true);
  },
  watch: {
    shops: function(val, oldVal) {
      if (val.length !== oldVal?.length) this.pingShops();
    }
  },
  computed: {
    headerActions: function() {
      return [
        {
          action: () => {
            this.showDialog = true;
          },
          label: this.$t("add_new")
        }
      ];
    },
    shops: {
      get: function() {
        return this.$store.state.shops;
      },
      set: function(shops) {
        this.$store.dispatch("setShops", shops);
      }
    }
  },
  methods: {
    edit: function(shop) {
      this.shop = shop;
      this.showEditDialog = true;
    },
    pingShops() {
      this.$_.each(this.shops, s => {
        if (
          s.id &&
          !(
            s.pingback_result ||
            (s.pingback_result.status !== "ok" &&
              s.pingback_result.status !== "missing_pingback_url")
          )
        )
          this.$get(`shops/${s.id}/ping`).then(r => {
            const shops = [...this.shops];
            const index = this.$_.findIndex(shops, ["id", s.id]);
            shops[index].pingback_result = r;
            this.shops = shops;
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#shops_container {
  max-width: 100%;
}
.grid-container {
  display: flex;
  margin: -12px -20px;
}
</style>
