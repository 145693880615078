<template>
  <VueCtkDateTimePicker
    ref="parent"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled-dates="getDisabledDates()"
    :enabled-dates="getEnabledDates()"
    v-model="model"
    :format="format"
  />
</template>

<script>
import moment from "moment";
export default {
  name: "DateTimePicker",
  data: () => ({}),
  props: {
    disabledDates: {
      type: [Array],
      default: () => []
    },
    enabledDates: {
      type: [Array],
      default: () => []
    },
    value: {
      type: [String, Date]
    },
    format: {
      type: String,
      default: "YYYY-MM-DD HH:mm"
    }
  },
  computed: {
    moment: () => moment,
    model: {
      get: function() {
        if (this.value) return moment(this.value).format("YYYY-MM-DD HH:mm a");
        else return null;
      },
      set: function(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    getDisabledDates: function() {
      const dates = this.disabledDates;

      for (const key in dates)
        dates[key] = moment(dates[key]).format("YYYY-MM-DD");

      return dates;
    },
    getEnabledDates: function() {
      const dates = this.enabledDates;

      for (const key in dates)
        dates[key] = moment(dates[key]).format("YYYY-MM-DD");

      return dates;
    }
  }
};
</script>

<style lang="scss" scoped>
.date-time-picker {
  &::v-deep {
    .field-input {
      height: 54px;
    }
    .datepicker-buttons-container .datepicker-button .datepicker-button-effect {
      background: #448aff;
    }
    .datepicker-buttons-container .datepicker-button svg {
      fill: #448aff;
    }
  }
}
</style>
