<template>
  <div
    class="widget bg-white shadow-sm rounded-md relative p-6 md:p-8 pb-2 overflow-hidden"
    style="height: 550px;"
  >
    <div class="flex justify-between text-left w-full mb-5 items-start">
      <div class="relative w-full ">
        <div class="text-xl font-medium">
          {{ subtitle }}
        </div>
        <div class="text-base text-faded font-medium">
          {{ title }}
        </div>
        <div v-if="hasDescription" class="absolute -top-5 -right-5">
              <p class="has-tooltip">
                <font-awesome-icon
                  class="text-2xl text-gray-300 cursor-pointer"
                  :icon="['fal', 'info-circle']"
                /><span
                  class="tooltip text-sm bg-gray-50 shadow-md rounded-md p-2"
                  >{{ hasDescription }}</span
                >
              </p>
            </div>
      </div>
      <div v-if="filteringEnabled" class="filter-menu">
        <core-button
          class="mr-2"
          @click="showFilter = !showFilter"
          :icon="['far', 'filter']"
        >
          <core-badge v-if="numberOfFilters" class="filter--count">{{
            numberOfFilters
          }}</core-badge>
        </core-button>
        <el-popover
          v-if="showIntervalSelector"
          placement="bottom-end"
          width="200"
          trigger="click"
          :popper-options="{
            // boundariesElement: 'body',
            gpuAcceleration: true,
            positionFixed: true,
            preventOverflow: true
          }"
          :append-to-body="false"
          popper-class="action-popup available-views"
        >
          <core-button class="h-full" slot="reference" v-if="showIntervalSelector">{{
            $t("select_interval")
          }}</core-button>

          <el-col class="action-popup__menu">
            <el-row
              v-for="iv in intervals"
              class="action-popup__menu-item"
              :class="{ 'is-active': isActiveInterval(iv) }"
              v-bind:key="iv"
            >
              <div
                class="action-popup__menu-item__wrapper"
                @click="() => setInterval(iv)"
              >
                <div>
                  {{ $t(iv) }}
                </div>
              </div>
            </el-row>
          </el-col>
        </el-popover>
      </div>
    </div>
    <template>
      <slot>
        <div />
      </slot>
    </template>
    <core-filter-modal
      v-if="filteringEnabled"
      :visible="showFilter"
      @confirm="confirmFilters"
      @update-filter="updateFilter"
      @remove-filter="removeFilter"
      @set-interval="val => $emit('set-interval', val)"
      :filters="filters"
      :interval="interval"
    />
  </div>
</template>

<script>
import Filter from "@/mixins/Filter";
import { stringify } from 'qs';

export default {
  name: "WidgetBase",
  mixins: [Filter],
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    shadow: {
      type: String,
      default: "never"
    },
    titlePosition: {
      type: String,
      default: "lower"
    },
    showNoData: {
      type: Boolean,
      default: false
    },
    filteringEnabled: {
      type: Boolean,
      default: true
    },
    initialFilters: {
      type: Object,
      default: () => ({})
    },
    interval: {
      type: String,
      default: null
    },
    showIntervalSelector: {
      type: Boolean,
      default: false
    },
    hasDescription: {
      type: String,
      default: ""
    }
  },
  computed: {
    breakpoint: function() {
      return this.$store.state.breakpoint;
    }
  },
  mounted: function() {
    if (this.initialFilters) this.filters = this.initialFilters;
  },
  methods: {
    isActiveInterval: function(interval) {
      return this.interval === interval;
    }
  }
};
</script>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply opacity-0 absolute -left-20 transition duration-300 ease-in-out pointer-events-none;
}

.has-tooltip:hover .tooltip {
  @apply opacity-100 z-50 transition duration-300 ease-in-out pointer-events-none;
}
</style>
