<template>
  <div class="core-list__content" style="height: 100%">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingInstance: null
  }),
  mounted: function() {
    this.$nextTick(() => {
      this.setLoading();
    });
  },
  watch: {
    loading: function() {
      this.setLoading();
    }
  },
  computed: {
    id: function() {
      return this.$_.uniqueId();
    }
  },
  methods: {
    setLoading: function() {
      let loadingOptions = {
        lock: true,
        fullscreen: false,
        customClass: "z-10",
        text: this.$t("loading"),
        target: this.$el
      };

      if (this.expandable) loadingOptions.background = "transparent";

      if (this.loading) {
        this.loadingInstance = this.$loading(loadingOptions);
      } else if (this.loadingInstance) {
        this.$nextTick(() => {
          this.loadingInstance.close();
        });
      }
    }
  }
};
</script>
