import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from "@fortawesome/vue-fontawesome";
import { faSave } from "@fortawesome/pro-regular-svg-icons/faSave";
import { faClipboard } from "@fortawesome/pro-regular-svg-icons/faClipboard";
import { faBookmark } from "@fortawesome/pro-regular-svg-icons/faBookmark";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faFilter } from "@fortawesome/pro-regular-svg-icons/faFilter";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faBan } from "@fortawesome/pro-regular-svg-icons/faBan";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";
import { faSync } from "@fortawesome/pro-regular-svg-icons/faSync";
import { faAddressBook } from "@fortawesome/pro-regular-svg-icons/faAddressBook";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faFileDownload } from "@fortawesome/pro-regular-svg-icons/faFileDownload";
import { faFileCsv } from "@fortawesome/pro-regular-svg-icons/faFileCsv";
import { faFileCode } from "@fortawesome/pro-regular-svg-icons/faFileCode";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons/faFileAlt";
import { faFileExcel } from "@fortawesome/pro-regular-svg-icons/faFileExcel";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons/faEllipsisH";
import { faFileSpreadsheet } from "@fortawesome/pro-regular-svg-icons/faFileSpreadsheet";
import { faFileTimes } from "@fortawesome/pro-regular-svg-icons/faFileTimes";
import { faPrint } from "@fortawesome/pro-regular-svg-icons/faPrint";
import { faRadar } from "@fortawesome/pro-regular-svg-icons/faRadar";
import { faUndo } from "@fortawesome/pro-regular-svg-icons/faUndo";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faShare } from "@fortawesome/pro-regular-svg-icons/faShare";
import { faHomeAlt } from "@fortawesome/pro-regular-svg-icons/faHomeAlt";
import { faTruck } from "@fortawesome/pro-regular-svg-icons/faTruck";
import { faTruckContainer} from "@fortawesome/pro-regular-svg-icons/faTruckContainer";
import { faTruckRamp} from "@fortawesome/pro-regular-svg-icons/faTruckRamp";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faCube } from "@fortawesome/pro-regular-svg-icons/faCube";
import { faBagsShopping } from "@fortawesome/pro-regular-svg-icons/faBagsShopping";
import { faBoxes } from "@fortawesome/pro-regular-svg-icons/faBoxes";
import { faMoneyCheckAlt } from "@fortawesome/pro-regular-svg-icons/faMoneyCheckAlt";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import { faCogs } from "@fortawesome/pro-regular-svg-icons/faCogs";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faSignOutAlt } from "@fortawesome/pro-regular-svg-icons/faSignOutAlt";
import { faBracketsCurly } from "@fortawesome/pro-regular-svg-icons/faBracketsCurly";
import { faTools } from "@fortawesome/pro-regular-svg-icons/faTools";
import { faBoxCheck } from "@fortawesome/pro-regular-svg-icons/faBoxCheck";
import { faBoxOpen } from "@fortawesome/pro-regular-svg-icons/faBoxOpen";
import { faReply } from "@fortawesome/pro-regular-svg-icons/faReply";
import { faRepeatAlt } from "@fortawesome/pro-regular-svg-icons/faRepeatAlt";
import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons/faLightbulbOn";
import { faRouter } from "@fortawesome/pro-regular-svg-icons/faRouter";
import { faRoute } from "@fortawesome/pro-regular-svg-icons/faRoute";
import { faImages } from "@fortawesome/pro-regular-svg-icons/faImages";
import { faAnalytics } from "@fortawesome/pro-regular-svg-icons/faAnalytics";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons/faSyncAlt";
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons/faLongArrowLeft";
import { faArrowSquareRight } from "@fortawesome/pro-regular-svg-icons/faArrowSquareRight";
import { faDolly } from "@fortawesome/pro-regular-svg-icons/faDolly";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faPuzzlePiece } from "@fortawesome/pro-regular-svg-icons/faPuzzlePiece";
import { faProjectDiagram } from "@fortawesome/pro-regular-svg-icons/faProjectDiagram";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faTicket } from "@fortawesome/pro-regular-svg-icons/faTicket";
import { faCommentsAlt } from "@fortawesome/pro-regular-svg-icons/faCommentsAlt";
import { faComment } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faCommentExclamation } from "@fortawesome/pro-regular-svg-icons/faCommentExclamation";
import { faClone } from "@fortawesome/pro-regular-svg-icons/faClone";

import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import { faAlignSlash } from "@fortawesome/pro-duotone-svg-icons/faAlignSlash";

import { faFileInvoiceDollar } from "@fortawesome/pro-light-svg-icons/faFileInvoiceDollar";
import { faFile } from "@fortawesome/pro-light-svg-icons/faFile";
import { faBoxAlt } from "@fortawesome/pro-light-svg-icons/faBoxAlt";
import { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faMapMarker } from "@fortawesome/pro-solid-svg-icons/faMapMarker";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { faSave as fasSave } from "@fortawesome/pro-solid-svg-icons/faSave";
import { faPrintSlash } from "@fortawesome/pro-solid-svg-icons/faPrintSlash";
import { faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons/faExternalLinkAlt";
import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faUnlock } from "@fortawesome/pro-solid-svg-icons/faUnlock";
import { faUserTie } from "@fortawesome/pro-solid-svg-icons/faUserTie";
import { faThLarge } from "@fortawesome/pro-solid-svg-icons/faThLarge";
import { faTable } from "@fortawesome/pro-solid-svg-icons/faTable";

import { faCcVisa } from "@fortawesome/free-brands-svg-icons/faCcVisa";
import { faCcMastercard } from "@fortawesome/free-brands-svg-icons/faCcMastercard";
import { faCcApplePay } from "@fortawesome/free-brands-svg-icons/faCcApplePay";
import { faDev } from "@fortawesome/free-brands-svg-icons/faDev";

library.add(
  faTable,
  faClone,
  faProjectDiagram,
  faCommentExclamation ,
  faComment,
  faCommentsAlt,
  faTicket,
  faChevronDown,
  faEnvelope,
  faPuzzlePiece,
  faThLarge,
  faBars,
  faFile,
  faArrowSquareRight,
  faRoute,
  faBoxes,
  faBoxOpen,
  faCube,
  faDolly,
  faClipboard,
  faTimesCircle,
  faSpinnerThird,
  faExclamationTriangle,
  faCcVisa,
  faInfoCircle,
  faCcMastercard,
  faCcApplePay,
  faSearch,
  faFilter,
  faCheck,
  faBan,
  faSave,
  faBookmark,
  faTimesCircle,
  faMapMarker,
  faMapMarkerAlt,
  fasSave,
  faSync,
  faAlignSlash,
  faPrintSlash,
  faAddressBook,
  faFileInvoiceDollar,
  faBoxAlt,
  faReceipt,
  faLink,
  faCopy,
  faTrash,
  faFileDownload,
  faRadar,
  faUndo,
  faEdit,
  faShare,
  faFileCsv,
  faTruckContainer,
  faFileCode,
  faFileAlt,
  faFileExcel,
  faFileSpreadsheet,
  faFileTimes,
  faPrint,
  faHomeAlt,
  faTruck,
  faUsers,
  faBagsShopping,
  faMoneyCheckAlt,
  faCogs,
  faCog,
  faSignOutAlt,
  faCircle,
  faExternalLinkAlt,
  faLock,
  faUnlock,
  faUser,
  faUserTie,
  faDev,
  faBracketsCurly,
  faTools,
  faTruckRamp,
  faQuestionCircle,
  faBoxCheck,
  faReply,
  faRepeatAlt,
  faLightbulbOn,
  faRouter,
  faImages,
  faAnalytics,
  faLongArrowLeft,
  faSyncAlt,
  faEllipsisH
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
