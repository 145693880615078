var render = function render(){var _vm=this,_c=_vm._self._c;return _c('core-view',{attrs:{"id":"shops"}},[(!_vm.canView)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('page_restricted'))+" ")]):_vm._e(),(_vm.canView)?_c('div',[_c('core-view-header',{attrs:{"name":"my_shops","actions":_vm.headerActions},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('i18n',{attrs:{"path":"page_descriptions.shops","tag":"p"},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'tools_smartcheckout' }}},[_vm._v(_vm._s(_vm.$tc("shipping_method", 99).toLowerCase()))])]},proxy:true}],null,false,384182663)})]},proxy:true}],null,false,353066856)}),_c('div',{staticStyle:{"margin-top":"16px"}},[_c('div',{staticClass:"grid grid-cols-12 gap-5",attrs:{"id":"shops_container"}},_vm._l((_vm.shops),function(shop){return _c('div',{key:shop.shop_url,staticClass:"col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"},[_c('shop',{attrs:{"shop":shop},on:{"click":() => _vm.edit(shop)}})],1)}),0)]),(_vm.showDialog)?_c('new-shop-dialog',{attrs:{"visible":_vm.showDialog},on:{"update:visible":function($event){_vm.showDialog=$event},"shop-created":() => _vm.$store.dispatch('loadShops', true),"shop-activated":() => _vm.$store.dispatch('loadShops', true)}}):_vm._e(),(_vm.showEditDialog)?_c('edit-dialog',{attrs:{"visible":_vm.showEditDialog,"shop":_vm.shop},on:{"update:visible":function($event){_vm.showEditDialog=$event},"shop-updated":() => {
        _vm.$store.dispatch('loadShops', true);
        _vm.showEditDialog = false;
        _vm.shop = null;
      },"close":() => {
        _vm.shop = null;
        _vm.showEditDialog = false;
      }}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }