<template>
  <core-view :class="{ importer: isImporterView }">
    <core-view-header
      class="shipment-importer-header"
      :name="'import_title'"
      :description="'page_descriptions.import'"
      :routeName="$route.name"
      route="labels"
      showViewName
      :help="{ video: 'https://www.youtube.com/watch?v=Q_759rre4no' }"
    >
      <template slot="lower">
        <el-tabs
          :value="activeTab"
          class="import-tabs mt-6"
          @tab-click="tabClick"
        >
          <el-tab-pane name="importer" :label="$t('import')" />
          <el-tab-pane
            v-for="state in $_.without(Object.keys(importerStates), 'done')"
            :key="state"
            :name="state"
            :label="importerStates[state]"
          >
            <template slot="label"
              >{{ importerStates[state]
              }}<el-badge
                type="primary"
                :value="importStateCounts[state]"
                :max="99"
            /></template>
          </el-tab-pane>
          <el-tab-pane name="done" :label="importerStates['done']">
            <template slot="label"
              >{{ importerStates["done"]
              }}<el-badge
                type="primary"
                :value="importStateCounts['done']"
                :max="99"
            /></template>
          </el-tab-pane>
        </el-tabs>
      </template>
    </core-view-header>

    <div
      style="background: white;
    margin-top: 0;
    padding: 15px 30px 0;
"
      class="view-header_actions"
      v-if="isImporterView"
    >
      <core-button
        v-for="(action, i) in headerActions"
        :class="{ 'ml-0': i === 0, 'ml-2': i > 0 }"
        :key="action.label"
        :loading="action.loading"
        :disabled="action.disabled"
        @click="action.action"
        >{{ action.label }}</core-button
      >
    </div>
    <drag-upload
      class="rounded-b-md flex-grow-0 shadow-sm"
      ref="uploader"
      @update:uploading="(val) => (askBeforeLeave = val)"
      @success="activeTab = 'awaiting'"
      v-show="isImporterView"
    />

    <import-list
      class="rounded-b-md flex-grow-0 shadow-sm"
      v-if="!isImporterView"
      :key="activeTab"
      :type="activeTab"
      :listIcon="icon"
      :listEndpoint="endpoint"
      :listName="name"
    />
    <template-dialog :visible.sync="showTemplateDialog" />
  </core-view>
</template>

<script>
import I18n from "@/plugins/i18n";
import EventBus from "../utils/eventbus";
import DragUpload from "../components/Import/DragUpload";
import TemplateDialog from "../components/Import/TemplateDialog";
import ImportList from "../components/Import/List";
import { importerStates } from "@/utils/constants";
import BeforeUnload from "@/mixins/BeforeUnload";

export default {
  name: "Importer",
  mixins: [BeforeUnload],

  components: { DragUpload, TemplateDialog, ImportList },
  data: () => ({
    viewType: "shipments",
    endpoint: "import",
    name: "shipment",
    icon: ["fal", "box-alt"],
    hasFiles: false,
    activeTab: "importer",
    showTemplateDialog: false,
    confirmLeavePageText: I18n.t("confirm_leave.upload"),
  }),
  mounted: function() {
    EventBus.$on("HAS_FILES", (val) => (this.hasFiles = val));

    if (
      this.$route.query.state === "awaiting" ||
      this.$route.query.state === "failed"
    )
      this.activeTab = this.$route.query.state;
    else if (!this.$route.query.state)
      this.$router
        .replace({
          name: "labels_import",
          query: { state: "importer" },
        })
        .catch((err) => {});
  },
  computed: {
    importerStates: () => importerStates,
    importStateCounts: function() {
      return this.$store.state.importCounts;
    },
    isImporterView: function() {
      return this.activeTab === "importer";
    },
    headerActions: function() {
      return [
        {
          buttonType: "default",
          action: () => EventBus.$emit("TRIGGER_UPLOAD_WINDOW"),
          label: I18n.t("upload_entity", {
            entity: I18n.t("list").toLowerCase(),
          }),
        },
        {
          buttonType: "default",
          action: () => {
            this.showTemplateDialog = true;
          },
          label: I18n.t("download_entity", {
            entity: I18n.t("template").toLowerCase(),
          }),
        },
        {
          buttonType: "coolrunner",
          action: () => EventBus.$emit("TRIGGER_UPLOAD_ALL_FILES"),
          disabled: !this.hasFiles,
          label: I18n.t("upload_entity", {
            entity: I18n.t("all").toLowerCase(),
          }),
        },
      ];
    },
    delay: function() {
      return this.items.length * 0.05;
    },
  },
  watch: {
    "$route.query.state": function(val) {
      this.activeTab = val;
    },
    activeTab: function(val) {
      if (val !== "importer") this.$store.dispatch("setImportStateCount", val);
    },
  },
  methods: {
    tabClick: function(val) {
      if (val.name === "done")
        this.$router.push({
          name: "labels",
          query: {
            filters: {
              order_context: [
                {
                  value: ["imported_orders"],
                },
              ],
            },
          },
        });
      else
        this.$router.push({
          name: "labels_import",
          query: { state: val.name },
        });
    },
  },
};
</script>

<style lang="scss">
.shipment-importer-header {
  .view-header_actions {
    justify-content: flex-start;
    button:last-of-type {
      margin-left: auto;
    }
  }
}

.import-tabs {
  margin-left: -30px;
  width: calc(100% + 60px);

  .el-tabs__nav-scroll {
    padding: 0 30px;
  }
}

.importer {
  .import-tabs {
    .el-tabs__header {
      margin: 0;
    }
  }
}
</style>
