import Vue from "vue";
//import "../styles/_settings.scss";
import lang from "element-ui/lib/locale/lang/da";
import locale from "element-ui/lib/locale";

Vue.prototype.$ELEMENT = { size: "small", zIndex: 101 };

locale.use(lang);

import {
  Pagination,
  Drawer,
  Dialog,
  Autocomplete,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Checkbox,
  Switch,
  Select,
  Option,
  Button,
  Table,
  TableColumn,
  TimePicker,
  Popover,
  Tooltip,
  Tabs,
  TabPane,
  Icon,
  Row,
  Col,
  Progress,
  Badge,
  Card,
  Collapse,
  CollapseItem,
  Container,
  Main,
  Timeline,
  TimelineItem,
  Divider,
  Image,
  Loading,
  MessageBox,
  Message,
  Notification
} from "element-ui";

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Drawer);
Vue.use(Autocomplete);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Progress);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Container);
Vue.use(Main);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Divider);
Vue.use(Image);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
