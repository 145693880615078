<template>
  <div>
    <row :gutter="10" style="border-bottom: 1px solid #D2D5D9">
      <column :xs="4" :sm="3">
        <core-text
          v-model="droppointQueryAddress.zip_code"
          :label="$t('zip_code')"
          @keypress.enter.native="fetchDroppoints"
        />
      </column>
      <column :xs="8" :sm="6">
        <core-text
          v-model="droppointQueryAddress.street"
          :label="$t('street')"
          @keypress.enter.native="fetchDroppoints"
        />
      </column>
      <column
        :xs="12"
        :sm="3"
        style="display: flex; align-items: center; justify-content: center;"
      >
        <core-button type="coolrunner" @click="fetchDroppoints">{{
          $t("search")
        }}</core-button>
      </column>
    </row>
    <core-products-list
      id="droppoints-list"
      :options="droppoints"
      labelKey="servicepoint_id"
      @select="selectDroppoint"
      :contentStyle="{ height: '128px' }"
      :selectedIndex="droppointIndex"
    >
      <slot slot-scope="item">
        <div class=" flex-col w-full">
          {{ item.item.name }}
          <div class="">{{ item.item.distance }} m</div>
          <div class="">{{ item.item.address.street }}</div>
          <div class="">
            {{ item.item.address.zip_code }} {{ item.item.address.city }}
          </div>
        </div>
      </slot>
    </core-products-list>
  </div>
</template>

<script>
import Loader from "@/mixins/Loader";

export default {
  name: "CreateShipmentDroppoint",
  mixins: [Loader],
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    defaultLoaderTarget: "#droppoints-list",
    droppoints: [],
    droppointQueryAddress: {
      zip_code: "",
      street: "",
      country_code: ""
    }
  }),
  mounted() {
    this.droppointQueryAddress = {
      zip_code: this.value.receiver.zip_code,
      street: this.value.receiver.street1,
      country_code: this.value.receiver.country
    };

    this.fetchDroppoints();
  },
  computed: {
    droppointIndex() {
      return this.droppoints.findIndex(
        item => item.servicepoint_id === this.value.servicepoint_id
      );
    }
  },
  methods: {
    fetchDroppoints: function() {
      this.loading = true;
      this.$get(
        `servicepoints/${this.value.carrier}`,
        this.droppointQueryAddress
      )
        .then(response => {
          this.droppoints = response.servicepoints;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    selectDroppoint(idx) {
      this.$emit("update:value", {
        ...this.value,
        servicepoint_id: this.droppoints[idx].servicepoint_id
      });
      this.$emit("set:nextStep", "insurance");
    }
  }
};
</script>

<style scoped></style>
