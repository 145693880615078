<template>
  <widget-base
    :title="cTitle"
    :subtitle="sub"
    :shadow="shadow"
    :titlePosition="titlePosition"
    :showNoData="showNoData"
    :initialFilters="data.filters"
    :interval="interval"
    :filteringEnabled="this.filteringEnabled"
    @set-filters="val => (filters = val)"
    @set-cleaned-filters="val => (cleanedFilters = val)"
    @set-interval="val => (interval = val)"
    showIntervalSelector
    class="chart-wrapper"
    :hasDescription="hasDescription"
  >
  <div class="failText" v-if="showNoData">
      {{$t('chart_error')}}
    </div>
    <chart-pie
      v-if="tooltipsOptions"
      :chart-id="uniqueID"
      :chart-data="dataCollection"
      :options="{
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            boxWidth: 12,
            padding: 20,
            fontSize: 14
          }
        },
        usePointStyle: true,
        fullCornerRadius: false,
        responsive: true,
        maintainAspectRatio: false,
        tooltips: tooltipsOptions(title)
      }"
    />
  </widget-base>
</template>

<script>
import ChartWidget from "../../mixins/ChartWidget";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getTheme } from "@/components/widgets/options";

export default {
  name: "PieChart",
  mixins: [ChartWidget],
  props: {},
  data: () => ({
    baseID: "pie-chart"
  }),
  computed: {
    plugins: () => [ChartDataLabels]
  },
  methods: {
    fillData(widgetData) {
      let canvas = document.getElementById(this.uniqueID);
      canvas.parentNode.style.height = "400px";
      this.dataCollection = {
        ...widgetData.data,
        datasets: this.$_.map(widgetData.data.datasets, ds => {
          return {
            ...ds,
            backgroundColor: getTheme(ds).reverse()
          };
        })
      };
      this.loading = false;
    }
  }
};
</script>
