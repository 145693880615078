<template>
  <div class="wrapper">
    <div class="title">{{ title }}</div>
    <div class="description" v-if="description">{{ description }}</div>
    <core-search :query.sync="query" />
    <div class="select">
      <div
        class="select__choice"
        :class="{ selected: isSelected(key) }"
        v-for="(choice, key) in choices"
        v-bind:key="key"
        @click.stop="onChoiceClick(key)"
      >
        {{ $t(filter.choices[key]) }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import mixin from "./typeMixin";
export default {
  mixins: [mixin],
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [Array, Object],
      default: () => [{ value: [] }]
    },
    optionsMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    query: "",
    val: []
  }),
  computed: {
    choices: function() {
      const regex = new RegExp(this.query, "i");

      const result = _.pickBy(this.filter.choices, c => {
        if (!c) return false;
        return c.match(regex);
      });

      return result;
    }
  },
  created: function() {
    this.val =
      (this.optionsMode ? this.value?.value : this.value[0]?.value) || [];
  },
  watch: {
    value: function(value) {
      this.val = (this.optionsMode ? value?.value : value[0]?.value) || [];
    }
  },
  methods: {
    onChoiceClick: function(key) {
      this.isSelected(key)
        ? this.$emit("update-filter", this.$_.without(this.val, key), 0)
        : this.$emit("update-filter", [...this.val, key], 0);
    },
    findSelectedIndexForChoice: function(key) {
      return _.findIndex(this.val, v => v === key);
    },
    isSelected: function(key) {
      return this.findSelectedIndexForChoice(key) !== -1;
    }
  }
};
</script>
