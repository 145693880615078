<template>
  <core-dialog
    class="filter"
    :visible="visible"
    @update:visible="$emit('confirm')"
    append-to-body
    modal-append-to-body
    close-on-press-escape
    :width="70"
    :title="title"
  >
    <template v-if="possibleFilters">
      <div class="content__left filter__list h-screen md:h-pdf">
        <template v-if="!(breakpoint.innerWidth < breakpoints.medium)">
          <core-input
            type="text"
            :bordered="false"
            :rounded="false"
            :labelIcon="['far', 'search']"
            :label="$t('search_in_filters')"
            v-model="searchValue"
          />
          <core-filter-modal-list-item
            @click="activeFilter = 'active_filters'"
            :active="'active_filters' === activeFilter"
            :filter="{ title: $t('active_filters') }"
            :value="numberOfFilters"
          />
          <core-filter-modal-list-item
            v-for="filter in filteredView"
            @click="activeFilter = filter"
            :filter="view[filter]"
            :value="filters[filter]"
            :active="filter === activeFilter"
            :options-mode="optionsMode"
            v-bind:key="filter"
          />
        </template>
        <div v-else class="wrapper h-screen md:h-pdf">
          <core-select
            v-model="activeFilter"
            :options="mobilePossibleFilters"
            :labelKey="
              filter =>
                filter === 'active_filters' ? $t(filter) : view[filter].title
            "
          />
        </div>
      </div>
      <div
        class="content__right"
        v-if="currentFilter"
        v-bind:key="'filter_' + activeFilter"
      >
        <component
          v-if="!$_.isString(currentFilter)"
          :is="`core-filter-modal-type-${currentFilter.base_type}`"
          :filter="currentFilter"
          v-bind:key="activeFilter"
          @update-filter="updateFilter"
          @remove-filter="removeFilter"
          :value="filterValue"
          :options-mode="optionsMode"
        />
        <core-filter-modal-type-active
          v-else
          :filters="filters"
          :view="view"
          @tag-click="val => (activeFilter = val)"
          @update-filter="updateFilter"
          @remove-filter="removeFilter"
          :optionsMode="optionsMode"
        />
      </div>
    </template>
    <template v-slot:footer>
      <core-button type="coolrunner" @click="$emit('confirm')">{{
        $t("show")
      }}</core-button>
    </template>
  </core-dialog>
</template>

<script>
import _ from "lodash";
import { BREAKPOINTS } from "@/utils/constants";

export default {
  props: {
    viewType: {
      type: String,
      default: "shipments"
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Filter"
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    numberOfFilters: {
      type: Number,
      default: 0
    },
    optionsMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activeFilter: null,
    searchValue: null
  }),

  computed: {
    view: function() {
      const vds = this.$store.state.viewDescriptions;
      if (!vds) return {};
      return this.$_.get(vds, this.viewType) || {};
    },
    breakpoint: function() {
      return this.$store.state.breakpoint;
    },

    breakpoints: () => BREAKPOINTS,
    mobilePossibleFilters: function() {
      return ["active_filters", ...this.possibleFilters];
    },

    possibleFilters: function() {
      let objectArray = Object.entries(this.view);
      let sortedArray = objectArray.sort(function(a, b) {
        if (a[1].title < b[1].title) {
          return -1;
        }
        if (a[1].title > b[1].title) {
          return 1;
        }
        return 0;
      });
      return sortedArray.map(el => el[0]);
    },
    currentFilter: function() {
      return this.view[this.activeFilter] || this.activeFilter;
    },
    filterValue: function() {
      return this.filters[this.activeFilter];
    },
    filteredView() {
      if (this.possibleFilters && this.searchValue) {
        return this.possibleFilters.filter(key => {
          const item = this.view[key];
          let v = this.searchValue.toLowerCase();
          if (!v || !item.title) return true;

          return item.title.toLowerCase().includes(v);
        });
      } else {
        return this.possibleFilters;
      }
    }
  },
  watch: {
    possibleFilters: function(val, old) {
      if (!old || _.isEmpty(old)) {
        this.activeFilter = _.first(val);
      }
    }
  },
  created: function() {
    if (!_.isEmpty(this.possibleFilters))
      this.activeFilter = _.first(this.possibleFilters);
  },
  methods: {
    updateFilter: function(val, index, key = this.activeFilter) {
      this.$emit("update-filter", key, val, index);
    },
    removeFilter: function(index, key = this.activeFilter) {
      this.$emit("remove-filter", key, index);
    }
  }
};
</script>
