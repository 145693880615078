import _ from "lodash";

export const getTheme = ds => {
  // eslint-disable-next-line no-undef
  const theme = [
    "#e0f3db",
    "#ccebc5",
    "#a8ddb5",
    "#7bccc4",
    "#4eb3d3",
    "#2b8cbe",
    "#0868ac",
    "#084081"
  ];
  let repeatedTheme = theme;

  if (repeatedTheme.length <= ds.data.length) {
    const requiredThemeRepeats = Math.ceil(ds.data.length / theme.length); //Divide with theme colors count
    _.each(_.range(requiredThemeRepeats), i => {
      repeatedTheme = [...repeatedTheme, ...theme];
    });
  }

  return repeatedTheme;
};

export const TOOLTIPS = title => {
  return {
    callbacks: {
      title: () => title
    },
    titleFontFamily: "Inter",
    titleFontSize: 14,
    titleFontColor: "#272727",
    titleMarginBottom: 11,
    bodyFontFamily: "Inter",
    bodyFontColor: "#5D6470",
    bodyFontSize: 14,
    backgroundColor: "white",
    shadowOffsetX: 0,
    shadowOffsetY: 4,
    shadowBlur: 16,
    shadowColor: "rgba(0,0,0,.16)",
    xPadding: 19,
    yPadding: 19,
    caretSize: 0,
    displayColors: false
  };
};

export const SCALES = (yAxisCount = 1) => {
  const yAxes = [];

  for (let i = 0; i < yAxisCount; i++) {
    yAxes.push({
      id: i + "",
      position: i === 0 ? "left" : "right",
      ticks: {
        beginAtZero: true,
        fontColor: "#1A1A1A",
        padding: 16.3,
        callback: function(value) {
          let converted = new Intl.NumberFormat("dk-DK").format(value);
          return converted;
        }
      },
      scaleLabel: {
        fontColor: "#C9CCD0"
      },
      gridLines: {
        color: "#CFD7E6",
        zeroLineColor: "#CFD7E6",
        drawTicks: false
      }
    });
  }

  return {
    xAxes: [
      {
        scaleLabel: {
          fontColor: "#5D6470"
        },
        gridLines: {
          color: "#F1F5F8",
          zeroLineColor: "#F1F5F8",
          drawTicks: false,
          display: false
        },
        ticks: {
          beginAtZero: true,
          fontColor: "#1A1A1A",
          padding: 16.3
        }
      }
    ],
    yAxes
  };
};
