<template>
  <core-dialog
    id="help-dialog"
    :visible="visible"
    :title="$t('help')"
    :md_width="90"
    :lg_width="90"
    :xl_width="90"
    @close="$emit('close')"
  >
    <div
      class="wrapper form"
      style="display: flex; flex-direction: column; width: 100%; min-height: 180px;"
    >
      <template v-if="videoId && visible">
        <div class="video-wrapper">
          <youtube class="video" :video-id="videoId" ref="youtube" />
        </div>
      </template>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="display: flex; width: 100%;"
    >
      <core-button type="default_outline" @click="$emit('close')">{{
        $t("close")
      }}</core-button>
    </div>
  </core-dialog>
</template>

<script>
import Loader from "@/mixins/Loader";

export default {
  mixins: [Loader],
  name: "HelpDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    help: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    videoId: function() {
      if (!this.help.video) return "";

      return this.$youtube.getIdFromUrl(this.help.video);
    }
  }
};
</script>

<style lang="scss"></style>
