<template>
  <core-dialog
    :visible="visible"
    :title="dialogTitle"
    @open="$emit('wizard-dialog:open')"
    @close="$emit('wizard-dialog:close')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :id="'dialog-wizard-' + _uid"
    class="core-dialog--wizard"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template slot="title">
      <span class="el-dialog__title">{{ title }}</span>
    </template>
    <el-col
      :span="24"
      style="min-height: 256px"
      v-if="steps"
      :id="'dialog-wizard-' + _uid + '-container'"
    >
      <span v-for="(step, ei) in steps" v-bind:key="'dialog-step-' + ei">
        <div v-if="current_step_index == ei" style="height: 100%">
          <component
            :is="step.component"
            :loading.sync="loading"
            :entity.sync="edit_entity"
            @step:locked="val => (step.locked = val)"
            @update:entity="val => $emit('update:entity', entity)"
            @step:next="
              $emit('wizard-dialog:change-step', current_step_index + 1)
            "
            @step:previous="
              $emit('wizard-dialog:change-step', current_step_index - 1)
            "
            @step:skip="
              $emit(
                'wizard-dialog:change-step',
                current_step_index + last_step.action
              )
            "
            @step:set="s => $emit('wizard-dialog:change-step', s)"
          />
        </div>
      </span>
    </el-col>
    <div
      slot="footer"
      style="width: 100%"
      class="dialog-footer"
      :step="current_step"
      :loading="loading"
    >
      <el-col class="flex">
        <div
          style="float:left; width: 50%; transform: translateY(25%);"
          v-if="showProgressBar && progressBarLocation === 'footer'"
        >
          <el-progress
            :percentage="progressPercentage"
            :stroke-width="26"
            :text-inside="true"
            :format="formatProgressBar"
          />
        </div>
        <slot name="buttons-prefix" :step="current_step" :loading="loading" />
        <core-button
          type="default_outline"
          @click="$emit('wizard-dialog:close')"
          v-if="!current_step.hide_buttons.close"
          class="float-left mr-auto	"
          >{{ $t("close") }}</core-button
        >
        <slot name="buttons-center" :step="current_step" :loading="loading" />
        <core-button
          class="mr-2"
          type="default"
          v-if="!current_step.hide_buttons.back"
          @click="$emit('wizard-dialog:change-step', current_step_index - 1)"
        >
          {{ $t("back") }}
        </core-button>
        <core-button
          type="coolrunner"
          v-if="!current_step.hide_buttons.next"
          :loading="loading"
          @click="$emit('wizard-dialog:change-step', current_step_index + 1)"
          :disabled="current_step.locked === true"
        >
          {{ $t("next") }}
        </core-button>
        <slot name="buttons-suffix" :step="current_step" :loading="loading" />
      </el-col>
    </div>
  </core-dialog>
</template>

<script>
import Loader from "@/mixins/Loader";
import Payment from "@/mixins/Payment";
import _ from "lodash";
export default {
  inheritAttrs: false,
  name: "DialogWizard",
  mixins: [Loader],
  props: {
    title: {
      type: String,
      default: ""
    },
    footerActions: {
      type: Array,
      default: () => []
    },
    steps: {
      type: Array,
      default: () => [],
      validator: function(val) {
        return val.some(val => !_.isFunction(val.component));
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: () => ({})
    },
    showProgressBar: {
      type: Boolean,
      default: false
    },
    progressBarLocation: {
      type: String,
      default: "footer"
    },
    skipToStep: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    current_step_index: 0,
    edit_entity: {},
    last_step: {
      step: 0,
      action: null
    }
  }),
  watch: {
    edit_entity: {
      handler(val) {
        this.$emit("update:entity", val);
      },
      deep: true
    },
    skipToStep: function(val, old) {
      this.current_step_index = val;
    }
  },
  created() {
    //this.defaultLoaderTarget.customClass = "sticky";
    this.defaultLoaderTarget = `#dialog-wizard-${this._uid}-container`;
  },
  mounted() {
    this.edit_entity = { ...this.entity };
    this.$on("wizard-dialog:close", () => {
      this.$emit("update:visible", false);
      this.loading = false;
      this.current_step_index = 0;
    });

    this.$on("wizard-dialog:open", step => {});

    this.$on("wizard-dialog:change-step", step => {
      this.last_step.step = this.current_step_index;
      this.last_step.action = step - this.current_step_index;

      this.current_step_index = step;
      this.loading = false;
    });
  },
  computed: {
    progressPercentage: function() {
      return (this.current_step_index / (this.steps.length - 1)) * 100;
    },
    dialogTitle: function() {
      if (this.title && this.steps[this.current_step_index]?.name) {
        return `${this.title}: ${this.$t(
          this.steps[this.current_step_index].name
        )}`;
      }
      return this.title;
    },
    step_placeholder: function() {
      return {
        name: null,
        component: null,
        locked: null,
        hide_buttons: {
          close: false,
          back: this.current_step_index === 0,
          next: this.current_step_index >= this.steps.length - 1
        }
      };
    },
    current_step: function() {
      const step = {
        ...this.step_placeholder,
        ...this.steps[this.current_step_index]
      };

      const buttons = {
        ...this.step_placeholder.hide_buttons,
        ...(this.steps[this.current_step_index].hide_buttons ?? {})
      };

      step.hide_buttons = buttons;

      return step;
    }
  },
  methods: {
    formatProgressBar(percentage) {
      return `${this.$t(this.current_step?.name)} ${this.current_step_index +
        1}/${this.steps.length} `;
    }
  }
};
</script>

<style scoped></style>
