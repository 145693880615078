<template>
  <div class="w-full">
    <div id="account-info--wrapper" class="grid grid-cols-12 gap-6">
      <div
        id="account-payment--billing_address"
        class="col-span-12 lg:col-span-6"
      >
        <div class="border rounded-md p-3">
          <h4 class="pb-3">
            {{ $t(`billing_address`) }}
          </h4>
          <div>
            <div class="mb-2">
              <core-input
                v-model="account.billing_address.name"
                :value="account.billing_address.name"
                :label="$t('name')"
              />
            </div>
            <div class="mb-2" v-if="$store.state.account.business">
              <core-input
                v-model="account.billing_address.company_name"
                :value="account.billing_address.company_name"
                :label="$t('company_name')"
              />
            </div>
            <div class="mb-2">
              <core-input
                v-model="account.billing_address.street1"
                :value="account.billing_address.street1"
                :label="$t('street')"
              />
            </div>

            <div class="mb-2 grid grid-cols-12 gap-2">
              <div class="col-span-4">
                <core-input
                  v-model="account.billing_address.zip_code"
                  :value="account.billing_address.zip_code"
                  :label="$t('zip_code')"
                  style="min-width: unset"
                />
              </div>

              <div class="col-span-8">
                <core-input
                  v-model="account.billing_address.city"
                  :value="account.billing_address.city"
                  :label="$t('city')"
                  style="min-width: unset"
                />
              </div>
            </div>

            <div v-if="!!account" class="mb-2">
              <core-select-country
                v-model="account.billing_address.country"
                class="w-full max-w-full"
                :value="account.billing_address.country"
              />
            </div>

            <div class="mb-2">
              <core-input
                v-model="account.billing_address.phone"
                :value="account.billing_address.phone"
                :label="$t('phone')"
              />
            </div>

            <div class="mb-2">
              <core-input
                v-model="account.billing_address.email"
                :value="account.billing_address.email"
                :label="$t('email')"
              />
            </div>
          </div>
          <p v-if="!formOkay" class="">Indtast en gyldig email</p>
        </div>
      </div>
      <div
        id="account-payment--shipping_address"
        class="col-span-12 lg:col-span-6"
      >
        <div class="border rounded-md p-3">
          <h4 class="pb-3">
            {{ $t(`shipping_address`) }}
          </h4>
          <div>
            <div class="mb-2">
              <core-input
                v-model="account.shipping_address.name"
                :value="account.shipping_address.name"
                :label="$t('name')"
              />
            </div>
            <div class="mb-2" v-if="$store.state.account.business">
              <core-input
                v-model="account.shipping_address.company_name"
                :value="account.shipping_address.company_name"
                :label="$t('company_name')"
              />
            </div>
            <div class="mb-2">
              <core-input
                v-model="account.shipping_address.street1"
                :value="account.shipping_address.street1"
                :label="$t('street')"
              />
            </div>

            <div class="mb-2 grid grid-cols-12 gap-2">
              <div class="col-span-4">
                <core-input
                  v-model="account.shipping_address.zip_code"
                  :value="account.shipping_address.zip_code"
                  :label="$t('zip_code')"
                  style="min-width: unset"
                />
              </div>

              <div class="col-span-8">
                <core-input
                  v-model="account.shipping_address.city"
                  :value="account.shipping_address.city"
                  :label="$t('city')"
                  style="min-width: unset"
                />
              </div>
            </div>

            <div v-if="!!account" class="mb-2">
              <core-select-country
                v-model="account.shipping_address.country"
                class="w-full max-w-full"
                :value="account.shipping_address.country"
              />
            </div>

            <div class="mb-2">
              <core-input
                v-model="account.shipping_address.phone"
                :value="account.shipping_address.phone"
                :label="$t('phone')"
              />
            </div>

            <div class="mb-2">
              <core-input
                v-model="account.shipping_address.email"
                :value="account.shipping_address.email"
                :label="$t('email')"
              />
            </div>
          </div>

          <div
            class="mt-3 pt-3 border-t border-gray-200 border-solid flex justify-between"
          >
            <core-button
              type="default"
              class="mr-auto"
              @click="modalOpen = true"
              >{{
                $t("change_entity", {
                  entity: $t("password").toLowerCase()
                })
              }}
            </core-button>
            <core-button
              :loading="saving"
              type="default_outline"
              class="mr-2"
              @click="reset"
              >{{ $t("cancel") }}
            </core-button>
            <core-button
              v-if="formOkay"
              type="coolrunner"
              :loading="saving"
              @click="save"
              >{{ $t("save") }}
            </core-button>
          </div>
        </div>
      </div>
      <div v-if="$store.state.account.business" class="border rounded-md p-3 col-span-6">
          <h4 class="pb-3">
            {{ $t(`account.info`) }}
          </h4>
          <div class="mb-2">
            <core-input
              v-model="accountInfo.account.name"
              :value="accountInfo.account.name"
              :label="$t('name')"
            />
          </div>
          <div class="mb-2">
            <core-input
              v-model="accountInfo.subdomain"
              :value="accountInfo.subdomain"
              :label="$t('Subdomain')"
            />
          </div>
          <core-button
              type="coolrunner"
              :disabled="!domainOkay"
              :loading="savingInfo"
              @click="saveAccountInfo()"
              >{{ $t("save") }}
            </core-button>
      </div>
    </div>

    <core-dialog
      :title="
        $t('change_entity', { entity: $t('password').toLocaleLowerCase() })
      "
      :lg_width="25"
      :xl_width="20"
      :visible.sync="modalOpen"
      :close-on-click-modal="false"
      @close="resetPassword"
    >
      <div id="account_password__container" class="w-full">
        <div class="form">
          <core-input
            v-model="password"
            style="margin-bottom: 0.5em"
            :value="password"
            :label="$t('password')"
          />

          <core-input
            v-model="confirm_password"
            :value="confirm_password"
            :label="$t('confirm_password')"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer flex">
        <core-button
          class="mr-auto"
          type="default_outline"
          @click="modalOpen = false"
          >{{ $t("cancel") }}</core-button
        >
        <core-button type="coolrunner" @click="updatePassword">{{
          $t("update")
        }}</core-button>
      </span>
    </core-dialog>
  </div>
</template>

<script>
import Loader from "@/mixins/Loader";
import _ from "lodash";
import { URL_PREFIX } from "@/utils/constants";

export default {
  name: "Info",
  mixins: [Loader],
  data: () => ({
    endpoint: "account",
    cancelToken: null,
    modalOpen: false,
    password: null,
    confirm_password: null,
    account: { shipping_address: {}, billing_address: {} },
    saving: false,
    savingInfo: false,
    accountInfo: {}
  }),
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    formOkay: function() {
      // eslint-disable-next-line no-useless-escape
      const emailFormat = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      if (
        !emailFormat.test(this.account.shipping_address.email) ||
        !emailFormat.test(this.account.billing_address.email)
      ) {
        return false;
      } else {
        return true;
      }
    },
    domainOkay: function() {
      const domainFormat = new RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)
      if (
        !domainFormat.test(this.accountInfo.subdomain)
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    "this.$store.state.account": function(val) {
      this.account = val;
    }
  },
  mounted: function() {
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#account_info--wrapper"
    };
    this.account = { ...this.$store.state.account };
    this.getAccountInfo()
  },
  methods: {
    resetPassword() {
      this.password = this.confirm_password = "";
    },
    getAccountInfo() {
      let id = this.$store.state.account_id
      _.forEach(this.$store.state.accountRes, (account, key) => {
        if (account.account.id == id) {
          this.accountInfo = account
        }
      })
    },
    updatePassword() {
      let self = this;
      this.$put(`${this.endpoint}/password`, {
        password: this.password,
        password_confirmation: this.confirm_password
      })
        .then(function() {
          self.$notification({
            title: self.$t("entity_saved_correctly", {
              entity: self.$t("password").toLocaleLowerCase()
            }),
            type: "success"
          });

          self.modalOpen = false;
        })
        .catch(function(error) {
          let response = error.response;
          if (self.$_.get(response, "data.errors.password")) {
            response.data.errors.password.forEach(function(message) {
              self.$notification({
                title: message,
                type: "error"
              });
            });
          }
        });
    },
    reset() {
      this.account = { ...this.$store.state.account };
    },
    saveAccountInfo() {
      this.savingInfo = true;
      let entity = {
        name: this.accountInfo.account.name,
        subdomain: this.accountInfo.subdomain
      }
      this.$put( URL_PREFIX + `/auth-service/accounts/${this.$store.state.auth_id}`, entity)
        .then(() => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$t(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
        })
        .catch(e => {
          const errors =
            e.response.data.message ?? e.response.data.response_data;
          this.$notification({
            title: this.$t("error", {
              entity: this.$t(this.name).toLowerCase()
            }),
            text: errors,
            type: "error"
          });
        })
        .finally(() => {
          this.savingInfo = false;
        });
    },
    save() {
      let url = `${this.endpoint}`;
      this.saving = true;
      let entity = { ...this.account };
      this.$put(url, entity, { useDev: this.useDev, failSilently: true })
        .then(() => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$t(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
          this.getDataFromApi();
        })
        .catch(e => {
          const errors =
            e.response.data.errors ?? e.response.data.response_data;
          this.$notification({
            title: this.$t("error", {
              entity: this.$t(this.name).toLowerCase()
            }),
            text: this.$_.join(this.$_.map(errors), "</br>"),
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getDataFromApi() {
      this.$store.dispatch("loadAccount");
    }
  }
};
</script>

<style scoped></style>
