<template>
  <el-image :src="url" :fit="'fill'" ondragstart="return false">
    <div slot="placeholder" class="image-slot">
      <div class="fa-3x">
        <font-awesome-icon
          :icon="['fad', 'spinner-third']"
          spin
          style="color:#409EFF;opacity: 0.4;"
        />
      </div>
    </div>
    <div slot="error" class="image-slot">
      <div class="fa-3x">
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
      </div>
    </div>
  </el-image>
</template>

<script>
import { getCarrierImageUrl } from "../../utils/tools";

export default {
  name: "CarrierLogo",
  props: {
    carrier: {
      type: String,
      default: null
    },
    context: {
      type: String,
      default: "business_panel"
    }
  },
  created() {},
  computed: {
    url: function() {
      return getCarrierImageUrl(this.carrier, 128, this.context);
    }
  }
};
</script>

<style scoped></style>
