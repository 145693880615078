<template>
  <div class="bg-white rounded-md shadow-sm">
    <div class="relative">
    <h3 class="text-xl font-medium p-8">{{ title }}</h3>
    <div v-if="hasDescription" class="absolute top-3 right-3">
              <p class="has-tooltip">
                <font-awesome-icon
                  class="text-2xl text-gray-300 cursor-pointer"
                  :icon="['fal', 'info-circle']"
                /><span
                  class="tooltip text-sm bg-gray-50 shadow-md rounded-md p-2"
                  >{{ hasDescription }}</span
                >
              </p>
            </div>
    </div>
    <el-table
      :data="tableData"
      height="462"
      style="
      width: 100%;
      max-width: 90vw;
      border-radius: 5px;"
      :cell-style="{ padding: '5px', height: '50px' }"
      row-class-name="tableRows"
    >
      <el-table-column
        v-for="label in labels"
        v-bind:key="label.key"
        :prop="label.key"
        :label="label.title"
        :min-width="33"
        style="color: #0b0c0e "
      />
    </el-table>
  </div>
</template>

<script>
import Widget from "@/mixins/Widget";

export default {
  mixins: [Widget],
  data: () => ({
    tableData: [],
    labels: []
  }),
  props: {
    hasDescription: {
      type: String,
      default: ""
    }
  },
  methods: {
    fillData(widgetData) {
      this.title = widgetData.title;
      this.tableData = widgetData.data.datasets;
      this.labels = widgetData.data.labels;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.el-table thead {
  color: #ccccd3;
}
.el-table__row {
  font-size: 13px;
}

.tooltip {
  @apply opacity-0 absolute -left-20 transition duration-300 ease-in-out pointer-events-none;
}

.has-tooltip:hover .tooltip {
  @apply opacity-100 z-50 transition duration-300 ease-in-out pointer-events-none;
}
</style>
