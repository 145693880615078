<template>
  <div class="credit-card">
    <div style="display: flex;">
      <div class="logo-wrapper">
        <font-awesome-icon
          class="logo"
          :icon="['fab', `cc-${cardTypeClass}`]"
        />

        <div v-if="type == 'mastercard-debet'" style="font-size: 8pt;">
          debit
        </div>
        <div v-else-if="type == 'visa-electron'" style="font-size: 8pt;">
          electron
        </div>
      </div>
      <div class="info">
        <p class="number">{{ cardNumber }}</p>
        <p class="name">{{ cardName }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    number: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: "FULL NAME"
    }
  },
  computed: {
    cardTypeClass: function() {
      let type = this.type;

      type = type.replace(/-[\s\S]*$/, "");
      return this.$_.snakeCase(type);
    },
    cardName: function() {
      return this.name.toUpperCase();
    },
    cardNumber: function() {
      let string = "";
      if (this.number) {
        string = this.number.toString();
      }

      const maxLength = 16;

      if (string.length > maxLength) {
        string = string.slice(0, maxLength);
      }

      while (string.length < maxLength) {
        string += "•";
      }

      const amountOfSpaces = Math.ceil(maxLength / 4);
      const spacesBetween = 3;

      for (let i = 1; i <= amountOfSpaces; i++) {
        const spaceIndex = i * 4 + (i - 1) * spacesBetween;
        string = `${string.slice(0, spaceIndex)}${this.$_.repeat(
          " ",
          spacesBetween
        )}${string.slice(spaceIndex)}`;
      }

      return string;
    }
  }
};
</script>

<style lang="scss">
.credit-card {
  background-image: url("/img/creditcard/card-front.png");
  padding: 20px;
  width: 85.6mm;
  background-size: 125%;
  height: 53.98mm;
  z-index: 1;
  overflow: hidden;
  max-width: 100%;
  border-radius: 8px;
  position: relative;
  color: white;
  display: flex;
  .info {
    margin-top: auto;
    p {
      color: white;
      &.name {
        margin-top: 15px;
        font-size: 11px;
      }
    }
  }
  .logo-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    align-items: center;
  }
  .logo {
    font-size: 40px;
  }
}
</style>
