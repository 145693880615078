<template>
  <div>
    <el-image
      :src="
        `${url}/images/carriers/${carrier}.png?w=500&context=business_panel`
      "
      class="carrier-image"
      :fit="'contain'"
      ondragstart="return false"
    >
      <div slot="error" class="image-slot">{{ carrier }}</div>
    </el-image>
  </div>
</template>

<script>
export default {
  name: "CarrierWithImageCell",
  props: {
    carrier: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    }
  }
};
</script>
