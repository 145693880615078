<template>
  <header id="bp_header" class="sticky top-0 z-[100] shadow-md">
    <div v-if="showbanner" class="bg-white p-1">
      <div class="flex items-center justify-center gap-4 rounded-xl bg-[#0456D1]">
        <div class="left-graphic absolute left-1 top-1 h-11 w-[240px]"></div>
        <p class="text-center text-sm font-medium text-white">{{ $t("new_platform_teaser") }}</p>
        <div class="my-2 flex h-7 max-w-max cursor-pointer items-center rounded-lg bg-[#26282C] px-3 transition duration-300 hover:bg-[#2D3239]" style="box-shadow: 0px 1px 3px 0px rgba(38, 40, 44, 0.1), 0px -1px 0px 0px rgba(38, 40, 44, 0.1) inset" @click="goToNewPlatform">
          <div class="flex items-center gap-1.5">
            <p class="text-sm font-medium text-white">{{ $t("go_to_new") }}</p>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="ArrowUpRight">
                <path
                  id="Vector"
                  d="M13.25 4V10.5C13.25 10.6989 13.171 10.8897 13.0303 11.0303C12.8897 11.171 12.6989 11.25 12.5 11.25C12.3011 11.25 12.1103 11.171 11.9697 11.0303C11.829 10.8897 11.75 10.6989 11.75 10.5V5.8125L5.03063 12.5306C4.88973 12.6715 4.69864 12.7507 4.49938 12.7507C4.30012 12.7507 4.10902 12.6715 3.96813 12.5306C3.82723 12.3897 3.74808 12.1986 3.74808 11.9994C3.74808 11.8001 3.82723 11.609 3.96813 11.4681L10.6875 4.75H6C5.80109 4.75 5.61033 4.67098 5.46967 4.53033C5.32902 4.38968 5.25 4.19891 5.25 4C5.25 3.80109 5.32902 3.61032 5.46967 3.46967C5.61033 3.32902 5.80109 3.25 6 3.25H12.5C12.6989 3.25 12.8897 3.32902 13.0303 3.46967C13.171 3.61032 13.25 3.80109 13.25 4Z"
                  fill="white"
                  style="fill: white; fill-opacity: 1"
                />
              </g>
            </svg>
          </div>
        </div>
        <div class="right-graphic absolute right-0 top-1 h-11 w-[240px]"></div>
        <div class="absolute right-3 top-3 flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg bg-[#FFFFFF33]" @click="showbanner = false">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5292_27)">
              <path
                d="M13.0306 11.9694C13.1715 12.1103 13.2506 12.3014 13.2506 12.5006C13.2506 12.6999 13.1715 12.891 13.0306 13.0319C12.8897 13.1728 12.6986 13.2519 12.4993 13.2519C12.3001 13.2519 12.109 13.1728 11.9681 13.0319L7.99997 9.0625L4.0306 13.0306C3.8897 13.1715 3.69861 13.2507 3.49935 13.2507C3.30009 13.2507 3.10899 13.1715 2.9681 13.0306C2.8272 12.8897 2.74805 12.6986 2.74805 12.4994C2.74805 12.3001 2.8272 12.109 2.9681 11.9681L6.93747 8L2.96935 4.03063C2.82845 3.88973 2.7493 3.69863 2.7493 3.49938C2.7493 3.30012 2.82845 3.10902 2.96935 2.96813C3.11024 2.82723 3.30134 2.74807 3.5006 2.74807C3.69986 2.74807 3.89095 2.82723 4.03185 2.96813L7.99997 6.9375L11.9693 2.9675C12.1102 2.8266 12.3013 2.74745 12.5006 2.74745C12.6999 2.74745 12.891 2.8266 13.0318 2.9675C13.1727 3.1084 13.2519 3.29949 13.2519 3.49875C13.2519 3.69801 13.1727 3.8891 13.0318 4.03L9.06247 8L13.0306 11.9694Z"
                fill="white"
                style="fill: white; fill-opacity: 1"
              />
            </g>
            <defs>
              <clipPath id="clip0_5292_27">
                <rect width="16" height="16" fill="white" style="fill: white; fill-opacity: 1" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-between bg-white p-3 lg:p-5">
      <div v-if="isHomerunner">
        <router-link :to="{ name: 'home' }">
          <img alt="Homerunner Logo" src="https://a.storyblok.com/f/120110/x/c8ab0bf836/homerunner_logo.svg" class="hidden h-9 lg:block" />
          <img src="https://a.storyblok.com/f/120110/x/dd311f5a33/icon.svg" alt="Homerunner Logo" class="h-9 lg:hidden" />
        </router-link>
      </div>
      <div v-else>
        <router-link :to="{ name: 'labels' }">
          <img alt="CoolRunner Logo" src="/img/logo.svg" class="hidden h-9 lg:block" />
          <img src="/img/logo_simple.svg" alt="CoolRunner Logo" class="h-9 lg:hidden" />
        </router-link>
      </div>

      <div class="header-buttons ml-4 flex items-center justify-start lg:mr-2 lg:justify-end">
        <div class="cursor-pointer rounded-md bg-coolrunner-500 px-3 py-2 font-semibold text-white transition-all duration-300 hover:bg-coolrunner-400" :class="{ 'bg-[#004062] text-white hover:bg-[#0a5075]': account.business }" @click="showCreate">
          {{ $t("send_package") }}
        </div>
        <a href="https://help.homerunner.com/" target="_blank" class="header-button text-coolrunner-900" :class="{ 'text-[#004062]': account.business }">
          {{ $t("help_center") }}
        </a>
        <a href="https://status.coolrunner.dk/" target="_blank" class="header-button text-coolrunner-900" :class="{ 'text-[#004062]': account.business }">
          {{ $t("status") }}
        </a>
        <div class="relative ml-5 mr-3">
          <div class="flex cursor-pointer text-coolrunner-900" :class="{ 'text-[#004062]': account.business }" @click="openOrganisation()">
            {{ account.business ? account.company_name : account.name }} <font-awesome-icon v-if="hasMultipleAccountRes" class="ml-2 mt-1.5 h-3 w-3 transition-all duration-300" :class="[organisationOpen ? 'rotate-180 transform' : '']" :icon="['far', 'chevron-down']" fixed-width />
          </div>
          <div
            v-if="organisationOpen"
            v-click-outside="
              () => {
                organisationOpen ? closeOrganisation() : () => null;
              }
            "
            class="absolute right-0 top-6 w-full min-w-[250px] overflow-hidden rounded bg-white shadow-lg transition-all duration-300"
            :class="[organisationOpen ? 'max-h-[300px]' : 'max-h-0']"
          >
            <div class="p-3">
              <div v-for="acc in $store.state.accountRes" :key="acc.account.id" class="mb-1 flex cursor-pointer" @click="goToSubdomain(acc.subdomain)">
                <p class="text-coolrunner-900 transition-all duration-300 hover:text-opacity-50" :class="{ 'text-[#004062]': acc.business }">{{ acc.account.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="ml-3 h-7 w-7 rounded-full bg-coolrunner-800 pt-[7px] text-xs font-bold uppercase text-white">{{ username }}</div>
      </div>
      <div class="flex flex-grow-0 items-center lg:hidden">
        <div
          color="#004062"
          class="hamburger hamburger--elastic"
          :class="{
            'is-active': menuOpen,
          }"
          @click="menuOpen = !menuOpen"
        >
          <div class="hamburger-box">
            <div class="hamburger-inner" />
          </div>
        </div>
      </div>
    </div>
    <el-drawer size="85%" :visible.sync="menuOpen" direction="ltr" append-to-body>
      <core-menu @entry-click="menuOpen = false" />
    </el-drawer>
  </header>
</template>

<script>
import EventBus from "@/plugins/eventbus";

export default {
  data: () => ({
    menuOpen: false,
    organisationOpen: false,
    showbanner: true,
  }),
  computed: {
    account: function () {
      return this.$store.state.account || {};
    },
    isHomerunner: function () {
      return window.location.hostname === "homerunner.com" || this.account.business;
    },
    hasMultipleAccountRes: function () {
      return this.$store.state.accountRes?.length > 1;
    },
    username: function () {
      let firstAccount = this.$store.state.accountRes ? this.$store.state.accountRes[0] : null;
      let name = firstAccount?.user?.name;

      if (!name) {
        return "";
      }
      if (name.includes(" ")) {
        let splitstring = name.split(" ");
        return `${splitstring[0].substring(0, 1)}${splitstring[1].substring(0, 1)}`;
      } else {
        return name.substring(0, 2);
      }
    },
  },
  methods: {
    toggleMenu: function () {
      this.menuOpen = !this.menuOpen;
    },
    openOrganisation: function () {
      if (this.hasMultipleAccountRes) {
        this.organisationOpen = true;
      }
    },
    closeOrganisation: function () {
      if (this.organisationOpen) {
        this.organisationOpen = false;
      }
    },
    goToSubdomain: function (subdomain) {
      window.location.replace(`https://${subdomain}.homerunner.com`);
    },
    showCreate: function () {
      EventBus.$emit("SHOW_CREATE_SHIPMENT");
    },
    goToNewPlatform: function () {
      this.$cookies.remove("frontend-version", null, `.${this.getCurrentParentDomain()}`);

      window.location.href = "/auth/website/forwarder"
    },
    getCurrentParentDomain: function () {
      const domainParts = window.location.hostname.split(".");
      const parentDomain = domainParts.slice(domainParts.length - 2).join(".");

      return parentDomain;
    },
  },
};
</script>

<style>
.right-graphic {
  background-image: url("/img/right-graphic.svg");
  background-size: contain;
}
.left-graphic {
  background-image: url("/img/left-graphic.svg");
  background-size: contain;
}
</style>
