<template>
  <div>
    <core-dialog
      id="create-shipment-dialog"
      :visible="visible"
      @update:visible="close"
      :title="title"
      @close="close"
      :use_default_breakpoints="false"
      :lg_width="30"
      :xl_width="30"
      :md_width="30"
      :xs_width="90"
      :closeOnClickModal="false"
    >
      <div class="h-pdf">
        <transition name="page" mode="out-in">
          <component
            class="step"
            :is="`core-dialog-create-shipment-steps-${step}`"
            :value="entity"
            :paymentSessionId="paymentSessionId"
            :acceptUrl="acceptUrl"
            @update:value="val => (entity = val)"
            @set:nextStep="setNextStep"
            @set:step="goToStep"
            @terms:accepted="val => (termsAccepted = val)"
            @process="val => processShipment(orderResponse)"
          >
          </component>
        </transition>
      </div>
      <div
        slot="footer"
        style="width: 100%"
        class="dialog-footer flex justify-end mt-2"
      >
        <template v-if="step !== 'success'">
          <core-button
            type="coolrunner"
            class="ml-2"
            :disabled="!steps.length || loading"
            @click="doStep(false)"
          >{{ $t("back") }}
          </core-button>
          <core-button
            v-if="step !== 'review'"
            type="coolrunner"
            class="ml-2"
            :disabled="!nextStep || loading"
            @click="doStep(true)"
          >{{ $t("next") }}
          </core-button>
          <core-button
            v-else
            type="coolrunner"
            class="ml-2"
            :disabled="!termsAccepted || loading"
            @click="createOrder"
          >{{ $t("create") }}
          </core-button>
        </template>
        <core-button v-else type="coolrunner" class="ml-2" @click="close"
        >{{ $t("finish") }}
        </core-button>
      </div>
    </core-dialog>
  </div>
</template>

<script>
import Payment from "@/mixins/Payment";
import Loader from "@/mixins/Loader";
import { URL_PREFIX } from "@/utils/constants";

const emptyShipment = {
  receiver: {
    city: "",
    country: "",
    email: "",
    name: "",
    company_name: "",
    phone: "",
    street1: "",
    zip_code: "",
    type: "private"
  },
  sender: {
    city: "",
    country: "",
    email: "",
    name: "",
    company_name: "",
    phone: "",
    street1: "",
    zip_code: "",
    type: "private"
  },
  carrier: "",
  carrier_product: "",
  carrier_service: "",
  reference: "",
  servicepoint_id: null,
  insurance: null,
  height: null,
  width: null,
  length: null,
  weight: null,
  meta: {
    instructions: null,
    delivery_date: null,
    expiry_date: null
  }
};

const fullSteps = [
  "receiver",
  "sender",
  "dimensions",
  "weight",
  "products",
  "droppoint",
  "insurance"
];

export default {
  name: "CreateShipmentDialog",
  mixins: [Payment, Loader],
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    duplicate: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    steps: [],
    entity: emptyShipment,
    step: "receiver",
    nextStep: null,
    prevStep: null,
    termsAccepted: false,
    defaultLoaderTarget: "#create-shipment-dialog .step",
    paymentSessionId: null,
    acceptUrl: null,
    orderResponse: null
  }),

  mounted: function() {
    this.init();
  },
  computed: {
    title: function() {
      const title = this.$t("create_entity", {
        entity: this.$tc("shipment").toLocaleLowerCase()
      });
      const sub_title = this.$t(this.step);

      return `${title}: ${sub_title}`;
    }
  },
  methods: {
    close: function() {
      this.entity = emptyShipment;
      this.$emit("update:visible", false);
    },
    setduplicate: function() {
      let {
        id: sender_id,
        state: sender_state,
        notify_email: sender_notify_email,
        notify_sms: sender_notify_sms,
        attention: sender_attention,
        ...sender
      } = this.duplicate.sender;

      if (sender_attention) {
        sender = {
          ...sender,
          name: sender_attention,
          company_name: this.duplicate.sender.name,
          type: "business"
        };
      }

      let {
        state: receiver_state,
        notify_email: receiver_notify_email,
        notify_sms: receiver_notify_sms,
        attention: receiver_attention,
        ...receiver
      } = this.duplicate.receiver;

      if (receiver_attention) {
        receiver = {
          ...receiver,
          name: receiver_attention,
          company_name: this.duplicate.receiver.name,
          type: "business"
        };
      }

      this.entity = {
        carrier: this.duplicate.carrier,
        carrier_product: this.duplicate.carrier_product,
        carrier_service: this.duplicate.carrier_service,
        height: this.duplicate.height,
        insurance: this.duplicate.insurance,
        length: this.duplicate.length,
        receiver: receiver,
        reference: this.duplicate.reference,
        sender: sender,
        servicepoint_id: this.duplicate.servicepoint_id,
        weight: this.duplicate.weight,
        width: this.duplicate.width,
        meta: this.duplicate.meta,
        order_lines: this.duplicate.order_lines,
        price: {
          title: this.duplicate.title,
          excl_tax: this.duplicate.price.excl_tax,
          incl_tax: this.duplicate.price.incl_tax,
          insurance: this.duplicate.insurance
        }
      };

      if (this.duplicate.carrier_service !== "droppoint") {
        this.steps = fullSteps.filter(step => step !== "droppoint");
      } else this.steps = fullSteps;

      this.step = "review";
    },
    createOrder: function() {
      this.loading = true;

      if (this.entity.receiver.type === "business") {
        this.entity.receiver.attention = this.entity.receiver.name;
        this.entity.receiver.name = this.entity.receiver.company_name;
      }

      if (this.entity.sender.type === "business") {
        this.entity.sender.attention = this.entity.sender.name;
        this.entity.sender.name = this.entity.sender.company_name;
      }

      let shipment = {
        carrier: this.entity.carrier,
        carrier_product: this.entity.carrier_product,
        carrier_service: this.entity.carrier_service,
        weight: this.entity.weight,
        servicepoint_id: this.entity.servicepoint_id,
        length: this.entity.length,
        height: this.entity.height,
        width: this.entity.width,
        sender: this.entity.sender,
        receiver: this.entity.receiver,
        reference: this.entity.reference,
        insurance: this.entity.insurance || false,
        meta: this.entity?.meta || {},
        order_lines: this.entity.order_lines || []
      };

      this.$post(
        `shipments`,
        {
          shipment: { ...shipment },
          final_url: this.landing_page.order_success
        },
        { failSilently: true }
      )
        .then(response => {
            this.handlePayment(response);
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          if (response.data.response_data) {
            this.errors = response.data.response_data.errors.carrier;
          }
          this.$notification({
            title: this.$t("error"),
            text: this.$_.join(this.$_.map(this.errors), "</br>"),
            type: "error"
          });
          this.loading = false;
        });
    },
    handlePayment(response) {
      if (response.type !== "invoice" && response.type !== "balance") {
        let payment_window = this.popup(response.payment_url);
        this.$on(`payment-${payment_window}:success`, () => {
          this.processShipment(response);
        });
      } else {
        this.processShipment(response);
      }
    },
    processShipment(order_response) {
      this.$get(
        `${URL_PREFIX}/business-panel/order/${order_response.order.id}/process`,{},
        {
          failSilently: true
        }
      )
        .then(response => {
          this.order = response;

          let gtagProducts = [
            {
              name: response.title
            }
          ];
          if (response.insurance) gtagProducts.push({ name: "Insurance" });

          this.$gtag.purchase({
            transaction_id: order_response.order.id,
            currency: "DKK",
            value: response.shipment.price.excl_tax,
            products: gtagProducts
          });

          if (order_response.type === "balance") {
            this.$store.dispatch("loadPaymentInfo");
            this.$notification({
              title: this.$t("balance"),
              duration: -1,
              text:
                this.$t("entity_drawn_from_balance", {
                  entity: this.$n(
                    this.$store.state.account.business
                      ? response.shipment.price.excl_tax
                      : response.shipment.price.incl_tax,
                    "currency",
                    "da-DK"
                  )
                }) + ` (#${response.order.id})`,
              data: {
                actions: [
                  {
                    label: this.$t("go_to_entity", {
                      entity: this.$t("balance")
                    }),
                    click: () => {
                      this.$router.push({ name: "account_settings_payment" });
                    }
                  }
                ]
              }
            });
          }

          this.$notification({
            title: this.$t("created"),
            text: this.$t("entity_saved_correctly", {
              entity: this.$tc("shipment").toLocaleLowerCase()
            })
          });
          this.$store.dispatch("setTemp", {
            key: "order",
            value: response
          });

          this.goToStep("success");

          this.loading = false;
        })
        .catch(e => {
          const errors =
            e.response.data.errors ?? e.response.data.response_data;
          this.$notification({
            title: e.response.data.status_message,
            text: this.$_.join(this.$_.map(errors), "</br>"),
            type: "error",
            duration: -1
          });
          this.loading = false;
        });
    },
    closeModal(e) {
      this.$emit("update:visible", e);
      this.duppedShipment = null;
      this.dupStep = 0;
    },
    init() {
      if (this.duplicate) this.setduplicate();
      else
        this.entity = {
          ...this.entity,
          sender: {
            ...this.entity.sender,
            ...this.$store.state.account.shipping_address,
            type: this.$store.state.account.business ? "business" : "private"
          }
        };
    },
    setNextStep(step) {
      this.nextStep = step;
    },
    doStep(forward) {
      if (forward) {
        this.steps.push(this.step);
        this.step = this.nextStep;
        this.nextStep = null;
      } else this.step = this.steps.pop();
    },
    goToStep(step) {
      if (this.steps.includes(step)) {
        while (this.step !== step) this.step = this.steps.pop();
      } else {
        this.steps.push(this.step);
        this.step = step;
      }
    }
  }
};
</script>

<style lang="scss">
#create-shipment-dialog.el-dialog__wrapper .el-dialog__body {
  display: block !important;
  margin-bottom: auto;


}

#create-shipment-dialog .el-dialog {
  display: flex;
  flex-direction: column;
}
</style>
