<template>
  <core-select
    class="w-full"
    v-bind="$attrs"
    v-on="$listeners"
    :options="options"
    labelKey="name"
    :label="$tc('shipping_method', 1)"
    valueKey="slug"
    clearable
    remote
    filterable
    :remote-method="fetchOptions"
    :loading="loading"
    :valueIsObject="valueIsObject"
  />
</template>
<script>
export default {
  name: "CPSSelect",
  inheritAttrs: false,
  props: {
    valueIsObject: {
      type: Boolean,
      default: true
    },
    cps: {
      type: Boolean,
      default: false

    }
  },
  data: () => ({
    loading: true,
    options: []
  }),
  created: function() {
    this.fetchOptions();
  },
  methods: {
    fetchOptions: function(q) {
      this.loading = true;
      this.$get("/carrier-product-services", { q })
        .then(r => {
          if (this.cps) {
            this.$_.forEach(r, function (option) {
              option.name = `${option.name} ( ${option.slug} )`

            });
            this.options = r;
          } else {
            this.options = r;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
