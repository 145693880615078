<template>
  <core-view id="account_wrapper">
    <core-view-header
      :name="name"
      :count="groupsTotal"
    > 
    <template v-slot:description>
        <i18n
          path="page_descriptions.agreements"
          tag="div"
          class="small"
          style="white-space: pre-line;text-align: left; margin-top: 1em; max-width: 70%"
        >
          <template v-slot:contact_link>
            <a class="underline"
              href="http://service.coolrunner.dk/contact"
              >{{ $t("service.coolrunner.dk") }}</a
            >
          </template>
        </i18n>
      </template>
    </core-view-header>

    <core-list-new-table
      :items="availableGroups"
      :headers="headers"
      :loading="loading"
      :paginate="paginate"
      :orderBy.sync="options.orderBy"
      :orderDir.sync="options.orderDir"
      :query.sync="search"
      :totalItems="groupsTotal"
      @row-click="openModal"
    />

    <core-dialog
      class="filter "
      :visible.sync="showModal"
      @update:visible="$emit('confirm')"
      append-to-body
      :lg_width="40"
      :xl_width="40"
      :title="
        $t('update_entity', {
          entity: `${editedItem['group_label'] || ''} ${$tc(
            'agreement'
          ).toLowerCase()}`
        })
      "
      :lock-scroll="lock_scroll"
      :close-on-click-modal="false"
    >
      <template>
        <div class="form wrapper">
          <el-row class="grid-content" v-if="editedItem.description">
            <div class="paragraph" style="padding:0 10px;margin-bottom: 24px">
              {{ editedItem.description }}
            </div>
          </el-row>
          <el-row v-if="editedItem">
            <el-col :span="24">
              <el-row
                v-for="(val, index) in editedItem.options"
                v-bind:key="'input_' + index"
                align="middle"
                class="grid-content"
                size="small"
                type="flex"
              >
                <component
                  v-if="selectedGroupModel[val.key]"
                  :is="getType(selectedGroupModel[val.key].type)"
                  :label="$t(selectedGroupModel[val.key].label)"
                  style="margin-bottom: 10px"
                  v-model="val.value"
                  :value="val.value"
                  :options="selectedGroupModel[val.key].values"
                  :disabled="selectedGroupModel[val.key].disabled"
                  :hidden="selectedGroupModel[val.key].hidden"
                />
              </el-row>
            </el-col>
          </el-row>
        </div>
      </template>
      <template v-slot:footer>
        <core-button
          class="mr-auto"
          type="default_outline"
          @click="showModal = !showModal"
          >{{ $t("cancel") }}
        </core-button>
        <core-button type="coolrunner" @click="save" :loading="saving"
          >{{ $t("save") }}
        </core-button>
      </template>
    </core-dialog>
  </core-view>
</template>

<script>
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";

export default {
  mixins: [List],
  name: "Agreements",
  data: () => ({
    name: "agreement",

    endpoint: "agreements",
    headers: [
      {
        label: "carrier",
        key: "group_label",
        type: "carrier",
        orderable: false
      },
      {
        label: "updated_at",
        key: "updated_at",
        type: "datetime"
      }
    ],
    groups: {
      gls: {
        gls_agreement: {
          type: Boolean,
          label: "enabled",
          key: "gls_agreement",
          default: null,
          disabled: false
        },
        gls_customer_id: {
          type: String,
          label: "customer_id",
          key: "gls_customer_id",
          default: null,
          disabled: false
        },
        gls_connection_type: {
          type: Array,
          label: "type",
          key: "gls_connection_type",
          default: "api",
          disabled: true,
          override: true,
          hidden: true,
          values: [
            {
              label: "API",
              value: "api"
            }
          ]
        },
        gls_api_username: {
          type: String,
          label: "username",
          key: "gls_api_username",
          default: null,
          disabled: false
        },
        gls_api_password: {
          type: String,
          label: "password",
          key: "gls_api_password",
          default: null,
          disabled: false
        }
      }
    },
    paginate: false,
    showModal: false,
    lock_scroll: false,
    selectedGroup: [
      {
        created_at: null,
        key: null,
        value: null
      }
    ],
    saving: false
  }),
  computed: {
    groupsTotal: function() {
      let count = 0;
      let self = this;
      this.items.forEach(item => {
        if (self.groups[item.group] || false) count += 1;
      });

      return count;
    },
    selectedGroupModel: function() {
      return this.groups[this.editedItem.group] || null;
    },
    availableGroups: function() {
      let data = [];
      let self = this;
      this.items.forEach(item => {
        if (self.groups[item.group]) data.push(item);
      });

      return data;
    }
  },
  mounted: function() {
    this.getDataFromApi();
  },
  methods: {
    getValue(option) {
      let group = this.selectedGroupModel[option.key];
      if (group.override) return group.default;
      else {
        return option.value || group.default;
      }
    },
    save() {
      let content = {};
      this.saving = true;

      let group = this.editedItem.group;
      this.editedItem.options.forEach(function(item, key) {
        content[item.key] = item.value;
      });

      let url = `${this.endpoint}/${group}`;

      this.$put(url, content, { useDev: this.useDev })
        .then(() => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$t(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
          this.getDataFromApi();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    convertUTCToLocal: function(val) {
      return convertUTCToLocal(val, "date");
    },
    setItemForEditing: function() {
      let self = this;

      this.editedItem = this.$_.cloneDeep(this.items[this.selectedGroup]);
      this.editedItem.options.forEach(function(val, index) {
        self.editedItem.options[index].value = self.selectedGroupModel[val.key]
          ? self.getValue(val)
          : val.value;
      });
    },
    openModal: function(item, index) {
      this.selectedGroup = index;
      this.setItemForEditing();
      this.showModal = true;
    },
    getType: function(type) {
      switch (type) {
        case Boolean:
          return "core-switch";
        case Array:
          return "core-select";
        default:
          return "core-input";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-dialog {
  .el-dialog__body {
    max-height: unset;
  }
}

.core-select {
  &[hidden="hidden"] {
    display: none;
  }
}
</style>
