<template>
  <el-menu
    :id="elementName"
    ref="menu"
    class="core-menu text-left overflow-hidden border-r-0"
    :class="elementClass"
    unique-opened
    :default-openeds="initialOpenMenu"
    @open="submenuClick"
  >
    <template v-for="(menuEntry, index) in menuEntries">
      <core-menu-sub
        v-if="menuEntry.type === 'group'"
        :key="index + '.' + menuEntry[keyProp]"
        :entry="menuEntry"
        :key-prop="keyProp"
        :label-prop="labelProp"
        :label-localized="labelLocalized"
        v-on="$listeners"
      />

      <core-menu-entry
        v-else
        :key="index + '.' + menuEntry[keyProp]"
        :entry="menuEntry"
        :label="getLabel(menuEntry)"
        :key-prop="keyProp"
        v-on="$listeners"
        @click.native="() => closeSubMenu()"
      />
      <el-divider :key="index + 'divider' " />
    </template>
  </el-menu>
</template>

<script>
import { defaultMenu } from "@/router";
import pageAccess from "@/mixins/PageAccess";
import _ from "lodash";
export default {
  mixins: [pageAccess],
  props: {
    keyProp: {
      type: String,
      default: "index"
    },
    labelProp: {
      type: String,
      default: "label"
    },
    labelLocalized: {
      type: Boolean,
      default: true
    },
    elementName: {
      type: String,
      default: ""
    },
    elementClass: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    initialOpenMenu: null
  }),
  computed: {
    menuEntries: function() {
      const services = this.$store?.state?.account?.enabled_services || [];
      let menuEntries = {...defaultMenu}
      let entries = this.$_.filter(menuEntries, e => {
        if (!e.serviceKey) return true;
        if (e.serviceKey == 'has_fixed_pickup' && services[e.serviceKey]) {
          return true
        }

        return services[e.serviceKey];
      });

      //filter menu business only points
      if (
        this.$store?.state?.account && !this.$store?.state?.account?.business
      ) {
      entries = this.$_.filter(entries, me => !me.businessOnly);
      entries = this.filterEntries(entries)
      }

      //filter menu pr capabilities of user
      if (this.$store?.state?.capabilities) {
        entries = this.$_.filter(entries, ce => {
          if (!ce.capabilityKey) return true;
          return this.checkSingleAccess(ce.capabilityKey, '');
        });
      }

      return entries;
    }
  },
  watch:{
      $route (to, from){
        if (to.name == 'labels_details' && from.name == 'wmsorders_details') {
          this.$refs.menu.open('labels', 0);
        }
      }
    },
  mounted: function() {
    const routeIndex = this.$_.get(this.$route, "matched[1].path")
      ?.replace(/\//, "")
      .replace(/\/[a-z]+$/, "");

    this.initialOpenMenu = [routeIndex];
  },
  methods: {
    filterEntries: function(entries) {
      return entries.filter(entry => {
        if (entry.businessOnly) {
          return false;
        }
        if (entry.entries) {
          entry.entries = this.filterEntries(entry.entries);
        }
        return true;
      });
    },
    getLabel: function(entry) {
      return this.labelLocalized
        ? this.$tc(entry[this.labelProp], 99)
        : entry[this.labelProp];
    },
    isOpen: function(entry) {
      if (!this.$refs.menu) return false;
      const opened = this.$refs.menu.openedMenus;
      return opened.includes(entry);
    },
    closeSubMenu: function() {
      if (!this.$refs.menu) return false;
      this.$refs.menu.openedMenus = [];
    },
    submenuClick: function(sm) {
      const submenu = this.$_.find(this.menuEntries, ["index", sm]);
      if (submenu && submenu.href)
        setTimeout(() => {
          this.$emit("entry-click");
          this.$router.push({ name: submenu.href });
        }, 250);
    }
  }
};
</script>
