<template>
  <el-popover
    placement="bottom-end"
    :trigger="trigger"
    :value="open"
    v-click-outside="
      () => {
        open ? $emit('update:open', false) : () => null;
      }
    "
    :popper-options="{
      boundariesElement: 'body',
      gpuAcceleration: true,
      positionFixed: positionFixed,
      preventOverflow: true
    }"
    :append-to-body="false"
    popper-class="action-popup"
  >
    <template slot="reference">
      <div
        class="action-popup__enabler"
        :class="[{ enabled: open }, !$slots.activator ? class_override : '']"
        @click.stop="
          () => {
            if (disabled) {
              return;
            }
            $emit('update:open', !open);
          }
        "
      >
        <slot name="activator">
          <i class="el-icon-more " />
        </slot>
      </div>
    </template>
    <el-col class="action-popup__menu">
      <el-row
        class="action-popup__menu-item"
        :class="{ disabled: action.disabled }"
        v-bind:key="action.key || action.label"
        v-for="action in filteredActions"
      >
        <div
          class="action-popup__menu-item__wrapper"
          v-if="action.action"
          @click="
            () => {
              if (action.disabled) return;
              $emit('update:open', false);
              action.action(entity);
            }
          "
        >
          <div
            style="display: flex;align-items: center;word-break: break-word;"
          >
            <font-awesome-icon
              :icon="action.icon"
              v-if="action.icon"
              fixed-width
            />
            <div v-else-if="action.icon_html" v-html="action.icon_html" />
            <span>
              {{ action.label }}
            </span>
          </div>
        </div>
        <a
          v-else-if="action.link"
          class="action-popup__menu-item__wrapper"
          :href="action.disabled ? '' : action.link(entity)"
          @click="
            () => {
              if (action.disabled) return;
              $emit('update:open', false);
            }
          "
          target="_blank"
        >
          <div
            style="display: flex;align-items: center;word-break: break-word;"
          >
            <font-awesome-icon
              :icon="action.icon"
              v-if="action.icon"
              fixed-width
            />
            <div v-else-if="action.icon_html" v-html="action.icon_html" />
            <span>
              {{ action.label }}
            </span>
          </div>
        </a>
      </el-row>
    </el-col>
  </el-popover>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    entity: {
      type: Object,
      default() {
        return {};
      }
    },
    index: {
      type: Number
    },
    trigger: {
      type: String,
      default: "manual"
    },
    class_override: {
      type: String,
      default: "default"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    positionFixed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    filteredActions: function() {
      return this.$_.filter(
        this.actions,
        ac => !ac.hidden || !ac.hidden(this.entity)
      );
    }
  }
};
</script>
