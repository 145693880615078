<template>
  <div
    id="insights_dashboard"
    style="margin-top: 10px; height: 100%; position: relative;"
  >
    <div class="grid grid-cols-3 gap-5 max-w-full">
      <div
        v-for="(widget, index) in dashboard"
        :key="'_static_' + index"
        :class="[
          {
            'col-span-1': getColumnCount(widget) === 1,
            'col-span-2': getColumnCount(widget) === 2,
            'col-span-3': getColumnCount(widget) === 3
          }
        ]"
        :xs="getColumnCount(widget)"
      >
        <transition appear name="widget-animation" mode="out-in">
          <component
            :is="getChartComponent(widget)"
            v-if="checkCharts"
            title-position="lower"
            :title="widget.title"
            :subtitle="widget.title"
            :data="widget"
            class="default"
            :filtering-enabled="false"
            :style="{ '--i': index, '--length': widgets.length }"
            :location="`insights/charts/${widget.id}?type=${widget.type}`"
            :has-description="widget.description"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import Loader from "@/mixins/Loader";
import Axios from "axios";

export default {
  name: 'Dashboard',
  mixins: [Loader],
  props: {
    page: {
      default: 'page_generelt',
      type: String
    },
  },
  data: () => ({
    widgets: [],
    checkCharts: true,
    description: "",
    loaderOptions: {
      lock: true,
      fullscreen: false,
      text: "loading",
      target: "#insights_dashboard",
      customClass: null,
      cancelToken: null,

    }
  }),
  computed: {
    dashboard: function () {
      const dashboardColumnCount = 3;
      let dashboard = []
      let row = []
      this.$_.each(this.widgets, (widget, i) => {
        const isLastWidget = i === this.widgets.length-1;
        let w = {...widget}
        let usedCols = this.$_.sumBy(row, c => c.col_span)

        if (usedCols && ((usedCols + w.col_span) > dashboardColumnCount)) {
          row[row.length - 1].col_span += dashboardColumnCount - usedCols

          dashboard.push(row)
          row = []
        }

        if(isLastWidget) {
            if (usedCols + w.col_span !=3){
              w.col_span = dashboardColumnCount - usedCols
          }
        }

        row.push(w)
        usedCols = this.$_.sumBy(row, c => c.col_span)
        if(usedCols === dashboardColumnCount || isLastWidget) {

          dashboard.push(row)
          row = [] ;
        }
      })

      return this.$_.flatten(dashboard)
    }
  },
  watch: {
    page: function () {
      this.$nextTick(() => {
        this.getCharts()

      })
    }
  },
  mounted: function () {
    this.getCharts();
  },
  methods: {
    getChartComponent: function (widget) {
      const type = widget.type
      if (type == "table") return `widget-table`
      if (type == "kpi") return `widget-kpi`
      if (type == "map") return `widget-ChoroplethMap`
      if (type && type !== "simple") return `widget-${type}-chart`;

      return "widget-bar-chart";
    },
    getColumnCount: function (widget) {
      const breakpoint = this.$store.state.breakpoint.breakpoint
      const colspan = widget.col_span;
      if (colspan == 1) {
        switch (breakpoint) {
          case 'small':
            return 3;
          case 'medium':
            return 3;
          case 'large':
            return 1;
          case 'xlarge':
            return 1;
          default:
            'xsmall'
            return 3;
        }
      }
      if (colspan == 3) {
        return 3;
      }
      if (colspan == 2) {
        switch (breakpoint) {
          case 'small':
            return 3;
          case 'medium':
            return 3;
          case 'large':
            return 2;
          case 'xlarge':
            return 2;

        }
      }
    },
    getCharts: async function() {
      this.checkCharts = false
      await this.sleep(200);
      this.widgets = []
      const dashboard = []
      const userId = this.$store.state.account.id
      this.loading = true

      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = Axios.CancelToken.source();


      this.$get(`/insights/charts?userId=${userId}&page=${this.page}`, {
        cancelToken: this.cancelToken.token,
      }).then(r => {
        this.description = r[0]
        this.setDescription(this.description)
        this.$_.each(r.charts, (c, i) => {
          let aChart = {...c};
          let colSpan = this.$_.find(aChart.tags, t => t.includes('colspan_'))
          colSpan = colSpan ? colSpan.split('_')[1] : '1';
          aChart.col_span = parseInt(colSpan)
          dashboard.push(aChart);
        })
        this.widgets = dashboard
        this.checkCharts = true
        this.loading = false
        this.show = true

      })
      .catch(e => {
        if (e !== "cancelled") this.loading = false;
      });

    },
    sleep: function sleep(ms) {
     return new Promise(resolve => setTimeout(resolve, ms));
    },
    setDescription: function (val) {
      this.$emit('update-description', val)
    }
  },


}
</script>

