<template>
  <core-list-content :loading="loading" style="padding: 0">
    <div class="w-full">
      <div class="bg-white rounded-md p-6">
        <p
            class="
              group
              text-left
              self-center
              text-lg
              font-semibold
              cursor-pointer
              mb-5
            "
            @click="navigate"
          >
            <font-awesome-icon
              :icon="['far', 'long-arrow-left']"
              class="mr-2 group-hover:animate-bounce-left"
              id="arrow"
            />
          </p>
      <div class="flex flex-wrap w-full">
        <div
          class="
            h-24
            w-24
            rounded
            bg-gray-50
            relative
            p-2
            mr-5
            content-center
          "
        >
          <el-image
            :src="`${URL_PREFIX}/images/carriers/${entity.type}.png?w=500`"
            class="carrier-image m-auto h-20 w-20"
            fit="contain"
            ondragstart="return false"
          >
            <div slot="error" class="image-slot">
              <div class="h-full w-full text-center pt-6">
                <font-awesome-icon
                  class="
                    text-coolrunner-500
                    transition
                    duration-300
                    text-4xl
                  "
                  :icon="['far', `${entity.icon}`]"
                />
              </div>
            </div>
          </el-image>
        </div>
        <div class="pl-2 h-32 relative">
          <h3 class="text-lg w-full">
            {{ entity.title }}
          </h3>
          <p class="text-sm line-clamp-3 w-full mb-2 text-gray-500">
            Bussiness insights giver dig et grafisk overblik over din virksomhed
          </p>
          <div class="flex absolute left-2 bottom-6">
            <div
              @click="installApp()"
              class="
                text-center
                border border-coolrunner-500
                bg-coolrunner-500
                text-white
                px-2
                py-1
                text-sm
                rounded
                transition
                duration-300
                hover:text-coolrunner-500 hover:bg-white
                cursor-pointer
                mr-4
              "
            >
              {{ $t("add") }}
            </div>
            <p class="pt-1">
              {{ entity.billing.price }} {{ entity.billing.currency }}
              {{ entity.billing.billing_method }}
            </p>
          </div>
        </div>
        <div class="bg-gray-50 py-3 px-4 rounded-md text-sm ml-auto">
          <p class="mb-2 text-sm">
            • Bruges af <span class="font-bold">88</span> brugere
          </p>
          <p class="mb-2 text-sm">
            • Sidst opdateret den <span class="font-bold">10.09.2021</span>
          </p> 
        </div>
      </div>
      </div>
      <div class="h-[2px] bg-gray-200 w-full my-5"></div>
      <div class="bg-white rounded-md p-6 grid grid-cols-1 md:grid-cols-2">
        <div class="col-span-1">
          <h3 class="text-lg w-full">
            {{ $t("description") }}
          </h3>
          <div class="pt-3 max-w-[90%] text-sm text-gray-500 text-left">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tempus
            rutrum sem. Cras id sapien nisl. Duis venenatis laoreet mattis.
            Suspendisse pulvinar finibus posuere. Fusce sed accumsan tellus,
            vitae tempor sem. Praesent gravida, odio at hendrerit imperdiet,
            purus sapien interdum mi, ac semper justo enim ac risus. Sed
            sagittis laoreet risus.
            <br />
            <br />
            Quisque eu mi tempus, porttitor urna sit amet, venenatis elit.
            Praesent vitae finibus ipsum. Donec et lacinia nibh. Proin lacus
            mauris, varius vulputate porta at, iaculis vel sapien. Integer
            posuere semper enim, quis mattis augue commodo sit amet. Vivamus
            ultrices turpis augue, eget condimentum odio iaculis egestas. Aenean
            sit amet odio quis velit pulvinar pharetra vitae at nibh. Etiam a
            fringilla velit. Etiam consequat venenatis tristique. Cras placerat
            hendrerit nibh ac pellentesque. Vestibulum varius enim et accumsan
            accumsan. Etiam eget suscipit tellus. Fusce suscipit diam id velit
            maximus tincidunt.
            <br />
            <br />
            Nam sagittis ante eget turpis dapibus rutrum. Etiam hendrerit ac
            lacus vitae viverra. Aenean aliquet nibh turpis, non semper orci
            dignissim vitae. Suspendisse eu massa nunc. Sed commodo leo in
            mauris venenatis, mattis maximus tortor tincidunt. Nulla et dapibus
            sapien. Vivamus accumsan odio ut ante imperdiet aliquam. Ut
            vestibulum enim velit, sed volutpat lorem ultricies a. Quisque
            dictum suscipit neque in viverra. Pellentesque non libero sapien.
            Nulla viverra eros cursus fermentum egestas. Vivamus id commodo
            quam.
          </div>
        </div>
        <div class="col-span-1 rounded-md mt-10 md:mt-0">
          <youtube
            class="rounded-md"
            :nocookie="true"
            :resize="true"
            :fitParent="true"
            video-id="8LD5PI28M2U"
            ref="youtube"
          ></youtube>
        </div>
        <div class="w-full mt-10 mb-10 h-[2px] bg-gray-200 col-span-1 md:col-span-2"></div>
        <h3 class="text-lg w-full col-span-1 md:col-span-2">
            Andre apps til integration
          </h3>
      <div class="grid grid-cols-1 md:grid-cols-3 pt-4 gap-6 pb-6 bg-white col-span-1 md:col-span-2"
     v-if="show">
        <div
        v-for="service, index in services"
        v-bind:key="`route-${index}`"
      >
        <transition appear name="return-animation" mode="out-in">
        <core-appcard
        @serviceEnabled="enableService"
        type="product"
        :data="service"
        :style="{ '--i': index, '--length': service.length }"
        :headline="service.title"
        :description="service.description"
        :icon="service.icon"
        :enabled="service.installed"
        :set_entity="service" 
        :id="service.type"
        />
        </transition>

      </div>

    </div>
      </div>
    </div>
  </core-list-content>
</template>

<script>
import { URL_PREFIX } from "@/utils/constants";
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from) vm.from = from;
      // access to component instance via `vm`
    });
  },
  name: "appDetails",
  data: () => ({
    show: false,
    loading: false,
    type: "carrier",
    entity: null,
    services: [
          {
              title: 'Insights',
              type: 'insights',
              description: 'Bussiness insights giver dig et grafisk overblik over din virksomhed',
              billing: {
                price: 20,
                currency: 'DKK',
                billing_method: 'pr. måned'
              },
              icon: 'analytics',
              installed: true
          },
          {
              title: 'Mail',
              type: 'mail',
              description: 'Email service kan køre automatisk gennem CoolRunners API',
              billing: {
                price: 20,
                currency: 'DKK',
                billing_method: 'pr. måned'
              },
              icon: 'envelope',
              installed: true
          },
          {
              title: 'WMS',
              type: 'wms',
              description: 'Gennem CoolRRunners WMS system kan du få et nemt og overskueligt overblik over dit lager og status af pakker.',
              billing: {
                price: 20,
                currency: 'DKK',
                billing_method: 'pr. måned'
              },
              icon: 'boxes',
              installed: false
          }

      ],
    }),
  created() {
    this.show = true;
    this.fetchData();
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$get(
        `app-store/${this.$route.params.id}`,
        {},
        {
          failSilently: true,
        }
      )
        .then((r) => {
          this.entity = r;
          this.delay = 1.4;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.entity = {
            title: "Insights",
            type: "insights",
            description:
              "Bussiness insights giver dig et grafisk overblik over din virksomhed",
            billing: {
              price: 20,
              currency: "DKK",
              billing_method: "pr. måned",
            },
            icon: "analytics",
            installed: true,
          };
        });
    },
    navigate: function () {
      this.show = false;
      document.getElementById("arrow").animate(
        [
          { transform: "translateX(0)", opacity: 1 },
          {
            opacity: 1,
          },
          { transform: "translateX(-100%)", opacity: 0 },
        ],
        {
          duration: 250,
          easing: "ease-in-out",
          fill: "forwards",
        }
      );
      if (this.from.name) this.$router.go(-1);
      else this.$router.push({ name: "apps" });
    },
  },
};
</script>