<template>
  <div class="wrapper">
    <div class="title">{{ title }}</div>
    <div v-if="description" class="description">{{ description }}</div>
    <div v-if="range_error" class="text-sm">{{$t('range_error')}}</div>

    <div class="number">
      <template v-if="values.length">
        <el-row
          v-for="(val, index) in values"
          :key="index"
          :gutter="20"
          type="flex"
          align="middle"
        >
          <el-col :span="11">
            <core-select
              v-model="values[index].inverse"
              class="w-full items-stretch max-w-full col-span-2"
              :options="selectValues"
              label-key="label"
              value-key="value"
              style="width: 100%"
              :default-to-first="true"
              @input="val => handleExclude(val, index)"
            />
          </el-col>
          <el-col :span="11">
            <core-input
              :key="'input_' + index + '_from'"
              :label="$t('from')"
              type="number"
              :value="val.value.gte"
              :autofocus="index === values.length - 1"
              @input="v => handleInput(v, true, index)"
            />
          </el-col>
          <el-col :span="11">
            <core-input
              :key="'input_' + index + '_to'"
              :label="$t('to')"
              type="number"
              :value="val.value.lte"
              :autofocus="index === values.length - 1"
              @input="v => handleInput(v, false, index)"
            />
          </el-col>
          <el-col :span="2">
            <core-filter-modal-filter-clear
              @click="$emit('remove-filter', index)"
            />
          </el-col>
        </el-row>
        <core-button @click="$emit('update-filter', {})">
          {{ $t("add_new") }}
        </core-button>
      </template>

      <el-row v-else :key="0" :gutter="20" type="flex" align="middle">
        <el-col :span="11">
          <core-select
            v-model="inverse"
            class="w-full items-stretch max-w-full col-span-2"
            :options="selectValues"
            label-key="label"
            value-key="value"
            style="width: 100%"
            :default-to-first="true"
            @input="val => handleExclude(val, -1)"
          />
        </el-col>
        <el-col :span="11">
          <core-input
            :key="'input_0_from'"
            :label="$t('from')"
            type="number"
            :autofocus="true"
            @input="val => handleNewInput(val, true)"
          />
        </el-col>
        <el-col :span="11">
          <core-input
            :key="'input_0_to'"
            :label="$t('to')"
            type="number"
            :autofocus="true"
            @input="val => handleNewInput(val, false)"
          />
        </el-col>
        <el-col :span="2">
          <core-filter-modal-filter-clear disabled />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import mixin from "./typeMixin";
export default {
  mixins: [mixin],
  data: () => ({
    range_error: false,
    inverse: false
  }),
  computed: {
    selectValues: function () {
      return [
      {
        label: this.$t('include'),
        value: false
      },
      {
        label: this.$t('exclude'),
        value: true
      }
    ]
    }
  },
  created() {
    this.handleNewInput = _.debounce(this.handleNewInput, 500);
  },
  methods: {
    handleExclude: function (val, index) {
      if (index == -1) {
        this.inverse = val
      } else {
        let value = {gte: this.values[index].value.gte, lte: this.values[index].value.lte};

        if (value.gte === undefined) delete value.gte;
        if (value.lte === undefined) delete value.lte;
        
        let data = {
        value: value,
        inverse: val
      }
      this.$emit(
          "update-filter",
          data,
          index
        );
      }
    },
    handleNewInput: function(val, from) {
      if (!val && val !== 0) return;
      const parsedVal = val || val === 0 ? parseFloat(val) : undefined;
      const newValue = {};
      if (from) newValue.gte = parsedVal;
      else newValue.lte = parsedVal;

      if (newValue.gte > newValue.lte ) {
        this.range_error = true
        return
      }

      if (newValue.gte !== undefined || newValue.lte !== undefined) {
        this.range_error = false
        this.$emit("update-filter", {value: newValue, inverse: this.inverse});
      }
    },
    handleInput: function(val, from, index) {
      const orgVal = this.optionsMode
        ? this.values[index]
        : this.values[index].value;
      const newValue = { ...orgVal };
      const parsedVal = val || val === 0 ? parseFloat(val) : undefined;
      if (from) newValue.gte = parsedVal;
      else newValue.lte = parsedVal;
      if (newValue.gte > newValue.lte) {
        this.range_error = true
        return
      }

      if (newValue.gte !== undefined || newValue.lte !== undefined) {
        this.range_error = false
        this.$emit("update-filter", {value: newValue, inverse: this.values[index].inverse}, index);
      }
      else this.$emit("remove-filter", index);
    }
  }
};
</script>
