<template>
  <core-view :style="{ '--delay': delay }">
    <div class="relative">
      <core-view-header :name="'Afhentninger'" :count="pallets.length" :description="'page_descriptions.pallets'" :route-name="$route.name" route="labels" show-view-name> </core-view-header>
    </div>
    <div v-if="pallets.length !== 0" class="col-span-8 mt-5">
      <div v-for="(pallet, index) in pallets" :key="'pallet' + index" class="mb-5">
        <div class="grid h-20 w-full grid-cols-10 rounded-md bg-white">
          <div class="col-span-2 px-3 py-4 text-left">
            <p class="font-semibold">{{ pallet.pickup_booked_for }}</p>
            <p class="mt-3 text-xs">{{ $t("pickup_date") }}</p>
          </div>
          <div class="col-span-2 px-3 py-4 text-left">
            <p class="font-semibold">{{ pallet.shipments }}</p>
            <p class="mt-3 text-xs">{{ $t("number_of_packages") }}</p>
          </div>
          <div class="col-span-2 px-3 py-4 text-left">
            <p class="font-semibold">{{ pallet.consignment_number }}</p>
            <p class="mt-3 text-xs">{{ $t("consignment_number") }}</p>
          </div>
          <div class="col-span-2 px-3 py-4 text-left">
            <p class="font-semibold capitalize">{{ pallet.type.title }}</p>
            <p class="mt-3 text-xs">{{ $t("type") }}</p>
          </div>
          <div class="relative col-span-2 py-3 pl-3 pr-16 text-left">
            <p class="font-semibold">{{ $t(pallet.state) }}</p>
            <p class="mt-3 text-xs">{{ $t("status") }}</p>
            <div class="absolute top-4 right-2 cursor-pointer px-3 py-4 text-left" @click="selectPallet(pallet.id)">
              <font-awesome-icon v-if="pallet.shipments" :icon="['far', 'chevron-down']" class="transform transition-all duration-300" :class="{ 'rotate-180': palletCode == pallet.id }" />
            </div>
          </div>
        </div>
        <div class="max-h-0 overflow-hidden transition-all duration-500" :class="{ 'max-h-[100000px]': palletCode == pallet.id }">
          <core-list-new-table
            :items="selectedPallet.shipments"
            :headers="headers"
            :loading="loading"
            :order-by.sync="options.orderBy"
            :order-dir.sync="options.orderDir"
            :current-page.sync="options.page"
            :show-header-actions="false"
            :total-items="100"
            :entity-name="name"
            :page-size="options.itemsPerPage"
            :paginate="false"
            :selection="false"
            push-path="labels_details"
          >
            <template v-slot:[`column.receiver.name`]="{ item }">
              <core-list-cell-templates-receiver-with-flag
                :name="item.receiver.name"
                :country-code="item.receiver.country"
              />
            </template>
            <template v-slot:[`column.package_number`]="{ item }">
              <div
                style="cursor: copy"
                @click.stop="$clipboard(item.package_number)"
              >
                {{ item.package_number }}
              </div>
            </template>

            <template v-slot:[`column.labelless_code`]="{ item }">
              <div
                style="cursor: copy"
                @click.stop="$clipboard(item.labelless_code)"
              >
                {{ item.labelless_code }}
              </div>
            </template>

            <template v-slot:[`column.measurement`]="{ item }">
              <div>
                {{ item.height + "cm x " + item.width + "cm x " + item.length + "cm" }}
              </div>
            </template>

            <template v-slot:[`column.carrier`]="{ item }">
              <core-list-cell-templates-carrier-with-image
                :carrier="item.carrier"
                :url="URL_PREFIX"
              />
            </template>
          </core-list-new-table>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-md shadow-sm">
      <el-pagination
        v-if="totalPallets"
        class="core-list__pagination rounded-b-md"
        :current-page="currentPage"
        :page-size="15"
        :total="totalPallets"
        :pageSizes="[15]"
        :disabled="loading"
        @current-change="c => loadNewPage(c)"
      />
    </div>
  </core-view>
</template>

<script>
import axios from "axios";
import List from "@/mixins/List";
import Loader from "@/mixins/Loader";
import { URL_PREFIX, API_PREFIX } from "@/utils/constants";

export default {
  name: "Pallets",
  mixins: [Loader, List],
  data: () => ({
    pallets: [],
    palletCode: null,
    selectedPallet: {},
    headers: [
      {
        label: "date",
        key: "created_at",
        type: "date",
        minWidth: "11",
      },
      {
        label: "receiver",
        key: "receiver.name",
        orderableKey: "receiver_name",
        minWidth: "11",
      },
      {
        label: "carrier",
        key: "carrier",
        type: "carrier_image",
        minWidth: "11",
      },
      {
        label: "labelless_code",
        key: "labelless_code",
        minWidth: "10",
      },
      {
        label: "package_number",
        key: "package_number",
        minWidth: "12",
      },
      {
        label: "reference",
        key: "reference",
        minWidth: "11",
      },
      {
        label: "measurement",
        key: "measurement",
        minWidth: "11",
      },
    ],
    totalPallets: 0,
    currentPage: 1
  }),
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    API_PREFIX: () => API_PREFIX,
    delay: function () {
      return this.pallets.length * 0.03;
    },
  },
  mounted: function () {
      if (this.$route?.query?.state == 'intro') {
        this.pallets = [{
          arrived_at: null,
          carrier: {slug: "dfm", title: "Danske Fragtmænd"},
          closed_at: null,
          comment: "Du skal gå ind af port 3 og taste koden 1252",
          consignment_number: "EC123456",
          id: 1020,
          opened_at: "2023-05-16T13:23:45.000000Z",
          pdf: {available: true, prints_using_print_service: false},
          picked_up_at: null,
          pickup_booked_for: this.$moment().format("YYYY-MM-DD"),
          shipments: 1,
          state: "closed",
          type: {slug: "HALF_PALLET", title: "halvpalle"},
        }]
      } else {
        this.getPallets();
      }
  },
  methods: {
    loadNewPage: function (page) {
      this.currentPage = page;
      this.getPallets()
    },
    selectPallet: function (val) {
      if (this.$route?.query?.state == 'intro') {
        this.palletCode = 1020;
        this.selectedPallet = {
          shipments: [{
            carrier: "bring",
            created_at: "2023-05-17T06:22:49.000000Z",
            height: 10,
            id: 47075665,
            length: 10,
            package_number: "00370724762400320672",
            receiver: {
              attention: null,
              city: "Testby",
              country: "DK",
              email: "test@homerunner.com",
              name: "Peter Test",
              notify_email: null,
              notify_sms: null,
              phone: "12345678",
              state: null,
              street1: "Testgade",
              street2: null,
              zip_code: "9000",
            },
            reference: "TestMig",
            width: 10
          }]
        }
        this.loading = false;
      } else {
        if (this.palletCode == val) {
        this.palletCode = null;
        } else {
          this.palletCode = val;
          this.loading = true;
          this.$get(`/transfer/containers/${val}`)
            .then((response) => {
              this.selectedPallet = response;
            })
            .catch((e) => {})
            .finally(() => {
              this.loading = false;
            });
        } 
      }
    },
    getPallets: function () {
      this.loading = true;
      this.$get(`/transfer/containers?page=${this.currentPage}`)
        .then((response) => {
          this.pallets = response.data;
          this.totalPallets = response.total
          this.loading = false;
        })
        .catch((e) => {});
    },
    goToShipment: function (shipment) {
      this.$router.push({
            name: 'labels_details',
            params: { id: shipment.id }
          });
    }
  },
};
</script>
