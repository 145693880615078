<template>
  <core-dialog
    id="refill-dialog"
    :visible="visible"
    @update:visible="close"
    :title="$t('refill_balance')"
    :md_width="90"
    :lg_width="90"
    :xl_width="90"
    @close="close"
  >
    <div
      class="wrapper form"
      style="display: flex; flex-direction: column; width: 100%; min-height: 180px; text-align: left"
    >
      <div
        class="description"
        v-html="
          $t('fill_balance_dialog', {
            current: $n(balance, 'currency', 'da-DK'),
            required: $n(requiredBalance, 'currency', 'da-DK'),
            minimum: $n(50, 'currency', 'da-DK')
          })
        "
      />
      <div slot="footer" class="dialog-footer flex w-100">
        <core-button
          class="mr-auto"
          outline
          type="default_outline"
          @click="close"
          >{{ $t("cancel") }}</core-button
        >
        <core-number
          size="small"
          class="max-w-100 mx-2 ml-auto"
          :label="$t('amount')"
          style="margin: 0 10px"
          :min="50"
          v-model="refillAmount"
        />
        <core-button
          type="coolrunner"
          :loading="loading"
          @click="refillBalance"
          >{{ $t("refill") }}</core-button
        >
      </div>
    </div>
  </core-dialog>
</template>

<script>
import Loader from "@/mixins/Loader";
import Payment from "@/mixins/Payment";

export default {
  mixins: [Loader, Payment],
  name: "FillBalanceDialog",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    balance: {
      type: Number,
      default: 0
    },
    requiredBalance: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    refillAmount: 0,
    loaderOptions: {
      lock: true,
      fullscreen: false,
      text: "please_wait",
      target: "#refill-dialog .el-dialog",
      customClass: null
    }
  }),
  mounted: function() {
    this.refillAmount = Math.max(
      Math.ceil(this.requiredBalance - this.balance || 0),
      50
    );
  },
  computed: {
    minimumRefill: function() {
      return Math.ceil(this.requiredBalance - this.balance || 0);
    }
  },
  watch: {
    minimumRefill: function(val) {
      this.refillAmount = Math.max(val - this.balance, 50);
    }
  },
  methods: {
    close: function() {
      this.$emit("update:visible", false);
    },
    refillBalance: function() {
      this.loading = true;
      this.$put(`account/payment/balance`, {
        amount: this.refillAmount,
        final_url: this.landing_page.order_success
      })
        .then(response => {
          let payment_window = this.popup(response.payment_url);
          this.$on(`payment-${payment_window}:success`, () => {
            this.$emit("success");
          });
        })
        .catch(e => {
          this.$notification({
            title: this.$t("error"),
            text: `${this.$t(e)}`,
            type: "warning"
          });
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
#refill-dialog {
  .el-dialog {
    overflow: hidden;
  }
}
</style>
