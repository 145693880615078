var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"return_shipment_order_container"}},[_c('div',{staticClass:"wrapper"},[_c('row',[_c('column',{staticStyle:{"margin":"auto"},attrs:{"xs":12,"xl":3}},[_c('core-animated-checkmark',{staticStyle:{"margin-bottom":"2em"}}),_c('core-button',{staticClass:"mx-auto",attrs:{"type":"coolrunner"},on:{"click":_vm.downloadLabel}},[_vm._v(_vm._s(_vm.$t("download_label")))])],1),_c('column',{attrs:{"xs":12,"xl":9}},[_c('row',{attrs:{"gutter":12}},[_c('column',{attrs:{"xs":12,"xl":7}},[_c('el-card',{staticStyle:{"height":"100%"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("order_summary")))]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("order_date"))+":")]),_vm._v(" "+_vm._s(_vm.convertUTCToLocal(undefined, "datetime"))+" ")])])],1),_c('column',{attrs:{"xs":12,"xl":5}},[_c('el-card',{staticStyle:{"height":"100%"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("entity_address", { entity: _vm.$t("sender") })))]),_c('p',[_vm._v(_vm._s(_vm.order.sender.name))]),_c('p',[_vm._v(_vm._s(_vm.order.sender.street1))]),_c('p',[_vm._v(" "+_vm._s(_vm.order.sender.zip_code)+" "+_vm._s(_vm.order.sender.city)+" ")])])],1),_c('column',{attrs:{"xs":12,"xl":7}},[_c('el-card',{staticStyle:{"height":"100%"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("label_information")))]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("product"))+": ")]),_vm._v(" "+_vm._s(_vm.order.carrier)+" "+_vm._s(_vm.order.carrier_product)+" "+_vm._s(_vm.order.carrier_service)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("package_number"))+": ")]),_c('router-link',{staticStyle:{"font-size":"13px"},attrs:{"to":{
                    name: 'labels',
                    query: {
                      filter: {
                        package_number: _vm.order.package_number
                      }
                    }
                  },"target":"_blank"}},[_vm._v(_vm._s(_vm.order.package_number)+" "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'external-link-alt'],"size":"sm"}})],1)],1),(_vm.order.labelless_code)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("labelless_code"))+": ")]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.order.labelless_code))])]):_vm._e(),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("reference"))+": ")]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.order.reference))])])])],1),_c('column',{attrs:{"xs":12,"xl":5}},[_c('el-card',{staticStyle:{"height":"100%"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("entity_address", { entity: _vm.$t("receiver") })))]),_c('p',[_vm._v(_vm._s(_vm.order.receiver.name))]),_c('p',[_vm._v(_vm._s(_vm.order.receiver.street1))]),_c('p',[_vm._v(" "+_vm._s(_vm.order.receiver.zip_code)+" "+_vm._s(_vm.order.receiver.city)+" ")]),_c('p',[_vm._v(_vm._s(_vm.order.receiver.email))]),_c('p',[_vm._v(_vm._s(_vm.order.receiver.phone))])])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }