var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"10px","height":"100%","position":"relative"},attrs:{"id":"insights_dashboard"}},[_c('div',{staticClass:"grid grid-cols-3 gap-5 max-w-full"},_vm._l((_vm.dashboard),function(widget,index){return _c('div',{key:'_static_' + index,class:[
        {
          'col-span-1': _vm.getColumnCount(widget) === 1,
          'col-span-2': _vm.getColumnCount(widget) === 2,
          'col-span-3': _vm.getColumnCount(widget) === 3
        }
      ],attrs:{"xs":_vm.getColumnCount(widget)}},[_c('transition',{attrs:{"appear":"","name":"widget-animation","mode":"out-in"}},[(_vm.checkCharts)?_c(_vm.getChartComponent(widget),{tag:"component",staticClass:"default",style:({ '--i': index, '--length': _vm.widgets.length }),attrs:{"title-position":"lower","title":widget.title,"subtitle":widget.title,"data":widget,"filtering-enabled":false,"location":`insights/charts/${widget.id}?type=${widget.type}`,"has-description":widget.description}}):_vm._e()],1)],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }