import _ from "lodash";
import EventBus from "../plugins/eventbus";
export default {
  props: {
    query: {
      type: String,
      default: ""
    },
    viewType: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    showSaveFilter: false,
    filters: {},
    view: {},
    showFilter: false,
    viewName: "",
    saving: false,
    availableViewsFilter: "",
    intervals: ["auto", "day", "week", "month", "quarter", "year"]
  }),
  mounted() {
    EventBus.$on("FILTER_ROW.SET_FILTERS", f => {
      this.setFilter(f);
    });
  },
  computed: {
    description: function() {
      const vds = this.$store.state.viewDescriptions;
      if (!vds) return {};
      return vds[this.viewType] || {};
    },
    supportsFilters: function() {
      return !this.$_.isEmpty(this.description);
    },
    availableViews: function() {
      return this.$_.filter(this.$store.state.availableViews, [
        "type",
        this.viewType
      ]);
    },
    filteredAvailableViews: function() {
      const reg = new RegExp(this.availableViewsFilter, "i");

      return this.$_.filter(this.availableViews, av => reg.test(av.name));
    },
    numberOfFilters: function() {
      let fi = this.$_.flatten(this.$_.values(this.cleanedFilters));
      fi = this.$_.map(fi, f =>
        this.$_.isArray(f.value) ? this.$_.flatten(f.value) : f.value
      );
      return this.$_.flatten(fi).length;
    },
    cleanedFilters: function() {
      const filters = {};

      this.$_.each(this.filters, (f, key) => {
        const temp = this.$_.filter(f, filter => {
          if (!filter.value) return false;

          if (this.$_.isArray(filter.value) && !filter.value.length)
            return false;

          if (this.$_.isObject(filter.value)) {
            /* Check if object has empty values */
            return Object.values(filter.value).some(
              x => x !== null && x !== ""
            );
          }
          return true;
        });

        if (temp && temp.length) filters[key] = temp;
      });

      return filters;
    }
  },
  watch: {
    view(val) {
      if (!this.$_.isEmpty(val)) {
        this.viewName = val.is_global ? "" : val.name;

        this.filters = {
          ...val.filters
        };
      } else {
        this.clearView();
      }
    },
    filters(val) {
      this.$emit("set-filters", val);
    },
    cleanedFilters(val) {
      this.$emit("set-cleaned-filters", val);
    },
    "$route.params.view_id": function(val, oldVal) {
      if (val == undefined && oldVal) {
        this.view = {};
      }
    }
  },
  created: function() {
    if (this.$route.params.view_id !== undefined) {
      this.setActiveView(this.$route.params.view_id);
    }

    if (!this.$route.query) return;
    const { filters, ...q } = this.$route.query;

    if (filters) {
      this.setFilter(filters);
      //this.$router.replace({ query: q });
    }
  },

  methods: {
    clearView() {
      this.viewName = "";
      this.filters = {};
      this.$router.replace({
        name: "labels"
      });
    },
    setActiveView(id) {
      if (!id) {
        this.clearView();
        return;
      }
      const view = this.$_.find(this.availableViews, ["id", parseInt(id)]);
      let routeName = this.$route.name;

      if (routeName.indexOf("_view") < 0) {
        routeName += "_view";
      }

      if (view) {
        this.view = view;
        if (id !== this.$route.params.view_id)
          this.$router.push({
            name: routeName,
            params: {
              view_id: id
            }
          });
      } else {
        this.$router.replace({
          name: routeName.replace(/_view$/, "")
        });
      }
    },
    setView(value) {
      this.filters = value;
    },
    confirmFilters() {
      this.showFilter = false;
    },
    setInterval(val) {
      this.$emit("set-interval", val);
    },
    setFilter(filters) {
      _.forEach(filters, function(value) {
        _.forEach(value, function(filterValue) {
          if (filterValue.inverse) {
            filterValue.inverse = filterValue.inverse == 'true' ? true : false
          }
        });
      });

      this.filters = filters;
    },
    updateFilter(key, value, index = -1) {
      const filters = this.$_.cloneDeep(this.filters);

      let array = filters[key] || [];

      if (_.isObject(value)&& 'inverse' in value) {
        if (index === -1) {
          array.push(value);
        } else
          array[index] = value;
      } else {
        if (index === -1) {
          array.push({
            value
          });
        } else
          array[index] = {
            value
          };
      }

      filters[key] = array;
      this.filters = filters;
    },
    removeFilter(key, index) {
      const filters = this.$_.cloneDeep(this.filters);

      let array = filters[key] || [];

      if (this.$_.isEmpty(array)) return;
      array.splice(index, 1);

      if (this.$_.isEmpty(array)) delete filters[key];
      else filters[key] = array;

      this.filters = filters;
    },
    saveFilter: function(update) {
      let apiCall = null;
      this.saving = true;
      if (update) {
        let path = `/views/${this.view.id}`
        if (this.viewType == 'ticket') {
          path = `/ticket/views/${this.view.id}`
        }
        apiCall = () =>
          this.$put(path, {
            type: this.viewType,
            name: this.viewName,
            filters: this.cleanedFilters
          });
      } else {
        let path = `/views`
        if (this.viewType == 'ticket') {
          path = `/ticket/views`
        }
        apiCall = () =>
          this.$post(path, {
            type: this.viewType,
            name: this.viewName,
            filters: this.cleanedFilters
          });
      }
      let viewId = null;
      apiCall()
        .then(res => {
          viewId = res.id;
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.viewName
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
        })
        .then(() => this.$store.dispatch("loadViews"))
        .then(() => {
          this.setActiveView(viewId);
        })
        .catch(() => null)
        .finally(() => {
          this.saving = false;
          this.showSaveFilter = false;
        });
    },
    deleteFilter(item) {
      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$tc("view").toLowerCase()
        })
      ).then(() => {
        let path = `/views/${item.id}`
        if (this.viewType == 'ticket') {
          path = `/ticket/views/${item.id}`
        }
        this.$del(path, undefined, {
          useDev: this.useDev
        })
          .then(() => {
            this.$store.dispatch("loadViews");
            this.view = {};
            this.$notification({
              title: this.$t("success"),
              text: `${this.$tc("view")} ${this.$t(
                "has_been_deleted"
              ).toLowerCase()}`,
              type: "success"
            });
          })
          .catch(e => {
            this.$notification({
              title: this.$t("not_deleted"),
              text: `${this.$tc("view")} ${this.$t(
                "has_not_been_deleted"
              ).toLowerCase()}`,
              type: "error"
            });
          });
      });
    }
  }
};
