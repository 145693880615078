<template>
  <div class="wrapper form">
    <h2 style="text-align: center;" v-if="newShop.activation_code">
      {{ newShop.activation_code }}
    </h2>

    <core-clipboard-input
      v-else
      readonly
      hidePrefix
      :value="newShop.shop_token"
      :label="$t('shop_token')"
    />

    <div
      class="description"
      v-if="shopTypes[newShop.platform].description"
      style="margin-top: 10px;"
    >
      {{ shopTypes[newShop.platform].description }}
    </div>

    <template v-if="shopTypes[newShop.platform].video_url">
      <div class="description">
        {{ $t("create_new_shop.video_description") }}
      </div>
      <div class="video-wrapper">
        <youtube class="video" :video-id="videoId" ref="youtube" />
      </div>
    </template>
    <div class="status" style="text-align: center;">
      <div
        class="indicator blink_me"
        :class="{ up: activated, down: !activated }"
      />
      {{ $t(`shop_status.${activated ? "activated" : "not_activated"}`) }}
    </div>
    <div v-if="this.newShop.platform !== 'custom'">
      {{ $t("activation_expires_at") }}
      {{ convertUTCToLocal(newShop.activation_expires_at) }}
    </div>
  </div>
</template>

<script>
import { convertUTCToLocal } from "@/utils/date";
export default {
  data: () => ({
    activated: false
  }),
  props: {
    newShop: {
      type: Object,
      default: () => ({})
    }
  },
  created: function() {
    this.$echo()
      .private(`shop.${this.newShop.id}`)
      .listen(`.activated`, () => {
        this.$emit("shop-activated");
        this.activated = true;
        this.$echo()
          .private(`shop.${this.newShop.id}`)
          .stopListening(`.activated`);
      });
  },
  destroyed: function() {
    this.$echo()
      .private(`shop.${this.newShop.id}`)
      .stopListening(`.activated`);
  },

  computed: {
    shopTypes: function() {
      return this.$store.state.shopSettings;
    },
    videoId: function() {
      if (!this.shopTypes[this.newShop.platform].video_url) return "";

      return this.$youtube.getIdFromUrl(
        this.shopTypes[this.newShop.platform].video_url
      );
    }
  },
  methods: {
    convertUTCToLocal: function(val, type) {
      return convertUTCToLocal(val, type);
    }
  }
};
</script>
