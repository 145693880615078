<template>
  <core-dialog
    class="filter "
    :visible="visible"
    @update:visible="$emit('close')"
    append-to-body
    :lg_width="40"
    :xl_width="40"
    :title="
      $t('update_entity', {
        entity: `${$tc('printer').toLowerCase()}`
      })
    "
    :close-on-click-modal="false"
  >
    <template>
      <div class="wrapper form">
        <div class="grid-container">
          <row :gutter="20">
            <column :xs="12">
              <core-switch
                :label="$t('default')"
                v-model="editEntity.default"
                :active-value="1"
                :inactive-value="0"
              />
            </column>
            <column :xs="12">
              <core-text :label="$t('alias')" v-model="editEntity.alias" />
            </column>
          </row>
        </div>
      </div>
    </template>
    <template v-slot:footer class="flex">
      <core-button
        type="default_outline"
        class="mr-auto"
        @click="$emit('close')"
        >{{ $t("cancel") }}
      </core-button>
      <core-button type="coolrunner" @click="save" :loading="saving"
        >{{ $t("save") }}
      </core-button>
    </template>
  </core-dialog>
</template>

<script>
export default {
  name: "PrinterEditDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    errors: [],
    editEntity: {},
    saving: false
  }),
  mounted: function() {
    this.editEntity = { ...this.entity };
  },
  methods: {
    save: function() {
      this.saving = true;
      this.errors = {};
      this.$put(`printers/${this.editEntity.id}`, this.editEntity, {
        failSilently: true
      })
        .then(r => {
          this.$emit("updated");
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc("printer", 1).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.$notification({
            title: this.$t("error_updating_entity", {
              entity: this.$tc("printer", 1).toLowerCase()
            }),
            text: this.$_.join(this.$_.map(response.data.errors), "</br>"),
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>
