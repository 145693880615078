<template>
  <core-view>
    <div v-if="!canView">
      {{$t('page_restricted')}}
    </div>
    <div v-if="canView">
      <core-view-header name="account.header">
        <template v-slot:description>
          <el-col>
            <el-tabs
              v-model="activeTab"
              @tab-click="
                tab => {
                  activeTab = tab.name;
                  $router.push({ name: tab.name });
                }
              "
            >
              <el-tab-pane
                v-for="tab in tabs"
                :key="tab.name"
                :name="tab.name"
                :label="$t(tab.label)"
              />
            </el-tabs>
          </el-col>
        </template>
      </core-view-header>

      <el-container class="bg-white account-container flex-grow-0 shadow-sm">
        <router-view name="account_settings" />
      </el-container>
    </div>
  </core-view>
</template>

<script>
import pageAccess from "@/mixins/PageAccess";
export default {
  name: "settings",
  mixins:[pageAccess],
  data() {
    return {
      activeTab: "",
    };
  },
  computed: {
    tabs: function() {
      const tabs = [
        {
          label: "account.info",
          name: "account_settings_info"
        },
        {
          label: "account.payment",
          name: "account_settings_payment"
        },
        {
          label: "account.label",
          name: "account_settings_label"
        },
        {
          label: "account.users",
          name: "account_settings_users"
        },
        /*
        {
          label: "account.roles",
          name: "account_settings_roles"
        },*/
        {
          label: "account.profile",
          name: "account_settings_profile"
        },
        {
          label: "account.pickup",
          name: "account_settings_pickup"
        }
      ];

      if(!this.$store.state.account.enabled_services.transfer_enabled) {
        tabs.splice(tabs.findIndex(tab => tab.name === "account_settings_pickup"), 1);
      }
      if(!this.$store.state.account.business) {
        tabs.splice(tabs.findIndex(tab => tab.name === "account_settings_users"), 1);
      }
      return tabs;
    }

  },
  watch: {
    "$route.name": function(val) {
      this.ensureCorrectPage(val);
    }
  },
  created: function() {
    this.ensureCorrectPage();
  },
  methods: {
    ensureCorrectPage: function(val = this.$route.name) {
      if (val === "account_settings") {
        this.$router.replace({ name: "account_settings_info" });
      }
      this.activeTab = this.$route.name;
    }
  }
};
</script>
