<template>
  <core-dialog
    id="create-return-portal-dialog"
    :visible="visible"
    @update:visible="close"
    :title="$t('choose_portal')"
    @close="close"
    :use_default_breakpoints="false"
    :lg_width="50"
    :xl_width="50"
    :md_width="50"
    :xs_width="90"
  >
  <div id="create-return-portal-dialog-view" class="w-full h-pdf">
    <div v-if="portals.length == 0" class="text-center"><p class="p-4 ">{{$t('no_portals')}}</p></div>
    <div v-if="portals.length !== 0" class="w-full p-4">
    <transition name="widget-animation" mode="out-in">
    <div v-if="showPortals">
      <div v-for="portal in portals" v-bind:key="portal.name">
        <div @click="createReturnLink(portal.uid)" class="grid grid-cols-3 text-left border rounded-md m-2 border-gray-200 place-content-center cursor-pointer hover:shadow-md hover:z-50 transition duration-300 ease-in-out">
          <div class="p-4"><core-carrier-logo :carrier="findCarrier(portal.product)" /></div>
          <div class="px-2 py-4 grid place-content-center">
              <p class="text-sm mb-2"><span class="font-medium">{{$t('name')}}:</span> {{portal.name}}</p>
              <p class="text-sm "><span class="font-medium">{{$t('product')}}:</span> {{ formatProduct(portal.product) }}</p>
          </div>
          <div class="px-2 py-4 grid place-content-center">
               <p class="text-sm "><span class="font-medium">{{$t('price')}}: </span>{{ portal.price }}</p>
            </div>
        </div>
      </div>
    </div>
    </transition>
    <transition name="widget-animation" mode="out-in">
        <div class="w-full h-24" v-if="!showPortals" :style="{ '--delay': 1 }">
        <core-clipboard-input
        class="w-full mt-5"
        :value="url"
        ref="copyToClipboard"
      /></div>
    </transition>
    </div>
  </div>
  </core-dialog>
</template>

<script>
import Axios from "axios";
import Loader from "@/mixins/Loader"
export default {
  mixins: [
    Loader
  ],
  name: "ReturnPortalView",
  props: {
    shipment: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    portals: [],
    defaultLoaderTarget: "#create-return-portal-dialog-view",
    showPortals: true,
    url: ""
  }),
  watch: {
    shipment: function (newVal, oldVal) {
      this.shipment = newVal;
      this.getPortals(newVal);
    },
  },
  methods: {
    getPortals: function (val) {
      if (!this.$store.state.return_products) {
        this.loading = true
        this.$store.dispatch("loadReturnProducts").then((res) =>{
          let country = val.receiver.country;
          this.$get(`returns/portals/${country}`, {})
            .then((r) => {
              this.portals = r
              this.loading = false
            })
            .catch((e) => {
            });
        })
      } else {
        this.loading = true
        let country = val.receiver.country;
        this.$get(`returns/portals/${country}`, {})
          .then((r) => {
            this.portals = r
            this.loading = false
          })
          .catch((e) => {
          });
      }
    },
    close: function () {
      this.$emit("update:visible", false);
      this.showPortals = true
    },
    formatProduct: function (val) {
        let products = this.$store.state.return_products[this.shipment.receiver.country]
        let product = products.find(x => x.value == val)
        if (product) {
            return product.title
        } else {
            return val
        }
    },
    findCarrier: function (val) {
        let carrier = val.split("_")
        return carrier[0]
    },
    createReturnLink: function (val) {
      this.loading = true
        this.$get(`returns/${val}/key/${this.shipment.package_number}`)
        .then((r) => {
          this.url = r.url
          this.loading = false
        })
        .catch((e) => {
        });
        this.showPortals = false
    }
  },
};
</script>

<style lang="scss">
.el-dialog__wrapper .el-dialog__body {
    display: block;
}
</style>