<template>
  <div class="wrapper">
    <div class="title">{{ title }}</div>
    <div class="description" v-if="description">{{ description }}</div>

    <div class="number">
      <template v-if="values.length">
        <el-row
          v-for="(val, index) in values"
          v-bind:key="index"
          :gutter="20"
          type="flex"
          align="middle"
        >
          <el-col :span="22">
            <core-time-picker
              is-range
              :clearable="false"
              :key="'input_' + index"
              :value="getRange(optionsMode ? val : val.value)"
              format="HH:mm"
              value-format="HH:mm"
              @input="v => handleInput(v, index)"
            />
          </el-col>
          <el-col :span="2">
            <core-filter-modal-filter-clear
              @click="$emit('remove-filter', index)"
            />
          </el-col>
        </el-row>
        <core-button
          @click="$emit('update-filter', { start: '00:00', end: '23:59' })"
        >
          {{ $t("add_new") }}
        </core-button>
      </template>

      <el-row v-bind:key="0" v-else :gutter="20" type="flex" align="middle">
        <el-col :span="22">
          <core-time-picker
            style="opacity: 0.7"
            is-range
            :clearable="false"
            :key="'input_0'"
            :value="getRange({ start: '00:00', end: '23:59' })"
            format="HH:mm"
            value-format="HH:mm"
            @input="v => handleNewInput(v)"
          />
        </el-col>
        <el-col :span="2">
          <core-filter-modal-filter-clear disabled />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import mixin from "./typeMixin";

export default {
  mixins: [mixin],
  props: {
    value: {
      type: [Array, Object],
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  created() {
    this.handleNewInput = _.debounce(this.handleNewInput, 500);
  },
  computed: {
    title: function() {
      return this.filter.title || this.$t(`filters.${this.$vnode.key}`);
    },
    description: function() {
      return this.filter.description;
    },
  },
  methods: {
    getRange: function(val = { start: "00:00", end: "23:59" }) {
      return [this.getDateVal(val.start), this.getDateVal(val.end)];
    },

    getDateVal: function(val) {
      const value = this.optionsMode ? val.value : val;
      const moment = value
        ? this.$moment(value, "HH:mm")
        : this.$moment().endOf("day");

      return moment.toDate();
    },
    getTime: function(val) {
      return this.$moment(val).format("HH:mm");
    },
    handleNewInput: function(val) {
      if (!val) return;
      const newValue = {};
      newValue.start = val[0];
      newValue.end = val[1];

      if (newValue.start !== undefined || newValue.end !== undefined)
        this.$emit("update-filter", newValue);
    },
    handleInput: function(val, index) {
      const orgVal = this.optionsMode
        ? this.values[index]
        : this.values[index].value;
      const newValue = { ...orgVal };

      newValue.start = val[0];
      newValue.end = val[1];

      if (newValue.start !== undefined || newValue.end !== undefined)
        this.$emit("update-filter", newValue, index);
      else this.$emit("remove-filter", index);
    }
  }
};
</script>
