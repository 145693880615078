<template>
  <core-view :style="{ '--delay': delay }">
    <core-view-header
      :name="'error_logs'"
      :routeName="$route.name"
      route="errorlogs"
      :description="'page_descriptions.error_logs'"
      showViewName
    >
    </core-view-header>
    <core-list-new-table
      :items="errors"
      :totalItems="totalItems"
      :headers="headers"
      :loading="loading"
      :pageSize="options.itemsPerPage"
      :currentPage.sync="options.page"
      :orderBy.sync="options.orderBy"
      :orderDir.sync="options.orderDir"
      :query.sync="searchValue"
      :show-create-new="false"
      :entityName="name"
      :emptyListIcon="icon"
      :checkbox="false"
      :selection="false"
      :paginate="false"
    >
      <template v-slot:[`column.request_body`]="{ item }">
        <div class=" overflow-hidden rounded-md px-2 w-full truncate ">
            {{ returnCarrier(item) }}
        </div>
      </template>
      <template v-slot:[`column.response_body`]="{ item }">
        <div class=" overflow-hidden rounded-md px-2 w-full truncate ">
            {{ returnError(item) }}
        </div>
      </template>
      <template v-slot:[`column.created_at`]="{ item }">
        <div class=" overflow-hidden rounded-md px-2 w-full truncate ">
            {{ returnTime(item) }}
        </div>
      </template>
    </core-list-new-table>
     <div class="w-full h-14 bg-white rounded-b-md shadow-md">
      <div class="max-w-max mx-auto flex pt-5">
        <font-awesome-icon
          v-if="prev_page_url"
          :icon="['far', 'chevron-down']"
          class="transform rotate-90 cursor-pointer"
          @click="getErrors('prev')"
        />
        <div v-if="(prev_page_url && next_page_url)" class="w-10"></div>
        <font-awesome-icon
          v-if="next_page_url"
          :icon="['far', 'chevron-down']"
          class="transform -rotate-90 cursor-pointer"
          @click="getErrors('next')"
        />
      </div>
     </div>
  </core-view>
</template>

<script>
import I18n from "@/plugins/i18n";
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";
import { URL_PREFIX, API_PREFIX } from "@/utils/constants";
import EventBus from "@/plugins/eventbus";

export default {
  mixins: [List],
  name: "ErrorLogs",
  data: () => ({
    name: "ErrorLogs",
    icon: ["fal", "box-alt"],
    errors: [],
    totalItems: 0,
    headers: [],
    searchValue: "",
    prev_page_url: null,
    next_page_url: null
  }),
  created() {
    this.getErrors('start')
      this.headers = [
        {
        label: "date",
        key: "created_at",
        type: "date",
        minWidth: "15"
        },
        {
            label: "request",
            key: "request_body",
            orderableKey: "request_body",
            minWidth: "40"
        },
        {
            label: "response",
            key: "response_body",
            minWidth: "45"
        }
      ];
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    API_PREFIX: () => API_PREFIX,
    delay: function() {
      return this.items.length * 0.03;
    }
  },
  watch: {
    searchValue: function (n,o) {
      this.searchValue = n;
      this.getErrors('search');
    }
  },
  methods: {
    getErrors: async function (code) {
      this.loading = true;
      let url = `logsapi/?q=${this.searchValue}`
      if (code == 'prev') {
        url = this.prev_page_url
      } else if (code == 'next') {
        url = this.next_page_url
      }
      await this.$get(url,
        {},
        {
          failSilently: true
        }
      ).then(response => {
        this.errors = response.data,
        this.next_page_url = response.next_page_url,
        this.prev_page_url = response.prev_page_url,
        this.totalItems = response.data.length
      });
      this.loading = false
    },
    returnCarrier: function(item) {
      let request = JSON.parse(item.request_body);
      if (request.reference && request.order_number) {
        return `${this.$t('reference')}: ${request.reference}, ${this.$t('order_number')}: ${request.order_number}`;
      } else if (request.reference && !request.order_number) {
        return `${this.$t('reference')}: ${request.reference}, ${this.$t('carrier')}: ${request.carrier}`;
      } else if (!request.reference && request.order_number) {
        return `${this.$t('order_number')}: ${request.order_number}, ${this.$t('carrier')}: ${request.carrier}`;
      } else {
        return `${this.$t('receiver')}: ${request.receiver.name}, ${this.$t('carrier')}: ${request.carrier}`;
      }
    },
    returnError: function(item) {
      let error = JSON.parse(item.response_body);
      if (error.message) {
        return error.message;
      } else {
        return error;
      }
    },
    returnTime: function(item) {
      return this.$moment(item.created_at).format("LLL")
    }
  }
};
</script>