import { set } from "lodash/fp";

export default {
  data: () => ({
    options: {},
    showOptions: false
  }),
  watch: {
    options: function(options) {
      this.shippingMethod.options = options;
    }
  },
  computed: {
    optionsDescribeKey: function() {
      return "";
    },
    describedOptions: function() {
      const vds = this.$store.state.viewDescriptions;
      if (!vds || !this.optionsDescribeKey) return {};
      return this.$_.get(vds, this.optionsDescribeKey);
    },
    supportsOptions: function() {
      return !this.$_.isEmpty(this.describedOptions);
    },
    numberOfOptions: function() {
      let opt = this.$_.chain(this.options)
        .values()
        .flatten()
        .filter()
        .value();

      opt = this.$_.map(opt, f =>
        this.$_.isArray(f.value) ? this.$_.flatten(f.value) : f.value
      );
      return this.$_.flatten(opt).length;
    }
  },
  methods: {
    confirmOptions() {
      this.shippingMethod = { ...this.shippingMethod, options: this.options };
      this.showOptions = false;
    },
    setOption(options) {
      this.options = options;
    },
    updateOption(key, val, index = -1) {
      const type = this.describedOptions[key]?.base_type;
      const options = this.$_.cloneDeep(this.options);

      let value = val;

      if (type !== "select") {
        value = options[key]?.value || [];
        if (index === -1) {
          value.push(val);
        } else value[index] = val;
      }

      options[key] = {
        ...options[key],
        value,
        key,
        type
      };

      this.options = options;
    },
    removeOption(key, index) {
      const options = this.$_.cloneDeep(this.options);

      let value = (options[key] || { value: [] }).value;

      if (this.$_.isEmpty(value)) return;
      value.splice(index, 1);

      if (this.$_.isEmpty(value)) delete options[key];
      else
        options[key] = {
          ...options[key],
          value
        };

      this.options = options;
    }
  }
};
