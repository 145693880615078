import Axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import data from "../plugins/data";
import { URL_PREFIX } from "../utils/constants";
import VuexPersistence from "vuex-persist";
import localStorage from "../plugins/localstorage";
import I18n from "@/plugins/i18n";
import { Model } from "vue-breakpoint-component";
import router from "../router";
import VueCookies from "vue-cookies";
import { importerStates } from "@/utils/constants";
import _ from "lodash";

const getCurrentParentDomain = () => {
  const domainParts = window.location.hostname.split(".");
  const parentDomain = domainParts.slice(domainParts.length - 2).join(".");

  return parentDomain;
};



const getCurrentSubDomain = () => {
  const domainParts = window.location.hostname.split(".");
  const subDomain = domainParts.slice(0, domainParts.length - 2).join(".");
  return subDomain;
};

export const setCookies = (key, value, expires) => {
  VueCookies.set(key, value, expires, null, `.${getCurrentParentDomain()}`, false, null);
};

export const removeCookies = (key) => {
  VueCookies.remove(key, null, `.${getCurrentParentDomain()}`)
};

const vuexCookie = new VuexPersistence({
  restoreState: (key, storage) => VueCookies.get(key),
  saveState: (key, state, storage) => VueCookies.set(key, state, 60 * 60 * 24, null, `.${getCurrentParentDomain}`, true, "none"),
  removeItem: (key) => VueCookies.remove(key, null, `.${getCurrentParentDomain()}`),
});

Vue.use(Vuex);
// initial state
const initialState = () => ({
  initialLoaded: undefined,
  capabilities: null,
  services: [],
  account_id: null,
  auth_id: null,
  viewsLoaded: false,
  auth: null,
  account: null,
  paymentInfo: null,
  availableViews: null,
  viewDescriptions: {},
  availableWidgets: null,
  availableViewTypes: null,
  claim_types: {},
  currencies: {},
  claim_statuses: {},
  carriers: null,
  breakpoint: new Model(),
  shops: null,
  shopSettings: null,
  return_products: null,
  return_countries: null,
  customer: null,
  returns: null,
  exportOptions: null,
  accountRes: null,
  temp: {},
  product_dimensions: null,
  errors: {
    login: null,
  },
  printServices: null,
  countries: null,
  filterTypes: null,
  sdrRate: 7.5,
  importCounts: _.mapValues(importerStates, function (o) {
    return undefined;
  }),
  withTransition: "slide-bottom",
  loggingOut: false,
});

export default new Vuex.Store({
  plugins: [vuexCookie.plugin],
  state: initialState,
  mutations: {
    LOGOUT(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    SET_VIEWS_LOADED(state) {
      state.viewsLoaded = true;
    },
    SET_FILTER_TYPES(state, ft) {
      state.filterTypes = ft;
    },
    SET_INITIAL_LOADED(state, l) {
      state.initialLoaded = l;
    },
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    SET_BREAKPOINT(state, breakpoint) {
      state.breakpoint = breakpoint;
    },
    SET_ACCOUNT(state, a) {
      state.account = a;
    },
    SET_COUNTRIES(state, c) {
      state.countries = c;
    },
    SET_SHOPS(state, s) {
      state.shops = s;
    },
    SET_PRINT_SERVICES(state, ps) {
      state.printServices = ps;
    },
    SET_ERROR(state, error, key) {
      const errors = { ...state.errors };
      error[key] = error;
      state.errors = errors;
    },
    SET_SHOP_SETTINGS(state, s) {
      state.shopSettings = s;
    },
    SET_RETURN_PRODUCTS(state, rp) {
      state.return_products = rp;
    },
    SET_RETURN_COUNTRIES(state, rc) {
      state.return_countries = rc;
    },
    SET_RETURN_CURRENCIES(state, rc) {
      state.return_currencies = rc;
    },
    SET_INSURANCE_PRODUCTS(state, ip) {
      state.insurance_products = ip;
    },
    SET_PAYMENT_INFO(state, pi) {
      state.paymentInfo = pi;
    },
    SET_AVAILABLE_VIEWS(state, av) {
      state.availableViews = av;
    },
    SET_AVAILABLE_VIEW_TYPES(state, vts) {
      state.availableViewTypes = vts;
    },
    SET_VIEW_DESCRIPTION(state, { view, description }) {
      let vds = state.viewDescriptions ? { ...state.viewDescriptions } : {};
      vds = _.set(vds, view, description);
      state.viewDescriptions = vds;
    },
    SET_AVAILABLE_WIDGETS(state, av) {
      state.availableWidgets = av;
    },
    SET_CARRIERS(state, c) {
      state.carriers = c;
    },
    SET_EXPORT_OPTIONS(state, eo) {
      state.exportOptions = eo;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_TEMP(state, temp) {
      // const [key, value] = temp;
      state.temp[temp.key] = temp.value;
    },
    SET_PRODUCT_DIMENSIONS(state, pd) {
      state.product_dimensions = pd;
    },
    SET_IMPORT_STATE_COUNT(state, importState, count) {
      state.importCounts[importState] = count;
    },
    INCREMENT_IMPORT_STATE_COUNT(state, importState) {
      let cur = state.importCounts[importState] || 0;
      cur += 1;
      state.importCounts[importState] = cur;
    },
    SET_SDR_RATE(state, rate) {
      state.sdrRate = rate;
    },
    SET_TRANSITION(state, name) {
      state.withTransition = name;
    },
    SET_TRANSITION_DELAY(state, value) {
      state.transitionDelay = value;
    },
    SET_PENDING_LEGAL_DOCUMENTS(state, data) {
      state.account = { ...state.account, pending_documents: data };
    },
    SET_CLAIM_STATUSES(state, data) {
      state.claim_statuses = data;
    },
    SET_CLAIM_TYPES(state, data) {
      state.claim_types = data ;
    },
    SET_CURRENCIES(state, data) {
      state.currencies = data ;
    },
    SET_DOCUMENT_TYPES(state, data) {
      state.document_types = data;
    },
    SET_DOCUMENT_COUNTRIES(state, data) {
      state.document_countries = data;
    },
    SET_LOGGING_OUT(state, data) {
      state.loggingOut = data;
    },
    SET_ACCOUNT_ID(state, data) {
      state.account_id = data;
    },
    SET_CAPABILITIES(state, data) {
      state.capabilities = data;
    },
    SET_SERVICES(state, data) {
      state.services = data;
    },
    SET_ACCOUNTS(state, data) {
      state.accountRes = data;
    },
    SET_AUTH_ID(state, data) {
      state.auth_id = data;
    },
    SET_ONBOARDING_FLOW(state, data) {
      state.onboarding.flow = data;
    },
  },
  actions: {
    logout({ commit }) {
      commit("SET_LOGGING_OUT", true);
      let token = VueCookies.get("cr_access_token")
      return data.http
        .post(
          "https://auth.homerunner.com/api/auth/logout",
          {},
          {
            logoutOnError: false,
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .catch((e) => {
          throw e;
        })
        .finally(() => {
          removeCookies("cr_access_token");
          removeCookies("hr_token");
          removeCookies("hr_account_id");
          removeCookies("hr_token_expire");
          
          commit("SET_LOGGING_OUT", false);
          return Promise.resolve(commit("LOGOUT")).then(() => {
            router.push({ name: "login" });
          });
        });
    },
    refresh({ dispatch, commit }) {
      let token = VueCookies.get("cr_access_token")
      return data.http
        .post(
          "https://auth.homerunner.com/api/auth/refresh",
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(({ data }) => {
          const cookieValObject = {
            token: data.access_token,
            expires_in: data.expires_in / 1000,
            expires: Date.now() + data.expires_in,
          };
          removeCookies("cr_access_token");
          setCookies("cr_access_token", cookieValObject, data.expires_in / 1000);
        })
        .finally(() => {
          return dispatch("setAuthFromCookie");
        });
    },
    setAuthFromCookie({ dispatch, commit }) {
      if (Vue.prototype.$cookies.isKey("cr_access_token")) {
        let cookie = Vue.prototype.$cookies.get("cr_access_token");
        return dispatch("setAuth", {
          access_token: cookie.token,
          expires: cookie.expires,
        });
        
      } else {
          commit("LOGOUT");
      }
    },

    doAuth({ dispatch, commit }, opts) {
      commit("SET_INITIAL_LOADED", false);
      const { ...credentials } = opts;
      //if current path includes /onboarding, then we are onboarding and should not load capabilities
      const omitCapabilities = router.currentRoute.path.includes("/onboarding");


      return Axios.post(URL_PREFIX + "/api/auth/login", {
        ...credentials,
        grant_type: "password",
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_AUTH_CLIENT_SECRET,
      })
        .then(({ data }) => {
          if (!data) {
            throw null;
          }
          const cookieValObject = {
            token: data.access_token,
            expires_in: data.expires_in / 1000,
            expires: Date.now() + data.expires_in,
          };
          setCookies("cr_access_token", cookieValObject, data.expires_in / 1000);
          return dispatch("setAuth", {
            access_token: data.access_token,
            expires: Date.now() + data.expires_in,
            omitCapabilities,
          });
        })
        .catch((e) => {
          Vue.notification({
            title: I18n.t("login_failed"),
            text: I18n.t("login_failed_desc"),
            type: "error",
          });
          return Promise.reject(e);
        })
        .finally(() => {
          commit("SET_INITIAL_LOADED", true);
        });
    },
    doAuthExternal({ dispatch, commit, state }, data) {
      let prms = new Promise((resolve) => resolve());
      if (!_.isEmpty(state.auth)) prms = dispatch("logout");

      return prms
        .then(() => {
          commit("SET_INITIAL_LOADED", false);
          setCookies("cr_access_token", data, data.expires_in);
          return dispatch("setAuthFromCookie");
        })
        .catch(() => {
          throw null;
        });
    },
    setAuth({ commit, dispatch }, opts) {
      const { omitCapabilities, ...auth } = opts;
      commit("SET_AUTH", auth);

      return dispatch("loadCapabilities");
    },
    loadCapabilities({ dispatch, commit, state }) {
      return data
        .fetch(URL_PREFIX + "/api/auth/capabilities", {}, { failSilently: true })
        .then((result) => {
          if(!result?.data?.length) return Promise.reject("No accounts found");

          let accountDomain = result.data[0].subdomain;
          commit("SET_ACCOUNTS", result.data);

          if (getCurrentSubDomain() == "newclaim.d1ou4iffrltxb8") {
            console.log(result);
            let data = {
              id: result.data[0].account.id,
              auth_id: result.data[0].account.account_id,
              capabilities: result.data[0].capabilities,
              services: result.data[0].account.services,
            };
            return dispatch("setAccountServicesAndCapabilities", data);
          }

          if (getCurrentParentDomain() == "localhost" || getCurrentParentDomain() == "127.0.0.1") {
            let data = {
              id: result.data[0].account.id,
              auth_id: result.data[0].account.account_id,
              capabilities: result.data[0].capabilities,
              services: result.data[0].account.services,
            };
            return dispatch("setAccountServicesAndCapabilities", data);
          }
          const frontendVersion = VueCookies.get("frontend-version");

          /**If user only has access to one account */
          if (result.data.length === 1) {
            const account = result.data[0].account;
            const capabilities = result.data[0].capabilities;
            const data = {
              id: account.id,
              auth_id: account.account_id,
              capabilities,
              services: account.services,
            };

            let redirectParamsString = `token=${state.auth.access_token}&expires_in=86400000`;
            //if current path is not login add current path as redirect url
            if (!router.currentRoute.path.includes('/login')) redirectParamsString += `&nextUrl=${router.currentRoute.path}`;

            if (getCurrentParentDomain() == "coolrunner.dk") {
              if (account.business){
                if(frontendVersion == "old")
                window.location.replace(`https://${accountDomain}.homerunner.com/login/o?${redirectParamsString}`);
                else
                window.location.replace(`https://${accountDomain}.homerunner.com/auth/login?${redirectParamsString}`);              
              }
              else dispatch("setAccountServicesAndCapabilities", data);
            } else if (getCurrentParentDomain() == "homerunner.com") {
              if (!account.business) window.location.replace(`https://account.coolrunner.dk/login/o?${redirectParamsString}`);
              else if (getCurrentSubDomain() == accountDomain) dispatch("setAccountServicesAndCapabilities", data);
              else {
                if (account.business){
                  if(frontendVersion == "old")
                  window.location.replace(`https://${accountDomain}.homerunner.com/login/o?${redirectParamsString}`);
                  else
                  window.location.replace(`https://${accountDomain}.homerunner.com/auth/login?${redirectParamsString}`);              
                }
              }
            }
          } else {
            if (getCurrentParentDomain() == "coolrunner.dk") {
              const privateAccount = _.find(result.data, (value) => {
                return !value.account.business;
              });

              if (privateAccount) {
                let data = {
                  id: privateAccount.account.id,
                  auth_id: privateAccount.account.account_id,
                  capabilities: privateAccount.capabilities,
                  services: privateAccount.account.services,
                };
                dispatch("setAccountServicesAndCapabilities", data);
              } else {
                  if(frontendVersion == "old")
                    window.location.replace(`https://account.homerunner.com/login/o?token=${state.auth.access_token}&expires_in=86400000`);
                  else
                    window.location.replace(`https://${accountDomain}.homerunner.com/auth/login?token=${state.auth.access_token}&expires_in=86400000`);              
                }
            } else if (getCurrentParentDomain() == "homerunner.com") {
              const correctAccount = _.find(result.data, (value) => {
                return value.subdomain == getCurrentSubDomain();
              });

              if (correctAccount) {
                let data = {
                  id: correctAccount.account.id,
                  capabilities: correctAccount.capabilities,
                  auth_id: correctAccount.account.account_id,
                  services: correctAccount.account.services,
                };
                dispatch("setAccountServicesAndCapabilities", data);
              } else if (window.location.pathname !== "/accountselect" && !state.account_id) {
                commit("SET_INITIAL_LOADED", true);
                window.location.href = "/accountselect";
              }
            }
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    setBreakpoint({ commit }, breakpoint) {
      commit("SET_BREAKPOINT", breakpoint);
    },

    setAccountServicesAndCapabilities({ dispatch, commit }, data) {
      commit("SET_ACCOUNT_ID", data.id);
      commit("SET_CAPABILITIES", data.capabilities);
      commit("SET_SERVICES", data.services);
      commit("SET_AUTH_ID", data.auth_id);
      if (router.currentRoute.path == "/onboarding/shop") {
        return dispatch("loadShopSettings").then(() => Promise.resolve(data));
      }

      return dispatch("loadInitial").then(() => {
        return Promise.resolve(data);
      });
    },
    loadInitial({ dispatch, commit }) {
      commit("SET_INITIAL_LOADED", false);
      return dispatch("loadAccount").then((res) => {
        let actions = [
          dispatch("loadSDRRate"),
          dispatch("loadInsuranceProducts"),
          dispatch("loadPaymentInfo"),
          dispatch("loadCarriers"),
          dispatch("loadViews"),
          dispatch("loadViewTypes"),
          dispatch("loadCurrencies"),
          dispatch("loadCountries"),
          dispatch("loadExportOptions"),
          dispatch("loadProductDimensions"),
        ];

        if (res?.business !== false)
          actions = [
            ...actions,
            dispatch("loadWidgets"),
            dispatch("describeSmartcheckout"),
            dispatch("describeCasambiGroups"),
            dispatch("loadReturnCountries"),
            dispatch("loadReturnCurrencies"),
            dispatch("loadShopSettings"),
            dispatch("loadShops"),
            dispatch("loadPrintServices"),
            dispatch("loadCustomFilterTypes"),
            dispatch("loadClaimStatuses"),
            dispatch("loadClaimTypes"),
            dispatch("loadDocumentTypes"),
            dispatch("loadDocumentCountries"),
          ];

        return Promise.all(actions).finally(() => {
          commit("SET_INITIAL_LOADED", true);
        });
      });
    },
    describeSmartcheckout({ commit, state }, cps) {
      const view = `smartcheckout.${cps || "base"}`;
      /*if(_.get(state.viewDescriptions, view))
        return;*/

      let url = "smartcheckouts/describe";
      if (cps) url += `/${cps}`;
      return data
        .fetch(url, {}, { failSilently: true })
        .then((result) => {
          commit("SET_VIEW_DESCRIPTION", {
            view,
            description: result,
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    describeCasambiGroups({ commit }) {
      return data
        .fetch("casambi/groups/describe", {}, { failSilently: true })
        .then((result) => {
          commit("SET_VIEW_DESCRIPTION", {
            view: "lightGroup",
            description: result,
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadPrintServices({ commit }) {
      return data
        .fetch("printservices", {}, { failSilently: true })
        .then((result) => {
          commit("SET_PRINT_SERVICES", result.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadInsuranceProducts({ commit }) {
      return data
        .fetch("insurance-products", {}, { failSilently: true })
        .then((result) => {
          commit("SET_INSURANCE_PRODUCTS", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadCustomFilterTypes({ commit }) {
      return data
        .fetch("smartcheckouts/filters/types", {}, { failSilently: true })
        .then((result) => {
          commit("SET_FILTER_TYPES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadAccount({ commit, dispatch }) {
      return data
        .fetch("account", {}, { failSilently: true })
        .then((result) => {
          return dispatch("setAccount", result.data).then(() => {
            return Promise.resolve(result.data);
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    setAccount({ commit }, account) {
      commit("SET_ACCOUNT", account);
    },
    loadClaimStatuses({ commit }) {
      return data
        .fetch("ticket/status-list", {}, { failSilently: true })
        .then((result) => {
          commit("SET_CLAIM_STATUSES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadCurrencies({ commit }) {
      return data
        .fetch("claims/currency-list", {}, { failSilently: true })
        .then((result) => {
          commit("SET_CURRENCIES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadClaimTypes({ commit }) {
      return data
        .fetch("claims/types", {}, { failSilently: true })
        .then((result) => {
          commit("SET_CLAIM_TYPES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadCountries({ commit }) {
      return data
        .fetch("countries", {}, { failSilently: true })
        .then((result) => {
          commit("SET_COUNTRIES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },

    loadPaymentInfo({ commit }) {
      return data
        .fetch("account/payment", {}, { failSilently: true })
        .then((result) => {
          commit("SET_PAYMENT_INFO", result.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadExportOptions({ commit }) {
      return data
        .fetch("views/export/options", {}, { failSilently: true })
        .then((result) => {
          commit("SET_EXPORT_OPTIONS", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadShopSettings({ commit }) {
      return data
        .fetch("shops/settings", {}, { failSilently: true })
        .then((result) => {
          commit("SET_SHOP_SETTINGS", result.types);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadShops({ commit }, force) {
      return data
        .fetch("shops", {}, { failSilently: true, force })
        .then((result) => {
          commit("SET_SHOPS", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    setShops({ commit }, shops) {
      commit("SET_SHOPS", shops);
    },
    loadReturnProducts({ commit }) {
      return data
        .fetch("returns/products", {}, { failSilently: true })
        .then((result) => {
          commit("SET_RETURN_PRODUCTS", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadReturnCountries({ commit }) {
      return data
        .fetch("returns/countries", {}, { failSilently: true })
        .then((result) => {
          commit("SET_RETURN_COUNTRIES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadReturnCurrencies({ commit }) {
      return data
        .fetch("returns/currencies", {}, { failSilently: true })
        .then((result) => {
          commit("SET_RETURN_CURRENCIES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadDocumentTypes({ commit }) {
      return data
        .fetch("documents/types", {}, { failSilently: true })
        .then((result) => {
          commit("SET_DOCUMENT_TYPES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadDocumentCountries({ commit }) {
      return data
        .fetch("documents/countries", {}, { failSilently: true })
        .then((result) => {
          commit("SET_DOCUMENT_COUNTRIES", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadCarriers({ commit }) {
      return data
        .fetch("carriers", {}, { failSilently: true })
        .then((result) => {
          commit("SET_CARRIERS", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadWidgets({ commit }) {
      return data
        .fetch("widgets", {}, { failSilently: true })
        .then((result) => {
          commit("SET_AVAILABLE_WIDGETS", result);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },

    loadViews({ commit }) {
      return data
        .fetch("views", {}, { failSilently: true })
        .then(result => {
          let bpViews = result
          return data 
          .fetch("ticket/views", {}, { failSilently: true })
          .then(ticketViews => {
            return commit("SET_AVAILABLE_VIEWS", [...bpViews, ...ticketViews]);
          })
          .catch(e => {
            console.log(e.message);
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    loadViewTypes({ commit, dispatch }) {
      return data.fetch("views/types").then((result) => {
        commit("SET_AVAILABLE_VIEW_TYPES", result);
        dispatch("describeViews");
      });
    },
    describeViews({ commit, state }) {
      state.availableViewTypes.push('ticket')
      state.availableViewTypes.forEach(type => {
        if (type == 'ticket') {
          data
          .fetch(`ticket/views/types/${type}`)
          .then(description => {
            commit("SET_VIEW_DESCRIPTION", { view: type, description });
          })
          .finally(() => {
            commit("SET_VIEWS_LOADED");
          });
        } else {
          data
          .fetch(`views/types/${type}`)
          .then((description) => {
            commit("SET_VIEW_DESCRIPTION", { view: type, description });
          })
          .finally(() => {
            commit("SET_VIEWS_LOADED");
          });
        }
      });
    },
    setTemp({ commit, state }, data) {
      commit("SET_TEMP", { ...data });
    },
    setPendingLegalDocuments({ commit }, data) {
      commit("SET_PENDING_LEGAL_DOCUMENTS", data);
    },
    loadProductDimensions({ commit }) {
      return data.fetch("dimensions").then((result) => {
        commit("SET_PRODUCT_DIMENSIONS", result);
      });
    },
    loadSDRRate({ commit }) {
      return data.fetch("sdr").then((result) => {
        commit("SET_SDR_RATE", result.data);
      });
    },

    incrementImportStateCount({ commit }, importState) {
      commit("INCREMENT_IMPORT_STATE_COUNT", importState);
    },
    setImportStateCount({ commit }, importState, count) {
      commit("SET_IMPORT_STATE_COUNT", importState, count);
    },
  },
});
