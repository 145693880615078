var render = function render(){var _vm=this,_c=_vm._self._c;return _c('core-dialog',{attrs:{"destroy-on-close":"","title":this.$t(`edit`),"visible":_vm.visible,"xs_width":90,"lg_width":30},on:{"update:visible":_vm.close}},[_c('div',{staticClass:"form wrapper"},[_c('div',{staticClass:"grid-container"},[_c('row',{attrs:{"gutter":20}},[_vm._l((Object.keys(_vm.fieldsToAutoGenerate)),function(key){return [_c('el-divider',{key:key,attrs:{"content-position":"left"}},[_vm._v(_vm._s(_vm.$t(key)))]),_vm._l((Object.keys(_vm.fieldsToAutoGenerate[key])),function(subkey){return _c('column',{key:subkey,attrs:{"xs":_vm.fieldsToAutoGenerate[key][subkey].width}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.isBulk)?_c('core-checkbox',{staticStyle:{"margin-right":"10px"},attrs:{"value":_vm.shouldFieldBeUpdated(subkey)},on:{"change":val => _vm.toggleFieldBeUpdated(subkey, val)}}):_vm._e(),(subkey !== 'cps')?_c(_vm.fieldsToAutoGenerate[key][subkey].component,{tag:"component",attrs:{"options":_vm.fieldsToAutoGenerate[key][subkey].options,"label":_vm.fieldsToAutoGenerate[key][subkey].label},on:{"change":val => _vm.setFieldBeUpdated(subkey)},model:{value:(_vm.entity[subkey]),callback:function ($$v) {_vm.$set(_vm.entity, subkey, $$v)},expression:"entity[subkey]"}}):_c('core-select-cps',{attrs:{"valueIsObject":false,"value":_vm.$_.join(
                    [
                      _vm.entity.carrier,
                      _vm.entity.carrier_product,
                      _vm.entity.carrier_service
                    ],
                    '_'
                  )},on:{"change":val => _vm.setFieldBeUpdated(subkey),"update:value":_vm.splitCps}})],1)])})]}),(_vm.entity.order_lines)?_c('div',{staticClass:"h-auto mt-2 mx-2 w-full text-left border border-gray-300 rounded-md"},[_c('pre',[_vm._v(_vm._s(_vm.formatted))])]):_vm._e()],2)],1)]),_c('span',{staticClass:"dialog-footer flex",attrs:{"slot":"footer"},slot:"footer"},[_c('core-button',{staticClass:"mr-auto",attrs:{"type":"default_outline"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("close")))]),_c('core-button',{attrs:{"disabled":!this.fieldsToUpdate.length,"loading":_vm.saving,"type":"coolrunner"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.state === "failed" ? _vm.$t("save_restore") : _vm.$t("save")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }