<template>
  <div class="relative w-full" :class="{ dense }">
    <input
      v-if="inputType === 'input'"
      :id="id"
      ref="input"
      class="focus:outline-none  focus:border-coolrunner-500 focus:shadow-sm w-full p-3 peer"
      :class="[
        { 'pt-6': !!label, border: bordered, 'rounded-md': rounded, },
        inputClass, error ? 'border-red-500' : 'border-gray-200'
      ]"
      :placeholder="placeholder || label"
      :autofocus="!!autofocus"
      :value="checkValue(value)"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :onkeypress="inputFilter"
      onwheel="this.blur()"
      :onpaste="disablePaste"
      :name="name"
      :pattern="pattern"
      :required="required"
      @input="
        val => {
          $emit('input', val.target.value);
          $emit('change', val.target.value);
        }
      "
      @blur="() => {
        $emit('blur');
      }"
      @click="$emit('clicked')"
    />
    <textarea
      v-else
      :id="id"
      ref="input"
      rows="3"
      class="focus:outline-none  focus:border-coolrunner-500 focus:shadow-sm w-full p-3 peer"
      :class="[
        { 'has-label': !!label, border: bordered, 'rounded-md': rounded },
        inputClass, error ? 'border-red-500' : 'border-gray-200'
      ]"
      :placeholder="placeholder"
      :autofocus="!!autofocus"
      :value="checkValue(value)"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :name="name"
            @input="
        val => {
          $emit('input', val.target.value);
          $emit('change', val.target.value);
        }
      "

    />
    <label
      v-if="label"
      :for="id"
      :class="{
      'scale-75 -translate-y-2.5 translate-x-1 text-gray-500': inputValue,
      'translate-y-2.5' : !inputValue,
      'scale-75 -translate-y-1 translate-x-1 text-gray-500': inputValue && dense,
      'translate-y-1' : !inputValue && dense
    }"
      class="absolute top-3 left-0 px-3 h-full flex  pointer-events-none transform origin-left transition-all duration-100 ease-in-out leading-none text-gray-500 peer-focus:scale-75 peer-focus:-translate-y-2.5 peer-focus:translate-x-1 peer-focus:text-gray-500"
      ><font-awesome-icon v-if="labelIcon" :icon="labelIcon" class="mr-2"/>{{
        label
      }}
      <div>
        <el-tooltip
          v-if="!!helperText"
          class="pointer-events-auto cursor-help"
          effect="light"
          :content="helperText"
          placement="top-start"
        >
          <span
            class="el-icon-question input-description"
            style="margin-left: .25em"
        /></el-tooltip></div
    ></label>
    <p v-if="suffix" class="absolute top-1 right-0 p-3 text-gray-400">{{suffix}}</p>
  </div>
</template>

<script>
const sizes = {
  mini: { input: "h-5 text-sm", label: "py-0.5 text-sm" },
  dense: { input: "h-10 text-base", label: "py-3.5 text-sm" },
  default: { input: "h-14 text-base", label: "py-5 text-sm" },
  textarea: { input: "pt-8 text-base", label: "py-5 text-base" }
};
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false,
      validator: val => {
        const key = val ? "dense" : "default";
        return Object.keys(sizes).includes(key);
      }
    },
    label: {
      type: String,
      default: ""
    },
    labelIcon: {
      type: Array,
      default: null
    },
    error: {
      type: Boolean, 
      default: false
    },
    helperText: {
      type: String,
      default: ""
    },
    placeholder: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: "input"
    },
    description: {
      type: String,
      default: ""
    },
    bordered: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    suffix: {
      type: String,
      default: ""
    },
    inputFilter: {
      type: String,
      default: ""
    },
    disablePaste: {
      type: String,
      default: ""
    },
    pattern: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
     inputValue: false
  }),
  computed: {
    id: function() {
      return `${this.inputType}_${this.$_.uniqueId()}`;
    },
    sizeKey: function() {
      return this.inputType === "textarea"
        ? "textarea"
        : this.dense
        ? "dense"
        : "default"
    },
    inputClass: function() {
      return `${sizes[this.sizeKey].input}`;
    },
    labelClass: function() {
      return `${sizes[this.sizeKey].label}`;
    }
  },
  watch: {
  },
  methods: {
    checkValue: function (val) {
      if (val || val == 0) {
        this.inputValue = true
        return val
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: transparent;
  opacity: 0; /* Firefox */
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: transparent;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: transparent;
}
</style>
