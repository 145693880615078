<template>
  <div>
    <div class="grid grid-cols-12 gap-5" id="account-payment--wrapper">
      <div
        class="col-span-12 lg:col-span-6 flex flex-col text-left h-full rounded-md p-5 border"
        id="account-payment--wrapper__subscription"
      >
        <div>
          <h4 class="pb-5">
            {{ $t(`account.payment_entity`, { entity: $t("card") }) }}
          </h4>
          <p class="small">
            {{ $t("card_payment_desc") }}
          </p>
        </div>
        <div v-if="!!creditCard.active" class="mt-5 pl-0">
          <core-credit-card
            :type="creditCard.type"
            :number="creditCard.number"
            :name="$store.state.account.name"
          />
        </div>
        <div class="flex flex-grow-0 mt-5">
          <core-button
            :disabled="!payment || !!creditCard.active"
            type="coolrunner"
            @click="addCard"
            >{{ $t("add_entity", { entity: $t("card").toLowerCase() }) }}
          </core-button>
          <core-button
            class="ml-2"
            :disabled="!payment || !creditCard.active"
            type="coolrunner"
            @click="deleteCard"
            >{{ $t("delete_entity", { entity: $t("card").toLowerCase() }) }}
          </core-button>
        </div>
      </div>
      <div
        class="col-span-12 lg:col-span-6 flex flex-col text-left h-full rounded-md p-5 border"
        id="account-payment--wrapper__balance"
      >
        <div>
          <div>
            <h4 class="mb-5">
              {{ $t("account.payment_entity", { entity: $t("balance") }) }}
            </h4>
            <p class="small">
              {{ $t("saldo_payment_desc") }}
            </p>
          </div>
          <div style="margin-top: 10px; padding-left:0;">
            <core-input
              readonly
              :value="balance"
              :label="$t('balance_amount')"
            />
            <div class="flex mt-5">
              <core-number
                :label="$t('amount')"
                class="mr-2"
                :min="50"
                v-model="refillAmount"
              />
              <core-button type="coolrunner" @click="refillBalance"
                >{{ $t("refill") }}
              </core-button>
            </div>
          </div>
          <div>
            <el-divider content-position="left">{{
              $t("autofill_saldo")
            }}</el-divider>
            <div class="grid grid-cols-12 gap-5">
              <div class="col-span-12 sm:col-span-4">
                <core-number
                  :label="$t('minimum_amount')"
                  style="margin-top: 2px; margin-right: 10px;"
                  :min="50"
                  v-model="autofill.autofill_threshold"
                  :disabled="!creditCard.active || !autofill.autofill"
                />
              </div>
              <div class="col-span-12 sm:col-span-4">
                <core-number
                  :label="$t('amount_to_fill')"
                  style="margin-top: 2px; margin-right: 10px;"
                  :min="50"
                  v-model="autofill.autofill_amount"
                  :disabled="!creditCard.active || !autofill.autofill"
                />
              </div>
              <div class="col-span-12 sm:col-span-4 flex items-center">
                <core-switch
                  :label="$t('enabled')"
                  :disabled="!creditCard.active"
                  v-model="autofill.autofill"
                  :active-value="1"
                  :inactive-value="0"
                />
              </div>

              <div class="col-span-12">
                <core-button
                  type="coolrunner"
                  @click="updateAutofill"
                  :disabled="!creditCard.active"
                  >{{ $t("save") }}
                </core-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-span-12 flex flex-col text-left h-full rounded-md p-5 border"
        id="account-payment--wrapper__balance_history"
      >
        <h4 class="mb-5">{{ $t("balance_history") }}</h4>
        <core-list-new-table
          style="padding: 0px;"
          :items="items"
          :headers="balanceHistoryHeaders"
          :pageSize.sync="options.itemsPerPage"
          :currentPage.sync="options.page"
          :totalItems="totalItems"
          :paginate="true"
          :orderBy.sync="options.orderBy"
          :orderDir.sync="options.orderDir"
          :query.sync="search"
        >
          <template v-slot:column.text="{ item }">
            <el-tooltip
              class="item"
              effect="light"
              :content="item.text"
              placement="top-start"
            >
              <p class="small content">{{ item.text }}</p>
            </el-tooltip>
          </template>
        </core-list-new-table>
      </div>
      <div
        class="col-span-6 flex flex-col text-left h-full rounded-md p-5 border"
        id="account-payment--wrapper__export_balance_history"
      >
        <h4 style="padding-bottom:10px;">
          {{ $t("export") }} {{ $t("balance_history") }}
        </h4>
        <core-date-picker-tailwind
        class="mb-4"
        label="select_date_from"
        @update:modelValue="setDateFrom"/>
        <core-date-picker-tailwind
        class="mb-4"
        label="select_date_to"
        @update:modelValue="setDateTo"/>
        <core-button
          type="coolrunner"
          @click="exportBalance"
          :disabled="!exportDates.dateTo || !exportDates.dateFrom"
          >{{ $t("export") }}
        </core-button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/mixins/Loader";
import List from "@/mixins/List";
import axios from "axios";
import Payment from "@/mixins/Payment";
import { URL_PREFIX } from "../../../utils/constants";

export default {
  mixins: [Loader, List, Payment],
  name: "AccountPayment",
  data: () => ({
    endpoint: "account/payment/balance",
    paymentEndpoint: "account/payment",
    selectable: false,
    cancelToken: null,
    defaultLoaderTarget: "#account-payment--wrapper",
    refillAmount: 50,
    balanceModal: false,
    account: { billing_address: {} },
    loading: false,
    balanceHistoryHeaders: [
      {
        label: "amount",
        key: "amount",
        type: "price"
      },
      {
        label: "description",
        key: "text",
        type: "text"
      },
      {
        label: "date",
        key: "created_at",
        type: "datetime"
      }
    ],
    autofill: {
      autofill_threshold: null,
      autofill_amount: null,
      autofill: false
    },
    exportDates: {
      dateFrom: "",
      dateTo: ""
    },
    menu1: false,
    menu2: false
  }),
  computed: {
    payment: function() {
      return this.$store.state.paymentInfo;
    },
    balance: function() {
      if (this.payment) return `${this.payment.balance.amount.toFixed(2)} DKK`;
      else return "0.00";
    },
    creditCard: function() {
      if (this.payment) return this.payment.card;

      return {};
    }
  },
  watch: {
    params: {
      handler() {
        //   this.getDataFromApi();
      },
      deep: true
    }
  },
  mounted: function() {
    this.account = { ...this.$store.state.account };
    this.token = this.$store.state.auth.access_token;
    this.options.itemsPerPage = 5;
    this.loaderOptions = {
      ...this.loaderOptions,
      target: this.defaultLoaderTarget
    };
    let { amount, ...autofill } = this.payment.balance;
    this.autofill = autofill;
  },
  methods: {
    exportBalance() {
      window.open(
        `${URL_PREFIX}/business-panel/${this.endpoint}/csv?from=${this.exportDates.dateFrom}&to=${this.exportDates.dateTo}&access_token=${this.token}&account_id=${this.$store.state.account_id}`,
        "_blank"
      );
    },
    resetAccount() {
      this.account = { ...this.$store.state.account };
    },
    refresh: function() {
      this.getPaymentDataFromApi();
    },
    deleteCard: function() {
      this.loaderOptions = {
        ...this.loaderOptions,
        target: "#account-payment--wrapper__subscription"
      };
      this.loading = true;
      this.$axios.delete(`${this.paymentEndpoint}/card`).then(response => {
        this.$notification({
          title: this.$t("success"),
          text: this.$t(response.response_data),
          type: "success"
        });
        this.refresh();
      });
    },
    addCard: function() {
      this.loading = true;
      this.$put(`${this.paymentEndpoint}/card`, {
        final_url: this.landing_page.subscribed
      })
        .then(response => {
          let payment_window = this.popup(response.redirect_url);
          this.$on(`payment-${payment_window}:success`, () => {
            this.loading = true;
            this.getPaymentDataFromApi().finally(() => {
              this.loading = false;
            });
          });
        })
        .catch(e => {
          this.$notification({
            title: this.$t("error"),
            text: `${this.$t(e)}`,
            type: "warning"
          });
          this.loading = false;
        });
    },
    updateAutofill: function() {
      this.loading = true;
      this.$put(`${this.paymentEndpoint}/balance/autofill`, this.autofill)
        .then(response => {
          this.$notification({
            title: this.$t("saved_correctly")
          });
          this.getPaymentDataFromApi();
        })
        .catch(e => {
          this.$notification({
            title: this.$t("error"),
            text: `${this.$t(e)}`,
            type: "warning"
          });
        })
        .finally(() => (this.loading = false));
    },
    refillBalance: function() {
      this.loading = true;
      this.$put(`${this.paymentEndpoint}/balance`, {
        amount: this.refillAmount,
        final_url: this.landing_page.order_success
      })
        .then(response => {
          let payment_window = this.popup(response.payment_url);
          this.$on(`payment-${payment_window}:success`, () => {
            this.getDataFromApi();
            this.getPaymentDataFromApi();
          });
        })
        .catch(e => {
          this.$notification({
            title: this.$t("error"),
            text: `${this.$t(e)}`,
            type: "warning"
          });
          this.loading = false;
        });
    },
    getPaymentDataFromApi: function() {
      return this.$store.dispatch("loadPaymentInfo");
    },

    saveAccount() {
      let url = "account";

      this.$put(url, this.account, { useDev: this.useDev }).then(() => {
        this.$notification({
          title: this.$t("success"),
          text: `${this.$t(this.name)} ${this.$t("saved_correctly")}`,
          type: "success"
        });
        this.$store.dispatch("loadAccount");
      });
    },
    getDataFromApi() {
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();

      this.loading = true;

      this.$get(
        `/${this.endpoint}`,
        {
          paginate: this.paginate ? 1 : 0,
          ...this.params,
          cancelToken: this.cancelToken.token
        },
        { mutateLocation: true, useDev: this.useDev }
      )
        .then(response => {
          this.items = response.data;
          this.totalItems = response.total;
          this.loading = false;
        })
        .catch(e => {
          if (e !== "cancelled") this.loading = false;
        });
    },
    setDateTo(val) {
       this.exportDates.dateTo = this.$moment(val).format('YYYY-MM-DD')
    },
    setDateFrom(val) {
       this.exportDates.dateFrom = this.$moment(val).format('YYYY-MM-DD')
    }
  }
};
</script>
