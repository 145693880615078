<template>
  <core-view>
    <div v-if="!canView">
      {{$t('page_restricted')}}
    </div>
    <div v-if="canView">
    <core-view-header :name="name" :count="items.length">
      <template v-slot:description>
        <i18n
          path="page_descriptions.printers"
          tag="div"
          class="small"
          style="white-space: pre-line;text-align: left; margin-top: 1em; max-width: 70%"
        >
          <template v-slot:mac_link>
            <a
              class="underline"
              href="https://assets.coolrunner.dk/printservice/PrintService.dmg"
              >{{ $t("mac") }}</a
            >
          </template>
          <template v-slot:windows_link>
            <a
              class="underline"
              href="https://assets.coolrunner.dk/printservice/PrintService.msi"
              >{{ $t("windows") }}</a
            >
          </template>
        </i18n>
      </template>
    </core-view-header>
    <div
      id="printer-lists-wrapper"
      style="height: 100%; display: flex; flex-direction: column;"
    >
      <div
        class="list-wrapper mb-4 rounded shadow bg-white"
        :class="{ 'pt-4': index !== 0 }"
        v-for="(id, index) in orderByActive"
        :key="id"
      >
        <core-list-new-table
          :items="$_.values(groups[id])"
          :showHeaderActions="index === 0"
          :headers="headers"
          :pageSize.sync="options.itemsPerPage"
          :currentPage.sync="options.page"
          :totalItems="groups[id].length"
          :orderBy.sync="options.orderBy"
          :orderDir.sync="options.orderDir"
          :query.sync="search"
          :selected="selected"
          @checkbox-change="handleSelection"
          @all-selected-change="handleSelection"
          :rowActions="rowActions"
          @row-click="item => editItem(item)"
          :paginate="paginate"
          :entityName="name"
          :emptyListIcon="icon"
        >
          <template slot="list-title" v-if="groups[id].length">
            <div class="print-service__location">
              <el-input
                :value="getLocation(id)"
                :placeholder="getMacAddress(id)"
                @input="val => setPrintServiceLocation(id, val)"
              >
                <template slot="prefix">
                  <font-awesome-icon
                    :icon="['fas', 'map-marker-alt']"
                    fixed-width
                  />
                </template>
              </el-input>
              <core-button
                type="coolrunner"
                @click="savePrintService(id)"
                :disabled="!locationEdited(id)"
              >
                <font-awesome-icon :icon="['fas', 'save']" fixed-width />
              </core-button>
            </div>
          </template>
        </core-list-new-table>
      </div>
      <core-list-empty
        v-if="!loading && !orderByActive.length"
        :emptyListIcon="icon"
        :entityName="name"
      />
    </div>
    <edit-dialog
      v-if="dialog"
      :visible.sync="dialog"
      :entity="printer"
      @updated="
        () => {
          getDataFromApi();
          printer = null;
          dialog = false;
        }
      "
      @close="
        () => {
          printer = null;
          dialog = false;
        }
      "
    />
  </div>
  </core-view>
</template>

<script>
import EditDialog from "./components/EditDialog";
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";
import Loader from "@/mixins/Loader";
import pageAccess from "@/mixins/PageAccess";

export default {
  mixins: [List, Loader, pageAccess],
  components: { EditDialog },
  name: "PrinterList",
  data: () => ({
    defaultLoaderTarget: "#printer-lists-wrapper",

    endpoint: "printers",
    paginate: false,
    dialog: false,
    name: "printer",
    icon: ["fas", "print-slash"],
    headers: [
      {
        label: "alias",
        key: "alias"
      },
      { label: "name", key: "name" },
      {
        label: "default",
        key: "default",
        type: "boolean"
      },
      {
        label: "last_active",
        key: "active_at",
        type: "datetime"
      }
    ],
    options: {
      itemsPerPage: 20,
      page: 1,
      orderBy: "active_at",
      orderDir: "desc"
    },

    printer: null,
    printServices: []
  }),
  mounted: function() {
    this.printServices = this.$_.cloneDeep(this.$store.state.printServices);
    this.getDataFromApi();
  },
  watch: {
    "$store.state.printServices": function(val) {
      this.printServices = this.$_.cloneDeep(val);
    }
  },
  computed: {
    rowActions: function() {
      return [
        {
          label: this.$t("test_printer"),
          icon: ["far", "print"],
          action: p => this.testPrinter(p.alias)
        },
        {
          label: this.$t("delete"),
          icon: ["far", "trash"],
          action: this.deleteItem
        },
        {
          label: this.$t("edit"),
          icon: ["far", "clipboard"],
          action: p => this.showDialog(p)
        }
      ];
    },
    keyedPrintServices: function() {
      return this.$_.keyBy(this.printServices, "id");
    },
    orderByActive: function() {
      return this.$_.map(
        this.$_.uniqBy(
          this.$_.orderBy(this.items, "active_at", "desc"),
          "print_service_id"
        ),
        "print_service_id"
      );
    },
    groups: function() {
      return this.items && this.items.length
        ? this.$_.groupBy(this.items, i => i.print_service_id)
        : { empty: [] };
    }
  },
  methods: {
    showDialog: function(item) {
      this.printer = item;
      this.dialog = true;
    },
    locationEdited: function(key) {
      const index = this.$_.findIndex(this.printServices, [
        "id",
        parseInt(key)
      ]);

      if (index === -1) return false;

      return (
        this.printServices[index].location !==
        this.$store.state.printServices[index].location
      );
    },
    editItem: function(item) {
      this.printer = item;
      this.dialog = true;
    },
    convertUTCToLocal: function(val) {
      return convertUTCToLocal(val, "date");
    },
    getLocation: function(key) {
      const ps = this.keyedPrintServices[key];
      if (!ps) return "";
      return ps.location;
    },
    getMacAddress: function(key) {
      const ps = this.keyedPrintServices[key];
      if (!ps) return "";
      return ps.mac_address;
    },
    setPrintServiceLocation: function(key, value) {
      const ps = [...this.printServices];
      const index = this.$_.findIndex(ps, ["id", parseInt(key)]);
      ps[index].location = value;
      this.printServices = ps;
    },
    savePrintService: function(key) {
      let ps = [...this.printServices];
      const index = this.$_.findIndex(ps, ["id", parseInt(key)]);
      ps = ps[index];

      this.loading = true;
      this.errors = {};
      this.$put(`printservices/${ps.id}`, ps, {
        failSilently: true
      })
        .then(r => {
          this.$emit("updated");
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc("print_service", 1).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
        })
        .then(() => this.$store.dispatch("loadPrintServices"))
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.$notification({
            title: this.$t("error", {
              entity: this.$tc("print_service", 1).toLowerCase()
            }),
            text: this.$_.join(this.$_.map(response.data.errors), "</br>"),
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    testPrinter: function(alias) {
      this.loading = true;
      this.errors = {};
      this.$get(`printers/${alias}/test`)
        .then(r => {
          this.$notification({
            title: this.$t("request send"),
            text: this.$t("print_should_start"),
            type: "success"
          });
        })
        .catch(({ response }) => {
          this.$notification({
            title: this.$t("print_failed"),
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.print-service__location {
  display: flex;
  .el-input {
    font-size: 16px;
    margin-right: 10px;
    .el-input__prefix {
      display: flex;
      align-items: center;
    }
    input {
      height: 100%;
      line-height: 100%;
      border-width: 0;
      border-radius: 0;
      border-bottom-width: 1px;
    }
  }
  .el-button {
    transition: all 250ms;
    &.is-disabled {
      opacity: 0;
    }
  }
}
</style>
