<template>
  <div>
    <core-dialog
      :visible="visible"
      :show-close="true"
      width="60"
      @update:visible="close"
      :title="`${entity.name} ${entity.date}`"
      @close="close">
      <div v-if="entity.type == 'PDF'" class="w-full h-full">
          <iframe :src="entity.url" frameborder="0" class="w-full" height="600px"></iframe>
          <div class="px-5 flex">
          <core-list-cell-templates-receiver-with-flag class="mr-2" v-bind:key="country" v-for=" country in entity.countries"
          :name="country"
          :countryCode="country"
            />
          </div>
      </div>
      <div v-if="entity.type == 'XML'" class="w-full h-full">
          <div>XML-content: <pre>{{ xmlContent }}</pre></div>
          <div class="px-5 flex">
          <core-list-cell-templates-receiver-with-flag class="mr-2" v-bind:key="country" v-for=" country in entity.countries"
          :name="country"
          :countryCode="country"
            />
          </div>
      </div>
      <div v-if="entity.type == 'CSV'" class="w-full overflow-auto">
      <input type="file" @change="onChange" class="w-full" />
      <xlsx-read class="w-full" :file="getFile()">
        <xlsx-table class="w-full overflow-auto" />
      </xlsx-read>
      </div>
      <div v-if="entity.type == 'XLS'" class="w-full overflow-auto">
      <iframe :src="`https://view.officeapps.live.com/op/view.aspx?src=${encode(entity.url)}`" frameborder="0" class="w-full" height="600px">
</iframe>
      </div>
    </core-dialog>
  </div>
</template>
<script>
// mangler at kunne vise xls og xml
import { XlsxRead, XlsxTable } from "vue-xlsx"
export default {
  name: "DocumentViewer",
  components: {
    XlsxRead,
    XlsxTable
  },
  data: () => ({
    file: null,
    xmlContent: null
  }),
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    url: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    countries: {
      type: Array,
      default: null
    }
  },
  methods: {
      close: function () {
      this.$emit("update:visible", false);
      this.showPortals = true
      this.entity.url = ""
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    getXML: function (){
     this.$get(this.entity.url)
     .then(response => {
          this.loading = false;
          this.xmlContent = response.data
        })
    },
    getFile: function() {
           this.$get(this.entity.url, {
            responseType: 'blob'
             })
            .then(r => {

              this.file = new Blob(r, {type: 'application/vnd.ms-excel'});
            })
            .catch(e => {
            })
            .finally(() => {
              this.loading = false;
            });
    },
    encode: function(val) {
      let string = val.toString()
      let code = encodeURIComponent(string)
      return code
    }
  }
};
</script>

<style lang="scss">
.el-dialog__wrapper .el-dialog__body {
    display: block;
    table {
        width: 100%;

        td {
            padding: 5px;
            min-width:100%;
            white-space:nowrap;
            border-bottom: 1px solid #EAEDF2;
        }
    }
}
</style>