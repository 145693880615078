<template>
  <core-dialog
    class="new-shop-dialog"
    :visible="visible"
    @update:visible="$emit('update:visible')"
    append-to-body
    :title="$t('edit')"
    :close-on-click-modal="false"
    :lg_width="40"
    :xl_width="40"
  >
    <div class="wrapper form">
      <component
        class="shop-field"
        v-for="(field, key) in fields"
        :key="key"
        v-model="newShop.data[key]"
        :is="getType('custom')"
        :label="field.title"
        :options="field.options"
        :required="field.required"
        :error="$_.first(errors[`data.${key}`])"
      />
      <checkout-select
        v-model="newShop.data.checkout"
        :label="$t('checkout')"
        class="shop-field w-full max-w-full"
      />
      <core-input
        :value="newShop.data.shop_token"
        readonly
        disabled
        :label="$t('shop_token')"
        @click.native="copyToClipboard"
      />
    </div>

    <template v-slot:footer>
      <core-button type="default" @click="$emit('close')" class="mr-auto"
        >{{ $t("close") }}
      </core-button>

      <core-button
        class="mr-2"
        type="default_outline"
        @click="deleteShop"
        :loading="deleting"
        :disabled="deleting"
        >{{ $t("delete") }}
      </core-button>

      <core-button
        type="coolrunner"
        :loading="saving"
        @click="save"
        :disabled="!pageOk"
        >{{ $t("save") }}
      </core-button>
    </template>
  </core-dialog>
</template>

<script>
import CheckoutSelect from "../CheckoutSelect";
export default {
  name: "ShopEditDialog",
  components: { CheckoutSelect },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    shop: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    errors: [],
    newShop: {},
    saving: false,
    deleting: false
  }),
  watch: {
    "newShop.data.checkout.id": function(val) {
      this.newShop.data.checkout_id = val;
    }
  },
  created: function() {
    this.newShop = { ...this.shop };
  },
  computed: {
    shopTypes: function() {
      return this.$store.state.shopSettings;
    },
    fields: function() {
      return this.shopTypes.custom.fields;
    },
    requiredFields: function() {
      return Object.keys(
        this.$_.pickBy(this.fields, (value, key) => {
          return value.required;
        })
      );
    },
    pageOk: function() {
      return this.$_.every(this.requiredFields, key => {
        let val = this.newShop.data[key];
        if (this.$_.isString(val)) val = val.trim();
        return val;
      });
    }
  },
  methods: {
    copyToClipboard() {
      this.$clipboard(this.newShop.data.shop_token);
      this.$notification({
        title: this.$t("copied"),
        text: this.$t("entity_copied_to_clipboard", {
          entity: this.$t("integration_token")
        }),
        type: "success"
      });
    },

    getType: function(type) {
      switch (type) {
        case "boolean":
        case "bool":
          return "core-switch";
        case "select":
          return "core-select";
        default:
          return "core-input";
      }
    },
    deleteShop: function() {
      this.$confirm(
        this.$t("confirm_delete", { type: this.$t("shop").toLowerCase() }),
        this.$t("delete_entity", {
          entity: this.$t("shop").toLocaleLowerCase()
        })
      ).then(() => {
        this.deleting = true;

        this.$del(`shops/${this.newShop.data.id}`, this.newShop, {
          failSilently: true
        })
          .then(r => {
            this.$emit("shop-updated");
            this.$notification({
              title: this.$t("success"),
              text: `${this.$t("shop")} ${this.$t(
                "has_been_deleted"
              ).toLowerCase()}`,
              type: "success"
            });
          })
          .catch(response => {
            this.$notification({
              title: this.$t("not_deleted"),
              text: `${this.$t("shop")} ${this.$t(
                "has_not_been_deleted"
              ).toLowerCase()}`,
              type: "error"
            });
          })
          .finally(() => {
            this.deleting = true;
          });
      });
    },
    save: function() {
      this.saving = true;
      this.errors = {};
      this.$put(`shops/${this.newShop.data.id}`, this.newShop, {
        failSilently: true
      })
        .then(r => {
          this.$emit("shop-updated");
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$t("shop").toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.$notification({
            title: this.$t("shops.error_updating"),
            text: this.$_.join(this.$_.map(response.data.errors), "</br>"),
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>
