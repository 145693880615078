<template>
  <widget-base
    :style="height"
    class="key-number"
    :title="cTitle"
    :titlePosition="titlePosition"
    :subtitle="sub"
    :shadow="shadow"
    :initialFilters="data.filters"
    :filteringEnabled="filteringEnabled"
    @set-filters="val => (filters = val)"
    @set-cleaned-filters="val => (cleanedFilters = val)"
    :hasDescription="hasDescription"
  >
    <div
      class="w-full h-full value absolute top-0 left-0 flex flex-col items-center justify-center mt-5 pointer-events-none"
    >
      <span class="px-6 md:px-8">{{ value }}</span>
    </div>
  </widget-base>
</template>

<script>
import Widget from "../../mixins/Widget";
import fitty from "fitty";
export default {
  name: "SimpleWidget",
  mixins: [Widget],
  props: {
    height: {
      type: String,
      default: "350px"
    }
  },
  mounted: function() {
    fitty(".value span", { minSize: 34, maxSize: 64, multiLine: false });
  },
  methods: {
    fillData(widgetData) {
      const valueArray = this.$_.get(widgetData, "data.datasets[0].data") || [];
      switch (valueArray.length) {
        case undefined:
        case null:
        case 0:
          this.value = "-";
          break;
        case 1:
          this.value = this.$options.filters.numeralFormat(valueArray[0]);
          break;
        case 2:
          this.value =
            this.$options.filters.numeralFormat(valueArray[1]) +
            "/" +
            this.$options.filters.numeralFormat(valueArray[0]);
          break;
      }
      this.loading = false;
    }
  }
};
</script>
