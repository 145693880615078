<template>
  <core-view>
    <core-view-header :name="$t('my_prices')" :count="totalItems">
      <template v-slot:description>
        <i18n
          path="page_descriptions.prices"
          tag="div"
          class="small"
          style="white-space: pre-line;text-align: left; margin-top: 1em; max-width: 70%"
        >
          <template v-slot:contact_link>
            <a class="underline" href="https://coolrunner.dk/kontakt/">{{
              $t("here").toLowerCase()
            }}</a>
          </template>
        </i18n>
      </template>
    </core-view-header>
    <div
      id="price__wrapper"
      align="left"
      style="padding: 30px; padding-top: 0; width: 100%;"
      class="bg-white relative"
    >
      <div style="display: flex">
        <core-select-country
          v-model="params.country_from"
          :label="$t('sender_country')"
          style="margin-right: 10px"
          @change="getDataFromApiSelect(mode)"
        />

        <core-select-country
          v-model="params.country_to"
          :label="$t('receiver_country')"
          @change="getDataFromApiSelect(mode)"
        />
      </div>
      <div class="absolute right-5 bottom-5 flex flex-wrap">
        <div
          class="w-10 h-10 pt-1 text-2xl pl-2 rounded-l cursor-pointer transition duration-300"
          :class="mode == 'table' ? 'bg-coolrunner-500' : 'bg-gray-300'"
          @click="setMode('table')"
        >
          <font-awesome-icon class="text-white" :icon="['fas', 'table']" />
        </div>
        <div
          class="w-10 h-10 pt-1 pl-2 text-2xl rounded-r cursor-pointer transition duration-300"
          :class="mode == 'list' ? 'bg-coolrunner-500' : 'bg-gray-300'"
          @click="setMode('list')"
        >
          <font-awesome-icon class="text-white" :icon="['far', 'bars']" />
        </div>
      </div>
    </div>
    <transition name="listtotable-animation" mode="out-in">
      <div
        v-if="!loading && prices && mode == 'table'"
        class="overflow-x-scroll rounded-md mt-5"
        @mouseleave="hoverRemove()"
      >
        <transition name="scale">
          <div
            v-if="showPopup"
            :id="selectedCountry + '_' + selectedWeight + '_popup'"
            class="w-full max-w-max bg-white shadow-lg p-5 transition-all duration-300 z-50 rounded-md absolute"
            :style="'top:' + top + 'px;left:' + left + 'px;'"
          >
            <p class="font-semibold text-center mb-2 text-coolrunner-900">
              {{ getCountry(params.country_from) }} - {{ selectedCountry }}:
              {{ selectedPrice.title }}
            </p>
            <div
              v-for="(price, index) in selectedPrice.prices"
              :key="selectedCountry + '_' + selectedPrice.title + '_' + index"
            >
              <p class="text-left mb-2 flex justify-between">
                {{ price.title }}:
                <span class="text-coolrunner-900 text-semibold ml-2">
                  <p v-if="isBusiness">
                    {{ returnPrice(price.price.excl_vat) }}
                    {{ price.price.currency }}
                  </p>
                  <p v-else>
                    {{ returnPrice(price.price.incl_vat) }}
                    {{ price.price.currency }}
                  </p>
                </span>
              </p>
            </div>
          </div>
        </transition>
        <table class="w-full mb-5 shadow">
          <colgroup>
            <col class="bg-white" />
            <col class="bg-white" />
            <col class="bg-[#FCF9F7]" />
            <col class="bg-white" />
            <col class="bg-[#FCF9F7]" />
            <col class="bg-white" />
            <col class="bg-[#FCF9F7]" />
            <col class="bg-white" />
            <col class="bg-[#FCF9F7]" />
            <col class="bg-white" />
            <col class="bg-[#FCF9F7]" />
          </colgroup>
          <tr class="bg-coolrunner-900 text-white h-14 font-light text-sm">
            <th
              class="min-w-[150px] md:min-w-[200px] text-left font-light pl-5 sticky left-0 z-10 bg-coolrunner-900"
            >
              {{ getCountry(params.country_from) }}
            </th>
            <th class="min-w-[150px] md:min-w-[200px] font-light">0-0.5kg</th>
            <th class="min-w-[150px] md:min-w-[200px] font-light">0.5-1kg</th>
            <th class="min-w-[150px] md:min-w-[200px] font-light">1-2kg</th>
            <th class="min-w-[150px] md:min-w-[200px] font-light">2-5kg</th>
            <th class="min-w-[150px] md:min-w-[200px] font-light">5-10kg</th>
            <th class="min-w-[150px] md:min-w-[200px] font-light">10-15kg</th>
            <th class="min-w-[150px] md:min-w-[200px] font-light">15-20kg</th>
          </tr>
          <tr
            v-for="(country, name, index) in prices"
            :key="'country_' + index"
            class="text-center h-14"
          >
            <td
              class="text-left pl-5 border-r border-baige sticky left-0 z-10 bg-white font-bold text-coolrunner-900 text-sm"
            >
              {{ country.title }}
            </td>
            <td
              v-for="price in country.prices"
              :id="country.title + '_' + price.title"
              :key="country.title + '_' + price.title"
              class="border-r border-baige text-sm text-gray-500 text-center relative group cursor-pointer hover:text-black hover:scale-110 transition-all duration-300"
              @mouseover="hoverShow($event, country.title, price.title, price)"
            >
              <div class="w-full text-center">
                <p v-if="isBusiness" class="text-center">
                  {{ returnPrice(price.price.excl_vat) }}
                  {{ price.price.currency }}
                </p>
                <p v-else class="text-center">
                  {{ returnPrice(price.price.incl_vat) }}
                  {{ price.price.currency }}
                </p>
              </div>
            </td>
          </tr>
        </table>

        <div v-if="NoPrices" class="mb-5">
          {{ $t("no_prices") }}
        </div>
      </div>
    </transition>
    <transition name="listtotable-animation" mode="out-in">
      <el-container
        v-if="mode == 'list'"
        id="price__wrapper"
        class="bg-white"
        style="border-bottom-right-radius: 8px; border-bottom-left-radius: 8px;"
      >
        <div
          id="price__wrapper"
          align="left"
          style="min-height: 170px; padding: 30px; padding-top: 0; width: 100%;"
        >
          <el-collapse style="margin-top: 26px">
            <el-collapse-item
              v-for="(country_from, country_from_iso) in pricesList"
              :key="`product-${country_from_iso}`"
              :title="country_from.name"
            >
              <template slot="title">
                <h4>
                  <span
                    :class="
                      `flag-icon flag-icon-${country_from_iso.toLowerCase()}`
                    "
                  />
                  {{ country_from.name }}
                </h4>
              </template>
              <el-collapse>
                <el-collapse-item
                  v-for="(country_to, country_to_iso) in country_from.countries"
                  :key="`product-${country_from_iso}-${country_to_iso}`"
                  style="padding-left: 2em"
                  :title="country_to.name"
                >
                  <template slot="title">
                    <h4>
                      <span
                        :class="
                          `flag-icon flag-icon-${country_to_iso.toLowerCase()}`
                        "
                      />
                      {{ country_to.name }}
                    </h4>
                  </template>

                  <el-collapse>
                    <el-collapse-item
                      v-for="(prices, carrier) in country_to.carriers"
                      :key="
                        `product-${country_from_iso}-${country_to_iso}-${carrier}`
                      "
                      style="padding-left: 2em"
                    >
                      <template slot="title">
                        {{ carrier | carrier($store) }}
                      </template>
                      <el-table :data="prices" style="padding-left: 2em">
                        <el-table-column type="expand">
                          <template slot-scope="scope">
                            <div class="pl-14">
                              <div class="grid grid-cols-5 mb-3" v-for="subproduct in scope.row.sub_products" :key="subproduct.title">
                                <p class=" text-xs col-span-1">{{ subproduct.title }}</p>
                                <p></p>
                                <p></p>
                                <p class=" text-xs col-span-1 pl-2">{{ subproduct.price_excl_tax }} {{ scope.row.currency }}</p>
                                <p class=" text-xs col-span-1 pl-2">{{ subproduct.price_incl_tax }} {{ scope.row.currency }}</p>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="title" :label="$t('title')">
                          <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                              {{ scope.row.carrier_product_service }}
                              <div slot="reference">
                                {{ scope.row.title }}
                              </div>
                            </el-popover>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="fuel_percentage"
                          :label="$t('fuel_percentage')"
                        >
                          <template slot-scope="scope">
                            {{ $n(scope.row.fuel_percentage) }}%
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="fuel_price"
                          :label="$t('fuel_price')"
                        >
                          <template slot-scope="scope">
                            {{ $n(scope.row.fuel_price) }}
                            {{ scope.row.currency }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="sales_price_excl_vat"
                          :label="$t('total_sales_price_excl_vat')"
                        >
                          <template slot-scope="scope">
                            {{ $n(scope.row.sales_price_excl_vat) }}
                            {{ scope.row.currency }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="sales_price_incl_vat"
                          :label="$t('total_sales_price_incl_vat')"
                        >
                          <template slot-scope="scope">
                            {{ $n(scope.row.sales_price_incl_vat) }}
                            {{ scope.row.currency }}
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-container>
    </transition>
  </core-view>
</template>

<script>
import axios from "axios";
import Loader from "@/mixins/Loader";

export default {
  name: "Prices",
  filters: {
    carrier: function(val, store) {
      return (store.state.carriers && store.state.carriers[val]) || val;
    }
  },
  mixins: [Loader],
  data: () => ({
    endpoint: "prices",
    prices: null,
    pricesList: null,
    showPopup: false,
    mode: "table",
    selectedPrice: "",
    selectedCountry: "",
    selectedWeight: "",
    top: 0,
    left: 0,
    params: {
      country_from: null,
      country_to: null
    },
    headers: [
      {
        label: "title",
        key: "title"
      },
      {
        label: "carrier",
        key: "carrier",
        type: "carrier_image"
      },
      {
        label: "price_excl_vat",
        key: "sales_price_excl_vat",
        type: "price"
      }
    ]
  }),
  computed: {
    totalItems() {
      if (this.prices) {
        return Object.keys(this.prices).length;
      } else {
        return 0;
      }
    },
    NoPrices() {
      if (this.prices && this.$_.isArray(this.prices)) {
        return true;
      } else {
        return false;
      }
    },
    isBusiness() {
      return this.$store?.state?.account?.business;
    }
  },
  mounted: function() {
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#price__wrapper"
    };

    this.getDataFromApi();

    if (this.isBusiness) {
      this.setMode("list");

    }
  },
  created: function() {
    const { country_from, country_to } = this.$route.query;
    this.params.country_from = country_from || "DK";
    this.params.country_to = country_to || "DK";
  },
  methods: {
    setMode: function(val) {
      if (val == "list" && this.pricesList == null) {
        this.getDataFromApiList();
        this.mode = val;
      }
      this.mode = val;
    },
    getCountry: function(iso) {
      let countries = this.$store.state.countries;
      let countryName = "";
      countries.forEach(country => {
        if (country.iso == iso) {
          countryName = country.full_name;
        }
      });
      return countryName;
    },
    getDataFromApiSelect: function (mode) {
      if (mode == 'list') {
        this.getDataFromApiList()
      }
      if (mode == 'table') {
        this.getDataFromApi();
      }
    },
    getDataFromApiList: function() {
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();

      this.loading = true;

      this.$get(
        `/${this.endpoint}/${this.params.country_from}/${this.params.country_to}`,
        {
          ...this.params,
          group_by_countries: 1,
          cancelToken: this.cancelToken.token
        },
        { mutateLocation: true, useDev: this.useDev }
      )
        .then(response => {
          this.pricesList = response;
          this.loading = false;
        })
        .catch(e => {
          if (e !== "cancelled") this.loading = false;
        });
    },
    getDataFromApi: function() {
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();

      this.loading = true;
      if (this.params.country_to) {
        this.$axios
          .get(
            `https://api.homerunner.com/frontend/prices/table/${this.params.country_from}/${this.params.country_to}`
          )
          .then(r => {
            this.prices = r.data;
          })
          .catch(e => {
            if (e !== "cancelled") this.loading = false;
          })
          .finally(() => {
            if (this.mode == 'table') {
              this.loading = false;
            }
          });
      } else {
        this.$axios
          .get(
            `https://api.homerunner.com/frontend/prices/table/${this.params.country_from}`
          )
          .then(r => {
            this.prices = r.data;
          })
          .catch(e => {
            if (this.mode == 'table') {
              this.loading = false;
            }
          })
          .finally(() => {
            if (this.mode == 'table') {
              this.loading = false;
            }
          });
      }
    },
    setVariables: function(country, weight, price) {
      this.selectedPrice = price;
      this.selectedCountry = country;
      this.selectedWeight = weight;
      this.showPopup = true;
    },
    hoverShow: async function(event, country, weight, price) {
      let widthParam = window.innerWidth / 2;
      await this.setVariables(country, weight, price);
      if (widthParam < event.pageX) {
        let el = document.getElementById(country + "_" + weight + "_popup");
        let elementWidth = el.clientWidth || 50;
        this.top = event.pageY;
        this.left = event.pageX - elementWidth;
      } else {
        this.top = event.pageY;
        this.left = event.pageX;
      }
    },
    hoverRemove: function() {
      this.showPopup = false;
    },
    closePopup: function(event) {
      this.showPopup = false;
    },
    returnPrice: function(val) {
      if (val || val == 0) {
        return val.toFixed(2);
      } else {
        return "-";
      }
    }
  }
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #033047;
  border-radius: 100vh;
  transition: 300ms ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #324e5c;
  transition: 300ms ease-in-out;
}
</style>
