import { TOOLTIPS, SCALES } from "../components/widgets/options";
import Widget from "./Widget";

let uuid = 0;

export default {
  mixins: [Widget],
  props: {
    small: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    baseID: "",
    uniqueID: "",
    dataCollection: {},
    hiddenData: [],
    options: {}
  }),
  beforeCreate() {
    uuid += 1;
  },
  created() {
    this.uniqueID = this.baseID + "-" + uuid.toString();
  },
  computed: {
    hasData: function() {
      return !this.loading && !!this.data?.data;
    },
    showNoData: function() {
      return (
        !this.loading && this.dataCollection.labels[0] == 'noDataFound'
      );
    },
    differentYAxes: function() {
      const datasets = this.dataCollection?.datasets;
      const temp = datasets
        ? this.$_.uniq(this.$_.map(datasets, ds => ds.yAxisID))
        : ["0"];
      return temp;
    },
    datasetCount: function() {
      return this.differentYAxes?.length || 1;
    }
  },
  methods: {
    getYAxisId: function(ds) {
      return (ds.yAxisID === undefined ? 0 : ds.yAxisID) + ""; //this.differentYAxes.length !== this.data?.data?.datasets.length ? 0+'' : ds.yAxisID
    },
    scalesOptions: function() {
      return SCALES(this.datasetCount);
    },
    tooltipsOptions: TOOLTIPS
  }
};
