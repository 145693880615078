<template>
  <div class="filter__table-row">
    <div class="flex flex-wrap w-full">
      <core-search
        :query="query"
        @update:query="val => $emit('update:query', val)"
        size="small"
      />
      <core-select
        ekstra_class="h-10 border-2 ml-4"
        :bordered="false"
        :full="false"
        value="Vælg status"
        v-if="wmsSelect"
        ref="select"
        :options="list"
        :query="query"
        @change="val => $emit('update:query', val)"
      />
      <core-button
        class="mx-2"
        v-if="supportsFilters"
        @click="showFilter = !showFilter"
        :icon="['far', 'filter']"
      >
        <p class="inline-flex">{{ $t("to_filter") }}</p>
        <core-badge class="ml-2" v-if="numberOfFilters">
          {{ numberOfFilters }}
        </core-badge>
      </core-button>
      <core-button
        v-if="supportsFilters && $_.isEmpty(view) && numberOfFilters"
        @click="showSaveFilter = !showSaveFilter"
        :icon="['far', 'save']"
        type="default"
      >
        <span
          v-if="
            !this.$store.state.breakpoint.isSmall &&
              !this.$store.state.breakpoint.isMedium &&
              !this.$store.state.breakpoint.noMatch
          "
        >
          {{ $t("save_entity", { type: $tc("view").toLowerCase() }) }}
        </span>
      </core-button>

      <div
        class="saved-view__actions ml-2"
        v-else-if="supportsFilters && !$_.isEmpty(view)"
      >
        <el-popover
          placement="bottom-end"
          width="260"
          trigger="click"
          :popper-options="{
            boundariesElement: 'body',
            gpuAcceleration: true,
            positionFixed: true,
            preventOverflow: true
          }"
          :append-to-body="false"
          popper-class="action-popup available-views"
        >
          <core-button
            class="h-full"
            type="default"
            slot="reference"
            :icon="['far', 'filter']"
          >
            <span class="hidden md:block">
              {{ $tc("view") }}
            </span>
          </core-button>

          <el-col class="action-popup__menu">
            <el-row
              class="action-popup__menu-item"
              @click.native="
                () => {
                  dialogType = 'create';
                  showSaveFilter = !showSaveFilter;
                }
              "
            >
              <div class="action-popup__menu-item__wrapper">
                <div>
                  {{
                    $t("create_entity", { entity: $tc("view").toLowerCase() })
                  }}
                </div>
              </div>
            </el-row>
            <el-row
              class="action-popup__menu-item"
              @click.native="
                () => {
                  dialogType = 'update';
                  showSaveFilter = !showSaveFilter;
                }
              "
            >
              <div class="action-popup__menu-item__wrapper">
                <div>
                  {{
                    $t("update_entity", { entity: $tc("view").toLowerCase() })
                  }}
                </div>
              </div>
            </el-row>

            <el-row
              class="action-popup__menu-item"
              @click.native="deleteFilter(view)"
            >
              <div class="action-popup__menu-item__wrapper">
                <div>
                  {{
                    $t("delete_entity", { entity: $tc("view").toLowerCase() })
                  }}
                </div>
              </div>
            </el-row>
          </el-col>
        </el-popover>
      </div>

      <div class="saved-views" v-if="filteredAvailableViews.length !== 0">
        <el-popover
          v-if="supportsFilters && availableViews.length"
          placement="bottom-end"
          width="260"
          trigger="manual"
          v-click-outside="
            () => {
              open = false;
            }
          "
          :value="open"
          :popper-options="{
            boundariesElement: 'body',
            gpuAcceleration: true,
            positionFixed: true,
            preventOverflow: true
          }"
          :append-to-body="false"
          popper-class="action-popup available-views"
        >
          <core-button
            class="h-full"
            slot="reference"
            :icon="['far', 'bookmark']"
            @click="open = !open"
          >
            <span class="hidden md:block">
              {{ $t("saved", { type: $tc("view", 99).toLowerCase() }) }}
            </span>
          </core-button>

          <el-col class="action-popup__menu">
            <core-search
              :query="availableViewsFilter"
              :rounded="false"
              :bordered="false"
              @update:query="val => (availableViewsFilter = val)"
            />
            <el-row
              v-for="av in filteredAvailableViews"
              class="action-popup__menu-item"
              :class="{ 'is-active': view.id === av.id }"
              v-bind:key="av.id"
            >
              <div
                class="action-popup__menu-item__wrapper"
                @click="open = !open"
              >
                <div @click="() => setActiveView(av.id)">
                  {{ $t(av.name) }}
                </div>
              </div>
            </el-row>
            <el-row
              class="action-popup__menu-item"
              v-if="!filteredAvailableViews || !filteredAvailableViews.length"
            >
              <div class="action-popup__menu-item__wrapper">
                <div>
                  {{ $t("no_views_found") }}
                </div>
              </div>
            </el-row>
          </el-col>
        </el-popover>
      </div>
      <slot name="actions" />
    </div>

    <core-dialog
      :title="
        this.$t(`${dialogType}_entity`, {
          entity: $tc('view').toLowerCase()
        })
      "
      :visible.sync="showSaveFilter"
      :xs_width="90"
      :lg_width="30"
    >
      <div class="form wrapper">
        <div class="description" v-if="dialogType === 'create'">
          {{ $t("save_view_description") }}
        </div>
        <core-input :label="$t('name')" v-model="viewName" />
      </div>
      <span slot="footer" class="dialog-footer flex">
        <core-button
          class="mr-auto"
          type="default_outline"
          @click="showSaveFilter = false"
          >{{ $t("cancel") }}</core-button
        >
        <core-button
          type="coolrunner_outline"
          v-if="dialogType === 'update'"
          :disabled="!viewName.length"
          @click="saveFilter(true)"
          :loading="saving"
          >{{ $t("update") }}</core-button
        >
        <core-button
          type="coolrunner"
          v-else
          :disabled="!viewName.length"
          :loading="saving"
          @click="!disabled && saveFilter()"
          >{{
            $t("save_new_entity", { type: $tc("view").toLowerCase() })
          }}</core-button
        >
      </span>
    </core-dialog>
    <core-filter-modal
      :visible="showFilter"
      @confirm="confirmFilters"
      @update-filter="updateFilter"
      @remove-filter="removeFilter"
      :filters="filters"
      :showIntervalSelector="false"
      :numberOfFilters="numberOfFilters"
      :viewType="viewType"
    />
  </div>
</template>
<script>
import Filter from "@/mixins/Filter";
export default {
  mixins: [Filter],
  data: () => ({
    dialogType: "create",
    open: false
  }),
  props: {
    wmsSelect: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    viewType: {
      type: String,
      default: "shipments"
    }
  }
};
</script>
