<template>
  <core-view>
    <core-view-header name="insight">
      <template v-slot:description>
        <el-col>
          <el-tabs :value="activeTab" @tab-click="tabClick" v-model="activeTab">
            <el-tab-pane
              v-for="(page, key) in pages"
              :name="key"
              :label="getLabel(page)"
              v-bind:key="key"
            />
          </el-tabs>
        </el-col>
        <div
          class="text-left">
          {{description}}
        </div>
      </template>
    </core-view-header>

    <dashboard v-bind:page="activeTab"
    v-on:update-description="updateDescription" />
  </core-view>
</template>
<script>
import { checkServiceAccess } from "@/utils/tools";
import dashboard from "./components/Dashboard.vue";
export default {
  components: {
    dashboard
  },
  beforeRouteEnter(to, from, next) {
    checkServiceAccess("insights", from, next);
  },
  data: vm => ({
    activeTab: vm.$_.first(Object.keys(vm.$store.state.account.insight_pages)),
    description: ""
  }),
  mounted: function() {
    this.$router
      .push({
        query: { page: this.$_.first(Object.keys(this.pages)) }
      })
      .catch(err => {});
  },
  computed: {
    pages: function() {
      let pages = this.$store.state.account.insight_pages;
      return pages;
    }
  },
  methods: {
    tabClick: function(val) {
      this.$router.push({
        query: { page: val.name }
      });
    },
    getLabel(page) {
      return page.title[this.$i18n.locale];
    },
    updateDescription: function (val) {
      this.description = val
    }
  }
};
</script>
