<template>
  <core-view :style="{ '--delay': delay }">
    <div v-if="!canView">
      {{$t('page_restricted')}}
    </div>
    <div v-if="canView">
    <core-view-header
      :name="'claims'"
      :count="totalItems"
      :description="'page_descriptions.claims'"
      :routeName="$route.name"
      route="claims"
      showViewName
    >
    </core-view-header>
    <div class="bg-white pb-6 pl-8 rounded-b flex flex-grow-0 gap-2">
      <core-button v-if="canEdit" @click="modals.createClaim.visible = true" type="coolrunner">
        {{ $t("create_claim") }}
      </core-button>
      <core-button v-if="filtersSet" @click="removeDashFilters()" type="coolrunner">
        {{ $t("remove") }} {{ $tc("filter", 1).toLowerCase() }}
      </core-button>
    </div>
    <transition name="widget-animation" mode="out-in">
      <div class="bg-white pb-6 pl-8 rounded-b md:flex" v-if="showcsv">
        <core-date-picker-tailwind
          class="mb-4 mr-2"
          label="select_date_from"
          :modelValue="$moment(new Date()).add(-7, 'days')._d"
          @update:modelValue="setDateFrom"
        />
        <core-date-picker-tailwind
          class="mb-4 mr-2"
          label="select_date_to"
          @update:modelValue="setDateTo"
        />
        <core-select
          class="mt-5 mr-2 w-full items-stretch max-w-xs"
          :options="csv_statuses"
          v-model="csv.status"
          :multiple="true"
          :label="
            $t('select_entity', { entity: $tc('status', 99).toLowerCase() })
          "
          labelKey="label"
          valueKey="key"
          style="width: 100%"
          @input="val => (this.csv.status = val)"
        />
        <core-select
          class="mt-5 w-full items-stretch max-w-[200px]"
          :options="file_types"
          v-model="csv.file_type"
          :label="
            $t('select_entity', { entity: $tc('type', 99).toLowerCase() })
          "
          labelKey="label"
          valueKey="key"
          style="width: 100%"
          @input="val => (this.csv.file_type = val)"
        />
        <core-button
          class="ml-2 mt-5 h-[55px]"
          @click="exportCSV()"
          type="coolrunner"
        >
          {{ $t("download") }}
        </core-button>
      </div>
    </transition>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6 mb-6">
      <div
        :class="['col-span-1 hover:cursor-pointer']"
        v-bind:key="'static_' + index"
        v-for="(widget, index) in widgets"
        @click="setFilterFromWidget(widget)"
      >
        <transition appear name="widget-animation" mode="out-in">
          <component
            :localData="true"
            :is="getChartComponent(widget)"
            titlePosition="lower"
            :subtitle="getWidgetTitle(widget.key)"
            :data="widget.data"
            class="default"
            :filteringEnabled="false"
            :style="{ '--i': index, '--length': 4, height: '200px' }"
            :hasDescription="widget.description"
          />
        </transition>
      </div>
    </div>
    <transition appear name="widget-animation" mode="out-in">
      <core-list-new-table
        :selection="false"
        :items="items"
        :headers="headers"
        :viewType="viewType"
        :loading="loading"
        :orderBy.sync="options.orderBy"
        :orderDir.sync="options.orderDir"
        :currentPage.sync="options.page"
        :query.sync="search"
        :show-create-new="false"
        :totalItems="totalItems"
        @set-cleaned-filters="val => $emit('set-cleaned-filters', val)"
        @set-filters="val => $emit('set-filters', val)"
        :entityName="name"
        :pageSize="options.itemsPerPage"
        pushPath="labels_details"
      >
        <template v-slot:[`column.status`]="{ item }">
          <div
              v-if="item.new_message"
              class="absolute -top-0.5 right-0 text-coolrunner-500 text-base"
            >
              <font-awesome-icon :icon="['far', 'comment']" class="mr-2" />
            </div>
          <div class="relative">
            {{ getStatus(item.status) }}
          </div>
        </template>
        <template v-slot:[`column.carrier`]="{ item }">
          <core-list-cell-templates-carrier-with-image
            :carrier="item.carrier"
            :url="URL_PREFIX"
          />
        </template>
        <template v-slot:[`column.package_number`]="{ item }">
          <div
            style="cursor: copy"
            @click.stop="$clipboard(item.package_number)"
          >
            {{ item.package_number }}
          </div>
        </template>
        <template v-slot:[`column.reference`]="{ item }">
          <div style="cursor: copy" @click.stop="$clipboard(item.reference)">
            {{ item.reference }}
          </div>
        </template>
      </core-list-new-table>
    </transition>
    <core-create-claim
      :visible.sync="modals.createClaim.visible"
      :shipment="modals.createClaim.shipment"
    />
    <iframe id="iframe" class="iframe hidden" :src="download_link" />
    </div>
  </core-view>
</template>

<script>
import pageAccess from "@/mixins/PageAccess";
import ESList from "@/mixins/ESList";
import { URL_PREFIX, API_PREFIX } from "@/utils/constants";
import EventBus from "@/plugins/eventbus";
import router from '../../router';
export default {
  mixins: [ESList,pageAccess],
  data: () => ({
    viewType: "ticket",
    endpoint: "ticket",
    exportType: "label",
    name: "claim",
    icon: ["fal", "box-alt"],

    showcsv: false,
    loading: true,
    showTypes: false,
    search: "",
    stats: {},
    totalItems: 0,
    pageData: {},
    files: [],
    types: [],
    currentPage: 1,
    limit: 50,
    status: "",
    download_link: "",
    filtersSet: false,
    csv: {
      dateFrom: null,
      dateTo: null,
      status: null,
      file_type: null
    },
    modals: {
      createClaim: {
        visible: false
      }
    },
    file_types: [
      {
        label: "CSV",
        key: "csv"
      },
      {
        label: "XLS",
        key: "xls"
      },
      {
        label: "XLSX",
        key: "xlsx"
      }
    ],
    headers: [
      {
        label: "created_at",
        key: "created_at",
        type: "date"
      },
      {
        label: "package_number",
        key: "package_number",
        orderable: false
      },
      {
        label: "reference",
        key: "reference",
        orderable: false
      },
      {
        label: "carrier",
        key: "carrier",
        orderable: false
      },
      {
        label: "updated_at",
        key: "updated_at",
        type: "date"
      },
      {
        label: "status",
        key: "status",
        orderable: false
      }
    ]
  }),
  watch: {
    itemsLoaded: function(val) {
      if (val && this.$route.query.state == 'intro') {
        this.items = [{
          id: '1234test',
          created_at: '2023-03-20T08:02:33.000000Z',
          updated_at: '2023-03-20T08:02:33.000000Z',
          receiver: {
            name: 'Lars Test',
            country: 'DK'
          },
          carrier: 'bring',
          latest_tracking_event: {
            title: 'IN_TRANSIT',
            event_code: 'IN_TRANSIT',
            event_sub_code: 'IN_TRANSIT'
          },
          new_note_by_agent: true,
          labelless_code: '123-456-789',
          package_number: '0002586845',
          reference: 'Ordre: 123585',
          status:"rejected",
          order_price: {
            excl_tax: '20',
            incl_tax: '25'
          }
        }]
        this.totalItems = 1
      }
      if (val && this.$route.query.state == 'createClaim') {
        this.modals.createClaim.visible = true
        this.items = [{
          id: '1234test',
          created_at: '2023-03-20T08:02:33.000000Z',
          updated_at: '2023-03-20T08:02:33.000000Z',
          receiver: {
            name: 'Lars Test',
            country: 'DK'
          },
          carrier: 'bring',
          latest_tracking_event: {
            title: 'IN_TRANSIT',
            event_code: 'IN_TRANSIT',
            event_sub_code: 'IN_TRANSIT'
          },
          new_note_by_agent: true,
          labelless_code: '123-456-789',
          package_number: '0002586845',
          reference: 'Ordre: 123585',
          status:"rejected",
          order_price: {
            excl_tax: '20',
            incl_tax: '25'
          }
        }]
        this.totalItems = 1
      }
    },
  },
  computed: {

    URL_PREFIX: () => URL_PREFIX,
    computedfiles: function() {
      if (this.files.length == 0) {
        return this.files;
      } else return this.files;
    },
    csv_statuses: function () {
      const objArray = [];
      if (Object.keys(this.$store.state.claim_statuses).length !== 0) {
        Object.keys(this.$store.state.claim_statuses).forEach(key =>
          objArray.push({
            key: key,
            label: this.$store.state.claim_statuses[key]
          })
        );
      }
      return objArray;
    },
    widgets: function() {
      let widgets = [];
      let self = this;
      if (this.stats.length !== 0) {
        this.$_.forEach(this.stats, function(widget, key) {
        let obj = {
          subtitle: self.$t(key),
          type: "simple",
          data: { data: { datasets: [{ data: [widget] }] } },
          render_as: "simple",
          key: key
        };
        widgets.push(obj);
      });
      }
      return widgets;
    },
    delay: function() {
      return this.items.length * 0.03;
    }
  },
  mounted() {
    this.getClaims();
    let datefrom = this.$moment(new Date()).add(-7, "days")._d;
    this.csv.dateFrom = this.$moment(datefrom).format("YYYY-MM-DD");
    this.csv.dateTo = this.$moment(new Date()).format("YYYY-MM-DD");
    this.pageName = 'claims'
  },
  methods: {
    removeDashFilters: function () {
        this.$router.push('/claims')
        this.filtersSet = false
        this.$nextTick(() => this.init() );
    },
    setDateTo(val) {
      this.csv.dateTo = this.$moment(val).format("YYYY-MM-DD");
    },
    setDateFrom(val) {
      this.csv.dateFrom = this.$moment(val).format("YYYY-MM-DD");
    },
    getWidgetTitle: function (key) {
      if (key == 'awaiting_customer') {
        return this.$t('awaiting_entity', {entity: this.$store.state.account.business ? this.$store.state.account.company_name : this.$store.state.account.name})
      } else {
        return this.$t(key)
      }
    },
    getChartComponent: function(widget) {
      const type = widget.render_as || widget.meta.chart_type;
      if (type && type !== "simple") return `widget-${type}-chart`;

      return "widget-simple";
    },
    getClaims: function() {
      this.$get(`ticket/stats`, {
        failSilently: true
      })
        .then(r => {
          this.stats = r;
        })
        .catch(e => {})
        .finally(() => {});
    },
    getStatus(key) {
      let statuses = this.$store.state.claim_statuses;
      if (key == 'awaiting_customer') return this.$t('awaiting_entity', {entity: this.$store.state.account.business ? this.$store.state.account.company_name : this.$store.state.account.name})
      return this.$_.get(statuses, [key]);
    },
    exportCSV: function() {
      this.download_link = `${URL_PREFIX}/business-panel/claims/export?from=${this.csv.dateFrom}&to=${this.csv.dateTo}&status=${this.csv.status}&format=${this.csv.file_type}&access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`;
    },
    setFilterFromWidget: function(filter) {
      let filters = window.location.search;
      if (filter.key == 'new_message_from_agent') {
        this.$router.push(`/claims` + filters + '&filters%5Bnew_message_from_agent%5D%5B0%5D%5Bvalue%5D=true');
        this.filtersSet = true
      } else if (filter.key == 'awaiting_customer') {
        this.$router.push(`/claims` + filters + '&filters%5Bstatus%5D%5B0%5D%5Bvalue%5D%5B0%5D=5');
        this.filtersSet = true
      } else if (filter.key == 'in_progress') {
        this.$router.push(`/claims` + filters + '&filters%5Bstatus%5D%5B0%5D%5Bvalue%5D%5B0%5D=2');
        this.filtersSet = true
      }
      this.$nextTick(() => this.init() );
    },
    clearFilters: function() {
      this.setFilterFromWidget({ filter: {} });
    }
  }
};
</script>

<style scoped>
.tracking-button:hover ~ .tracking-modal {
  opacity: 1;
  z-index: 50;
}
</style>
