var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"rounded-md p-3 py-2.5 font-medium text-sm flex flex-grow-0 items-center justify-center hover:shadow-md whitespace-nowrap focus:border-none disabled:cursor-default disabled:shadow-none disabled:bg-btn-grey-disabled disabled:text-disabled disabled:border-0 transition duration-300 ease-in-out h-[42px]",class:_vm.buttonClass,attrs:{"disabled":_vm.isDiabled},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.icon && !_vm.loading)?_c('font-awesome-icon',{staticClass:"font-medium text-base",attrs:{"icon":_vm.icon,"fixed-width":""}}):_vm._e(),_c('div',{class:[
      {
        'ml-1': !!_vm.icon && _vm.$slots.default,
        'mr-1': !!_vm.suffixIcon && _vm.$slots.default,
      },
      ..._vm.iconClass,
    ]},[(_vm.loading)?[_c('font-awesome-icon',{attrs:{"icon":['far', 'sync-alt'],"fixed-width":"","spin":""}})]:[_vm._t("default")]],2),(_vm.suffixIcon && !_vm.loading)?_c('font-awesome-icon',{staticClass:"font-medium text-base",attrs:{"icon":_vm.suffixIcon,"fixed-width":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }