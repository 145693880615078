<template>
  <div>
    <input
      class="hidden"
      ref="uploader"
      type="file"
      @change="setFile"
      accept="image/*"
    />
    <el-image
      :src="url"
      :fit="'fill'"
      ondragstart="return false"
      class="w-[70%]"
    >
      <div slot="error" class="image-slot">
        <div class="fa-3x">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
        </div>
      </div>

      <div slot="placeholder" class="image-slot">
        <div class="fa-3x">
          <font-awesome-icon
            :icon="['fad', 'spinner-third']"
            spin
            style="color:#409EFF;opacity: 0.4;"
          />
        </div>
      </div>
    </el-image>
    <div class="flex actions">
      <core-button
        v-if="value"
        class="w-full mr-2"
        type="delete_outline"
        @click="clear"
        >{{ $t("clear") }}</core-button
      >

      <core-button
        class="w-full"
        type="coolrunner_outline"
        @click="$refs.uploader.click()"
        >{{ $t("select") }}</core-button
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "ImageUploader",
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [File, String],
      default: null
    }
  },
  data: () => ({
    inputvalue: null,
    fileUrl: null
  }),
  computed: {
    url: function() {
      return this.value
        ? _.isString(this.value)
          ? this.value
          : URL.createObjectURL(this.value)
        : this.placeholder;
    }
  },
  methods: {
    clear() {
      this.$emit("update:value", { file: null });
    },
    setFile(event) {
      const file = event.target.files[0];
      this.$emit("update:value", { file });
    }
  }
};
</script>

<style scoped>
.actions {
  margin: 10px;
}
button {
  height: 35px;
  background: transparent;
}
</style>
