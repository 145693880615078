<template>
  <core-dialog
    id="add-gateway-dialog"
    :visible="visible"
    @update:visible="close"
    :title="$t('edit_entity', { entity: $tc('gateway', 1).toLowerCase() })"
    :md_width="90"
    :lg_width="90"
    :xl_width="90"
    @close="close"
  >
    <div
      class="wrapper form"
      style="display: flex; flex-direction: column; width: 100%; min-height: 180px;"
    >
      <row>
        <core-text v-model="gw.email" type="email" :label="$t('email')" />
        <core-text
          style="margin-top: 20px"
          v-model="gw.password"
          type="password"
          :label="$t('password')"
        />
      </row>
    </div>
    <template slot="footer" class="dialog-footer flex justify-between">
      <core-button type="default_outline" @click="close">{{
        $t("cancel")
      }}</core-button>

      <core-button type="coolrunner" :loading="loading" @click="save">{{
        $t("save")
      }}</core-button>
    </template>
  </core-dialog>
</template>

<script>
import Loader from "@/mixins/Loader";

export default {
  mixins: [Loader],
  name: "LightGatewayDialog",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    gateway: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    loaderOptions: {
      lock: true,
      fullscreen: false,
      text: "loading",
      target: "#add-gateway-dialog .wrapper",
      customClass: null
    },
    endpoint: "casambi/gateways",
    cancelToken: null,
    loading: false,
    name: "gateway",
    gw: {}
  }),
  mounted: function() {
    this.gw = { ...this.gateway };
  },
  methods: {
    save: function() {
      this.loading = true;
      let httpMethod = this.$post;
      if (this.gw.id) {
        httpMethod = this.$put;
      }
      let url = `${this.endpoint}`;

      if (this.gw.id) {
        url += `/${this.gw.id}`;
      }

      httpMethod(url, this.gw, { failSilently: true })
        .then(r => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
          this.$emit("saved");
          this.close();
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.$notification({
            title: this.gw.id
              ? this.$t("error_updating_entity", {
                  entity: this.$tc(this.name, 1).toLowerCase()
                })
              : this.$t("not_saved"),
            text: this.$_.join(this.$_.map(response.data.errors), "</br>"),
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close: function() {
      this.gw = {};
      this.$emit("update:visible");
    }
  }
};
</script>

<style lang="scss">
.unit-row {
  display: flex;
  height: 60px;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  cursor: pointer;
  &:nth-of-type(odd) {
    background-color: #f7f8fa;
  }
  &.active {
    background-color: #157efb33;
  }
}
</style>
