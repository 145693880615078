<template>
  <el-dialog
    class="core-dialog"
    customClass=""
    v-bind="$attrs"
    v-on="$listeners"
    :width="`${modalWidth}%`"
    :show-close="showClose"
    :title="title"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </el-dialog>
</template>

<script>
export default {
  name: "CoreDialog",
  inheritAttrs: false,
  data: () => ({
    modalWidth: null,
    default_breakpoints: {
      xs_width: 90,
      sm_width: 90,
      md_width: 50,
      lg_width: 30,
      xl_width: 30
    }
  }),
  props: {
    use_default_breakpoints: {
      type: Boolean,
      default: true
    },
    width: {
      type: [Number, String],
      default: null
    },
    xs_width: {
      type: Number,
      default: null
    },
    sm_width: {
      type: Number,
      default: null
    },
    md_width: {
      type: Number,
      default: null
    },
    lg_width: {
      type: Number,
      default: null
    },
    xl_width: {
      type: Number,
      default: null
    },
    showClose: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    }
  },
  computed: {},
  created() {
    if (!this.width) {
      window.addEventListener("resize", this.resize, { passive: true });
      this.resize();
    } else {
      this.modalWidth = this.width;
    }
  },
  methods: {
    getWidth(breakpoint_key) {
      if (this.width) return this.width;
      else if (!this.$props[breakpoint_key] || this.use_default_breakpoints)
        return this.default_breakpoints[breakpoint_key];
      else {
        let width = null;
        let loop = true;
        Object.keys(this.default_breakpoints).forEach(key => {
          if (loop) {
            if (this.$props[key]) width = this.$props[key];
            if (key === breakpoint_key) loop = false;
          }
        });
        return width;
      }
    },
    resize() {
      if (window.innerWidth < this.$vuetify.breakpoint.thresholds.xs)
        this.modalWidth = this.getWidth("xs_width");
      else if (window.innerWidth < this.$vuetify.breakpoint.thresholds.sm)
        this.modalWidth = this.getWidth("sm_width");
      else if (window.innerWidth < this.$vuetify.breakpoint.thresholds.md)
        this.modalWidth = this.getWidth("md_width");
      else if (window.innerWidth < this.$vuetify.breakpoint.thresholds.lg)
        this.modalWidth = this.getWidth("lg_width");
      else this.modalWidth = this.getWidth("xl_width");
    }
  }
};
</script>

<style>
.v-modal {
  z-index: 100 !important;
}
</style>
