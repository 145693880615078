export function editScrollbarOnWindows() {
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

  if (
    windowsPlatforms.indexOf(
      window.navigator?.userAgentData?.platform || window.navigator.platform
    ) !== -1
  ) {
    const styles = `
        /* firefox */
        :root {

            scrollbar-color: #7f8c8d #ecf0f1 !important;
            scrollbar-width: thin !important;
        }

        /* Chromium */
        ::-webkit-scrollbar {
            width: 8px;
            border: 4px solid white;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #7f8c8d;
            background-clip: padding-box;
            border: 0.05em solid #eeeeee;
            border-radius: 16px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #95a5a6;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-track {
            background-color: #ecf0f1;
        }
    `;

    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }
}
