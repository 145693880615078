var render = function render(){var _vm=this,_c=_vm._self._c;return _c('core-dialog',{attrs:{"id":"refill-dialog","visible":_vm.visible,"title":_vm.$t('refill_balance'),"md_width":90,"lg_width":90,"xl_width":90},on:{"update:visible":_vm.close,"close":_vm.close}},[_c('div',{staticClass:"wrapper form",staticStyle:{"display":"flex","flex-direction":"column","width":"100%","min-height":"180px","text-align":"left"}},[_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(
        _vm.$t('fill_balance_dialog', {
          current: _vm.$n(_vm.balance, 'currency', 'da-DK'),
          required: _vm.$n(_vm.requiredBalance, 'currency', 'da-DK'),
          minimum: _vm.$n(50, 'currency', 'da-DK')
        })
      )}}),_c('div',{staticClass:"dialog-footer flex w-100",attrs:{"slot":"footer"},slot:"footer"},[_c('core-button',{staticClass:"mr-auto",attrs:{"outline":"","type":"default_outline"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('core-number',{staticClass:"max-w-100 mx-2 ml-auto",staticStyle:{"margin":"0 10px"},attrs:{"size":"small","label":_vm.$t('amount'),"min":50},model:{value:(_vm.refillAmount),callback:function ($$v) {_vm.refillAmount=$$v},expression:"refillAmount"}}),_c('core-button',{attrs:{"type":"coolrunner","loading":_vm.loading},on:{"click":_vm.refillBalance}},[_vm._v(_vm._s(_vm.$t("refill")))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }