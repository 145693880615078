<template>
  <div class="flex items-center justify-end w-full">
    <label :for="id" class="cursor-pointer">
      <div class="relative">
        <input
          type="checkbox"
          @click="$emit('enabled', $event.target.checked)"
          :id="id"
          class="sr-only peer"
          :checked="enabled"
        />
        <div class="bg-gray-200 w-12 h-7 rounded-full transition duration-300 shadow-inner peer-checked:bg-coolrunner-500"></div>
        <div
          class="
            absolute
            left-1
            top-1
            bg-white
            w-5
            h-5
            rounded-full
            transition
            duration-300
            ease-in-out
            peer-checked:translate-x-full
          "
        ></div>
      </div>
    </label>
  </div>
</template>

<script>
import _ from "lodash";
export default {
    name: 'Toggle',
    props: {
      enabled: {
        type: Boolean,
        default: true
      }
    },
    computed: {
    id: function() {
      return this.$_.uniqueId();
    }
  },
}
</script>