<template>
  <core-view>
    <core-view-header
      :name="name"
      style="padding-bottom: 0;"
      :backTo="{
        name: 'tools_smartcheckout_checkout',
        params: { smartcheckout_id: checkoutId }
      }"
      @back="loading = true"
    />

    <el-container class="bg-white account-container">
      <div
        class="smartcheckout_shippingmethod__form"
        style="margin-top: 10px; width: 100%; justify-content: left"
      >
        <div class="form" v-if="shippingMethod">
          <div class="grid-container" style="margin: -12px -12px;">
            <row :columns="24" :gutter="10" style="">
              <column :xs="16">
                <core-text :label="$t('name')" v-model="shippingMethod.name" />
                <core-select-cps
                  v-model="shippingMethod.carrier_product_service"
                  :cps="true"
                />
                <div v-if="!describedRequirements.address_validation" class="text-left mt-2">
                  <core-checkbox
                    v-model="shippingMethod.address_validation"
                    :value="!!shippingMethod.address_validation"
                    :label="$t('requires_address_validation')"
                  />
                </div>
                <core-input
                  style="margin-top: 10px;"
                  v-model="shippingMethod.label"
                  :label="$tc('label')"
                  :helperText="$t('smartcheckout_input_description.label_text')"
                />
                <core-textarea
                  style="margin-top: 10px;"
                  :label="$t('description')"
                  v-model="shippingMethod.description"
                  :helperText="
                    $t('smartcheckout_input_description.description')
                  "
                />
              </column>
              <column :xs="8">
                <div class="image-wrapper border">
                  <image-uploader
                    :placeholder="carrierImageUrl"
                    :value="shippingMethod.logo"
                    @update:value="val => (shippingMethod.logo = val.file)"
                  />
                </div>
              </column>
            </row>
          </div>
          <div class="rules-list options" v-if="supportsOptions">
            <h3 class="mb-3 mt-5">{{ $tc("option", 99) }}</h3>
            <div v-if="describedOptions" class="rule-wrapper">
              <div class="filter">
                <core-filter-modal-type-active
                  :filters="options"
                  :view="describedOptions"
                  :optionsMode="true"
                  :clearable="false"
                  v-if="!$_.isEmpty(options)"
                />

                <core-button
                  class="ml-auto"
                  type="coolrunner"
                  @click="
                    () => {
                      showOptions = true;
                    }
                  "
                  >{{ $t("edit") }}</core-button
                >
              </div>
            </div>
          </div>
          <div class="rules-list">
            <h3 style="margin-bottom: 10px; margin-top: 14px;">
              {{ $t("set_of_rules") }}
            </h3>
            <div
              class="rule-wrapper"
              v-for="(rule, index) in shippingMethod.rules"
              :key="index"
            >
              <p>{{ $t("set_of_rules_no", { number: index + 1 }) }}</p>
              <row :columns="24" :gutter="30">
                <div class="rule-form-wrapper">
                  <column :xs="24" :sm="12">
                    <core-number
                      required
                      :label="$t('sales_price')"
                      v-model="rule.price"
                    />
                  </column>
                  <column :xs="24" :sm="12">
                    <core-number
                      required
                      :label="$t('priority')"
                      :min="1"
                      step="1"
                      v-model="rule.priority"
                    />
                  </column>
                  <column :xs="24">
                  <core-textarea
                      style="margin-top: 10px;"
                      :label="$t('description')"
                      v-model="rule.description"
                      />
                  </column>
                </div>
                <div class="filter">
                  <div v-if="!$_.isEmpty(rule.filters)">
                    <p>{{ $t("shown_if") }}:</p>
                    <core-filter-modal-type-active
                      :filters="rule.filters"
                      :view="describedFilters"
                      :clearable="false"
                    />
                  </div>
                  <div class="flex justify-end">
                    <core-button
                      type="default_outline"
                      class="mr-2"
                      @click="deleteRule(index)"
                      >{{
                        $t("delete_entity", {
                          entity: $t("set_of_rules").toLowerCase()
                        })
                      }}</core-button
                    >
                    <core-button
                      type="coolrunner"
                      v-if="$_.isEmpty(rule.filters)"
                      @click="
                        () => {
                          ruleIndex = index;
                          showFilter = true;
                        }
                      "
                      >{{
                        $t("add_entity", {
                          entity: $tc("filter", 1).toLowerCase()
                        })
                      }}</core-button
                    >
                    <core-button
                      type="coolrunner"
                      v-else
                      @click="
                        () => {
                          ruleIndex = index;
                          showFilter = true;
                        }
                      "
                      >{{ $t("edit") }}</core-button
                    >
                  </div>
                </div>
              </row>
            </div>
            <core-list-empty
              v-if="$_.isEmpty(shippingMethod.rules)"
              :emptyListIcon="['fad', 'align-slash']"
              entityName="set_of_rules"
            />
          </div>

          <div class="flex">
            <div class="mr-2">
              <core-button @click="addRule">{{
                $t("add_entity", { entity: $t("set_of_rules").toLowerCase() })
              }}</core-button>
            </div>
            <div>
              <core-button type="coolrunner" @click="save" :loading="saving">{{
                $t("save")
              }}</core-button>
            </div>
          </div>
        </div>
      </div>
    </el-container>
    <core-filter-modal
      v-if="showFilter"
      key="filters"
      :viewType="describeKey"
      :visible="showFilter"
      :filters="filters"
      @confirm="confirmFilters"
      @update-filter="updateFilter"
      @remove-filter="removeFilter"
      :numberOfFilters="numberOfFilters"
    />
    <core-filter-modal
      v-if="showOptions"
      key="options"
      :title="$tc('option', 99)"
      :viewType="optionsDescribeKey"
      :visible="showOptions"
      :filters="options"
      @confirm="confirmOptions"
      @update-filter="updateOption"
      @remove-filter="removeOption"
      :numberOfFilters="numberOfOptions"
      :optionsMode="true"
    />
  </core-view>
</template>

<script>
import Filter from "@/mixins/Filter";
import { set } from "lodash/fp";
import data from "@/plugins/data";
import Loader from "@/mixins/Loader";
import { getCarrierImageUrl } from "../../../utils/tools";
import ImageUploader from "./components/ImageUploader.vue";
import FormData from "../../../mixins/FormData";
import store from "@/store";
import Options from "./Options";

export default {
  beforeRouteEnter(to, from, next) {
    if (to.params.shipping_method_id && to.params.shipping_method_id !== "null")
      data
        .fetch(
          `smartcheckouts/${to.params.smartcheckout_id}/methods/${to.params.shipping_method_id}`
        )
        .then(r => {
          if (r.carrier_product_service?.slug)
            store
              .dispatch("describeSmartcheckout", r.carrier_product_service.slug)
              .finally(() => {
                const sm = {
                  logo: null,
                  options: {},
                  ...r
                };
                return next(vm => {
                  if (vm.$_.isArray(sm.options)) sm.options = {};
                  vm.shippingMethod = { ...sm };
                  vm.originalSm = { ...sm };
                  vm.options = { ...sm.options };
                });
              });
        });
    else
      return next(vm => {
        const sm = {
          address_validation: true,
          name: "",
          description: "",
          logo: null,
          carrier: "",
          carrier_product_service: {
            name: "",
            slug: "",
            carrier: ""
          },
          rules: [{ priority: 1, filters: {}, price: null, description: '' }],
          options: {}
        };
        vm.shippingMethod = { ...sm };
        vm.originalSm = { ...sm };
        vm.options = { ...sm.options };
      });
  },

  mixins: [Filter, Options, Loader, FormData],
  components: { ImageUploader },
  name: "SmartCheckoutShippingMethodEditor",
  props: {},
  data: () => ({
    name: "shipping_method",
    endpoint: "smartcheckouts",
    headers: [
      {
        label: "name",
        key: "name"
      }
    ],
    ruleIndex: null,
    saving: false,
    shippingMethod: null,
    originalSm: null
  }),
  watch: {
    ruleIndex: function(val) {
      if (val !== null)
        this.filters = !this.$_.isArray(this.shippingMethod.rules[val].filters)
          ? this.shippingMethod.rules[val].filters
          : {};
    },
    "shippingMethod.carrier_product_service.slug": function(slug) {
      this.$store.dispatch("describeSmartcheckout", slug);

      if (slug == this.originalSm.carrier_product_service.slug)
        this.options = { ...this.originalSm.options };
      else this.options = {};
    }
  },
  computed: {
    describeKey: function() {
      const cps = this.shippingMethod?.carrier_product_service?.slug || "base";
      return `smartcheckout.${cps}.filters`;
    },
    optionsDescribeKey: function() {
      const cps = this.shippingMethod?.carrier_product_service?.slug || "base";
      return `smartcheckout.${cps}.options`;
    },
    describedFilters: function() {
      return (
        this.$_.get(this.$store.state.viewDescriptions, this.describeKey) || {}
      );
    },
    describedRequirements: function() {
      const cps = this.shippingMethod?.carrier_product_service?.slug || "base";
      let key = `smartcheckout.${cps}.requirements`;
      return (
        this.$_.get(this.$store.state.viewDescriptions, key) || []
      );
    },
    checkoutId: function() {
      return this.$route.params.smartcheckout_id;
    },
    carrierImageUrl: function() {
      return getCarrierImageUrl(
        this.shippingMethod.carrier_product_service.carrier
      );
    }
  },
  methods: {
    confirmFilters: function() {
      this.shippingMethod = set(
        `rules[${this.ruleIndex}].filters`,
        this.cleanedFilters,
        this.shippingMethod
      );
      this.ruleIndex = null;
      this.showFilter = false;
      this.filters = {};
    },
    deleteRule: function(ruleIndex) {
      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$t("set_of_rules").toLowerCase()
        })
      ).then(() => {
        let rules = [...this.shippingMethod.rules];
        rules.splice(ruleIndex, 1);
        this.shippingMethod = set(`rules`, rules, this.shippingMethod);
      });
    },
    addRule: function() {
      let rules = [...this.shippingMethod.rules];
      let priority = this.$_.last(rules) ? this.$_.last(rules).priority : 0;
      priority++;
      rules.push({ priority: priority, filters: {}, price: null });
      this.shippingMethod = set(`rules`, rules, this.shippingMethod);
    },
    save: function() {
      let httpMethod = "POST";
      if (this.shippingMethod.id) {
        httpMethod = "PUT";
      }
      this.saving = true;
      let url = `${this.endpoint}/${this.$route.params.smartcheckout_id}/methods`;

      if (this.shippingMethod.id) {
        url += `/${this.shippingMethod.id}`;
      }

      if (httpMethod === "PUT") url += `?_method=${httpMethod}`;

      this.createFormData(this.shippingMethod, "", true, true);

      this.$post(
        url,
        this.formData,
        { failSilently: true },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
        .then(r => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
          this.shippingMethod = r;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.$notification({
            title: this.shippingMethod.id
              ? this.$t("error_updating_entity", {
                  entity: this.$tc(this.name, 1).toLowerCase()
                })
              : this.$t("not_saved"),
            text: this.$_.join(this.$_.map(response.data.errors), "</br>"),
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>

<style lang="scss">
.smartcheckout_shippingmethod__form {
  .form {
    max-width: 692px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    .core-input,
    .core-select {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .image-wrapper {
    padding: 10px 0;
    border-color: #d2d5d9;
    border-radius: 8px;
    position: relative;
  }
  .rules-list {
    margin-bottom: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .rules-list:not(.options) {
    .filter {
      > div {
        border-top: 2px solid #d2d5d9;
        padding-top: 20px;
      }
    }
  }
  .rule-wrapper {
    padding: 28px;
    border: 1px solid #d2d5d9;
    border-radius: 8px;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
    .rule-form-wrapper {
      width: calc(100% + 30px);
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    .filter {
      width: 100%;
      margin-top: 10px;
      p {
        font-weight: 500;
        margin-bottom: 10px;
      }

      .active-filters {
        text-align: left;
        .title {
          font-size: 15px;
        }
        .tag {
          &:hover {
            color: initial;
            cursor: initial;
          }
          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
