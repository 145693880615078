<template>
  <div v-if="options && options.length">
      <div
        v-for="(option, index) in options"
        v-bind:key="option[bindKey || labelKey]">
        <core-card
        @click="$emit('select', index)"
        :selected="index === selectedIndex"
        :class="[...contentClass]"
      >
        <slot
          name="default"
          :item="option"
          :selected="selectedIndex === index"
        />
      </core-card>
      </div>
  </div>
  <core-list-empty
    style="margin: auto"
    :emptyListText="$t('no_shipping_methods')"
    v-else-if="!loading && (!options || !options.length)"
  />
</template>

<script>
export default {
  name: "ProductsList",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      required: true
    },
    bindKey: {
      type: String
    },
    contentStyle: {
      type: Object,
      default: () => ({})
    },
    contentClass: {
      type: Array,
      default: () => []
    },
    selectedIndex: {
      type: [String, Number],
      default: null
    },
    column_span: {
      type: Number,
      default: 6
    },
    column_size: {
      type: Number,
      default: 12
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
