<template>
  <div class="w-full flex">
    <div class="absolute -top-28 right-4 md:right-8">
      <core-button
        type="coolrunner"
        @click="editVisible = true"
        >{{ $t('create_entity', { entity: $tc('role', 1).toLowerCase() }) }}
      </core-button>
    </div>
    <div class="w-[232px] h-[193px] shadow-md rounded-md mr-3 p-5 cursor-pointer" @click="editVisible = true" v-for="role, index in roles" :key="'role_' + index">
        <p class="font-semibold mb-2 uppercase">{{role.name}}</p>
        <p class="line-clamp-4 text-sm">{{role.description}}</p>
        <p class="text-sm mt-3">{{role.amount}} medlemmer</p>
    </div>
    <core-dialog
        id="edit_user"
        :visible="editVisible"
        @update:visible="editVisible = false"
        :title="$t('edit_entity', { entity: $tc('role', 1).toLowerCase() })"
        @close="close"
        :use_default_breakpoints="false"
        :lg_width="20"
        :xl_width="30"
        :md_width="40"
        :xs_width="90"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        >
        <div class="p-5 w-full">
            <core-input
                :label="$t('name')"
                v-model="user.name"
            />
            <core-input
                class="mt-3"
                inputType="textarea"
                :label="$t('description')"
                v-model="user.description"
            />
            <div>
              <p>Tilgængelighed</p>
              <div class="grid grid-cols-2 mt-3">
                <div class="flex" v-for="option in options" :key="option.id">
                  <core-checkbox
                    v-bind:key="'input_0'"
                    class="mb-4"
                    @input="v => handleInput(v)"
                  /><span class="ml-2">{{option.name}}</span>
                </div>
              </div>
            </div>
        </div>
        <div
        slot="footer"
        style="width: 100%"
        class="dialog-footer flex justify-end mt-4"
        >
        <slot name="buttons-prefix" />
        <core-button
            type="coolrunner"
            @click="editVisible = false"
            class="ml-2"
            :disabled="formOkay"
            >{{ $t("edit") }}
        </core-button>
        </div>
    </core-dialog>
  </div>
</template>

<script>
import { URL_PREFIX } from "@/utils/constants";
export default {
  name: "Users",
  data: () => ({
    name: 'Users',
    editVisible: false,
    roles: [],
    user: {
        name: null,
        description: null,
        role: null,
        phone: null,
        email: null,
        created_at: null
    },
    roleSelect: [
        {
            label: 'Admin',
            id: '1'
        },
        {
            label: 'Support',
            id: '2'
        }
    ],
    options: [
        {
            name: 'Shipments',
            id: '1'
        },
        {
            name: 'Rapporter',
            id: '2'
        },
        {
            name: 'Retur',
            id: '2'
        },
        {
            name: 'Andet',
            id: '2'
        }
    ],
    loading: false,
  }),
  created: function() {
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#account_info--wrapper"
    };
    this.account = { ...this.$store.state.account };
    this.getRoles()
  },
  watch: {
    "this.$store.state.account": function(val) {
      this.account = val;
    }
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    rowActions: function (item) {
        let actions = [
        {
          label: "Rediger",
          action: this.editItem
        },
        {
          label: "Slet",
          action: this.deleteItem
        },
      ];
      return actions;
    }

  },
  methods: {
    getRoles: function () {
      this.$get(
        `${URL_PREFIX}/auth-service/roles`,
        {},
        {
          failSilently: true
        }
      ).then(response => {
        this.roles = response.data
      });
    },
    editItem: function () {
        this.editVisible = true
    },
    deleteItem: function () {
        this.editVisible = true
    }
  }
};
</script>

<style scoped></style>