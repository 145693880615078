<template>
  <div class="number">
    <el-row v-bind:key="0" :gutter="20" type="flex" align="middle">
      <el-col :span="12">
        <core-input
          v-bind:key="'input_0_from'"
          :label="$t('from')"
          type="number"
          @input="val => handleInput(val, true)"
          :autofocus="true"
        />
      </el-col>
      <el-col :span="12">
        <core-input
          v-bind:key="'input_0_to'"
          :label="$t('to')"
          type="number"
          @input="val => handleInput(val, false)"
          :autofocus="true"
        />
      </el-col>
    </el-row>
    <core-button type="coolrunner" @click="$emit('update-filter', inputs)">{{
      $t("add")
    }}</core-button>
  </div>
</template>

<script>
import _ from "lodash";
import mixin from "../typeMixin";
export default {
  mixins: [mixin],
  props: {
    value: {
      type: [Object, String],
      default: null
    }
  },
  data: () => ({
    inputs: null
  }),
  created() {
    this.inputs = this.$_.cloneDeep(this.value);
  },
  methods: {
    handleInput: function(val, from, index) {
      const newValue = { ...this.inputs };
      const parsedVal = val || val === 0 ? parseFloat(val) : undefined;

      if (from) newValue.start = parsedVal;
      else newValue.end = parsedVal;
      newValue.type = "custom-range";
      this.inputs = newValue;
    }
  }
};
</script>
