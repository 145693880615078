<template>
  <div class="insurance_container p-5">
    <i18n
      path="insurance_description"
      tag="p"
      class="small p-[5px]"
      style="word-break: normal"
    >
      <template v-slot:weight>
        {{ weight }}
      </template>
      <template v-slot:sum>
        {{ $n(sum, "currency", "da-DK") }}
      </template>
      <template v-slot:link>
        <a v-if="$store.state.account.business" href="https://homerunner.com/handelsbetingelser" target="_blank">{{
          $t("terms_of_trade")
        }}</a>
        <a v-if="!$store.state.account.business" href="https://coolrunner.dk/handelsbetingelser" target="_blank">{{
          $t("terms_of_trade")
        }}</a>
      </template>
    </i18n>
    <core-products-list
      :options="products"
      labelKey="id"
      :selectedIndex="selected_index"
      @select="toggle"
      :column_span="12"
      :contentClass="['insurance__card']"
    >
      <slot slot-scope="prop">
        <el-col :span="22">
          <h3 class="small">
            {{ $t("add_entity", { entity: $t("insurance") }) }}
          </h3>
          <i18n path="insurance_card_description" tag="p" class="small">
            <template v-slot:entity>
              <b>{{ $n(prop.item.coverage, "currency", "da-DK") }}</b>
            </template>
          </i18n>
          <p v-if="$store.state.account.business">{{ $t("price") }}: {{ $n((prop.item.price_incl_tax / 100) * 80, "currency", "da-DK")}}</p>
          <p v-if="!$store.state.account.business">{{ $t("price") }}: {{ $n(prop.item.price_incl_tax, "currency", "da-DK")}}</p>
        </el-col>
      </slot>
    </core-products-list>
  </div>
</template>

<script>
export default {
  name: "CreateShipmentInsurance",
  props: {
    value: {
      type: Object,
      required: () => ({})
    }
  },
  data: () => ({
    selected_index: null
  }),
  computed: {
    products: function() {
      return this.$store.state.insurance_products;
    },
    weight: function() {
      return (this.value.weight / 1000).toFixed(2);
    },
    sum: function() {
      const sdrRate = this.$store.state.sdrRate;
      const insuranceRate = 8.33;
      let prices = {
        dao: 1000,
        bring: 1000,
        cool: (this.value.weight / 1000) * (sdrRate * insuranceRate)
      };
      let price = 1000;

      const cps =
        this.value.carrier +
        "_" +
        this.value.carrier_product +
        "_" +
        this.value.carrier_service;
      switch (cps) {
        case "dao_private_droppoint":
        case "dao_private_delivery":
        case "dao_private_delivery_package":
        case "dao_private_delivery_letter":
          price = prices.dao;
          break;
        case "bring_private_droppoint":
        case "bring_private_delivery":
          price = prices.bring;
          break;
        default:
          price = prices.cool;
      }

      return price;
    }
  },
  mounted() {
    this.selected_index = this.value.insurance ? 0 : null;
    this.$emit("set:nextStep", "review");
  },
  methods: {
    toggle: function(idx) {
      if (this.selected_index == idx) {
        this.selected_index = null;
        this.$emit("update:value", {...this.value, insurance: null });
        this.value.insurance = this.selected_index;
        this.value = { ...this.value };
      } else {
        this.$emit("update:value", {...this.value, insurance: this.products[idx].id });
        this.selected_index = idx;
        this.value.insurance = this.selected_index;
        this.value = { ...this.value };
      }
    }
  }
};
</script>

<style scoped>
.insurance_container >>> .insurance__card {
  align-items: baseline;
}
</style>
