<template>
  <core-view>
    <core-view-header :name="name" :count="totalItems"/>

    <core-list-new-table
      class="flex-grow-0 shadow-sm rounded-b-md"
      :items="items"
      :headers="headers"
      :pageSize.sync="options.itemsPerPage"
      :currentPage.sync="options.page"
      :totalItems="totalItems"
      :loading="loading"
      :orderBy.sync="options.orderBy"
      :orderDir.sync="options.orderDir"
      :query.sync="search"
      :selected="selected"
      @checkbox-change="handleSelection"
      @all-selected-change="handleSelection"
      @create-new-clicked="editItem()"
      @row-click="item => editItem(item)"
      showCreateNew
      :filterable="false"
      :rowActions="rowActions"
    >
      <template v-slot:column.type="{ item }">
        <p class="small content">
          {{ getFilterTypeLabel(item.type) }}
        </p>
      </template>
    </core-list-new-table>

    <core-dialog
      :title="
        this.$t(`create_entity`, {
          entity: $tc(name).toLowerCase()
        })
      "
      :visible.sync="dialog"
      :xs_width="90"
      :lg_width="30"
      @closed="afterClose"
    >
      <div class="form wrapper">
        <row :columns="24" :gutter="20">
          <column :xs="24">
            <core-input :label="$t('title')" v-model="editedItem.title" />
          </column>
          <column :xs="24">
            <core-input :label="$t('key')" v-model="editedItem.key" />
          </column>
          <column :xs="24">
            <core-select
              :options="$store.state.filterTypes"
              :label="$t('type')"
              v-model="editedItem.type"
            />
          </column>
        </row>
      </div>
      <span slot="footer" class="dialog-footer flex">
        <core-button class="mr-auto" type="default_outline" @click="close">{{
          $t("cancel")
        }}</core-button>
        <core-button
          :disabled="
            !editedItem.title.length ||
              !editedItem.key.length ||
              !editedItem.type.length
          "
          :loading="saving"
          type="coolrunner"
          @click="
            !(
              !editedItem.title.length ||
              !editedItem.key.length ||
              !editedItem.type.length
            ) && save()
          "
          >{{
            $t("save_new_entity", { type: $tc(name).toLowerCase() })
          }}</core-button
        >
      </span>
    </core-dialog>
  </core-view>
</template>

<script>
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";
export default {
  name: "CustomFilterTypes",
  mixins: [List],
  data: () => ({
    endpoint: "smartcheckouts/filters",
    name: "filter_type",
    headers: [
      {
        label: "key",
        key: "key"
      },
      {
        label: "title",
        key: "title"
      },
      {
        label: "type",
        key: "type"
      }
    ],
    showCreate: false,
    editedItem: {
      key: "",
      title: "",
      type: ""
    },
    defaultItem: {
      key: "",
      title: "",
      type: ""
    },
    reloadOnDelete: true
  }),
  mounted: function() {
    this.getDataFromApi();
  },
  computed: {
    rowActions: function() {
      return [
        {
          label: this.$t("delete"),
          icon: ["far", "trash"],
          action: this.deleteItem
        }
      ];
    }
  },
  methods: {
    editItem: function(item) {
      if (item) this.editedItem = { ...item };
      this.dialog = true;
    },
    afterSave: function() {
      this.$store.dispatch("describeSmartcheckout");
    },
    getFilterTypeLabel: function(type) {
      return this.$_.find(this.$store.state.filterTypes, ["value", type]).label;
    },
    convertUTCToLocal: function(val) {
      return convertUTCToLocal(val, "date");
    }
  }
};
</script>
