import I18n from "@/plugins/i18n";

export const URL_PREFIX_DEV = "https://core.crdev.dk";
export const URL_PREFIX = process.env.VUE_APP_CORE_URL;
export const URL_ECHO = process.env.VUE_APP_URL_ECHO ? process.env.VUE_APP_URL_ECHO : process.env.VUE_APP_CORE_URL;
export const API_PREFIX = process.env.VUE_APP_API_PREFIX || process.env.VUE_APP_CORE_URL;

export const BREAKPOINTS = {
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
};

export const exportIconsMap = {
  csv: "file-csv",
  html: "file-code",
  json: "file-alt",
  ods: "file-spreadsheet",
  xls: "file-excel",
  xlsx: "file-excel",
  xml: "file-times",
  printer: "print",
  gdpr: "user",
};

export const importerStates = {
  awaiting: I18n.t("loaded_multiple"),
  //cancelled: I18n.t("cancelled"),
  failed: I18n.t("failed"),
  done: I18n.t("generated"),
};
