export default {
  data: () => ({
    loading: false,
    loadingInstance: null,
    defaultLoaderTarget: "#bp__main-view",
    loaderOptions: {
      lock: true,
      fullscreen: false,
      text: "loading",
      customClass: null
    }
  }),
  methods: {
    setLoading: function() {
      if (this.loading) {
        if (this.loadingInstance)
        this.loaderOptions.text = this.$t(this.loaderOptions.text);
        this.loaderOptions.target = this.defaultLoaderTarget;
        this.loadingInstance = this.$loading(this.loaderOptions);
      } else if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    }
  },
  destroyed: function() {
    if (this.loadingInstance) {
      this.loadingInstance.close();
    }
  },
  watch: {
    loading: function() {
      this.setLoading();
    }
  }
};
