export default {
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    view: {
      type: Object,
      default: () => ({})
    },
    optionsMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object],
      default: () => []
    }
  },
  computed: {
    title: function() {
      return this.filter.title || this.$t(`filters.${this.$vnode.key}`);
    },
    description: function() {
      return this.filter.description;
    },
    values: function() {
      return (this.optionsMode ? this.value?.value : this.value) || [];
    }
  },
  methods: {
    getValue: function(val) {
      return this.optionsMode ? val : val.value;
    }
  }
};
