<template>
    <core-view :style="{ '--delay': delay}">
        <core-view-header class="mb-5" :name="$t('return_list_generator')" :description="'page_descriptions.return_list_generator'" :route-name="$route.name" route="labels" show-view-name> </core-view-header>
        <div class="w-full bg-white shadow-sm rounded-md p-5">
            <core-input
                class="max-w-[300px] mb-4"
                :label="$t('time_range_entity',{ entity: $tc('month').toLowerCase() })"
                v-model="filters.time_range"
            />
            <core-input
                class="max-w-[300px] mb-4"
                :label="$t('min_return_count')"
                v-model="filters.min_return_count"
            />
            <core-input
                class="max-w-[300px] mb-4"
                :label="$t('min_label_count')"
                v-model="filters.min_label_count"
            />
            <core-input
                class="max-w-[300px] mb-4"
                :label="$t('min_return_percentage')"
                v-model="filters.min_return_percentage"
            />
            <core-button class="w-full max-w-[300px]" type="coolrunner" @click="generateList()">{{$t('generate_list')}}</core-button>
        </div>
    </core-view>
</template>

<script>
import { convertUTCToLocal } from "@/utils/date";
import _ from "lodash";
import axios from 'axios';
import { URL_PREFIX } from "@/utils/constants";

export default {
data: () => ({
    name: "returnlistgenerator",
    filters: {
        time_range: 6,
        min_return_count: 2,
        min_label_count: 3,
        min_return_percentage: 50
    }
}),
mounted: function () {
},
watch: {
},
computed: {
    URL_PREFIX: () => URL_PREFIX,
    delay: function () {
        return 0
    },
},
methods: {
    generateList: function () {
        window.open(
        `${URL_PREFIX}/business-panel/returns/customer-list?time_range=${this.filters.time_range}&min_return_count=${this.filters.min_return_count}&min_label_count=${this.filters.min_label_count}&min_return_percentage=${this.filters.min_return_percentage}&access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`,
        "_blank"
        );
    }
}
};
</script>