import _ from "lodash"
export default {
    data: () => ({
        pageName: ''
    }),
    props: {
    },
    computed: {
        capabilities: function() {
            let capabilities = []
            _.forEach(this.$store.state.capabilities, function(value) {
               let stringArray = _.split(value, ':');
               if (stringArray.length > 1) {
                capabilities.push(stringArray[1])
               } else {
                capabilities.push(stringArray[0])
               }
            })
            return capabilities;
        },
        canEdit: function() {
            if (_.includes(this.capabilities, 'owner')) {
                return true;
            }
            if (_.includes(this.capabilities, this.pageName + '.edit')) {
                return true;
            } else {
                return false;
            }
        },
        canView: function() {
            if (_.includes(this.capabilities, 'owner')) {
                return true;
            }
            if (_.includes(this.capabilities, this.pageName)) {
                return true;
            } else {
                return false;
            }
        },
        canDelete: function() {
            if (_.includes(this.capabilities, 'owner')) {
                return true;
            }
            if (_.includes(this.capabilities, this.pageName + '.delete')) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted() {
    },
    watch: {
    },
    methods: {
        checkSingleAccess: function (key, ability) {
            if (_.includes(this.capabilities, 'owner')) {
                return true;
            }
            if (_.includes(this.capabilities, key + ability)) {
                return true;
            } else {
                return false;
            }
        }
    }
  };