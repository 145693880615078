import { render, staticRenderFns } from "./_Profile.vue?vue&type=template&id=852c9814&scoped=true&"
import script from "./_Profile.vue?vue&type=script&lang=js&"
export * from "./_Profile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "852c9814",
  null
  
)

export default component.exports