<template>
  <widget-base
    :title="cTitle"
    :subtitle="sub"
    :shadow="shadow"
    :titlePosition="titlePosition"
    :showNoData="showNoData"
    :initialFilters="data.filters"
    :interval="interval"
    :filteringEnabled="filteringEnabled"
    @set-filters="val => (filters = val)"
    @set-cleaned-filters="val => (cleanedFilters = val)"
    @set-interval="val => (interval = val)"
    showIntervalSelector
    class="chart-wrapper"
    :hasDescription="hasDescription"
  >
    <div>
      <div class="failText relative" v-if="showNoData">
      <span class="abolute top-1/2 left-1/2 transform mt-28 translate-x-1/2 translate-y-1/2">{{$t('chart_error')}}</span>
    </div>
    <component
      :chart-id="uniqueID"
      :is="breakpoint.innerWidth < 1 ? 'chart-horizontal-bar' : 'chart-bar'"
      :chart-data="dataCollection"
      :options="{
        legend: false,
        animation: {
          easing: 'easeInOutElastic'
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: tooltipsOptions(title),
        minBarLength: 10,
        scales: scalesOptions()
      }"
    />
    </div>
  </widget-base>
</template>

<script>
import ChartWidget from "../../mixins/ChartWidget";
import { getTheme } from "@/components/widgets/options";
import _ from "lodash";

export default {
  name: "BarChart",
  mixins: [ChartWidget],
  data: () => ({
    baseID: "bar-chart",
    backgroundLine: {}
  }),
  methods: {
    fillData(widgetData) {
      if (widgetData.data.labels.length == 0 && !this.localData) {
        widgetData.data = {
          datasets: [
            {
              backgroundColor: ['rgba(13,200,44,.5)'],
              data: [72]
            }
          ],
          labels:['noDataFound']
        }
      }
      this.dataCollection = {
        ...widgetData.data,
        datasets: this.$_.map(widgetData.data.datasets, ds => {
          if (ds.type == "line") {
            this.backgroundLine = {
              data: ds.data,
              max_val: ds.max_val,
              min_val: ds.min_val,
              yAxisID: this.getYAxisId(ds),
              borderColor: "#FFFFFF",
              order: 2,
              backgroundColor: "rgba(255, 255, 255, 0)",
              tension: 0,
              borderWidth: 8,
              type: "line",
              label: ds.label,
              pointHitRadius: 0,
              pointHoverRadius: 0,
              pointRadius: 0,
              pointBorderWidth: 0
            };
            return {
              ...ds,
              yAxisID: this.getYAxisId(ds),
              backgroundColor: "rgba(255, 255, 255, 0)",
              pointBorderColor: "#FFFFFF",
              pointBackgroundColor: "#084081",
              borderColor: "#4EB3D3",
              pointBorderWidth: 2,
              order: 1,
              tension: 0,
              pointRadius: 5,
              pointHitRadius: 10
            };
          }
          return {
            ...ds,
            yAxisID: this.getYAxisId(ds),
            backgroundColor: getTheme(ds).reverse(),
            barPercentage: 0.5,
            minBarLength: 10,
            maxBarThickness: 57.58,
            order: 3
          };
        })
      };
      this.loading = false;

      if (this.backgroundLine.data !== undefined) {
        this.dataCollection.datasets.push(this.backgroundLine);
      }
      let canvas = document.getElementById(this.uniqueID);
      canvas.parentNode.style.height = "400px";
    }
  }
};
</script>
