var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-md items-center",class:[
    _vm.class_override,
    _vm.ekstra_class,
    _vm.error ? 'border-red-500' : 'border-gray-200',
    { border: _vm.bordered, 'h-14': _vm.bordered }
  ]},[_c('el-select',_vm._g(_vm._b({directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],ref:"select",class:[
      'flex rounded-md h-full',
      {'w-full items-stretch': _vm.full }
    ],attrs:{"clearable":_vm.clearable,"disabled":_vm.disabled,"value-key":_vm.valueKey,"popper-append-to-body":false,"popper-options":{ boundariesElement: 'body', gpuAcceleration: false },"loading":_vm.loading,"placeholder":_vm.label,"loading-text":_vm.$t('loading'),"value":_vm.value,"multiple":_vm.multiple},on:{"change":val => _vm.$emit('update:value', val)},scopedSlots:_vm._u([_vm._l((_vm.$slots),function(index,name){return {key:name,fn:function(){return [_vm._t(name)]},proxy:true}})],null,true)},'el-select',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.options),function(item){return _c('el-option',{key:_vm.getKey(item),attrs:{"label":_vm.getLabel(item),"value":_vm.$_.isString(item) || _vm.valueIsObject ? item : item[_vm.valueKey]}},[_vm._t("option",null,{"option":item})],2)}),(_vm.paginate)?_c('infinite-loading',{attrs:{"force-use-infinite-wrapper":".el-select-dropdown__wrap"},on:{"infinite":infinity => _vm.$emit('bottom-reached', infinity)}},[_c('template',{slot:"spinner"},[_c('p',{staticClass:"el-select-dropdown__empty"},[_vm._v(" "+_vm._s(_vm.$t("loading"))+" ")])]),_c('template',{slot:"no-results"},[_c('p')]),_c('template',{slot:"no-more"},[_c('p')])],2):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }