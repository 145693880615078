<template>
  <div
    :disabled="disabled"
    @click="$emit('click')"
    class="filter__clear"
    :class="{ disabled }"
  >
    <font-awesome-icon :icon="['far', 'times-circle']" fixed-width />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass">
.filter__clear
    height: 100%
    font-size: 22px
    cursor: pointer
    &.disabled
        opacity: .25
        cursor: initial
</style>
