<template>
  <l-map
    :center="[51.40807792, 11.13992588]"
    :zoom="4"
    style="height: 500px; border-radius: 5px; min-width: 300px;"
    :options="mapOptions"
  >
    <l-choropleth-layer
      :data="countryArray"
      titleKey="country_name"
      idKey="country_iso"
      :value="value"
      :geojsonIdKey="geokey"
      :geojson="worldgeojson"
      :colorScale="colorScale"
    >
      <template slot-scope="props">
        <l-info-control
          :item="props.currentItem"
          :unit="props.unit"
          :title="`${$t('country')}`"
          position="topright"
          :placeholder="`${$t('hover_over_a_country')}`"
        />
        <l-reference-chart
          :title="`${$tc('package', 99)} ${$t('sent')}`"
          :colorScale="colorScale"
          :min="props.min"
          :max="props.max"
          position="bottomleft"
        />
      </template>
    </l-choropleth-layer>
  </l-map>
</template>

<script>
import Widget from "../../mixins/Widget";
import { LMap } from "vue2-leaflet";
import { InfoControl, ReferenceChart, ChoroplethLayer } from "vue-choropleth";
import worldgeojson from "./world.geo.json";
import i18n from "@/plugins/i18n";

export default {
  name: "ChoroplethMap",
  mixins: [Widget],
  data() {
    return {
      baseID: "Choro_map",
      countryArray: [],
      colorScale: ["#D5EFF9", "#081D58"],
      geokey: "iso_a2",
      value: {
        key: "amount",
        metric: i18n.tc("package", 99)
      },
      mapOptions: {
        attributionControl: false
      }
    };
  },
  computed: {
    worldgeojson: () => worldgeojson
  },
  components: {
    LMap,
    "l-info-control": InfoControl,
    "l-reference-chart": ReferenceChart,
    "l-choropleth-layer": ChoroplethLayer
  },
  methods: {
    fillData(widgetData) {
      this.countryArray = this.$_.get(widgetData, "data.datasets[0].data");
      this.loading = false;
    }
  }
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
.leaflet-container {
  background: white;
  position: inherit;
}
</style>
