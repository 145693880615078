<template>
  <div class="w-full">
    <core-view-header :name="'returns'">
      <template slot="description">
        <div class="flex w-full justify-between">
          <div />
          <div class="flex flex-none">
            <core-button class="mr-2" type="coolrunner" @click="showPreview">
              {{ $t("preview") }}
            </core-button>
            <core-button type="coolrunner" @click="modals.share = true">
              {{ $t("share") }}
            </core-button>
            <core-button class="ml-2" type="coolrunner" @click="back">
              {{ $t("back") }}
            </core-button>
          </div>
        </div>
      </template>
    </core-view-header>

    <div class="bg-white px-4 md:px-8 md:pb-5 shadow-sm rounded-b-md">
      <div class="w-full mb-2">
        <div class="flex justify-start">
          <label for="smart">
            <input
              id="smart"
              type="checkbox"
              :checked="page.settings.smartcheckout"
              :value="page.settings.smartcheckout"
              class="sr-only"
              @change="(val) => handleSelection('smart', val)"
            />
            <div class="smart-box mr-1 cursor-pointer border border-gray-300 bg-gray-100 px-2 py-1 rounded text-gray-500 transition duration-300">
              {{ $t("smart_return") }}
            </div>
          </label>
          <el-tooltip class="item max-w-sm" effect="light" :content="$t('smart_return_description')" placement="top-start">
            <span class="el-icon-question" style="margin-left: 0.25em" />
          </el-tooltip>
        </div>
      </div>
      <div class="input__container w-full max-w-3xl">
        <div class="">
          <core-input
            v-model="page.name"
            :value="page.route"
            :label="$t('name')"
            :disabled="!!this.page_id"
            :helper-text="$t('returns_input_description.name')"
          />
        </div>
        <div class="mt-2">
          <core-input :value="page_url" :label="$t('url_address')" disabled :helper-text="$t('returns_input_description.return_url')" />
        </div>
        <div>
          <transition name="height-fade-short">
            <core-select-country
              v-if="!page.settings.smartcheckout"
              v-model="page.country"
              class="w-full max-w-full mt-2"
              :data-override="$store.state.return_countries"
              :value="page.country"
              :disabled="!!page_id"
            />
          </transition>
        </div>
        <transition name="height-fade-short">
          <div v-if="!page.settings.smartcheckout && this.$store.state.return_products" class="mt-2">
            <core-select
              v-model="page.product"
              class="w-full max-w-full"
              :options="product_list"
              value-key="value"
              :label-key="
                (e) => {
                  return getProductTitle(e);
                }
              "
              :value="page.product"
              :helper-text="$t('returns_input_description.product')"
              :label="$t('select_entity', { entity: $t('product').toLowerCase() })"
            />
          </div>
        </transition>
        <transition name="height-fade-short">
          <div v-if="!page.settings.smartcheckout" class="grid grid-cols-4 gap-2">
            <div class="mt-2 col-span-3">
              <core-number v-model="page.price" :value="page.price" :label="$t('price')" :helper-text="$t('returns_input_description.price')" />
            </div>
            <div class="mt-2 col-span-1">
              <core-select
                v-model="page.currency"
                class="w-full max-w-full"
                :options="$store.state.return_currencies"
                :value="page.currency"
                :helper-text="$t('returns_input_description.currency')"
                :placeholder="$t('select_entity', { entity: $t('currency').toLowerCase() })"
              />
            </div>
          </div>
        </transition>
        <transition name="height-fade-short">
          <div v-if="page.settings.smartcheckout" class="grid grid-cols-4 gap-2">
            <div class="mt-2 col-span-3">
              <checkout-select v-model="page.settings.checkout_id" :label="$t('checkout')" class="shop-field w-full max-w-full" />
            </div>
            <div class="mt-2 col-span-1">
              <core-select
                v-model="page.currency"
                class="w-full max-w-full"
                :options="$store.state.return_currencies"
                :value="page.currency"
                :helper-text="$t('returns_input_description.currency')"
                :placeholder="$t('select_entity', { entity: $t('currency').toLowerCase() })"
              />
            </div>
          </div>
        </transition>
        <div class="mt-2">
          <core-input v-model="page.title" :value="page.title" :label="$t('title')" :helper-text="$t('returns_input_description.title')" />
        </div>
        <div class="mt-2">
          <core-input v-model="page.logo" :value="page.logo" :label="$t('logo_url')" :helper-text="$t('returns_input_description.logo')" />
        </div>
        <div class="mt-2">
          <core-input v-model="page.customer_url" :value="page.customer_url" :label="$t('url_for_website')" :helper-text="$t('returns_input_description.customer_url')" />
        </div>
        <div class="mt-2">
          <vueEditor v-model="page.description" :editor-toolbar="customToolbar" />
        </div>
        <div class="mt-2 w-full flex-wrap flex">
          <p v-for="tag in tags" :key="tag" class="mr-2 bg-gray-200 rounded-md p-1">
            {{ tag }}
          </p>
        </div>
        <!-- New return portal -->
        <div class="border-t-2 mt-5 pt-5" v-if="!page.uid">
          <div v-if="!page.settings.smartcheckout && page.country !== 'NO'" class="mt-2 flex justify-start">
            <label for="public">
              <input
                id="public"
                type="checkbox"
                :checked="page.settings.public"
                :value="page.settings.public"
                class="sr-only"
                @change="handleSelection('public')"
              />
              <div class="public-box mr-1 cursor-pointer border border-gray-300 bg-gray-100 px-2 py-1 rounded text-gray-500 transition duration-300">
                {{ $t("public_page") }}
              </div>
            </label>
            <el-tooltip class="item" effect="light" :content="$t('returns_public_tooltip')" placement="top-start">
              <span class="el-icon-question" style="margin-left: 0.25em" />
            </el-tooltip>
          </div>

          <div v-if="!page.settings.smartcheckout && page.country !== 'NO'" class="mt-2 flex justify-start">
            <label for="private">
              <input
                id="private"
                type="checkbox"
                :checked="page.settings.private"
                :value="page.settings.private"
                class="sr-only"
                @change="handleSelection('private')"
              />
              <div class="private-box mr-1 cursor-pointer border border-gray-300 bg-gray-100 px-2 py-1 rounded text-gray-500 transition duration-300">
                {{ $t("private_page") }}
              </div>
            </label>
            <el-tooltip class="item" effect="light" :content="$t('returns_private_tooltip')" placement="top-start">
              <span class="el-icon-question" style="margin-left: 0.25em" />
            </el-tooltip>
          </div>
          <transition name="widget-animation" mode="out-in">
            <div v-if="page.settings.public || page.settings.private" class="text-left mt-2">
              <core-checkbox v-model="page.settings.order_number" :value="!!page.settings.order_number" :label="$t('requires_order_number')" />
              <el-tooltip class="item" effect="light" :content="$t('returns_order_number_tooltip')" placement="top-start">
                <span class="el-icon-question" style="margin-left: 0.25em" />
              </el-tooltip>
            </div>
          </transition>
          <div v-if="!page.settings.smartcheckout" class="mt-2 flex justify-start">

            <label for="gate">
              <input
                id="gate"
                type="checkbox"
                :checked="this.norway_checked"
                :value="page.settings.order_gate"
                class="sr-only"
                @change="handleSelection('gate')"
              />
              <div class="gate-box mr-1 cursor-pointer border border-gray-300 bg-gray-100 px-2 py-1 rounded text-gray-500 transition duration-300">
                {{ $t("order_gate") }}
              </div>
            </label>
            <el-tooltip class="item" effect="light" :content="$t('order_gate_tooltip')" placement="top-start">
              <span class="el-icon-question" style="margin-left: 0.25em" />
            </el-tooltip>
          </div>
          <transition name="widget-animation" mode="out-in">
            <div v-if="page.settings.order_gate || page.country === 'NO'">
              <div class="mt-2">
                <core-input
                  v-model="page.settings.order_gate_title"
                  :value="page.settings.order_gate_title"
                  :label="$t('order_gate_title')"
                  :helper-text="$t('returns_input_description.order_gate_title')"
                />
              </div>
              <div class="mt-2">
                <core-input
                  v-model="page.settings.order_gate_description"
                  input-type="textarea"
                  :value="page.settings.order_gate_description"
                  :label="$t('order_gate_description')"
                  :helper-text="$t('returns_input_description.order_gate_description')"
                />
              </div>
              <div class="mt-2">
                <core-input
                  v-model="page.settings.order_gate_age_limit"
                  type="number"
                  :value="page.settings.order_gate_age_limit"
                  :label="$t('order_gate_limit')"
                  :helper-text="$t('returns_input_description.order_gate_limit')"
                />
              </div>
            </div>
          </transition>
        </div>

        <!-- Existing retur portal -->
        <div class="border-t-2 mt-5 pt-5" v-else-if="page.uid">
          <div v-if="!page.settings.smartcheckout && page.country !== 'NO' || !page.settings.smartcheckout && page.country === 'NO' && !page.settings.order_gate" class="mt-2 flex justify-start">
            <label for="public">
              <input
                  id="public"
                  type="checkbox"
                  :checked="page.settings.public"
                  :value="page.settings.public"
                  class="sr-only"
                  @change="handleSelection('public')"
              />
              <div class="public-box mr-1 cursor-pointer border border-gray-300 bg-gray-100 px-2 py-1 rounded text-gray-500 transition duration-300">
                {{ $t("public_page") }}
              </div>
            </label>
            <el-tooltip class="item" effect="light" :content="$t('returns_public_tooltip')" placement="top-start">
              <span class="el-icon-question" style="margin-left: 0.25em" />
            </el-tooltip>
          </div>

          <div v-if="!page.settings.smartcheckout && page.country !== 'NO' || !page.settings.smartcheckout && page.country === 'NO' && !page.settings.order_gate" class="mt-2 flex justify-start">
            <label for="private">
              <input
                  id="private"
                  type="checkbox"
                  :checked="page.settings.private"
                  :value="page.settings.private"
                  class="sr-only"
                  @change="handleSelection('private')"
              />
              <div class="private-box mr-1 cursor-pointer border border-gray-300 bg-gray-100 px-2 py-1 rounded text-gray-500 transition duration-300">
                {{ $t("private_page") }}
              </div>
            </label>
            <el-tooltip class="item" effect="light" :content="$t('returns_private_tooltip')" placement="top-start">
              <span class="el-icon-question" style="margin-left: 0.25em" />
            </el-tooltip>
          </div>
          <transition name="widget-animation" mode="out-in">
            <div v-if="!page.settings.smartcheckout && page.country !== 'NO' || !page.settings.smartcheckout && page.country === 'NO' && !page.settings.order_gate" class="text-left mt-2">
              <core-checkbox v-model="page.settings.order_number" :value="!!page.settings.order_number" :label="$t('requires_order_number')" />
              <el-tooltip class="item" effect="light" :content="$t('returns_order_number_tooltip')" placement="top-start">
                <span class="el-icon-question" style="margin-left: 0.25em" />
              </el-tooltip>
            </div>
          </transition>
          <div v-if="!page.settings.smartcheckout" class="mt-2 flex justify-start">
            <label for="gate">
              <input
                  id="gate"
                  type="checkbox"
                  :checked="page.settings.order_gate"
                  :value="page.settings.order_gate"
                  class="sr-only"
                  @change="handleSelection('gate')"
              />
              <div class="gate-box mr-1 cursor-pointer border border-gray-300 bg-gray-100 px-2 py-1 rounded text-gray-500 transition duration-300">
                {{ $t("order_gate") }}
              </div>
            </label>
            <el-tooltip class="item" effect="light" :content="$t('order_gate_tooltip')" placement="top-start">
              <span class="el-icon-question" style="margin-left: 0.25em" />
            </el-tooltip>
          </div>
          <transition name="widget-animation" mode="out-in">
            <div v-if="page.settings.order_gate">
              <div class="mt-2">
                <core-input
                    v-model="page.settings.order_gate_title"
                    :value="page.settings.order_gate_title"
                    :label="$t('order_gate_title')"
                    :helper-text="$t('returns_input_description.order_gate_title')"
                />
              </div>
              <div class="mt-2">
                <core-input
                    v-model="page.settings.order_gate_description"
                    input-type="textarea"
                    :value="page.settings.order_gate_description"
                    :label="$t('order_gate_description')"
                    :helper-text="$t('returns_input_description.order_gate_description')"
                />
              </div>
              <div class="mt-2">
                <core-input
                    v-model="page.settings.order_gate_age_limit"
                    type="number"
                    :value="page.settings.order_gate_age_limit"
                    :label="$t('order_gate_limit')"
                    :helper-text="$t('returns_input_description.order_gate_limit')"
                />
              </div>
            </div>
          </transition>
        </div>
        <p class="mb-2 mt-4">
          {{ $t("return_causes") }}
        </p>
        <div class="grid grid-cols-6">
          <div class="mt-2 col-span-5 mr-2 mb-2">
            <core-input v-model="cause" :label="$t('return_cause')" :helper-text="$t('returns_input_description.return_causes')" />
          </div>
          <core-button :disabled="!cause" class="bg-gray-500 text-white col-span-1 h-14 mt-2" type="default_add" @click="add">
            <span class="flex w-max">{{ $t("add") }}<font-awesome-icon class="mt-1 ml-2" :icon="['far', 'arrow-square-right']"/></span>
          </core-button>
        </div>
        <div v-if="page.settings.causes">
          <transition-group name="list">
            <div v-for="(item, index) in page.settings.causes" :key="item.id" class="bg-gray-200 rounded-md p-1 mb-2 w-max">
              <p class="flex w-max">
                {{ item.text }}
                <font-awesome-icon class="cursor-pointer ml-4 mt-1" :icon="['far', 'times-circle']" @click="remove(index)" />
              </p>
            </div>
          </transition-group>
        </div>
        <div class="mt-2">
          <core-input
            v-model="page.settings.reference_key"
            :value="page.settings.reference_key"
            :label="$t('reference_key')"
            :helper-text="$t('returns_input_description.reference_key')"
          />
        </div>
        <div class="my-5 py-5 border-t-2 border-b-2 text-left">
          <div id="alternative_address_container" class="form flex flex-col items-stretch">
            <div class="flex justify-start">
              <p class="mb-2">
                {{ $t("alternative_address") }}
              </p>
              <el-tooltip class="item max-w-sm" effect="light" :content="$t('alternative_return_address_description')" placement="top-start">
                <span class="el-icon-question" style="margin-left: 0.25em" />
              </el-tooltip>
            </div>
            <div>
              <core-input v-model="alternative_address.company" :value="alternative_address.company" :label="$t('company_name')" />
            </div>
            <div class="mt-2">
              <core-input v-model="alternative_address.attention" :value="alternative_address.attention" :label="$t('name')" />
            </div>
            <div class="mt-2">
              <core-input v-model="alternative_address.street" :value="alternative_address.street" :label="$t('street')" />
            </div>
            <div class="mt-2">
              <core-input v-model="alternative_address.zip_code" :value="alternative_address.zip_code" :label="$t('zip_code')" />
            </div>
            <div class="mt-2">
              <core-input v-model="alternative_address.city" :value="alternative_address.city" :label="$t('city')" />
            </div>
            <div class="mt-2">
              <core-input v-model="alternative_address.notification_email" :value="alternative_address.notification_email" :label="$t('email')" />
            </div>
            <div class="mt-2">
              <core-input v-model="alternative_address.telephone" :value="alternative_address.telephone" :label="$t('phone')" />
            </div>
            <div class="mt-2">
              <core-select-country
                v-model="alternative_address.country"
                class="w-full max-w-full mt-2"
                :label="$t('select_entity', { entity: $tc('country').toLowerCase() })"
                :value="alternative_address.country"
                :data-override="alternative_address.available_countries"
              />
            </div>
          </div>
        </div>
        <div class="flex">
          <div>
            <core-button @click="deletePage">
              {{ $t("delete") }}
            </core-button>
          </div>
          <div class="flex flex-none ml-auto">
            <core-button @click="cancel">
              {{ $t("cancel") }}
            </core-button>
            <core-button class="ml-2" type="coolrunner" @click="savePage">
              {{ $t("save") }}
            </core-button>
          </div>
        </div>
      </div>
    </div>
    <share-dialog v-if="modals.share" :route="page" :show="modals.share" @dialog:close="modals.share = false" />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/mixins/Loader";
import ShareDialog from "./components/ShareDialog";
import { VueEditor } from "vue2-editor";
import CheckoutSelect from "../Integrations/Shops/components/CheckoutSelect.vue";

export default {
  name: "ReturnPage",
  components: { ShareDialog, VueEditor, CheckoutSelect },
  mixins: [Loader],
  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }]
    ],
    endpoint: "returns",
    loading: false,
    checkoutsOpen: false,
    modals: {
      alternativeAddress: false,
      share: false,
      deliveryMethodDialog: {
        visible: false,
        deliveryMethod: null,
      },
    },
    alternative_address: {
      company: null,
      attention: null,
      street: null,
      city: null,
      zip_code: null,
      notification_email: null,
      telephone: null,
      country: null,
    },
    tags: {
      receiver_name: "{RECEIVER_NAME}",
      price: "{PRICE}",
      currency: "{CURRENCY}",
      carrier: "{CARRIER}",
    },
    cause: null,
    maxId: 1,
    listIndex: 0,
    page: {
      name: null,
      product: null,
      logo: null,
      customer_url: null,
      title: null,
      description: null,
      country: "DK",
      country_name: null,
      base_url: null,
      price: 0,
      currency: "DKK",
      settings: {
        causes: [],
        public: false,
        css_style: null,
        order_number: false,
        private: false,
        alternative_address: {},
        order_gate: false,
        order_gate_title: null,
        order_gate_description: null,
        order_gate_age_limit: null,
        reference_key: null,
        smartcheckout: false,
      },
    },
  }),
  computed: {
    page_id: function() {
      return this.$route.params.id;
    },
    return_countries: function() {
      return this.$store.state.return_countries;
    },
    product_list: function() {
      return this.$store.state.return_products[this.page.country] || [];
    },
    page_url: function() {
      if (this.page.base_url) return `${this.page.base_url}/${this.page.name}/${this.page.country}`;
      else return `https://return.coolrunner.dk/${this.page.name}/${this.page.country}`;
    },
    norway_checked: function () {
      if(this.page.country === 'NO') return true
      else return this.page.settings.order_gate
    }
  },
  watch: {
    product_list: function(newVal) {
      let current_product = this.page.product;
      let product_exists = !!this.$_.find(newVal, (product) => {
        return product.value === current_product;
      });

      if (!product_exists && !this.page_id) this.page.product = newVal[0].value;
    },
    "page.route": function(newVal) {
      this.page.name = newVal.replace(/[\W_]+/g, "");
    },
  },
  mounted() {
    if (!this.$store.state.return_products) {
      this.loading = true
      this.$store.dispatch("loadReturnProducts").then((res) =>{
        if (this.page_id) this.getDataFromApi();
        else {
        this.page.description = this.$t("returns_default_description");
        this.loading = false
        }
      })
    } else {
      if (this.page_id) this.getDataFromApi();
      else {
        this.page.description = this.$t("returns_default_description");
      }
    }
  },
  created() {
    this.loaderOptions = {
      ...this.loaderOptions,
    };
  },
  methods: {
    createDeliveryMethod: function() {
      this.modals.deliveryMethodDialog.visible = true;
    },
    showPreview() {
      if (this.page.settings.public || this.page.settings.order_gate) {
        window.open(`${this.page_url}?lang=${this.page.country.toLowerCase()}`);
      } else if (this.page.settings.private) {
        this.loading = true;
        this.$get(`returns/${this.page_id}/key`).then((response) => {
          this.loading = false;
          let private_key = response.url;
          window.open(`${private_key}?lang=${this.page.country.toLowerCase()}`);
        });
      }
    },
    updateAddress() {
      this.page.settings.alternative_address = { ...this.alternative_address };
      this.modals.alternativeAddress = false;
    },
    resetAddress() {
      this.alternative_address = { ...this.page.settings.alternative_address };
      this.modals.alternativeAddress = false;
    },
    back() {
      this.$router.back();
    },
    cancel() {
      this.getDataFromApi();
      this.resetAddress();
    },
    getProductTitle(product) {
      let price_top = this.$n(Math.ceil(product.prices.max.price_excl_vat), "currency", "da-DK");
      let price_bottom = this.$n(Math.ceil(product.prices.min.price_excl_vat));

      let price =
        product.prices.min.price_excl_vat == product.prices.max.price_excl_vat
          ? this.$n(product.prices.max.price_excl_vat, "currency", "da-DK")
          : `${price_bottom}-${price_top}`;

      return `${product.title} (${price})`;
    },
    handleSelection: function(val, value) {
      switch (val) {
        case "private":
          this.page = {
            ...this.page,
            settings: {
              ...this.page.settings,
              public: false,
              order_gate: false,
              private: true,
            },
          };
          break;
        case "public":
          this.page = {
            ...this.page,
            settings: {
              ...this.page.settings,
              public: true,
              order_gate: false,
              private: false,
            },
          };
          break;
        case "gate":
          this.page = {
            ...this.page,
            settings: {
              ...this.page.settings,
              public: false,
              order_gate: true,
              private: false,
            },
          };
          break;
        case "smart":
          this.page = {
            ...this.page,
            settings: {
              ...this.page.settings,
              smartcheckout: !this.page.settings.smartcheckout,
              public: false,
              order_gate: true,
              private: false,
              checkout_id: null,
            },
          };
          break;
      }
    },
    savePage() {
      this.updateAddress();
      this.loading = true;
      this.$put(`/${this.endpoint}/${this.page_id || null}`, this.page)
        .then(() => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$t("returns"),
            }),
            type: "success",
          });
          this.getDataFromApi();
        })
        .catch((error) => {
          this.loading = false;

          if (error.response && error.response.data) {
            let errors = error.response.data.errors || [];

            Object.entries(errors).forEach((error) => {
              const [key, messages] = error;

              messages.forEach((message) => {
                message = message.replace(key, this.$t(key));
                this.$notification({
                  title: this.$t("error"),
                  text: message,
                  type: "error",
                });
              });
            });
          }
        });
    },
    getDataFromApi() {
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();

      this.loading = true;

      this.$get(
        `/${this.endpoint}/${this.page_id}`,
        {
          cancelToken: this.cancelToken.token,
        },
        { mutateLocation: true, useDev: this.useDev }
      )
        .then((response) => {
          this.page = response;
          if (!response.settings.order_gate && !response.settings.private) {
            this.page.settings.public = true;
          }
          this.alternative_address = {
            ...this.page.settings.alternative_address,
          };
          this.loading = false;
        })
        .catch((e) => {
          if (e !== "cancelled") this.loading = false;
          this.$router.replace({ name: "returns" });
        });
    },
    deletePage: function() {
      let self = this;
      this.$confirm(
        self.page.name,
        self.$t("delete_entity", {
          entity: self.$t("returns").toLocaleLowerCase(),
        })
      )
        .then(function() {
          self.$axios.delete(`returns/${self.page_id}`).then(function() {
            self.$notification({
              title: self.$t("entity_deleted_correctly", {
                entity: self.$t("returns").toLowerCase(),
              }),
              type: "info",
            });
            self.cancel();
          });
        })
        .catch(function() {});
    },
    add() {
      const id = ++this.maxId;
      const index = this.listIndex;
      this.page.settings.causes.splice(index, 0, { id: id, text: this.cause });
      this.cause = "";
    },
    remove(val) {
      const index = val;
      this.page.settings.causes.splice(index, 1);
    },
    computedClass: function() {
      let classes = "";
      if (this.checkoutsOpen) {
        classes = "max-h-[300px]";
      } else {
        classes = "";
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.list-leave-active {
  opacity: 1;
}

.list-leave-to {
  opacity: 0;
  transform: translateX(50px);
}
#private:checked ~ .private-box {
  background-color: #eff6ff;
  border-color: #3b82f6;
  color: #3b82f6;
}
#public:checked ~ .public-box {
  background-color: #eff6ff;
  border-color: #3b82f6;
  color: #3b82f6;
}
#gate:checked ~ .gate-box {
  background-color: #eff6ff;
  border-color: #3b82f6;
  color: #3b82f6;
}
#simple:checked ~ .simple-box {
  background-color: #eff6ff;
  border-color: #3b82f6;
  color: #3b82f6;
}
#smart:checked ~ .smart-box {
  background-color: #eff6ff;
  border-color: #3b82f6;
  color: #3b82f6;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #033047;
  border-radius: 100vh;
  transition: 300ms ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #324e5c;
  transition: 300ms ease-in-out;
}
</style>
