var render = function render(){var _vm=this,_c=_vm._self._c;return _c('core-view',[_c('core-view-header',{attrs:{"name":_vm.name,"count":_vm.totalItems}}),_c('core-list-new-table',{staticClass:"flex-grow-0 shadow-sm rounded-b-md",attrs:{"items":_vm.items,"headers":_vm.headers,"pageSize":_vm.options.itemsPerPage,"currentPage":_vm.options.page,"totalItems":_vm.totalItems,"loading":_vm.loading,"orderBy":_vm.options.orderBy,"orderDir":_vm.options.orderDir,"query":_vm.search,"selected":_vm.selected,"showCreateNew":"","filterable":false,"rowActions":_vm.rowActions},on:{"update:pageSize":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:currentPage":function($event){return _vm.$set(_vm.options, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.options, "page", $event)},"update:orderBy":function($event){return _vm.$set(_vm.options, "orderBy", $event)},"update:order-by":function($event){return _vm.$set(_vm.options, "orderBy", $event)},"update:orderDir":function($event){return _vm.$set(_vm.options, "orderDir", $event)},"update:order-dir":function($event){return _vm.$set(_vm.options, "orderDir", $event)},"update:query":function($event){_vm.search=$event},"checkbox-change":_vm.handleSelection,"all-selected-change":_vm.handleSelection,"create-new-clicked":function($event){return _vm.editItem()},"row-click":item => _vm.editItem(item)},scopedSlots:_vm._u([{key:"column.type",fn:function({ item }){return [_c('p',{staticClass:"small content"},[_vm._v(" "+_vm._s(_vm.getFilterTypeLabel(item.type))+" ")])]}}])}),_c('core-dialog',{attrs:{"title":this.$t(`create_entity`, {
        entity: _vm.$tc(_vm.name).toLowerCase()
      }),"visible":_vm.dialog,"xs_width":90,"lg_width":30},on:{"update:visible":function($event){_vm.dialog=$event},"closed":_vm.afterClose}},[_c('div',{staticClass:"form wrapper"},[_c('row',{attrs:{"columns":24,"gutter":20}},[_c('column',{attrs:{"xs":24}},[_c('core-input',{attrs:{"label":_vm.$t('title')},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('column',{attrs:{"xs":24}},[_c('core-input',{attrs:{"label":_vm.$t('key')},model:{value:(_vm.editedItem.key),callback:function ($$v) {_vm.$set(_vm.editedItem, "key", $$v)},expression:"editedItem.key"}})],1),_c('column',{attrs:{"xs":24}},[_c('core-select',{attrs:{"options":_vm.$store.state.filterTypes,"label":_vm.$t('type')},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}})],1)],1)],1),_c('span',{staticClass:"dialog-footer flex",attrs:{"slot":"footer"},slot:"footer"},[_c('core-button',{staticClass:"mr-auto",attrs:{"type":"default_outline"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('core-button',{attrs:{"disabled":!_vm.editedItem.title.length ||
            !_vm.editedItem.key.length ||
            !_vm.editedItem.type.length,"loading":_vm.saving,"type":"coolrunner"},on:{"click":function($event){!(
            !_vm.editedItem.title.length ||
            !_vm.editedItem.key.length ||
            !_vm.editedItem.type.length
          ) && _vm.save()}}},[_vm._v(_vm._s(_vm.$t("save_new_entity", { type: _vm.$tc(_vm.name).toLowerCase() })))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }