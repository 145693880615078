<template>
  <div class="wrapper">
    <div class="title">{{ title }}</div>
    <div class="description" v-if="description">{{ description }}</div>
    <el-row
      :key="index"
      type="flex"
      align="middle"
      v-for="(val, index) in values"
      :gutter="20"
    >
      <el-col :span="22">
        <core-checkbox
          :key="`input_${index}`"
          :value="val.value"
          class="mb-4"
          @input="v => handleInput(v, index)"
        />
        {{ val.value ? $t("yes") : $t("no") }}
      </el-col>
      <el-col :span="2">
        <core-filter-modal-filter-clear
          @click="$emit('remove-filter', index)"
        />
      </el-col>
    </el-row>
    <el-col :span="22" v-if="!values.length">
    <core-checkbox
      v-bind:key="'input_0'"
      class="mb-4"
      @input="v => handleInput(v)"
    />
    {{ !value ? $t("yes") : $t("no") }}
    </el-col>
  </div>
</template>

<script>
import _ from "lodash";
import mixin from "./typeMixin";
export default {
  mixins: [mixin],
  created() {
  },
  methods: {
    handleInput: function(val, index) {
      this.$emit("update-filter", val, index);
    }
  }
};
</script>
