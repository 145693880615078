<template>
  <el-row class="core-list__row" @click.native="$emit('click')">
    <el-col
      :span="breakpoint.innerWidth < breakpoints.large ? 24 : 1"
      v-if="selectable"
      class="checkbox"
      style="max-width: 25px;"
    >
      <div @click.stop="">
        <el-checkbox
          :value="selected"
          @change="value => $emit('checkbox-change', { value: !!value, index })"
        />
      </div>
    </el-col>
    <el-col
      :span="availableSpans"
      v-bind:key="`column_${header.key}`"
      v-for="header in headers"
    >
      <el-row>
        <el-col :span="12" v-if="breakpoint.innerWidth < breakpoints.large">
          <p class="small content" style="font-weight: bold;">
            {{ header.localized ? header.label : $t(header.label) }}
          </p>
        </el-col>
        <el-col :span="breakpoint.innerWidth < breakpoints.large ? 12 : 24">
          <slot
            v-if="$scopedSlots[`column.${header.key}`]"
            v-bind:item="item"
            :name="`column.${header.key}`"
          />

          <el-image
            v-else-if="isImageHeader(header)"
            :src="
              `${URL_PREFIX}/images/carriers/${$_.get(
                item,
                header.key
              )}.png?w=500&context=business_panel`
            "
            class="carrier-image content"
            :fit="'contain'"
            ondragstart="return false"
          >
            <div slot="error" class="image-slot">{{ getValue(header) }}</div>
          </el-image>
          <div
            class="small content"
            v-else-if="header.type === 'boolean'"
            :class="{ empty: getValue(header) }"
          >
            <font-awesome-icon
              class="boolean"
              fixed-width
              :icon="['far', getValue(header) ? 'check' : 'ban']"
            />
          </div>
          <p class="small content" v-else :class="{ empty: !getValue(header) }">
            {{ getValue(header) || "-" }}
          </p>
        </el-col>
      </el-row>
    </el-col>
    <el-col
      v-if="
        (extraActions && extraActions.length) ||
          (rowActions && rowActions.length > 0)
      "
      :span="breakpoint.innerWidth < breakpoints.large ? 24 : 1"
      style="margin-left: auto; display: flex; align-items: center"
    >
      <template v-if="extraActions && extraActions.length">
        <font-awesome-icon
          v-for="(action, index) in extraActions"
          :key="$_.join([index, ...action], '_')"
          :icon="action.icon"
          fixed-width
          @click.stop="() => action.action(item)"
        />
      </template>
      <core-list-actions
        v-if="rowActions && rowActions.length > 0"
        :open.sync="actionsVisible"
        :actions="rowActions"
        :entity="item"
        class="w-full"
      >
        <template
          slot="activator"
          v-if="breakpoint.innerWidth < breakpoints.large"
        >
          <core-button type="default_outline" class="mt-3 w-full">{{
            $tc("action", 99)
          }}</core-button>
        </template>
      </core-list-actions>
    </el-col>
  </el-row>
</template>

<script>
import { convertUTCToLocal } from "../../../utils/date";
import { BREAKPOINTS } from "../../../utils/constants";
import { URL_PREFIX } from "@/utils/constants";

export default {
  name: "item-component",
  props: {
    index: {
      type: Number
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    headers: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    rowActions: {
      type: Array,
      default: () => []
    },
    extraRowActions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    actionsVisible: false
  }),
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    breakpoint: function() {
      return this.$store.state.breakpoint;
    },
    availableSpans: function() {
      let availableSpans = 24;

      if (this.breakpoint.innerWidth < BREAKPOINTS.large) return availableSpans;
      if (this.selectable) availableSpans -= 1;
      if (this.rowActions && this.rowActions.length) availableSpans -= 1;

      return Math.round(availableSpans / this.headers.length);
    },
    breakpoints: () => BREAKPOINTS,
    extraActions: function() {
      return this.$_.filter(this.extraRowActions, a => !a.hidden);
    }
  },
  methods: {
    isImageHeader: function(header) {
      return header?.type ? header.type.includes("image") : false;
    },
    getValue: function(header) {
      const val = this.$_.get(this.item, header.key);

      switch (header.type) {
        case "date":
        case "datetime":
        case "time":
          return convertUTCToLocal(val, header.type);
        case "price":
          return this.$n(val, "currency", "da-DK");
        case "carrier":
        case "carrier_image":
          return (
            (this.$store.state.carriers && this.$store.state.carriers[val]) ||
            val
          );
        default:
          return val;
      }
    }
  }
};
</script>
