<template>
  <div class="filter__list_item_search">
    <input type="text">
  </div>
</template>

<script>
export default {
    name: 'serch'
}
</script>
