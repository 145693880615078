<template>
  <div class="wrapper">
    <div class="title">{{ title }}</div>
    <div v-if="description" class="description">{{ description }}</div>
    <core-filter-modal-type-date-selector
      v-if="showSelector !== undefined"
      :index="showSelector"
      :filter="filter"
      :value="value[showSelector]"
      :choices="choices"
      @update-filter="updateFilter"
    />
    <core-filter-modal-type-date-selected
      v-else
      :value="value"
      :title="title"
      :filter="filter"
      @remove-filter="index => $emit('remove-filter', index)"
      @add-new="showSelector = value.length"
      @edit-filter="index => (showSelector = index)"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: [Array, Object],
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    showSelector: undefined
  }),
  created: function() {
    if (!this.value.length) this.showSelector = 0;
  },
  computed: {
    title: function() {
      return this.filter.title || this.$t(`filters.${this.$vnode.key}`);
    },
    description: function() {
      return this.filter.description;
    },
    choices: function() {
      return this.filter.choices;
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      if (!newVal.length) this.showSelector = 0;
    }
  },
  methods: {
    updateFilter: function(val, index) {
      this.$emit("update-filter", val, index);
      this.showSelector = undefined;
    }
  }
};
</script>
