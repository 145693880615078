import Vue from "vue";
import VueGtag from "vue-gtag";
import router from "@/router";

Vue.use(
  VueGtag,
  {
    config: { id: "G-4C39PEH53V" }
  },
  router
);
