<template>
  <core-dialog
    :title="`${page.name} (${page.country_name})`"
    :visible="show"
    @update:visible="$emit('update:show')"
    :close-on-click-modal="false"
    @close="$emit('dialog:close')"
    style="overflow: hidden"
    class="returns__dialog"
    :xs_width="90"
    :md_width="30"
    :destroy-on-close="true"
  >
    <div id="route_share_container" class="w-full py-5">
      <core-clipboard-input
        :value="url"
        v-model="url"
        ref="copyToClipboard"
        style="width: 90%"
      >
        <template v-slot:append v-if="page.settings.private">
          <core-button
            @click="updatePrivateKey"
            class="returns__dialog__reload-url"
            :class="{ loading: loading }"
            :disabled="loading"
            :icon="['far', 'sync']"
          />
        </template>
      </core-clipboard-input>
    </div>
    <template v-slot:footer class="dialog-footer flex">
      <core-button
        class="mr-auto"
        type="default_outline"
        @click="$emit('dialog:close')"
      >
        {{ $t("close") }}</core-button
      >
      <core-button
        type="coolrunner"
        v-if="$refs"
        @click="copyToClipboard"
        :disabled="!url"
      >
        {{
          $t("copy_entity", { entity: $t("link").toLocaleLowerCase() })
        }}</core-button
      >
    </template>
  </core-dialog>
</template>

<script>
export default {
  name: "ReturnsShareDialog",
  data: () => ({
    loading: false,
    page: {
      name: null,
      country_name: null,
      private_key: null,
      settings: {
        private: false
      }
    }
  }),
  props: {
    show: {
      type: Boolean,
      default: false
    },
    route: {
      type: Object,
      default: () => {}
    }
  },
  mounted: function() {
    this.page = { ...this.route };
    this.init();
  },
  watch: {
    route: function(val) {
      this.page = { ...val };
      this.init();
    }
  },
  computed: {
    open: function() {
      return this.show;
    },
    url: function() {
      if (this.page.settings.private) return this.page.private_key;
      else return this.page.endpoint;
    }
  },
  methods: {
    init() {
      if (this.page.settings.private && !this.page.private_key)
        this.updatePrivateKey();
    },
    coreClipBoardAppendAction() {},
    copyToClipboard() {
      this.$refs.copyToClipboard.copyToClipboard();
    },
    updatePrivateKey() {
      if (this.page.settings.private) {
        this.loading = true;
        this.page.private_key = null;
        this.$get(`returns/${this.route.uid}/key`).then(response => {
          this.loading = false;
          const route = { ...this.route };
          route.private_key = response.url;

          this.page = route;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.returns__dialog__reload-url {
  &:disabled {
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: inherit;
    }
  }

  &.loading {
    svg {
      animation: fa-spin 2s infinite linear;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      animation-name: fa-spin;
    }
  }
}

.core-clipboard {
  width: 100%;
  &.core-search {
    width: 100%;
  }

  &::v-deep {
    input {
      height: 4em;
    }

    .el-input__prefix {
      left: 10px;
    }

    .el-input__prefix,
    .el-input-group__append {
      button {
        transition: color 0.5s ease;

        &:hover,
        &.loading {
          color: #157efb;
        }
      }
    }

    .el-input__inner,
    .el-input-group__append {
      border-color: #bec1c4;
      &.el-input__inner {
        background-color: #ebeef2;
      }
    }
  }
}
</style>
