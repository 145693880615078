<template>
  <div class="filter__list_item" :class="{ active }" @click="$emit('click')">
    <span>
      {{ filter.title || $t(`filters.${$vnode.key}`) }}
    </span>
    <div v-if="numberOfFilters" class="box filter--count">
      {{ numberOfFilters }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: [Array, Object, Number],
      default: () => []
    },
    optionsMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    numberOfFilters: function() {
      if (this.filter.base_type !== "select" && !this.optionsMode)
        return this.$_.isArray(this.value) ? this.value.length : this.value;

      let temp = this.optionsMode ? this.value : this.value[0];

      return temp?.value?.length;
    }
  }
};
</script>
