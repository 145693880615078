<template>
  <div id="rp_container" class="h-pdf" >
  </div>
</template>

<script>
import Loader from "@/mixins/Loader";

export default {
  name: "CreateShipmentPayment",
  mixins: [Loader],
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    paymentSessionId: {
        type: String,
        default: null
    },
    acceptUrl: {
        type: String,
        required: true,
    }
  },
  data: () => ({
    approved: false
  }),
  computed: {
  },
  mounted() {
    this.loadPaymentWindow(this.paymentSessionId)
  },
  methods: {
    loadPaymentWindow: function (sessionID) {
        let rp = new window.Reepay.EmbeddedCheckout(sessionID, { html_element: 'rp_container', showReceipt: false } );
        rp.addEventHandler(window.Reepay.Event.Accept, (data) => {
            this.$axios.get(this.acceptUrl).then(() => {
                this.$emit("process", 'order');
            });
        });
    },
  }
};
</script>

<style scoped></style>