<template>
  <div class="p-5">
    <row :gutter="10">
      <column
        v-for="(size, index) in standard_products"
        v-bind:key="size.title"
        :xs="index === standard_products.length - 1 ? 6 : 4"
      >
        <core-card
          class="h-32 justify-center p-0"
          @click="selected_index = index"
          :selected="selected_index === index"
        >
          <img
            :src="size.image.uri"
            class="image"
            style="height: 42px; margin-top: 1em"
          />
          <div style="padding: 5px;">
            <span style="font-weight: bold;">{{ size.title }}</span>
            <div class="bottom clearfix">
              <span v-if="size.dimensions"
                >Max: {{ getDimensionString(size.dimensions) }} cm</span
              >
            </div>
          </div>
        </core-card>
      </column>
      <column :xs="6">
        <core-card
          @click="selected_index = lastDimensionIndex"
          :selected="selected_index === lastDimensionIndex"
          :class="[
            { 'h-80': selected_index === lastDimensionIndex },
            'justify-center h-32 overflow-hidden transition-height duration-500 ease-in-out 	'
          ]"
          class="product__card--last"
        >
          <img
            :src="lastDimension.image.uri"
            class="image"
            style="height: 42px; margin-top: 1em"
          />
          <div style="padding: 14px;">
            <span style="font-weight: bold">{{ lastDimension.title }}</span>
            <div class="bottom clearfix">
              <span>
                {{ $t("click_to_enter") }}
              </span>
            </div>
          </div>

          <row
            :columns="12"
            :gutter="10"
            v-if="selected_index === lastDimensionIndex"
          >
            <column :xs="12">
              <core-input
                type="number"
                :label="$t('length')"
                :value="value.length"
                @input="val => setValue('length', val)"
                :inputFilter="
                  `return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57`
                "
                disablePaste="this.blur()"
              />
            </column>
            <column :xs="12">
              <core-input
                type="number"
                :label="$t('width')"
                :value="value.width"
                @input="val => setValue('width', val)"
                :inputFilter="
                  `return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57`
                "
                disablePaste="this.blur()"
              />
            </column>
            <column :xs="12">
              <core-input
                style="margin-bottom: 1em"
                type="number"
                :label="$t('height')"
                :value="value.height"
                @input="val => setValue('height', val)"
                :inputFilter="
                  `return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57`
                "
                disablePaste="this.blur()"
              />
            </column>
          </row>
        </core-card>
      </column>
    </row>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data: () => ({
    selected_index: null
  }),
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    selected_index: function() {
      if (this.selected_index !== this.lastDimensionIndex)
        this.selectProduct(this.selected_index);
    }
  },

  computed: {
    standard_products: function() {
      const products = [...this.dimensions];
      products.pop();
      return products;
    },
    dimensions: function() {
      return this.$store.state.product_dimensions.sizes;
    },
    currentProductTitle: function() {
      return this.size?.title;
    },
    lastDimensionIndex: function() {
      return this.dimensions.length - 1;
    },
    lastDimension: function() {
      return this.dimensions[this.lastDimensionIndex];
    },
    currentDimensionString: function() {
      return `${this.value.length} x ${this.value.height} x ${this.value.width}`;
    }
  },
  mounted() {
    if (this.value.length && this.value.width && this.value.height) {
      this.selected_index = this.lastDimensionIndex;
      let idx = _.findIndex(this.dimensions, d => {
        return _.join(d.dimensions, " x ") == this.currentDimensionString;
      });
      if (idx !== -1) this.selected_index = idx;
      else if (this.currentDimensionString == "60 x 10 x 10")
        this.selected_index = this.lastDimensionIndex - 1;
      else this.selected_index = this.lastDimensionIndex;

      this.$emit("set:nextStep", "weight");
    } else this.selected_index = null;
  },

  methods: {
    setValue: function(prop, value) {
      const upd = {};
      upd[prop] = value;
      this.$emit("update:value", {
        ...this.value,
        ...upd
      });
      if (this.value.height && this.value.length && this.value.width) {
        this.$emit("set:nextStep", "weight");
      } else this.$emit("set:nextStep", "");
    },

    getDimensionString: function(dimension) {
      return dimension.join(" x ");
    },
    selectProduct(index) {
      const size = { ...this.dimensions[index] };

      const dimensions = size?.dimensions || [0, 0, 0];

      this.$emit("update:value", {
        ...this.value,
        height: this.$_.nth(dimensions, 1),
        width: this.$_.last(dimensions),
        length: this.$_.first(dimensions)
      });

      this.$emit("set:nextStep", "weight");
    }
  }
};
</script>
