const defaultMenu = [
  {
    type: "single",
    icon: ["far", "home-alt"],
    index: "home",
    label: "overview",
    businessOnly: true
  },
  {
    type: "group",
    icon: ["far", "truck"],
    index: "labels",
    href: "labels",
    label: "shipments",
    entries: [
      {
        type: "single",
        index: "labels",
        label: "overview"
      },
      {
        type: "single",
        index: "labels_import",
        query: { state: "importer" },
        label: "import",
        businessOnly: true
      }
    ]
  },
  {
    type: "single",
    icon: ["far", "boxes"],
    index: "wmsorders",
    label: "wms_orders",
    capabilityKey: "wms",
    serviceKey: "wms"
  },
  {
    type: "group",
    icon: ["far", "truck-ramp"],
    label: "pickup",
    index: "pickup",
    href: "pickup",
    businessOnly: true,
    capabilityKey: "transfer",
    serviceKey: "has_fixed_pickup",
    entries: [
      {
        type: "single",
        index: "pickup",
        label: "overview"
      },

      {
        type: "single",
        index: "pickup_pallets",
        label: "Afhentninger",
        serviceKey: "transfer_enabled",
      }
    ]
  },
  {
    type: "single",
    icon: ["far", "analytics"],
    index: "insights",
    label: "insight",
    businessOnly: true,
    serviceKey: "insights",
    capabilityKey: "insights",
  },
  {
    type: "group",
    icon: ["far", "undo"],
    label: "returns",
    index: "returns",
    href: "returns",
    businessOnly: true,
    entries: [
      {
        type: "single",
        index: "returns",
        label: "returns"
      },
      {
        type: "single",
        index: "returns_statistics",
        label: "statistics",
      },
      {
        type: "single",
        index: "returns_returnlistgenerator",
        label: "return_list_generator",
      }
    ]
  },
  {
    type: "single",
    index: "contacts",
    label: "contact",
    icon: ["far", "users"]
  },
  {
    type: "single",
    icon: ["far", "money-check-alt"],
    index: "prices",
    label: "prices"
  },
  {
    type: "single",
    icon: ["far", "file-alt"],
    index: "documents",
    label: "documents",
    capabilityKey: "documents",
    serviceKey: "documents"
  },
  {
    type: "single",
    icon: ["far", "ticket"],
    index: "claims",
    label: "claims",
    businessOnly: true,
  },
  {
    type: "single",
    icon: ["far", "puzzle-piece"],
    index: "apps",
    label: "add-ons",
    businessOnly: true,
    serviceKey: "apps",
    capabilityKey: "apps",
  },
  {
    type: "group",
    icon: ["far", "bags-shopping"],
    label: "integrations",
    index: "integrations",
    href: "integrations_api",
    capabilityKey: "integrations",
    businessOnly: true,
    entries: [
      {
        type: "single",
        index: "integrations_api",
        label: "api_key"
      },

      {
        type: "single",
        index: "integrations_shops",
        label: "my_shops"
      }
    ]
  },
  {
    type: "group",
    icon: ["far", "cogs"],
    label: "settings",
    href: "account_settings",
    index: "settings",
    capabilityKey: "settings",
    entries: [
      {
        type: "single",
        index: "account_settings",
        label: "account.menu_header"
      },
      {
        type: "single",
        index: "account_settings_payment",
        label: "account.payment"
      },
      {
        type: "single",
        index: "account_settings_users",
        label: "account.users",
        businessOnly: true
      },
      /*
      {
        type: "single",
        index: "account_settings_roles",
        label: "account.roles"
      },*/
      {
        type: "single",
        index: "account_customfilters",
        label: "filter_type",
        businessOnly: true
      },
      {
        type: "single",
        index: "orders",
        label: "order_history"
      },
      {
        type: "single",
        index: "invoices",
        label: "invoice",
        businessOnly: true
      }
    ]
  },
  {
    type: "group",
    icon: ["far", "tools"],
    label: "tools",
    href: "tools_smartcheckout",
    index: "tools",
    businessOnly: true,
    capabilityKey: "smartcheckout",
    entries: [
      {
        type: "single",
        index: "tools_smartcheckout",
        label: "checkouts",
        businessOnly: true,
      },
      {
        type: "single",
        index: "tools_printers",
        label: "printers",
        businessOnly: true
      },
      {
        type: "single",
        index: "tools_lights",
        label: "lights",
        businessOnly: true,
        serviceKey: "lights"
      }
    ]
  },
  {
    type: "single",
    icon: ["far", "sign-out-alt"],
    index: "logout",
    label: "logout"
  }
];

export default defaultMenu;
