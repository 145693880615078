<template>
  <div class="relative w-full" data-app>
    <div v-if="windowWidth < 600" class=" h-screen w-screen bg-[#EAF1FB] absolute top-0 left-0 z-[1000]" style="background-image: url(https://a.storyblok.com/f/124841/1155x1416/d2196d3833/grid-5.svg); background-size: cover; background-position: -200px 0px;">
      <div class="p-5">
        <div class="w-full bg-white py-3 px-3 rounded-full">
          <img alt="Homerunner Logo" src="https://a.storyblok.com/f/120110/x/c8ab0bf836/homerunner_logo.svg" class="h-5 mx-auto" />
        </div>
        <div class="bg-white rounded-2xl p-5 mt-10">
          <p class=" text-xl mb-5 font-semibold">{{ $t('not_for_phone') }}</p>
          <p>{{ $t('not_for_phone_description') }}</p>
        </div>
      </div>
    </div>
    <v-breakpoint v-model="breakpoint" />

    <div v-if="!$_.isEmpty(breakpoint) && windowWidth > 600">
      <core-terms :visible="hasPendingDocuments" />
      <core-header />
      <div id="bp__main-view-wrapper" class="p-3 md:p-5 flex">
        <div class="grid grid-cols-12 gap-5 w-full max-w-screen-hd mx-auto">
          <div
            class="hidden lg:block col-span-3 lg:col-span-3 xl:col-span-2 relative"
            :key="'sidebar'"
          >
            <core-menu
              elementName="side-nav"
              elementClass="main-content shadow-sm rounded-lg sticky bg-white w-full max-w-full top-0"
            />
          </div>
          <div
            class="col-span-12 lg:col-span-9 xl:col-span-10"
            :key="'main_router'"
          >
            <div
              id="bp__main-view"
              class="main-content main-content rounded-lg flex h-full"
            >
              <transition name="slide-bottom" mode="out-in">
                <router-view name="main" />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VBreakpoint } from "vue-breakpoint-component";
import { BREAKPOINTS } from "@/utils/constants";
export default {
  data: () => ({
    terms: false,
    issue: false
  }),
  mounted() {
    this.checkForIssues();
  },
  components: { VBreakpoint },
  computed: {
    breakpoints: () => BREAKPOINTS,
    windowWidth() {
      return window.innerWidth
    },
    breakpoint: {
      set: function(val) {
        this.$store.dispatch("setBreakpoint", val);
      },
      get() {
        return this.$store.state.breakpoint;
      }
    },
    computedTransition() {
      return this.$store.state.transitionDelay;
    },
    hasPendingDocuments: function() {
      const pending = this.$store.state?.account?.pending_documents;
      return !!pending?.length;
    }
  },
  methods: {
    checkForIssues: function() {
      let issues = this.$store.state?.account?.service_messages;

      if (issues && issues.length) {
        issues.forEach(issue => {
          this.$notification({
            title: issue.title,
            text: issue.html,
            type: "warning",
            duration: -5
          });
        });
      }
    }
  },
  watch: {
    $route(to, from) {
      if (this.breakpoint.innerWidth > this.breakpoints.large) {
        return;
      }
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    }
  }
};
</script>
