<template>
    <core-view>
    <core-list-content :loading="loading" style="padding:0">
    <div class="bg-white grid grid-cols-2 rounded-t-md pt-[3%] px-[4%] mb-6">
      <div class="col-span-1 text-left max-w-sm"><p class="text-3xl text-coolrunner-500 font-semibold">{{$t('page_descriptions.apps')}}</p></div>
      <div class="pr-4">
        <div class="col-span-1">
          <img class="max-w-md h-auto float-right border-8 border-gray-100 rounded-t-md" src="https://coolrunner-assets.s3.eu-central-1.amazonaws.com/public/img/appimg.PNG" alt="">
        </div>
      </div>
    </div>
    <div class="timeline bg-white p-6">
      <div class="flex mb-4">
        <div @click="scroll('integration')" class="bg-[#F5F7FA] flex max-w-min py-2 px-3  rounded-md group cursor-pointer transition duration-300 mr-3">
          <font-awesome-icon
                  class="text-sm mt-1.5 mr-2 group-hover:text-coolrunner-500 transition duration-300"
                  :icon="['far', 'project-diagram']"
                />
           <p>Integration</p>
        </div>
        <div @click="scroll('carriers')" class="bg-[#F5F7FA] flex max-w-min py-2 px-3 rounded-md group cursor-pointer transition duration-300 mr-3">
          <font-awesome-icon
                  class="text-sm mt-1.5 mr-2 group-hover:text-coolrunner-500 transition duration-300"
                  :icon="['far', 'truck']"
                />
           <p>Transportør</p>
        </div>
      </div>
      <core-search
      class="max-w-xs"
       :query.sync="query" />
    </div>
    <div class="recommended"></div>
    <div id="integration" class="w-full  flex-wrap relative bg-white">
        <h3 class="py-2 px-7 text-lg font-medium">{{'1. ' + $t('add-ons')}}</h3>
        <div class="absolute top-3.5 right-5">
              <p class="has-tooltip">
                <font-awesome-icon
                  class="text-xl text-gray-400 cursor-pointer"
                  :icon="['fal', 'info-circle']"
                /><span
                  class="tooltip text-sm bg-gray-50 shadow-md rounded-md p-2"
                  >{{ $t('add-ons_description') }}</span
                >
              </p>
            </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 pt-4 gap-6 pb-6 bg-white p-6"
     v-if="show">
        <div
        v-for="service, index in services"
        v-bind:key="`route-${index}`"
      >
        <transition appear name="return-animation" mode="out-in">
        <core-appcard
        @serviceEnabled="enableService"
        type="product"
        :data="service"
        :style="{ '--i': index, '--length': service.length }"
        :headline="service.title"
        :description="service.description"
        :icon="service.icon"
        :enabled="service.installed"
        :set_entity="service"
        :id="service.type"
        />
        </transition>

      </div>

    </div>
    <div class="px-6 bg-white pb-2">
    <div class="h-[2px] w-full bg-gray-100"></div>
    </div>
    <div id="carriers" class="w-full  flex-wrap relative bg-white">
        <h3 class="py-2 px-7 text-lg font-medium">{{'2. ' + $t('carriers')}}</h3>
        <div class="absolute top-3.5 right-5">
              <p class="has-tooltip">
                <font-awesome-icon
                  class="text-xl text-gray-400 cursor-pointer"
                  :icon="['fal', 'info-circle']"
                /><span
                  class="tooltip text-sm bg-gray-50 shadow-md rounded-md p-2"
                  >{{ $t('carriers_description') }}</span
                >
              </p>
            </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 pt-4 gap-6 pb-6 bg-white p-6"
     v-if="show">
        <div
        v-for="carrier, index in carriers"
        v-bind:key="`route-${index}`"
      >
        <transition appear name="return-animation" mode="out-in">
        <core-appcard
        @serviceEnabled="enableService"
        type="carrier"
        :data="carrier"
        :style="{ '--i': index, '--length': carriers.length }"
        :headline="carrier.title"
        :description="carrier.description"
        :carrier="carrier.type"
        :enabled="carrier.installed"
        :id="carrier.type"
        />
        </transition>

      </div>

    </div>
    </core-list-content>
    </core-view>
</template>

<script>
import Loader from "@/mixins/Loader";
export default {
   mixins: [Loader],
    name: 'Apps',
    data: () => ({
      show: false,
      loading: false,
      query: null,
      services: [
          {
              title: 'Insights',
              type: 'insights',
              description: 'Bussiness insights giver dig et grafisk overblik over din virksomhed',
              billing: {
                price: 20,
                currency: 'DKK',
                billing_method: 'pr. måned'
              },
              icon: 'analytics',
              installed: true
          },
          {
              title: 'Mail',
              type: 'mail',
              description: 'Email service kan køre automatisk gennem CoolRunners API',
              billing: {
                price: 20,
                currency: 'DKK',
                billing_method: 'pr. måned'
              },
              icon: 'envelope',
              installed: true
          },
          {
              title: 'WMS',
              type: 'wms',
              description: 'Gennem CoolRRunners WMS system kan du få et nemt og overskueligt overblik over dit lager og status af pakker.',
              billing: {
                price: 20,
                currency: 'DKK',
                billing_method: 'pr. måned'
              },
              icon: 'boxes',
              installed: false
          }

      ],
      carriers: [],
    }),
    created() {
        this.show = true
        this.getDataFromApi()
    },
    methods: {
      enableService: function (val) {
        console.log(val);
      },
      getDataFromApi: function () {
        this.loading = true
        this.$get("app-store", {
        })
        .then(response => {
          this.carriers = response
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      },
      scroll: function(val) {
        const element = document.getElementById(val);
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
}
</script>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply opacity-0 absolute -left-20 transition duration-300 ease-in-out pointer-events-none;
}

.has-tooltip:hover .tooltip {
  @apply opacity-100 z-50 transition duration-300 ease-in-out pointer-events-none;
}
</style>