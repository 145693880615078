<template>
  <core-contact
    :entity="value.sender"
    :countryEndpoint="'customer/countries/sender'"
    @update:entity="val => update(val)"
    @validate="val => unlock(val)"
  />
</template>

<script>
export default {
  name: "CreateShipmentSender",
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    update: function(val) {
      this.$emit("update:value", { ...this.value, sender: val });
    },
    unlock: function(val) {
      this.$emit("set:nextStep", val ? "dimensions" : null);
    }
  }
};
</script>

<style scoped></style>
