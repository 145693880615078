var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{staticClass:"core-list__row",nativeOn:{"click":function($event){return _vm.$emit('click')}}},[(_vm.selectable)?_c('el-col',{staticClass:"checkbox",staticStyle:{"max-width":"25px"},attrs:{"span":_vm.breakpoint.innerWidth < _vm.breakpoints.large ? 24 : 1}},[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('el-checkbox',{attrs:{"value":_vm.selected},on:{"change":value => _vm.$emit('checkbox-change', { value: !!value, index: _vm.index })}})],1)]):_vm._e(),_vm._l((_vm.headers),function(header){return _c('el-col',{key:`column_${header.key}`,attrs:{"span":_vm.availableSpans}},[_c('el-row',[(_vm.breakpoint.innerWidth < _vm.breakpoints.large)?_c('el-col',{attrs:{"span":12}},[_c('p',{staticClass:"small content",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(header.localized ? header.label : _vm.$t(header.label))+" ")])]):_vm._e(),_c('el-col',{attrs:{"span":_vm.breakpoint.innerWidth < _vm.breakpoints.large ? 12 : 24}},[(_vm.$scopedSlots[`column.${header.key}`])?_vm._t(`column.${header.key}`,null,{"item":_vm.item}):(_vm.isImageHeader(header))?_c('el-image',{staticClass:"carrier-image content",attrs:{"src":`${_vm.URL_PREFIX}/images/carriers/${_vm.$_.get(
              _vm.item,
              header.key
            )}.png?w=500&context=business_panel`,"fit":'contain',"ondragstart":"return false"}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_vm._v(_vm._s(_vm.getValue(header)))])]):(header.type === 'boolean')?_c('div',{staticClass:"small content",class:{ empty: _vm.getValue(header) }},[_c('font-awesome-icon',{staticClass:"boolean",attrs:{"fixed-width":"","icon":['far', _vm.getValue(header) ? 'check' : 'ban']}})],1):_c('p',{staticClass:"small content",class:{ empty: !_vm.getValue(header) }},[_vm._v(" "+_vm._s(_vm.getValue(header) || "-")+" ")])],2)],1)],1)}),(
      (_vm.extraActions && _vm.extraActions.length) ||
        (_vm.rowActions && _vm.rowActions.length > 0)
    )?_c('el-col',{staticStyle:{"margin-left":"auto","display":"flex","align-items":"center"},attrs:{"span":_vm.breakpoint.innerWidth < _vm.breakpoints.large ? 24 : 1}},[(_vm.extraActions && _vm.extraActions.length)?_vm._l((_vm.extraActions),function(action,index){return _c('font-awesome-icon',{key:_vm.$_.join([index, ...action], '_'),attrs:{"icon":action.icon,"fixed-width":""},on:{"click":function($event){$event.stopPropagation();return (() => action.action(_vm.item)).apply(null, arguments)}}})}):_vm._e(),(_vm.rowActions && _vm.rowActions.length > 0)?_c('core-list-actions',{staticClass:"w-full",attrs:{"open":_vm.actionsVisible,"actions":_vm.rowActions,"entity":_vm.item},on:{"update:open":function($event){_vm.actionsVisible=$event}}},[(_vm.breakpoint.innerWidth < _vm.breakpoints.large)?_c('template',{slot:"activator"},[_c('core-button',{staticClass:"mt-3 w-full",attrs:{"type":"default_outline"}},[_vm._v(_vm._s(_vm.$tc("action", 99)))])],1):_vm._e()],2):_vm._e()],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }