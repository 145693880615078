<template>
  <core-view>
    <core-view-header
      :name="checkout.name"
      :description="editCheckout.description"
      :actions="[
        {
          label: $t('save'),
          disabled: !descriptionEdited && !nameEdited && !shopsEdited,
          loading: saving,
          action: () => saveCheckout()
        }
      ]"
    >
      <template slot="title">
        <div class="checkout-name">
          <el-input v-model="editCheckout.name" :placeholder="$t('name')" />
        </div>
      </template>
      <template slot="description">
        <div class="checkout-description">
          <el-input
            v-model="editCheckout.description"
            type="textarea"
            resize="none"
            autosize
            :placeholder="$t('description')"
          />
        </div>
        <div>
          <div class="mb-2">
            <core-select
              :options="shopOptions"
              :label="
                $t('add_entity', { entity: $tc('shop', 1).toLowerCase() })
              "
              @update:value="value => addShopToCheckout(value)"
            />
          </div>
          <div class="flex flex-start">
            <core-badge
              v-for="shop in editCheckout.shops"
              :key="shop.id"
              class="flex-grow-0 px-4 py-2 bg-coolrunner-500 hover:bg-coolrunner-700 mr-2 shadow-sm-coolrunner"
              deleteable
              @delete="removeShop(shop.id)"
            >
              {{ shop.name }}
            </core-badge>
          </div>
        </div>
      </template>
    </core-view-header>
    <core-list-new-table
      :items="orderedShippingMethods"
      :headers="headers"
      :totalItems="orderedShippingMethods.length"
      @create-new-clicked="editItem()"
      @row-click="item => editItem(item)"
      showCreateNew
      :paginate="false"
      :entityName="name"
      :loading="loading"
      :createNewLabel="
        $tc('add_new_entity', 1, { entity: $tc(name, 1).toLowerCase() })
      "
      :rowActions="rowActions"
      pushPath="tools_smartcheckout_shippingmethod"
    >
      <template v-slot:[`column.carrier`]="{ item }">
        <core-list-cell-templates-carrier-with-image
          :carrier="item.carrier"
          :url="URL_PREFIX"
        />
      </template>
      <template v-slot:column.priority="{ item }">
        <p class="small content">
          {{ getHighestPriority(item) }}
        </p>
      </template>

      <template v-slot:column.rules="{ item }">
        <p class="small content">
          {{ getRulesCount(item) }}
        </p>
      </template>
      <template v-slot:column.logo="{ item }">
        <div>
          <el-image
            :src="item.logo"
            class="carrier-image"
            :fit="'contain'"
            v-if="item.logo"
          >
          </el-image>
          <div v-else>
            -
          </div>
        </div>
      </template>
    </core-list-new-table>
  </core-view>
</template>

<script>
import data from "@/plugins/data";
import { URL_PREFIX } from "@/utils/constants";

export default {
  name: "SmartCheckoutShippingMethod",
  props: {},
  beforeRouteEnter(to, from, next) {
    if (to.params.smartcheckout_id == 'test1') {
      data
      let object = {
        checkout: {
          description:"Test Checkout",
          id:'test1',
          name:"Test",
          shops: [{
            activated: true,
            checkout_id: 'test1',
            id: 'test',
            name: "TestButik",
          }]
        },
        shipping_methods:[{
          carrier:"bring",
          description:"Denne fragtmetode vises når du bor i postnummer 9000",
          label:"Hurtigst",
          logo:null,
          name:"Bring Hjemmelevering",
          rules:[{priority: 1}],
        }],
        shops: [{ 
          id:'test',
          name:"TestButik",
        }]
      }
      next(vm => vm.setShippingMethods(object));
    } else {
      data
      .fetch(`smartcheckouts/${to.params.smartcheckout_id}/methods`)
      .then(r => {
        next(vm => vm.setShippingMethods(r));
      });
    }
  },

  data: () => ({
    name: "shipping_method",
    endpoint: "smartcheckouts",
    headers: [
      {
        label: "priority",
        key: "priority",
        orderable: false
      },
      {
        label: "carrier",
        key: "carrier",
        orderable: false
      },
      {
        label: "logo",
        key: "logo",
        orderable: false
      },
      {
        label: "name",
        key: "name",
        orderable: false
      },
      {
        label: "description",
        key: "description",
        orderable: false
      },
      {
        label: "label",
        key: "label",
        orderable: false
      },
      {
        label: "set_of_rules",
        key: "rules",
        orderable: false
      }
    ],
    loading: false,
    saving: false,
    checkout: {},
    editCheckout: {},
    shippingMethods: [],
    shops: []
  }),
  watch: {},
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    shopOptions: function() {
      const existing = this.$_.map(this.editCheckout.shops, "id");
      const shops = this.$_.filter(this.shops, s => {
        return !existing.includes(s.id);
      });

      return this.$_.map(shops, s => ({ value: s.id, label: s.name }));
    },
    orderedShippingMethods: function() {
      return this.$_.orderBy(
        [...this.shippingMethods],
        this.getHighestPriority
      );
    },
    descriptionEdited: function() {
      return this.checkout.description !== this.editCheckout.description;
    },
    nameEdited: function() {
      return this.checkout.name !== this.editCheckout.name;
    },
    shopsEdited: function() {
      const originalShops = this.$_.map(this.checkout.shops, "id");
      const shops = this.$_.map(this.editCheckout.shops, "id");

      const diff = this.$_.xor(originalShops, shops);

      return !!diff.length;
    },

    rowActions: function() {
      return [
        {
          label: this.$t("edit"),
          icon: ["far", "edit"],
          action: this.editItem
        },
        {
          label: this.$t("delete"),
          icon: ["far", "trash"],
          action: this.deleteItem
        }
      ];
    }
  },
  methods: {
    deleteItem(item) {
      const index = this.$_.findIndex(
        this.shippingMethods,
        c => c.id === item.id
      );

      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$tc(this.name, 1).toLowerCase()
        })
      ).then(() => {
        if (index >= 0) {
          this.loading = true;
          this.$del(
            `/smartcheckouts/${this.$route.params.smartcheckout_id}/methods/${item.id}`,
            undefined,
            {
              useDev: this.useDev
            }
          )
            .then(r => {
              let items = [...this.shippingMethods];
              items.splice(index, 1);
              this.shippingMethods = items;
              this.$notification({
                title: this.$t("success"),
                text: `${this.$tc(this.name)} ${this.$t(
                  "has_been_deleted"
                ).toLowerCase()}`,
                type: "success"
              });
            })
            .catch(e => {
              this.$notification({
                title: this.$t("not_deleted"),
                text: `${this.$tc(this.name)} ${this.$t(
                  "has_not_been_deleted"
                ).toLowerCase()}`,
                type: "error"
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    editItem: function(item) {
      if (item?.id) this.loading = true;
      this.$router.push({
        name: "tools_smartcheckout_shippingmethod",
        params: {
          smartcheckout_id: this.$route.params.smartcheckout_id,
          shipping_method_id: item?.id || "null"
        }
      });
    },
    setShippingMethods: function(r) {
      const { shipping_methods, checkout, shops } = r;
      this.shippingMethods = [...shipping_methods];
      this.checkout = { ...checkout };
      this.editCheckout = { ...checkout };
      this.shops = [...shops];
    },
    saveCheckout: function() {
      this.saving = true;

      this.$put(`smartcheckouts/${this.editCheckout.id}`, this.editCheckout)
        .then(r => {
          const { shipping_methods: shippingMethods, ...checkout } = r;
          this.shippingMethods = [...shippingMethods];
          this.checkout = { ...checkout };
          this.editCheckout = { ...checkout };
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$tc("checkout").toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
        })
        .catch(() => {
          this.$notification({
            title: this.$t("not_saved"),
            text: `${this.$tc("checkout")} ${this.$t(
              "has_not_been_saved"
            ).toLowerCase()}`,
            type: "error"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getHighestPriority: function(item) {
      const priorities = this.$_.map(item.rules, r => r.priority);
      return Math.min(...priorities);
    },
    getRulesCount: function(item) {
      return this.$_.size(item.rules);
    },
    addShopToCheckout: function(id) {
      const shop = this.$_.find(this.shops, ["id", id]);
      this.editCheckout.shops = [...this.editCheckout.shops, shop];
    },
    removeShop: function(id) {
      let shops = [...this.editCheckout.shops];
      const index = this.$_.findIndex(shops, ["id", id]);
      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$tc('shop', 1).toLowerCase()
        })
      ).then(() => {
        shops.splice(index, 1);
        this.editCheckout.shops = shops;
      })
    },
  }
};
</script>

<style lang="scss">
.checkout-name {
  .el-input {
    input {
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      text-align: left;
      color: #272727;
      margin: 0;
      border-width: 0;
      border-radius: 0;
      border-bottom-width: 1px;
      padding-left: 0;
    }
  }
}
.checkout-description {
  margin-top: 1em;
  margin-bottom: 20px;
  .el-textarea__inner {
    border-width: 0;
    border-radius: 0;
    border-bottom-width: 1px;
    padding-left: 0;
  }
}
.checkout-description,
.checkout-name {
  display: flex;
  width: 100%;
  align-items: flex-end;
  .el-input,
  .el-textarea {
    font-size: 16px;
    margin-right: 10px;
  }
  .el-button {
    transition: all 250ms;
    &.is-disabled {
      opacity: 0;
    }
  }
}
</style>
