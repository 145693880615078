<template>
  <core-input
    class="core-search"
    dense
    v-model="q"
    :label="label"
    :labelIcon="['far', 'search']"
    v-bind="$attrs"
  />
</template>

<script>
import i18n from "../../plugins/i18n";
import _ from "lodash";

export default {
  inheritAttrs: false,
  props: {
    query: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: i18n.t("search")
    },
    error: {
      type: String,
      default: ""
    },
    helperText: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    q: null
  }),
  created() {
    this.$ = this.$query;
    this.updateQuery = _.debounce(this.updateQuery, 500);
  },
  watch: {
    q: function(val) {
      this.updateQuery(val);
    }
  },
  methods: {
    updateQuery: function(val) {
      this.$emit("update:query", val);
    }
  }
};
</script>
