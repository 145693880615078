<template>
  <div
    class="core-list__empty flex flex-grow-0 flex-col items-center m-auto break-normal h-pdf"
  >
    <font-awesome-icon
      fixed-width
      :icon="emptyListIcon"
      class="mx-auto mb-5 text-6xl mt-32"
    />
    <div class="w-2/4 max-w-prose">
      <p class="text-center font-semibold	text-2xl">
        {{
          emptyListText ||
            $t("empty_list", { entity: $tc(entityName, 99).toLowerCase() })
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyList",
  props: {
    emptyListText: {
      type: String,
      default: ""
    },
    emptyListIcon: {
      type: Array,
      default: () => ["fad", "align-slash"]
    },
    entityName: {
      type: String,
      default: "element"
    }
  }
};
</script>
