import axios from "axios";
import { getTheme } from "@/components/widgets/options";

export default {
  props: {
    titlePosition: {
      type: String,
      default: "lower"
    },
    subtitle: {
      type: String,
      default: ""
    },
    shadow: {
      type: String,
      default: "never"
    },
    widgetId: {
      type: Number,
      default: undefined
    },
    data: {
      type: Object,
      default: undefined
    },
    viewName: {
      type: String,
      default: "shipments"
    },
    filteringEnabled: {
      type: Boolean,
      default: true
    },
    localData: {
      type: Boolean,
      default: false
    },
    location: {
      type: String,
      default: undefined
    },
    hasDescription: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    loading: true,
    value: "-",
    endpoint: "widgets",
    title: "",
    filters: {},
    cleanedFilters: {},
    interval: "",
    cancelToken: null
  }),
  watch: {
    loading: function() {
      this.setLoader();
    },
    cleanedFilters: function(cur, old) {
      if (this.$_.isEqual(cur, old)) return;
      this.fetchData();
    },
    interval: function(cur, old) {
      if (this.$_.isEqual(cur, old)) return;
      this.fetchData();
    },
    data: function(cur, old) {
      if (this.$_.isEqual(cur, old)) return;
      this.fetchData();
    }
  },
  mounted() {
    
    if (this.data?.meta?.interval || this.data?.filters) {
      this.setLoader();
      if (this.data?.meta?.interval) this.interval = this.data.meta.interval;

      if (this.data.filters) this.filters = this.data.filters;
    } else this.fetchData();
  },
  computed: {
    breakpoint: function() {
      return this.$store.state.breakpoint;
    },
    sub: function() {
      let sub = this.subtitle || this.$t("always");
      if (!this.filteringEnabled) return sub;

      const created_at = this.$_.first(this.filters.created_at);

      if (created_at?.value)
        sub =
          this.$_.get(
            this.$store.state,
            `viewDescriptions.${this.viewName}.created_at.choices.${created_at.value}`
          ) || sub;

      if (this.data?.meta?.operator === "unique")
        sub += ` (${this.$t("based_on", {
          entity: this.$t(this.data?.meta?.key).toLowerCase()
        })})`;

      return sub;
    },
    cTitle: function() {
      let title = this.title;

      return title;
    }
  },
  methods: {
    setLoader() {
      if (this.loading) {
        this.loadingInstance = this.$loading({
          lock: true,
          fullscreen: false,
          text: this.$t("loading"),
          target: this.$el,
          customClass: "z-50"
        });
      } else if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    fillData(widgetData) {
      this.dataCollection = {
        ...widgetData.data,
        datasets: this.$_.map(widgetData.data.datasets, ds => {
          return {
            ...ds,
            backgroundColor: getTheme(ds).reverse()
          };
        })
      };
      this.loading = false;
    },
    fetchData() {
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();
      this.loading = true;
      if (this.widgetId) {
        this.$get(`${this.endpoint}/${this.widgetId}/render`, {
          cancelToken: this.cancelToken.token
        })
          .then(r => {
            this.title = r.name;
            this.fillData(r);
          })
          .catch(e => {
            if (e !== "cancelled") {
              this.dataCollection = {};
              this.loading = false;
            }
          });
      } else if (this.location) {
        this.$get(this.location, undefined, { failSilently: true })
          .then(r => {
            this.fillData(r.chart); 
          })
          .catch(e => {
            if (e !== "cancelled") {
              this.dataCollection = undefined;
              this.loading = false;
            }
          });
      } else if (this.localData){
        this.fillData(this.data)
    }
      else if (this.data) {
        this.title = this.data.name;
        this.$post(
          `${this.endpoint}/render`,
          {
            ...this.data,
            filters: this.cleanedFilters,
            meta: { ...this.data.meta, interval: this.interval }
          },
          undefined,
          { cancelToken: this.cancelToken.token }
        )
          .then(r => {
            this.fillData(r) 
            })
          .catch(e => {
            if (e !== "cancelled") {
              this.dataCollection = undefined;
              this.loading = false;
            }
          });
      }
    }
  }
};
