<template>
  <widget-base
    :title="cTitle"
    :subtitle="sub"
    :shadow="shadow"
    :titlePosition="titlePosition"
    :showNoData="showNoData"
    :initialFilters="data.filters"
    :interval="interval"
    :filteringEnabled="this.filteringEnabled"
    @set-filters="val => (filters = val)"
    @set-cleaned-filters="val => (cleanedFilters = val)"
    @set-interval="val => (interval = val)"
    showIntervalSelector
    class="chart-wrapper donut"
    :hasDescription="hasDescription"
  >
  <div class="failText" v-if="showNoData">
      {{$t('chart_error')}}
    </div>
    <chart-gauge
      v-if="tooltipsOptions"
      :chart-id="uniqueID"
      :chart-data="dataCollection"
      :options="{
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 1,
            fontSize: 30
          }
        },
        fullCornerRadius: false,
        responsive: true,
        maintainAspectRatio: false,
        tooltips: { enabled: false },
        hover: { mode: null },
        rotation: -1.0 * Math.PI, // start angle in radians
        circumference: Math.PI // sweep angle in radians
      }"
    />
  </widget-base>
</template>

<script>
import ChartWidget from "../../mixins/ChartWidget";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "GaugeChart",
  mixins: [ChartWidget],
  props: {},
  data: () => ({
    baseID: "gauge-chart"
  }),
  computed: {
    plugins: () => [ChartDataLabels]
  },
  methods: {
    fillData() {
      let canvas = document.getElementById(this.uniqueID);
      canvas.parentNode.style.height = "400px";
    }
  }
};
</script>
