<template>
  <div class="wrapper active-filters" :class="{ empty: $_.isEmpty(filters) }">
    <div v-if="$_.isEmpty(filters)">
      <p style="text-align: center">{{ $t("no_active_filters") }}</p>
    </div>

    <div
      v-else
      v-for="key in Object.keys(filters)"
      :key="key"
      class="filter-group"
    >
      <div class="title">{{ getFilterTitle(key) }}</div>
      <div class="tag-wrapper">
        <div
          @click="$emit('tag-click', key)"
          class="bg-[#EBEEF2] px-4 py-2 inline-block rounded ml-2.5 text-base cursor-pointer max-w-full truncate"
          v-for="(filter, index) in getFilterValues(key)"
          :key="`${key}_${index}`"
        >
          {{ getFilterValue(filter, key) }}
          <i
            v-if="clearable"
            class="el-icon el-icon-close ml-6 cursor-pointer"
            @click.stop="removeFilter(index, key, filter)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    view: {
      type: Object,
      default: () => ({})
    },
    clearable: {
      type: Boolean,
      default: true
    },
    optionsMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeFilter: function(index, key, filter) {
      if (!this.isSelect(key)) {
        this.$emit("remove-filter", index, key);
        return;
      }

      let val = this.filters[key][0].value;
      val = this.$_.without(val, filter);
      if (this.$_.isEmpty(val)) this.$emit("remove-filter", index, key);
      else this.$emit("update-filter", val, 0, key);
    },
    getFilterTitle: function(filter) {
      return this.getFilter(filter).title || this.$t(`filters.${filter}`);
    },
    getFilterValues: function(key) {
      if (this.optionsMode) return this.filters[key]?.value;
      return this.isSelect(key)
        ? this.filters[key][0]?.value
        : this.filters[key];
    },
    isSelect: function(key) {
      return this.getFilter(key).base_type === "select";
    },
    getFilterValue: function(filter, key) {
      let value = this.optionsMode ? filter : filter.value;
      switch (this.getFilter(key).base_type) {
        case "text":
          return `${filter.inverse ? this.$t('exclude') : this.$t('include')}: ${value}` ;
        case "number":
          return `${filter.inverse ? this.$t('exclude') : this.$t('include')}: ${value.gte === undefined ? "∞" : value.gte} - ${
            value.lte === undefined ? "∞" : value.lte
          }`;
        case "select":
          return `${this.view[key].choices[filter]}`;
        case "date":
          if (this.$_.isString(value))
            return `${this.view[key].choices[value]}`;
          return `${value.start} - ${value.end}`;
        case "time":
          return `${value.start} - ${value.end}`;
        case "boolean":
          return value ? this.$t("yes") : this.$t("no");

        default:
          return value;
      }
    },
    getFilter: function(key) {
      return this.view[key] || {};
    }
  }
};
</script>

<style lang="scss" scoped>
.active-filters.empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
