<template>
  <div id="main-view" class="w-full" :style="{ '--delay': delay }">
    <core-dialog
    id="comments-dialog"
    :visible="openComments"
    :title="$tc('comment', 99)"
    @close="openComments = !openComments"
    :use_default_breakpoints="false"
    :lg_width="30"
    :xl_width="40"
    :md_width="40"
    :xs_width="90"
  >
  <div class="w-full h-pdf p-5">
    <div class="pb-5" v-for="date, index in comments" :key="index + 'date'">
      <p class="font-bold mb-2">{{date[0].created_at}}</p>
      <div class="bg-gray-100 rounded-md px-3 py-4 mb-2 w-max max-w-full break-normal" v-for="comment, indexCom in date" :key="index + indexCom + '_comment'">
        <p class="text-sm">{{comment.comment}}</p>
      </div>
    </div>
  </div>
    </core-dialog>
    <div
      class="
        w-full
        bg-white
        h-14
        rounded-md
        flex
        justify-between
        px-6
        mb-4
        shadow-sm
      "
    >
      <p
        class="group text-left self-center text-lg font-semibold cursor-pointer"
        @click="navigate"
      >
        <font-awesome-icon
          :icon="['far', 'long-arrow-left']"
          class="mr-2 group-hover:animate-bounce-left"
          id="arrow"
        />
        {{ $route.params.id }}
      </p>
    </div>

    <div class="grid grid-cols-8 gap-5 max-w-full ">
      <div class="col-span-8 md:col-span-5 widget">
        <component :is="'widget-bar-chart'" 
        v-if="countries.length !== 0" 
        titlePosition="lower" 
        products.length="5"
        itemcount="itemcount" 
        :pageSize="options.itemsPerPage" 
        :subtitle="$t('most_used_causes')" 
        :data="barData" 
        class="default"
        :filteringEnabled="false" 
        viewName="widget.viewName" 
        :localData="true" />
      </div>

      <div class="col-span-8 md:col-span-3">
        <div data-v-f6d31c3e=""
          class=""
          style="">
          <component :is="'widget-donut-chart'" 
          v-if="countries.length !== 0" 
          titlePosition="lower" 
          :data="doughnutData"
          :subtitle="$t('return_by_country')" 
          class="default" 
          :filteringEnabled="false"
          viewName="widget.viewName" 
          :localData="true" />
        </div>
      </div>
      <div class="col-span-8" v-if="countries.length !== 0">
        <div v-for="country, countryKey, CountryIndex in countries" :key="'country' + CountryIndex">
          <div class="mb-5" v-for="product, key, index in country" :key="countryKey + 'product' + index">
            <div class="h-20 w-full rounded-md bg-white grid grid-cols-12">
              <div class="col-span-6 text-left px-3 py-4">
                <p class="font-semibold">{{getCountry(countryKey)}}</p>
                <p class="text-xs mt-3">{{$t('cause')}}</p>
              </div>
              <div class="col-span-2 text-left px-3 py-4">
                <p class="font-semibold ">{{product.product_qty}}</p>
                <p class="text-xs mt-3">{{$t('quantity')}}</p>
              </div>
              <div class="col-span-4 text-left pl-3 py-3 pr-16 relative">
                <core-button
                  v-if="product.comments_count !== 0"
                  class="w-full mt-2 max-w-max mx-auto"
                  type="coolrunner"
                  @click="selectCommentsAndOpen(product.comments)"
                  >{{$t('show')}} {{product.comments_count}} {{$tc('comment', product.comments_count)}}
                </core-button>
                <div class="text-left px-3 py-4 absolute top-4 right-2 cursor-pointer" @click="selectCountry(countryKey)">
                <font-awesome-icon
                  :icon="['far', 'chevron-down']"
                  class="transition-all duration-300 transform"
                  :class="{'rotate-180' : openCountry !== countryKey}"
                />
                </div>
              </div>
            </div>
            <div class="max-h-0 overflow-hidden transition-all duration-500" :class="{'max-h-[1000px]' : openCountry == countryKey}">
              <div v-for="cause, causeKey, causeIndex in product.causes" :key="countryKey + causeKey + causeIndex"
                  class="h-14 w-full rounded-md bg-white grid grid-cols-12"
                  :class="[{'mb-3': causeIndex + 1 !== product.causes.length}, {'mt-3': causeIndex == 0}]">
                <div class="col-span-6 text-left px-3 py-4 truncate">
                  <p class="mb-9 truncate">{{causeKey}}</p>
                </div>
                <div class="col-span-2 text-left px-3 py-4">
                  <p class="">{{cause}}</p>
                </div>
                <div class="col-span-4 text-left px-3 py-4">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
    
<script>
import Widget from "@/mixins/Widget";
import Bar from "vue-chartjs";
import ChartWidget from "../../mixins/ChartWidget";
import Doughnut from "vue-chartjs";
import { convertUTCToLocal } from "@/utils/date";
import _ from "lodash";

export default {
  mixins: [Bar ,ChartWidget, Widget, Doughnut],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from) vm.from = from;
      // access to component instance via `vm`
    });
  },
  data: () => ({
    endpoint: "returns/details",
    name: "returns",
    from: "",
    products: [],
    productsNoMerge: [],
    causes: [],
    countries: [],
    openCountry: null,
    openComments: false,
    comments: [],
    headers: [
      {
        label: "item_name",
        key: "name"
      },
      {
        label: "most_used_cause",
        key: "most_used"
      },
      {
        label: "comment",
        key: "comments",
      },
      {
        label: "amount",
        key: "item_count",
      },
    ]
  }),

  mounted: function () {
    if (this.$route.params.id == 'Test produkt 1') {
      this.total = 3;
      this.countries = {
        "DK": {
        "Test produkt 1": {
          "comments": {
              "22-06-2022": [
                  {
                      "comment": "Test 1",
                      "created_at": "22-06-2022"
                  },
              ],
              "09-06-2022": [
                  {
                      "comment": "Test 2",
                      "created_at": "09-06-2022"
                  }
              ],
          },
            "comments_count": 2,
            "product_qty": 14,
            "causes": {
                "Fejlbestilling": 10,
                "unknown": 3,
                "Fandt en bedre pris": 1
            },
            "most_used": "Fejlbestilling"
        }
        },
        "NL": {
            "Test produkt 1": {
                "comments": [],
                "comments_count": 0,
                "product_qty": 3,
                "causes": {
                    "unknown": 3
                },
                "most_used": "unknown"
            }
        },
        "NO": {
            "Test produkt 1": {
                "comments": [],
                "comments_count": 0,
                "product_qty": 1,
                "causes": {
                    "unknown": 1
                },
                "most_used": "unknown"
            }
        }
    };
    } else {
      this.loadData();
    }
  },

  computed: {
    delay: function () {
      if (this.total > 20) {
        return 1.2
      } else {
        return this.products.length * 0.050
      }
    },
    barData: function () {
      let labels = [];
      let data = [];
      let sortData = [];
      let cappedData = []
      _.forEach(this.countries, function (country, key) {
        _.forEach(country, function (product, key) {
          _.forEach(product.causes, function (cause, key) {
            if(!_.includes(labels, key)) {
            labels.push(key);
            data.push(cause);
            } else {
              let index = _.indexOf(labels, key);
              data[index] = data[index] + cause
            }
          })
        })
      });
      _.forEach(data, function (value, index) {
        sortData.push({value: value, label: labels[index]})
      })
      labels = [];
      data = [];
      _.forEach(_.orderBy(sortData,['value'],['desc']), function (value, index) {
        if (index <10) {
          labels.push(value.label);
          data.push(value.value);
        }
      })
      let dataObject = {
        data: {
          datasets: [{
            backgroundColor: [],
            data: data,
          }],
          labels: labels,
        }
      }
      return dataObject;
    },

    doughnutData: function () {
      let labels = [];
      let data = [];
      Object.keys(this.countries).forEach(key => {
          labels.push(this.getCountry(key));
          data.push(this.countries[key][this.$route.params.id].product_qty);
      });
      let dataObject = {
        data: {
          datasets: [{
            backgroundColor: [],
            data: data,
          }],
          labels: labels,
        }
      }
      return dataObject;
    },
  },

  methods: {
    selectCommentsAndOpen: function (comments) {
       this.openComments = true;
       this.comments = comments
    },
    selectCountry: function (iso) {
      if (this.openCountry == iso) {
        this.openCountry = null
      } else {
        this.openCountry = iso
      }
    },
    getCountry: function(iso){
      let countries = this.$store.state.countries
      let countryName = ''
      countries.forEach(country => {
        if(country.iso == iso) {
          countryName = country.full_name
        }
      })
      return countryName
    },
    convertUTCToLocal: function (val, type) {
      return convertUTCToLocal(val, type);
    },
    navigate: function () {
      this.show = false;
      document.getElementById("arrow").animate(
        [
          { transform: "translateX(0)", opacity: 1 },
          {
            opacity: 1
          },
          { transform: "translateX(-100%)", opacity: 0 }
        ],
        {
          duration: 250,
          easing: "ease-in-out",
          fill: "forwards"
        }
      );
      if (this.from.name) this.$router.go(-1);
      else this.$router.push({ path: "/returns/statistics" });
    },
    transformedProducts: function (val) {
      let array = [];
      _.forEach(val, function (product, key) {
        array.push({
          ...product,
          name: key
        });
      });
      return array;
    },  
    loadData() {
      this.loading = true;
      let key = this.$route.params.id
      this.$post(`returns/causes/${key}`)
        .then(response => {
          this.total = response.total.total;
          this.countries = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  }

};
</script>
    