<template>
  <core-select
    ref="select"
    filterable
    remote
    :remote-method="setQuery"
    :loading="loading"
    :options="list"
    :labelKey="labelKey"
    :valueKey="valueKey"
    :value="value[valueKey]"
    :paginate="paginate"
    :query="search"
    :label="placeholder"
    @update:value="
      val =>
        $emit(
          'update:value',
          $_.find(list, i => i[valueKey] === val)
        )
    "
    @bottom-reached="
      inf => {
        infinity = inf;
        options = { ...options, page: options.page + 1 };
        remoteMethod();
      }
    "
  />
</template>

<script>
import axios from "axios";
import i18n from "@/plugins/i18n";
export default {
  inheritAttrs: false,

  props: {
    labelKey: {
      type: [String, Array, Function],
      default: "label"
    },
    value: {
      type: Object,
      default: () => {}
    },
    valueKey: {
      type: String,
      default: "value"
    },
    placeholder: {
      type: String,
      default: i18n.t("choose_entity")
    },
    endpoint: {
      type: String,
      default: ""
    },
    paginate: {
      type: Boolean,
      default: false
    },
    orderBy: {
      type: String,
      default: "created_at"
    },
    orderDir: {
      type: String,
      default: "desc"
    }
  },
  data: () => ({
    loading: true,
    list: [],
    totalItems: 0,
    options: {
      itemsPerPage: 20,
      page: 1
    },
    search: "",
    infinity: null,
    cancelToken: null
  }),
  mounted() {
    this.remoteMethod();
  },
  computed: {
    params: function() {
      return this.paginate
        ? {
            limit: this.options.itemsPerPage,
            page: this.options.page,
            order_by: this.orderBy,
            order_dir: this.orderDir,
            q: this.search
          }
        : { q: this.search };
    }
  },
  methods: {
    setQuery: function(q) {
      this.infinity && this.infinity.reset();
      this.$refs.select.$refs.select.scrollTop = this.$refs.select.$refs.select.scrollHeight;
      this.list = [];
      this.totalItems = null;
      this.options = { ...this.options, page: 1 };
      this.search = q;
      this.loading = true;
      this.remoteMethod();

      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
    },
    remoteMethod() {
      this.cancelToken = axios.CancelToken.source();

      this.$get(this.endpoint, this.params, {
        cancelToken: this.cancelToken.token
      })
        .then(response => {
          this.list = [...this.list, ...response.data];
          this.totalItems = response.total;
          if (!response.next_page_url)
            this.infinity && this.infinity.complete();

          this.loading = false;
        })
        .catch(e => {
          if (e !== "cancelled") this.loading = false;
        })
        .finally(() => {
          this.infinity && this.infinity.loaded();
        });
    }
  }
};
</script>
