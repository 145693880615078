<template>
  <el-tabs v-model="selected" stretch>
    <el-tab-pane
      v-bind:key="key"
      v-for="(choice, key) in choices"
      :label="choices[key]"
      :name="key"
    >
      <template v-if="key === 'custom'">
        <core-filter-modal-type-date-custom
          :value="value ? value.value : null"
          @update-filter="val => onChoiceClick(val)"
        />
      </template>
      <template v-else-if="key === 'custom-range'">
        <core-filter-modal-type-date-custom-range
          :value="value ? value.value : {}"
          @update-filter="val => onChoiceClick(val)"
        />
      </template>
      <template v-else>
        <core-button @click="onChoiceClick(key)" type="coolrunner">
          {{ $t(value ? "update" : "add") }}
        </core-button>
      </template>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    choices: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Object,
      default: undefined
    },
    index: {
      type: Number,
      default: undefined
    }
  },
  data: () => ({
    selected: ""
  }),
  created: function() {
    if (this.value)
      this.selected = this.$_.isObject(this.value.value)
        ? "custom"
        : this.value.value;
    else {
      this.selected = Object.keys(this.choices)[0];
    }
  },
  methods: {
    onChoiceClick: function(key) {
      this.$emit("update-filter", key, this.index);
    }
  }
};
</script>
