export default {
  data: () => ({
    edit_entity: {},
    is_loading: false,
    store_key: null
  }),
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formData: {
      get: function() {
        return this.$store.state.temp[this.store_key];
      },
      set: function(val) {
        this.$store.dispatch("setTemp", { key: this.store_key, value: val });
      }
    }
  },
  mounted() {
    this.edit_entity = { ...this.entity };
  },
  watch: {
    edit_entity: {
      handler: "update",
      deep: true
    },
    loading: function(val) {
      this.is_loading = val;
    },
    is_loading: function(val) {
      this.$emit("update:loading", val);
    },
    is_locked: function(val) {
      this.$emit("step:locked", val);
    }
  },
  methods: {
    lock() {
      this.$emit("step:locked", true);
    },
    unlock() {
      this.$emit("step:locked", false);
    },
    load() {
      this.is_loading = true;
    },
    unload() {
      this.is_loading = false;
    },
    update() {
      this.$emit("update:entity", this.edit_entity);
    }
  }
};
