<template>
  <div class="inner-table">
    <row
      :columns="24"
      :gutter="20"
      class="inner-box row"
      style="max-width: 100%"
    >
      <column :xs="24" :md="8" :lg="6" :xl="4" class="info-box sender">
        <h4>{{ $t("sender") }}</h4>

        <div class="sender-name">{{ item.sender.name }}</div>
        <div class="sender-attention">{{ item.sender.attention }}</div>
        <div class="sender-street1">{{ item.sender.street1 }}</div>
        <div class="sender-street2">{{ item.sender.street2 }}</div>
        <div class="sender-zipcode-city">
          {{ item.sender.zip_code }} {{ item.sender.city }}
          {{ item.sender.country }}
        </div>
        <div class="sender-phone">
          {{ $t("abrreviations.phone") }} {{ item.sender.phone }}
        </div>
        <div class="sender-email">{{ item.sender.email }}</div>
      </column>

      <column :xs="24" :md="8" :lg="6" :xl="4" class="info-box receiver">
        <h4>{{ $t("receiver") }}</h4>

        <div class="receiver-name">{{ item.receiver.name }}</div>
        <div class="receiver-attention">
          {{ item.receiver.attention }}
        </div>
        <div class="receiver-street1">{{ item.receiver.street1 }}</div>
        <div class="receiver-street2">{{ item.receiver.street2 }}</div>
        <div class="receiver-zipcode-city">
          {{ item.receiver.zip_code }} {{ item.receiver.city }}
          {{ item.receiver.country }}
        </div>
        <div class="receiver-phone">
          {{ $t("abrreviations.phone") }} {{ item.receiver.phone }}
        </div>
        <div class="receiver-email">{{ item.receiver.email }}</div>
      </column>

      <column :xs="24" :md="8" :lg="6" :xl="4" class="info-box shipment">
        <h4>{{ $t("shipment_info") }}</h4>

        <div class="created">
          {{
            `${$t("created")}: ${convertUTCToLocal(
              item.created_at,
              "datetime"
            )}`
          }}
        </div>

        <div class="package-number">
          {{ $t("package_number") }}:
          {{ item.package_number }}
        </div>
        <div class="labelless-code" v-if="item.labelless_code">
          {{ $t("labelless_code") }}:
          {{ item.labelless_code }}
        </div>
        <el-tooltip
          :content="
            `${item.carrier}_${item.carrier_product}_${item.carrier_service}`
          "
          placement="bottom"
          effect="light"
        >
          <div class="product">
            {{ `${$t("product")}: ${item.title}` }}
          </div>
        </el-tooltip>
        <div class="insurance">
          {{ `${$t("has_insurance")}: ${$t(item.insurance ? "yes" : "no")}` }}
        </div>
        <div class="reference">{{ $t("reference") }}: {{ item.reference }}</div>
      </column>

      <column :xs="12" :md="8" :lg="4" class="info-box droppoint">
        <h4 class="droppoint-header">{{ $t("droppoint") }}:</h4>

        <div class="droppoint">
          <template v-if="item.servicepoint && $_.isObject(item.servicepoint)">
            <div class="droppoint-name">
              {{ item.servicepoint.name }}
            </div>
            <div class="droppoint-street1">
              {{ item.servicepoint.address.street }}
            </div>
            <div class="droppoint-zipcode">
              {{ item.servicepoint.address.zip_code }}
              {{ item.servicepoint.address.city }}
            </div>
            <div class="droppoint-country">
              {{ item.servicepoint.address.country }}
            </div>
          </template>
          <div v-else>-</div>
        </div>
      </column>

      <column :xs="24" :md="8" :lg="6" :xl="4" class="info-box shipment">
        <h4>{{ $t("dimensions") }}</h4>

        <div class="length">
          {{ `${$t("length")}: ${$n(item.length, "dimension", "da-DK")} cm` }}
        </div>
        <div class="width">
          {{ `${$t("width")}: ${$n(item.width, "dimension", "da-DK")} cm` }}
        </div>
        <div class="height">
          {{ `${$t("height")}: ${$n(item.height, "dimension", "da-DK")} cm` }}
        </div>
        <div class="weight">
          {{ `${$t("weight")}: ${$n(item.weight, "weight", "da-DK")} g` }}
        </div>
      </column>
      <column :xs="24" :md="8" :lg="6" :xl="4" class="info-box price">
        <h4>{{ $t("price") }}</h4>

        <div class="price_excl_tax">
          {{
            `${$t("price_excl_tax")}: ${$n(
              item.order_price.excl_tax,
              "currency",
              "da-DK"
            )}`
          }}
        </div>
        <div class="price_incl_tax">
          {{
            `${$t("price_incl_tax")}: ${$n(
              item.order_price.incl_tax,
              "currency",
              "da-DK"
            )}`
          }}
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { convertUTCToLocal } from "@/utils/date";

export default {
  name: "LabelExpandedRow",
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    convertUTCToLocal: () => convertUTCToLocal
  }
};
</script>
