<template>
  <core-dialog
    :title="
      this.$t(`download_entity`, {
        entity: $tc('template').toLowerCase()
      })
    "
    :visible="visible"
    @update:visible="close"
    :xs_width="90"
    :lg_width="30"
    :close-on-click-modal="false"
  >
    <div class="form wrapper">
      <div class="grid-container">
        <row :gutter="20">
          <column :xs="12">
            <core-select
              v-model="format"
              :valueIsObject="false"
              :options="formats"
              :label="$t('file_format')"
            />
          </column>

          <column :xs="12">
            <core-select-cps
              v-model="entity.product"
              :valueIsObject="false"
              defaultToFirst
              :clearable="false"
            />
          </column>
          <column :xs="12">
            <core-select
              v-model="entity.paper_size"
              :options="labelSizes"
              :label="$t('label_size')"
            />
          </column>
          <column :xs="12" :md="6">
            <core-number
              :label="`${$t('weight')} (gram)`"
              v-model="entity.weight"
            />
          </column>
          <column :xs="12" :md="6">
            <core-number
              :label="`${$t('height')} (cm)`"
              v-model="entity.height"
            />
          </column>
          <column :xs="12" :md="6">
            <core-number
              :label="`${$t('length')} (cm)`"
              v-model="entity.length"
            />
          </column>
          <column :xs="12" :md="6">
            <core-number
              :label="`${$t('width')} (cm)`"
              v-model="entity.width"
            />
          </column>
        </row>
      </div>
    </div>
    <span slot="footer" class="dialog-footer flex">
      <core-button type="default_outline" class="mr-auto" @click="close">{{
        $t("close")
      }}</core-button>
      <core-button type="coolrunner" @click="download">{{
        $t("download_entity", { entity: $t("template").toLowerCase() })
      }}</core-button>
    </span>
  </core-dialog>
</template>
<script>
export default {
  name: "TemplateDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    entity: {
      service: "",
      paper_size: "",
      weight: "",
      height: "",
      length: "",
      width: ""
    },
    labelSizes: [
      {
        label: "A4",
        value: "A4"
      },
      {
        label: "LabelPrint",
        value: "LabelPrint"
      }
    ],
    formats: [
      { label: "CSV", value: "csv" },
      { label: "Excel (XLS)", value: "xls" },
      { label: "Excel (XLSX)", value: "xlsx" },
      { label: "JSON", value: "json" },
      { label: "XML", value: "xml" }
    ],
    format: "csv"
  }),
  methods: {
    close: function() {
      this.$emit("update:visible", false);
    },
    download: function() {
      const id = Date.now();

      this.$notification({
        id: id,
        title: this.$t("action_started", { action: this.$t("download") }),

        duration: -1
      });

      this.$post(
        `import/template/${this.format}`,
        this.entity,
        {
          rawResponse: true,
          failSilently: true
        },
        { responseType: "blob", timeout: 2 * 60 * 1000 }
      )
        .then(response => {
          this.$notification.close(id);

          const contentDisposition = response.headers["content-disposition"];
          if (contentDisposition) {
            const suggestedFileName = contentDisposition.split("filename=")[1];
            let effectiveFileName =
              suggestedFileName === undefined
                ? `${this.$moment().format("yyyy-MM-dd HH:mm:ss")}.${
                    this.format
                  }`
                : suggestedFileName;

            // Let the user save the file.
            effectiveFileName = effectiveFileName.replace(/["']/g, "");
            this.$fileDownload(response.data, effectiveFileName);
            this.$notification({
              type: "success",
              title: this.$t("action_completed", {
                action: this.$t("download")
              }),
              duration: 2500
            });
          }
        })
        .catch(e => {
          this.$notification.close(id);
          const { data } = e.response;
          this.$readJsonFromBlob(data).then(r => {
            this.$notification({
              title: this.$t("export_failed"),
              duration: 2500,
              type: "error"
            });
          });
        });
    }
  }
};
</script>
