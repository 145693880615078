<template>
  <core-input
    v-bind="$attrs"
    v-on="$listeners"
    @change="val => $emit('change', val)"
  />
</template>

<script>
export default {
  inheritAttrs: false
};
</script>
