<template>
  <div id="return_shipment_order_container">
    <div class="wrapper">
      <row>
        <column :xs="12" :xl="3" style="margin: auto">
          <core-animated-checkmark style="margin-bottom: 2em" />
          <core-button type="coolrunner" class="mx-auto" @click="downloadLabel">{{
            $t("download_label")
          }}</core-button>
        </column>
        <column :xs="12" :xl="9">
          <row :gutter="12">
            <column :xs="12" :xl="7">
              <el-card style="height: 100%;">
                <h3>{{ $t("order_summary") }}</h3>
                <p>
                  <b>{{ $t("order_date") }}:</b>
                  {{ convertUTCToLocal(undefined, "datetime") }}
                </p>
              </el-card>
            </column>
            <column :xs="12" :xl="5">
              <el-card style="height: 100%;">
                <h3>{{ $t("entity_address", { entity: $t("sender") }) }}</h3>
                <p>{{ order.sender.name }}</p>
                <p>{{ order.sender.street1 }}</p>
                <p>
                  {{ order.sender.zip_code }}
                  {{ order.sender.city }}
                </p>
              </el-card>
            </column>
            <column :xs="12" :xl="7">
              <el-card style="height: 100%;">
                <h3>{{ $t("label_information") }}</h3>
                <p>
                  <b>{{ $t("product") }}: </b> {{ order.carrier }} {{ order.carrier_product}} {{ order.carrier_service}}
                </p>
                <p>
                  <b>{{ $t("package_number") }}: </b>

                  <router-link
                    style="font-size:13px"
                    class=""
                    :to="{
                      name: 'labels',
                      query: {
                        filter: {
                          package_number: order.package_number
                        }
                      }
                    }"
                    target="_blank"
                    >{{ order.package_number }}
                    <font-awesome-icon
                      :icon="['fas', 'external-link-alt']"
                      size="sm"
                  /></router-link>
                </p>
                <p v-if="order.labelless_code">
                  <b>{{ $t("labelless_code") }}: </b>
                  <span style="font-size: 13px">{{
                    order.labelless_code
                  }}</span>
                </p>
                <p>
                  <b>{{ $t("reference") }}: </b
                  ><span style="font-size: 13px">{{
                    order.reference
                  }}</span>
                </p>
              </el-card>
            </column>
            <column :xs="12" :xl="5">
              <el-card style="height: 100%;">
                <h3>{{ $t("entity_address", { entity: $t("receiver") }) }}</h3>
                <p>{{ order.receiver.name }}</p>
                <p>{{ order.receiver.street1 }}</p>
                <p>
                  {{ order.receiver.zip_code }}
                  {{ order.receiver.city }}
                </p>
                <p>{{ order.receiver.email }}</p>
                <p>{{ order.receiver.phone }}</p>
              </el-card>
            </column>
          </row>
        </column>
      </row>
    </div>
  </div>
</template>

<script>
import { URL_PREFIX } from "@/utils/constants";
import { convertUTCToLocal } from "@/utils/date";

export default {
  name: "CreateReturnLabelDialogStep2",
  props: {
    order: {
      type: Object,
      default: () => {
        return {
          title: null,
          order: {
            id: null,
            sales_price: null
          },
          shipment: {
            package_number: null,
            carrier: "pdk",
            receiver: {
              name: null,
              company: null,
              street1: null,
              zip_code: null,
              city: null
            },
            price: {
              incl_tax: 0,
              excl_tax: 0
            }
          }
        };
      }
    }
  },
  created() {},
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    account() {
      return this.$store.state.account;
    }
  },
  mounted() {},
  methods: {
    convertUTCToLocal: function(val, type) {
      return convertUTCToLocal(val, type);
    },
    downloadLabel: function() {
      window.open(
        `${URL_PREFIX}/business-panel/shipments/${this.order.package_number}/label?access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
