<template>
  <div class="shop_container" @click="() => $emit('click')">
    <div class="shop_container__header">
      <h5>{{ shop.data.name }}</h5>
      <p class="ellipsis">{{ shop.data.shop_url || $t("unknown") }}</p>
    </div>
    <div class="shop_container__body">
      <img :src="shop.data.image_url" />
    </div>
    <div class="shop_container__footer">
      <div class="status">
        <div v-if="shop.data.activation_expires_at !== null">
          <div class="indicator blink_me down" />
          {{ $t("activation_expiration_date") }}
          <br />
          {{ $moment(shop.data.activation_expires_at).fromNow() }}
        </div>
        <div v-if="shop.data.activation_expires_at == null">
          <div
            class="indicator blink_me"
            :class="shop.data.pingback_result.status"
          />
          {{ $t(`shop_status.${shop.data.pingback_result.status}`) }}

          <p>{{ convertUTCToLocal(shop.data.updated_at, "date") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertUTCToLocal } from "@/utils/date";

export default {
  name: "Shop",
  props: {
    shop: {
      type: Object,
      default: null
    }
  },
  methods: {
    convertUTCToLocal: function(val, type) {
      return convertUTCToLocal(val, type);
    }
  }
};
</script>

<style lang="scss">
.shop_container {
  cursor: pointer;
}
</style>
