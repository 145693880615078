<template>
  <el-col id="account_label--wrapper" :span="24" style="padding:0 !important; ">
    <p class="small">{{ $t("label_size_description") }}</p>
    <br />
    <h4>{{ $t("label_size") }}</h4>

    <core-select
      style="width:343px; max-width: calc(100% - 40px); float: left; margin-top: 10px;"
      :disabled="!account"
      :label="$t('label_size')"
      :options="options"
      v-model="account.label_format"
      @change="save"
    />
  </el-col>
</template>

<script>
export default {
  name: "Label",
  data: () => ({
    account: null,
    endpoint: "account",
    options: [
      {
        label: "A4",
        value: "A4"
      },
      {
        label: "LabelPrint",
        value: "LabelPrint"
      }
    ]
  }),
  created: function() {
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#account_info--wrapper"
    };
    this.account = { ...this.$store.state.account };
  },
  watch: {
    "this.$store.state.account": function(val) {
      this.account = val;
    }
  },

  methods: {
    save() {
      let url = `${this.endpoint}`;

      this.$put(url, this.account, { useDev: this.useDev })
        .then(() => {
          this.$notification({
            title: this.$t("entity_saved_correctly", {
              entity: this.$t(this.name).toLowerCase()
            }),
            text: this.$t("stored_a_safe_place"),
            type: "success"
          });
          this.$store.dispatch("loadAccount");
        })
        .finally(() => {});
    }
  }
};
</script>

<style scoped></style>
