<template>
  <div
    :class="
      `flag-header flag-icon-background flag-icon-${country.toLowerCase()}`
    "
  />
</template>

<script>
export default {
  name: "CountryFlag",
  props: {
    country: {
      type: String,
      default: null
    }
  },
  created() {},
  computed: {}
};
</script>

<style scoped>
.flag-header {
  padding-left: 36%;
  height: 100%;
  border-radius: 8px;
}
</style>
