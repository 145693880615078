<template>
  <div
    @click="() => (deleteable ? $emit('delete') : null)"
    :class="[
      'inline-flex bg-badge px-2 p-1 rounded-full leading-none items-center justify-center text-white',
      { 'hover:cursor-pointer': deleteable }
    ]"
  >
    <slot />
    <div v-if="deleteable" class="ml-1 -mr-2">
      <font-awesome-icon :icon="['far', 'times-circle']" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deleteable: {
      type: Boolean,
      default: false
    }
  }
};
</script>
