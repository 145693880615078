<template>
  <core-dialog
    id="print-dialog"
    :visible="visible"
    @update:visible="close"
    :title="$t('choose_entity', { entity: $tc('printer').toLowerCase() })"
    :md_width="90"
    :lg_width="90"
    :xl_width="90"
    @close="close"
    @open="getDataFromApi"
  >
    <div
      class="wrapper"
      style="display: flex; flex-direction: column; width: 100%; min-height: 180px;"
    >
      <div
        class="printer-row"
        v-for="item in printers"
        @click="printer = item"
        :class="{ active: printer ? item.id === printer.id : false }"
        :key="`${item.alias}-${item.print_service_id}`"
      >
        <p>
          {{ item.alias }}
        </p>
        <p style="font-size: 12px;">
          {{ getLocation(item.print_service_id) }}
        </p>
      </div>
      <core-list-empty
        style="padding: 20px; width: 100%;"
        :emptyListIcon="['fas', 'print-slash']"
        :emptyListText="$t('no_active_printers')"
        v-if="!printers.length && !loading"
      />
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="display: flex; width: 100%;"
    >
      <core-button class="mr-auto" type="default_outline" @click="close">{{
        $t("cancel")
      }}</core-button>

      <core-button
        type="coolrunner"
        :disabled="!printer"
        :loading="loading"
        @click="print"
        >{{ $t("print") }}</core-button
      >
    </div>
  </core-dialog>
</template>

<script>
import Loader from "@/mixins/Loader";
import Axios from "axios";

export default {
  mixins: [Loader],
  name: "PrintDialog",
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    loaderOptions: {
      lock: true,
      fullscreen: false,
      text: "loading",
      target: "#print-dialog .wrapper",
      customClass: null
    },
    endpoint: "printers",
    printer: null,
    paginate: false,
    cancelToken: null,
    items: [],
    loading: false,
    name: "printer",
    icon: ["fas", "print-slash"],
    headers: [
      {
        label: "alias",
        key: "alias"
      }
    ],
    options: {
      itemsPerPage: 20,
      page: 1,
      orderBy: "active_at",
      orderDir: "desc"
    }
  }),
  computed: {
    printers: function() {
      return this.$_.filter(this.items, ["active", true]);
    },
    printServices: function() {
      return this.$store.state.printServices;
    },
    keyedPrintServices: function() {
      return this.$_.keyBy(this.printServices, "id");
    }
  },
  methods: {
    getLocation: function(key) {
      const ps = this.keyedPrintServices[key];
      if (!ps) return "";
      return ps.location;
    },

    print: function() {
      this.$emit("print", this.printer.alias);
      this.close();
    },
    close: function() {
      this.items = [];
      this.printer = null;
      this.$emit("update:visible");
    },
    getDataFromApi() {
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = Axios.CancelToken.source();

      this.loading = true;

      this.$get(`/${this.endpoint}`, {
        cancelToken: this.cancelToken.token,
        paginate: 0
      })
        .then(response => {
          this.items = response.data;
          this.loading = false;
        })
        .catch(e => {
          if (e !== "cancelled") this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.printer-row {
  display: flex;
  height: 60px;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  &:nth-of-type(even) {
    background-color: #f7f8fa;
  }
  &.active {
    background-color: #157efb33;
  }
}
</style>
