<template>
  <div class="wrapper form">
    <component
      class="shop-field"
      v-for="(field, key) in fields"
      :key="key"
      v-model="shop.data[key]"
      :is="getType(field.type)"
      :label="field.title"
      :options="field.options"
      :required="field.required"
      :error="$_.first(errors[`data.${key}`])"
    />
  </div>
</template>

<script>
export default {
  props: {
    newShop: {
      type: Object,
      default: () => ({ data: {} })
    },
    errors: {
      type: Object,
      default: () => ({ data: {} })
    }
  },
  data: () => ({
    shop: { data: {} }
  }),
  created: function() {
    this.shop = { ...this.newShop };
  },
  watch: {
    pageOk: function(val, oldVal) {
      if (val !== oldVal) {
        this.$emit("page-ok", val);
      }
    },
    shop: function() {
      this.$emit("update:newShop", this.shop);
    }
  },
  computed: {
    shopTypes: function() {
      return this.$store.state.shopSettings;
    },
    fields: function() {
      return this.shopTypes[this.newShop.type].fields;
    },
    requiredFields: function() {
      return Object.keys(
        this.$_.pickBy(this.fields, (value, key) => {
          return value.required;
        })
      );
    },
    pageOk: function() {
      return this.$_.every(this.requiredFields, key => {
        let val = this.newShop.data[key];
        if (this.$_.isString(val)) val = val.trim();
        return val;
      });
    }
  },
  methods: {
    getType: function(type) {
      switch (type) {
        case "boolean":
        case "bool":
          return "core-switch";
        case "select":
          return "core-select";
        default:
          return "core-input";
      }
    }
  }
};
</script>
