<template>
  <widget-base
    :title="cTitle"
    :subtitle="sub"
    :shadow="shadow"
    :titlePosition="titlePosition"
    :showNoData="showNoData"
    :initialFilters="data.filters"
    :interval="interval"
    :filteringEnabled="this.filteringEnabled"
    @set-filters="val => (filters = val)"
    @set-cleaned-filters="val => (cleanedFilters = val)"
    @set-interval="val => (interval = val)"
    showIntervalSelector
    class="chart-wrapper"
    :hasDescription="hasDescription"
  >
    <div class="failText" v-if="showNoData">
      {{$t('chart_error')}}
    </div>
    <component
      v-else
      :chart-id="uniqueID"
      :is="'chart-line'"
      :chart-data="dataCollection"
      :options="{
        legend: false,
        animation: {
          easing: 'easeInOutElastic'
        },
        responsive: true,
        fullCornerRadius: false,
        maintainAspectRatio: false,
        tooltips: tooltipsOptions(title),
        scales: scalesOptions()
      }"
    />
  </widget-base>
</template>

<script>
import ChartWidget from "../../mixins/ChartWidget";

export default {
  name: "LineChart",
  mixins: [ChartWidget],

  data: () => ({
    baseID: "line-chart"
  }),
  computed: {},
  methods: {
    fillData(widgetData) {
      this.dataCollection = {
        ...widgetData.data,
        datasets: this.$_.map(widgetData.data.datasets, ds => {
          return {
            ...ds,
            yAxisID: this.getYAxisId(ds),
            backgroundColor: "rgba(255, 255, 255, 0)",
            pointBorderColor: "#FFFFFF",
            pointBackgroundColor: "#084081",
            borderColor: "#4EB3D3",
            pointBorderWidth: 2,
            borderWidth: 5,
            order: 1,
            tension: 0,
            pointRadius: 5
          };
        })
      };
      this.loading = false;

      let canvas = document.getElementById(this.uniqueID);
      canvas.parentNode.style.height = "400px";
    }
  }
};
</script>
