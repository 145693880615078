import axios from 'axios';
import AWS from "aws-sdk";

export default {
    name: "PdfDownloader",
    data: () => ({
    }),
    created: function () { },
    methods: {
        uploadImage(payload, key, shipmentId) {
            let file = payload
            this.loading = true;
            AWS.config.update({
                region: "eu-central-1",
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: "eu-central-1:000c114b-5d29-4237-80ea-be9b890b6456"
                })
            });
            this.s3 = new AWS.S3();
            let s3 = this.s3;
            return new Promise((resolve, reject) => {
                var filePath = `ticket_${shipmentId}/${file.name}`;
                var params = {
                    "Body": file,
                    "Bucket": "cr-temporary",
                    "Key": filePath,
                    "ACL": "public-read", /* This makes the image public, but only works if your S3 bucket allows public access */
                    "ContentType": file.contentType /* This is important to handle jpg vs png etc */
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        console.log(err);
                        reject(err);
                    } else {
                        resolve(data);
                    }
                });
            })
        }
    },
};