<template>
  <div class="flex flex-col w-full">
    <div class="grid grid-cols-12 gap-5">
      <div
        :class="[
          {
            'md:col-span-6 lg:col-span-4': !isLarge(widget)
          },
          `shadow-sm col-span-12 `
        ]"
        v-bind:key="'static_' + index"
        v-for="(widget, index) in widgets"
      >
        <component
          :is="getChartComponent(widget)"
          titlePosition="lower"
          :subtitle="widget.subtitle"
          :data="widget"
          class="default"
          :filteringEnabled="widget.filteringEnabled"
          :viewName="widget.viewName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  data: () => ({}),
  computed: {
    moment: () => moment,
    ...mapState(["availableWidgets"]),
    breakpoint: function() {
      return this.$store.state.breakpoint;
    },
    widgets: function() {
      if (this.$store.state.account.enabled_services.can_monitor_tracking_daily == "1") {
        return [
        {
          name: this.$tc("label", 99),
          type: "simple_histogram",
          model: "shipment",
          meta: {
            chart_type: "bar",
            key: "package_number",
            operator: "count",
            interval: "month"
          },
          viewName: "shipments",
          filters: {
            created_at: [{ value: "last-12-months" }]
          }
        },
        {
          name: this.$tc("label", 99),
          type: "simple",
          model: "shipment",
          meta: {
            key: "package_number",
            operator: "count"
          },
          viewName: "shipments",

          filters: {
            created_at: [{ value: "this-year" }]
          }
        },
        {
          name: this.$t("package_status"),
          type: "simple_chart",
          model: "shipment",
          meta: {
            chart_type: "donut",
            key: "latest_tracking_code",
          },
          viewName: "shipments",
          filters: {
            created_at: [{ value: "last-7-days" }]
          }
        },
        {
          name: this.$tc("label", 99),
          subtitle: this.$t("week_comparison"),
          type: "simple_histogram",
          render_as: "simple",
          model: "shipment",
          meta: {
            key: "package_number",
            operator: "count",
            interval: "week"
          },
          viewName: "shipments",
          filteringEnabled: false,
          filters: {
            created_at: [{ value: "last-2-weeks" }]
          }
        }
      ];
      } else {
      return [
        {
          name: this.$tc("label", 99),
          type: "simple_histogram",
          model: "shipment",
          meta: {
            chart_type: "bar",
            key: "package_number",
            operator: "count",
            interval: "month"
          },
          viewName: "shipments",
          filters: {
            created_at: [{ value: "last-12-months" }]
          }
        },
        {
          name: this.$tc("label", 99),
          type: "simple",
          model: "shipment",
          meta: {
            key: "package_number",
            operator: "count"
          },
          viewName: "shipments",

          filters: {
            created_at: [{ value: "this-year" }]
          }
        },
        {
          name: this.$t("unique_entities", {
            entity: this.$tc("customer", 99).toLowerCase()
          }),
          type: "simple",
          model: "shipment",
          meta: {
            key: "receiver_email",
            operator: "unique"
          },
          viewName: "shipments"
        },
        {
          name: this.$tc("label", 99),
          subtitle: this.$t("week_comparison"),
          type: "simple_histogram",
          render_as: "simple",
          model: "shipment",
          meta: {
            key: "package_number",
            operator: "count",
            interval: "week"
          },
          viewName: "shipments",
          filteringEnabled: false,
          filters: {
            created_at: [{ value: "last-2-weeks" }]
          }
        }
      ];
      }
    }
  },
  methods: {
    getChartComponent: function(widget) {
      const type = widget.render_as || widget.meta.chart_type;
      if (type && type !== "simple") return `widget-${type}-chart`;

      return "widget-simple";
    },
    isLarge: function(widget) {
      const type = widget.render_as || widget.type;

      return type === "histogram" || type === "simple_histogram";
    }
  }
};
</script>
