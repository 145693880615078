<template>
  <div
    class="
      rounded
      bg-[#F5F7FA]
      p-4
      cursor-pointer
      transition
      duration-300
      ease-in-out
      h-[150px]
      pb-10
      group
    "
    @click="goToApp(id)"
  >
    <div class="grid grid-cols-10 relative pb-4 h-full">
      <div
        v-if="type == 'product'"
        class="h-16 w-16 rounded bg-white col-span-4 lg:col-span-2 leading-[4.7rem]"
      >
        <div class="h-16 w-16 text-center">
          <font-awesome-icon class="text-gray-500 group-hover:text-coolrunner-500 transition duration-300 text-3xl" :icon="['far', `${icon}`]" />
        </div>
      </div>
      <div v-if="type == 'carrier'" class="h-16 w-16 rounded bg-white col-span-4 lg:col-span-2 relative p-2 flex content-center">
        <el-image
          :src="`${URL_PREFIX}/images/carriers/${carrier}.png?w=500`"
          class="carrier-image mx-auto"
          :fit="'contain'"
          ondragstart="return false"
        >
          <div slot="error" class="image-slot">{{ carrier }}</div>
        </el-image>
      </div>
      <div class="col-span-5 lg:col-span-7 pl-2 h-32 relative">
        <h3 class="text-lg w-full">
          {{ headline }}
        </h3>
        <p class="text-sm line-clamp-3 w-full mb-2 text-gray-500">
          {{ description }}
        </p>
        <div class="text-center border border-coolrunner-500 text-coolrunner-400 absolute left-0 bottom-1 px-2 py-1 text-sm rounded transition duration-300 hover:text-white hover:bg-coolrunner-500">Se mere</div>
      </div>
      <div v-if="!enabled" class="h-5 w-5 rounded-full bg-white justify-self-end">
      </div>
      <div v-if="enabled" class="h-5 w-5 rounded-full bg-white justify-self-end bg-coolrunner-500">
          <font-awesome-icon class="text-white text-sm" :icon="['far', `check`]" />
      </div>
    </div>
  </div>
</template>

<script>
import I18n from "@/plugins/i18n";
import { URL_PREFIX } from "@/utils/constants";
export default {
  name: "productCard",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "default",
    },
    headline: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    carrier: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
  },
  methods: {
    goToApp: function(val) {
      this.$router.push({ name: 'apps_details', params: { id: val } });
    }
  },
};
</script>