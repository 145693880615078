<script>
import { Scatter, mixins } from "vue-chartjs";

export default {
  mixins: [Scatter, mixins.reactiveProp],
  props: ["options", "chart-id"],
  data: () => ({
    htmlLegend: null
  }),
  watch: {
    htmlLegend: function() {
      this.$emit("legend-updated", this.htmlLegend);
    },
    chartData: function() {
      this.htmlLegend = this.generateLegend();
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
    this.htmlLegend = this.generateLegend();
  }
};
</script>

<style></style>
