<template>
  <core-view id="light_groups__container">
    <div class="bg-white shadow-sm rounded-md">
      <div>
        <core-view-header :name="name" :count="totalItems" />
        <div class="bg-white flex justify-between pt-0 p-4 md:px-8 md:pb-5">
          <div>
            <core-search
              :query="query"
              @update:query="val => $emit('update:query', val)"
            />
          </div>
          <core-button
            class="ml-auto mr-2"
            :icon="['far', 'router']"
            @click="
              $router.push({
                name: 'tools_lights_gateways'
              })
            "
          />
          <core-button type="coolrunner" @click="editItem()">{{
            $t("create_entity", { entity: $tc("light_group", 1).toLowerCase() })
          }}</core-button>
        </div>
      </div>
    </div>
    <div id="light_groups_container" class="rounded-md mt-3 h-full">
      <div class="grid grid-cols-12 gap-5" v-if="!loading && totalItems">
        <div
          class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2 min-h-44 rounded bg-white shadow-sm hover:shadow-lg"
          :class="{
            'inactive opacity-50 shadow-none hover:shadow-none hover:cursor-default': !group.active
          }"
          v-for="group in filteredItems"
          v-bind:key="`group-${group['name']}-${group['priority']}`"
        >
          <div
            class="p-7 pb-0 cursor-pointer relative"
            @click="() => editItem(group)"
          >
            <div class="group_container__header">
              <core-carrier-logo
                class="category"
                v-if="group.carriers && group.carriers.length"
                :carrier="$_.first(group.carriers)"
              />
              <core-country-flag
                class="category"
                v-else-if="group.countries && group.countries.length"
                :country="$_.first(group.countries)"
              />
              <div class="priority absolute font-semibold top-2 left-2">
                {{ group.priority }}
              </div>
              <div class="actions hidden md:block">
                <core-list-actions
                  trigger="hover"
                  :entity="group"
                  :actions="cardActions"
                  class="group__card__action"
                  style="right: 0"
                />
              </div>
            </div>

            <div class="group_container__body" v-on:click="editItem(group)">
              <p class="title">{{ group.name }}</p>
              <p class="small description mb-7" v-line-clamp="4">
                {{ group.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <core-list-empty
        v-else
        :emptyListText="emptyListText"
        style="height:500px"
      />
    </div>
  </core-view>
</template>

<script>
import Loader from "@/mixins/Loader";
import I18n from "@/plugins/i18n";
import { checkServiceAccess } from "@/utils/tools";
export default {
  beforeRouteEnter(to, from, next) {
    checkServiceAccess("lights", from, next);
  },
  mixins: [Loader],
  name: "LightGroups",
  data: () => ({
    name: "light_group",
    groups: [],
    query: null,
    defaultLoaderTarget: "#light_groups_container",
    modalOpen: false,
    breakpoint: null,
    endpoint: "casambi/groups",
    emptyListText: "Der var ikke nogle lysgrupper der matchede din søgning"
  }),
  computed: {
    totalItems() {
      return this.filteredItems.length;
    },
    cardActions() {
      return [
        {
          label: I18n.t("test_entity", {
            entity: I18n.t("light").toLowerCase()
          }),
          action: entity => {
            this.identify(entity);
          }
        },
        {
          label: I18n.t("edit"),
          action: entity => {
            this.editItem(entity);
          }
        },
        {
          label: I18n.t("activate"),
          hidden: entity => entity.active,
          action: entity => {
            this.toggleItem(entity);
          }
        },
        {
          label: I18n.t("deactivate"),
          hidden: entity => !entity.active,
          action: entity => {
            this.toggleItem(entity);
          }
        },
        {
          label: I18n.t("delete"),
          action: entity => {
            this.deleteItem(entity);
          }
        }
      ];
    },
    filteredItems: function() {
      return this.query
        ? this.$_.filter(
            this.$_.map(this.groups, g => {
              const categories = [...g.carriers, ...g.countries];

              const fulfillsQuery = this.$_.filter(
                categories,
                c => c.indexOf(this.query) !== -1
              ).length;
              return fulfillsQuery ? g : null;
            }),
            g => g !== null
          )
        : this.groups;
    }
  },
  mounted() {
    this.getDataFromApi();
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#light_groups_container"
    };

    this.$on("update:query", function(val) {
      this.query = val;
    });
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$get(this.endpoint, {
        paginate: 0,
        q: this.query
      })
        .then(response => {
          this.groups = response;
        })
        .catch(error => {
          this.groups = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem: function(item) {
      if (item?.id) this.loading = true;
      this.$router.push({
        name: "tools_lights_group",
        params: {
          group_id: item?.id || "null"
        }
      });
    },
    deleteItem(item) {
      const index = this.$_.findIndex(this.groups, c => c.id === item.id);

      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$tc(this.name, 1).toLowerCase()
        })
      ).then(() => {
        if (index >= 0) {
          this.loading = true;
          this.$del(`/${this.endpoint}/${item.id}`, undefined, {
            useDev: this.useDev
          })
            .then(() => {
              return this.getDataFromApi();
            })
            .then(() => {
              this.$notification({
                title: this.$t("success"),
                text: `${this.$tc(this.name)} ${this.$t(
                  "has_been_deleted"
                ).toLowerCase()}`,
                type: "success"
              });
            })
            .catch(e => {
              this.$notification({
                title: this.$t("not_deleted"),
                text: `${this.$tc(this.name)} ${this.$t(
                  "has_not_been_deleted"
                ).toLowerCase()}`,
                type: "error"
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    identify: function(group) {
      this.$get(`casambi/groups/${group.id}/identify`).then(() => null);
    },
    toggleItem: function(group) {
      this.$put(`casambi/groups/${group.id}/toggle`).then(r => {
        this.$notification({
          title: this.$t("success"),
          text: `${this.$tc(this.name)} ${this.$t(
            r.active ? "has_been_activated" : "has_been_deactivated"
          ).toLowerCase()}`,
          type: "success"
        });

        const index = this.$_.findIndex(this.groups, ["id", group.id]);

        if (index >= 0) {
          group.active = r.active;
          let groups = [...this.groups];
          groups.splice(index, 1, group);
          this.groups = groups;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.group_container {
  &__header {
    height: 72px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__body {
    .title {
      font-weight: bold;
      font-size: 16px;
    }

    .description {
      color: #5d6470;
      font-size: 14px;
    }
  }

  .actions {
    padding: 10px 14px;
    color: #272727;
    position: absolute;
    top: 0;
    width: 100%;

    .action-popup__enabler {
      opacity: 1;
      font-size: 20px;
    }

    .action-popup {
      margin-top: -16px;
      margin-right: 16px;
      min-width: 124px;
    }
  }

  .el-icon-border {
    font-size: 16px;
    border: 3px solid #272727;
    border-radius: 2em;
    padding: 3px;
  }

  &.inactive {
    .category {
      filter: brightness(0) saturate(100%) invert(63%) sepia(7%) saturate(60%)
        hue-rotate(169deg) brightness(92%) contrast(88%);
    }
  }
}

.group__card__action {
  position: absolute;
  margin: unset;
  top: 0;
}
</style>
