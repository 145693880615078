<template>
  <div
    class="core-list"
    :class="[
      expandable ? 'core-list-accordion' : '',
      { 'shadow-sm rounded-b-md': !expandable }
    ]"
  >
    <div class="core-list__header actions shadow-sm" v-if="showHeaderActions">
      <core-filter-table-row
        v-bind:key="viewType"
        v-if="filterable"
        :query="query"
        :viewType="viewType"
        :interval="interval"
        @update:query="val => $emit('update:query', val)"
        @set-filters="val => $emit('set-filters', val)"
        @set-cleaned-filters="val => $emit('set-cleaned-filters', val)"
      >
        <template slot="actions">
          <core-list-actions
            v-if="selectable"
            :open.sync="bulkActionsVisible"
            :actions="bulkActions"
            :disabled="!selected.length"
          >
            <template v-slot:activator="">
              <core-button
                :disabled="!selected.length"
                style="margin-left: 20px; height: 42px;"
                >{{
                  $tc("selected_with_count", selectedCount, {
                    count: `${selectAll ? "~ " : ""} ${$n(selectedCount)}`
                  })
                }}
                <i class="el-icon-arrow-down"
              /></core-button>
            </template>
          </core-list-actions>
        </template>
      </core-filter-table-row>
      <core-button
        type="coolrunner"
        class="w-full mt-2.5 md:mt-0 md:w-auto md:ml-6"
        @click="$emit('create-new-clicked')"
        v-if="showCreateNew"
      >
        {{ $t(createNewLabel) }}
      </core-button>
    </div>
    <div v-if="$slots['list-title']" class="core-list__title">
      <slot name="list-title" />
    </div>

    <el-row
      class="core-list__header"
      :class="{ 'with-checkbox': selectable, 'shadow-sm': expandable }"
      v-if="!(breakpoint.innerWidth < breakpoints.large)"
    >
      <el-col
        :span="1"
        v-if="selectable"
        style="max-width: 35px;"
        class="checkbox"
      >
        <div style="padding: 10px 0;">
          <el-checkbox
            :checked="selectAll"
            :indeterminate="isAllSelectedIndeterminate"
            @change="val => $emit('update:selectAll', val)"
          />
        </div>
      </el-col>
      <el-col
        :span="Math.round(availableSpans / headers.length)"
        v-bind:key="header.key"
        v-for="header in headers"
        :class="header.orderable !== false && 'clickable'"
      >
        <div @click="updateOrder(getOrderKey(header), header.orderable)">
          <p class="small fade">
            <span>
              {{ header.localized ? header.label : $tc(header.label) }}
            </span>
            <span v-if="orderBy === getOrderKey(header)">
              <i
                class="el-icon--right"
                :class="
                  orderDir === 'descending'
                    ? 'el-icon-arrow-up'
                    : 'el-icon-arrow-down'
                "
              />
            </span>
          </p>
        </div>
      </el-col>
      <el-col v-if="rowActions && rowActions.length" :span="1" />
    </el-row>
    <core-list-content
      class="flex flex-col justify-center"
      :expandable="expandable"
      :loading="loading"
    >
      <core-list-empty
        v-if="!loading && !totalItems"
        :emptyListIcon="emptyListIcon"
        :emptyListText="emptyListText"
        :entityName="entityName"
      />
      <div class="core-list__row-wrapper" v-else>
        <template v-if="expandable">
          <core-list-expandable
            v-for="(item, index) in items"
            v-bind:key="item.id"
            :index="index"
            :item="item"
            :class="{
              deleted: isInactive(item)
            }"
            :expandDefault="expandDefault"
          >
            <template v-slot:title="{ item }">
              <core-list-row
                v-bind:data-index="index"
                :index="index"
                :headers="headers"
                :item="item"
                :selectable="selectable"
                :selected="isSelected(index, item)"
                @checkbox-change="val => $emit('checkbox-change', val, item)"
                :rowActions="rowActions"
                :extraRowActions="extraRowActions"
              >
                <template
                  v-for="(index, name) in $scopedSlots"
                  v-slot:[name]="data"
                >
                  <slot :name="name" v-bind="data" />
                </template>
              </core-list-row>
            </template>

            <template v-slot:expanded-content="{ item }">
              <slot name="expanded-content" v-bind:item="item" />
            </template>
          </core-list-expandable>
        </template>
        <template v-else>
          <core-list-row
            v-for="(item, index) in items"
            v-bind:key="item.id"
            v-bind:data-index="index"
            :item="item"
            :index="index"
            :headers="headers"
            :selectable="selectable"
            :selected="isSelected(index, item)"
            @checkbox-change="val => $emit('checkbox-change', val, item)"
            :rowActions="rowActions"
            :extraRowActions="extraRowActions"
            @click="$emit('row-click', item, index)"
            :class="{
              clickable: !!$listeners['row-click'],
              odd: !(index % 2),
              deleted: isInactive(item)
            }"
          >
            <template
              v-for="(index, name) in $scopedSlots"
              v-slot:[name]="data"
            >
              <slot :name="name" v-bind="data" />
            </template>

            <template v-slot:expanded-content="{ item }">
              <slot name="expanded-content" v-bind:item="item" />
            </template>
          </core-list-row>
        </template>
      </div>
    </core-list-content>
    <el-pagination
      v-if="paginate && totalItems"
      class="core-list__pagination rounded-b-md"
      :class="{ 'shadow-sm': expandable }"
      :current-page="currentPage"
      :page-size="pageSize"
      :layout="pagerLayout"
      :total="totalItems"
      :pageSizes="[10, 20, 30]"
      :disabled="loading"
      @size-change="s => $emit('update:pageSize', s)"
      @current-change="c => $emit('update:currentPage', c)"
    />
  </div>
</template>

<script>
import { BREAKPOINTS } from "../../../utils/constants";
export default {
  name: "CoreTable",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    showHeaderActions: {
      type: Boolean,
      default: true
    },
    expandable: {
      type: Boolean,
      default: false
    },
    expandDefault: {
      type: Boolean,
      default: false
    },
    totalItems: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    orderBy: {
      type: String,
      default: ""
    },
    orderDir: {
      type: String,
      default: ""
    },
    query: {
      type: String,
      default: ""
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Array,
      default: () => []
    },
    excluded: {
      type: Array,
      default: () => []
    },

    viewType: {
      type: String,
      default: ""
    },
    rowActions: {
      type: Array,
      default: () => []
    },
    extraRowActions: {
      type: Array,
      default: () => []
    },
    paginate: {
      type: Boolean,
      default: true
    },
    interval: {
      type: String,
      default: null
    },
    selectionKey: {
      type: String,
      default: null
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    showCreateNew: {
      type: Boolean,
      default: false
    },
    bulkActions: {
      type: Array,
      default: () => []
    },
    filterable: {
      type: Boolean,
      default: () => true
    },
    emptyListText: {
      type: String,
      default: ""
    },
    emptyListIcon: {
      type: Array,
      default: () => ["fad", "align-slash"]
    },
    entityName: {
      type: String,
      default: "element"
    },
    createNewLabel: {
      type: String,
      default: "create_new"
    },
    checkbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 0,
      size: 0,
      bulkActionsVisible: false
    };
  },
  computed: {
    selectedCount: function() {
      return this.selectAll
        ? this.totalItems - this.excluded.length
        : this.selected.length;
    },
    selectable: function() {
      if (this.checkbox) return true;
      let hasBulkactions = !!(this.bulkActions && this.bulkActions.length);
      let screenSizeOK =
        !this.$store.state.breakpoint.isSmall &&
        !this.$store.state.breakpoint.isMedium &&
        !this.$store.state.breakpoint.noMatch;
      return hasBulkactions && screenSizeOK;
    },
    breakpoint: function() {
      return this.$store.state.breakpoint || {};
    },
    availableSpans: function() {
      let availableSpans = 24;
      if (this.selectable) availableSpans -= 1;
      if (this.rowActions && this.rowActions.length) availableSpans -= 1;

      return availableSpans;
    },
    isAllSelectedIndeterminate: function() {
      return this.selectAll && !!this.excluded.length;
    },
    pagerLayout: function() {
      return "prev, pager, next, ->, jumper";
    },
    breakpoints: () => BREAKPOINTS
  },
  created: function() {
    this.size = this.pageSize;
    this.page = this.currentPage;
  },
  methods: {
    isInactive: function(item) {
      return item.cancel_at || item.deleted_at || item.active === false;
    },

    updateOrder: function(key, orderable) {
      if (orderable === false) return;
      if (key === this.orderBy) {
        if (this.orderDir === "asc") this.$emit("update:orderDir", "desc");
        else this.$emit("update:orderDir", "asc");
      } else {
        this.$emit("update:orderBy", key);
        this.$emit("update:orderDir", "asc");
      }
    },
    updateQuery: function(query) {
      this.$emit("update:query", query);
    },
    getOrderKey: function(header) {
      return header.orderableKey || header.key;
    },
    isSelected: function(index, item) {
      if (!this.selectionKey) return this.$_.includes(this.selected, index);

      return this.$_.includes(this.selected, item[this.selectionKey]);
    }
  }
};
</script>
