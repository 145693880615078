<template>
  <el-dialog
    class=""
    :visible="!!packageNumber"
    @update:visible="$emit('update:packageNumber')"
    append-to-body
    width="730px"
    :title="$t('tracking_info')"
    :close-on-click-modal="false"
  >
    <template>
      <div class="p-5 w-full flex">
        <div
          v-if="loading"
          v-loading="loading"
          class="text-center w-full h-16 -top-3"
          :element-loading-text="$t('looking_for_tracking')"
        />

        <el-timeline
          v-else-if="data && data.events.length > 0"
          class="w-full"
        >
          <el-timeline-item
            v-for="event in data.events"
            :key="event.timestamp + '-' + event.carrier_code"
            placement="top"
          >
            <core-card>
              <div class="w-full">
                <h4>
                  {{ event.timestamp }}
                </h4>
                <p>{{ event.title || event.event }}</p>
                <p v-if="event.location">
                  <font-awesome-icon
                    :icon="['fas', 'map-marker-alt']"
                    fixed-width
                  />
                  {{ event.location }}
                </p>
              </div>
            </core-card>
          </el-timeline-item>
        </el-timeline>
        <div v-else class="w-full text-center">
          {{ $t("no_tracking_events") }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <a
        class="el-button el-button--secondary outline mr-3 rounded-md"
        v-if="data && data.link"
        type="primary"
        :href="data.link"
        target="_blank"
        >{{ $t("go_to_carrier_website") }}
      </a>

      <core-button type="coolrunner" @click="$emit('update:packageNumber')"
        >{{ $t("close") }}
      </core-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: "TrackingModal",
  props: {
    packageNumber: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: true,
    data: null
  }),
  watch: {
    packageNumber: function(val) {
      if (val) this.fetchTracking();
      else {
        this.loading = true;
        this.data = null;
      }
    }
  },
  methods: {
    fetchTracking: function() {
      this.$get(`shipments/${this.packageNumber}/track`)
        .then(r => {
          this.data = r;
        })
        .catch(e => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
