import store from "../store";
import _ from "lodash";

export const loggedIn = () => {
  if (_.isEmpty(store.state.auth)) return false;
  if (
    store.state.auth.access_token != null &&
    store.state.auth.expires > Date.now()
  )
    return true;

  return false;
};
