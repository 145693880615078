<template>
  <core-view :style="{ '--delay': delay}">
    <core-view-header class="mb-5" :name="$t('return_stats')" :description="'page_descriptions.return_stats'" :route-name="$route.name" route="labels" show-view-name> </core-view-header>
    <div class="grid grid-cols-8 gap-5 max-w-full mb-5" >
      <div class="col-span-8 md:col-span-5">
        <component :is="'widget-bar-chart'"
        ref="bar"
        titlePosition="lower" 
        :subtitle="$t('most_returned_products')" 
        :data="barData"
        class="default" 
        :filteringEnabled="false"
        :localData="true" />
      </div>

      <div class="col-span-8 md:col-span-3">
        <component :is="'widget-simple'"
        titlePosition="lower" 
        :subtitle="$t('total_returns')" 
        :data="total"
        class="default" 
        :filteringEnabled="false"
        :localData="true" />
      </div>
      </div>

      <div class="rounded-md col-span-8">
        <core-view-header 
        :name="$t('return_stats')" 
        :routeName="$route.name" route="returns/statistics" showViewName>
        </core-view-header>
        <core-list-new-table 
        class="rounded-md col-span-8" 
        :title="title" 
        :items="items" 
        :viewType="viewType"
        :headers="headers"
        :pageSize.sync="limit" 
        :currentPage.sync="page" 
        :query.sync="search"
        :totalItems="totalItems" 
        :loading="loading"
        :orderBy.sync="orderBy" 
        :orderDir.sync="orderDir" 
        @set-cleaned-filters="val => $emit('set-cleaned-filters', val)"
        @set-filters="val => $emit('set-filters', val)"
        :selection="false"
        :entityName="name"
        pushPath="returns_details">
        </core-list-new-table>
      </div>
  </core-view>
</template>
  
<script>
import {Bar} from "vue-chartjs";
import ESList from "@/mixins/ESList";
import { convertUTCToLocal } from "@/utils/date";
import _ from "lodash";
import axios from 'axios';

export default {
  mixins: [Bar, ESList],
  data: () => ({
    viewType: "returns",
    endpoint: "returns/causes",
    name: "statistics",
    products: [],
    countries: [],
    barItems: [],
    total: 0,
    search: null,
    title: "return_stats",
    limit: 30,
    orderBy: "created_at",
    orderDir: "desc",
    itemsLoaded: false,
    page: 1,
    headers: [
      {
        label: "name",
        key: "name",
        orderable: false
      },
      {
        label: "most_used_cause",
        key: "most_used",
        orderable: false
      },
      {
        label: "comment",
        key: "comments",
        orderable: false,
        minWidth: "20"
      },
      {
        label: "quantity",
        key: "product_qty",
        orderable: false,
        minWidth: "15"
      },
    ]
  }),

  mounted: function () {
  },

  watch: {
    itemsLoaded: function(val) {
      if (val && this.$route.query.state == 'intro') {
        this.items = [
        {
          "comments": 0,
          "product_qty": 71,
          "causes": {
              "Forkert størrelse": 71
          },
          "most_used": "Forkert størrelse",
          "name": "Test produkt 1"
        },
        {
          "comments": 0,
          "product_qty": 71,
          "causes": {
              "Forkert farve": 71
          },
          "most_used": "Forkert farve",
          "name": "Test produkt 2"
        },
        {
          "comments": 6,
          "product_qty": 50,
          "causes": {
              "Fejlbestilling": 42,
              "Beskadiget under transport": 1,
              "Defekt eller virker ikke som forventet": 1,
              "For stor": 1
          },
          "most_used": "Fejlbestilling",
          "name": "Test produkt 3"
        },
      ],
      this.totalItems = 56;
      this.total = {data: {datasets: [{data: [192]}]}};
      this.barItems = this.items;
      }
    },
    page: function (n,o) {
    },
    cleanedFilters: function () {
      this.page = 1;
    },
    search: function () {
      this.page = 1;
    }
  },

  computed: {
    delay: function () {
      if (this.total > 20) {
        return 1.2
      } else {
        return this.items.length * 0.050
      }
    },
    barData: function () {
      let labels = [];
      let data = [];
        let sortedData=_.orderBy(this.barItems,['item_count'],['desc']);
        _.forEach(sortedData, function (product, index) {
          if(index<10){
            labels.push(product.name);
          data.push(product.product_qty );
          }
        });
        let dataObject = {
        data: {
          datasets:[{
          backgroundColor:[],
          data: data,
        }],
        labels: labels,
        }
      }
      return dataObject;
    }
  },

  methods: {
    convertUTCToLocal: function (val, type) {
      return convertUTCToLocal(val, type);
    },
    getDataFromApi() {
      this.itemsLoaded = false;
      if (this.cancelToken) {
        this.cancelToken.cancel("cancelled");
      }
      this.cancelToken = axios.CancelToken.source();

      this.loading = true;
      this.$post(
        `${this.endpoint}${this.search ? "?query=" + this.search : ""}`,
        {
          filters: this.cleanedFilters,
          limit: this.limit,
          page: this.page,
          q: this.search
        },
        undefined,
        { cancelToken: this.cancelToken.token }
      )
        .then(response => {
          this.items = response.data.data;
          this.totalItems = response.data.total;
          this.total = {data: {datasets: [{data: [response.total.total_qty]}]}};
          if (this.page == 1) {
            this.barItems = response.data.data;
          }
          this.loading = false;
          this.itemsLoaded = true;
        })
        .catch(e => {
          if (e !== "cancelled") this.loading = false;
        });
    }
  }
};
</script>
