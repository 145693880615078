import axios from "axios";
import Qs from "qs";
import _ from "lodash";
import { URL_PREFIX, URL_PREFIX_DEV, API_PREFIX } from "@/utils/constants";
import Vue from "vue";
import I18n from "@/plugins/i18n";
import store from "@/store";
import router from "@/router";

const http = axios.create({
  baseURL: `${API_PREFIX}/business-panel`
});

const http_dev = axios.create({
  baseURL: `${API_PREFIX}/business-panel`
});

http.interceptors.request.use(
  function(config) {
    const token = store?.state?.auth?.access_token;
    const user_id = store?.state?.account_id;
    const auth_id = store?.state?.auth_id;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (user_id) config.headers['x-homerunner-account-id'] = user_id;
    if (auth_id) config.headers['x-homerunner-auth-id'] = auth_id;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

http_dev.interceptors.request.use(
  function(config) {
    const token = store?.state?.auth?.access_token;
    const user_id = store?.state?.account_id;
    const auth_id = store?.state?.auth_id;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (user_id) config.headers['x-homerunner-account-id'] = user_id;
    if (auth_id) config.headers['x-homerunner-auth-id'] = auth_id;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const mutateResponse = (response, rawResponse) => {
  if (rawResponse) return Promise.resolve(response);

  if (typeof response.data !== "string") {
    const data = response.data?.response_data
      ? response.data.response_data
      : response.data;

    if (!data) {
      return Promise.reject({ message: I18n.t("no_data") });
    }
    return Promise.resolve(data);
  }
  return Promise.reject(response);
};

const handleErrorResponse = (e, failSilently, logoutOnError = true) => {
  if (e?.response?.status === 401 && logoutOnError) {
    if (
      router &&
      router.history.current.name !== "login" &&
      !store.state.loggingOut &&
      store.state.auth
    ) {
      store.dispatch("logout");
      Vue.notification({
        title: I18n.t("logged_out"),
        text: I18n.t("logged_out_by_system"),
        type: "error",
        duration: -1
      });
    }
    return;
  }

  if (e.message === "cancelled") {
    return Promise.reject("cancelled");
  }
  if (!failSilently) {
    const errors =
      e.response.data.errors ||
      e.response.data.response_data ||
      e.response.message;
    Vue.notification({
      title: I18n.t("error"),
      text: _.isArray(errors) ? _.join(_.map(errors), "</br>") : errors,
      type: "error"
    });
  }
  return Promise.reject(e);
};

const paramsSerializer = (params, mutateLocation) => {
  const stringiFiedParams = Qs.stringify(params, { arrayFormat: "brackets" });

  if (mutateLocation) {
    var sp = new URLSearchParams(stringiFiedParams);
    const route = location.hash.split("?")[0];
    window.history.replaceState(null, null, `${route}?${sp.toString()}`);
  }

  return stringiFiedParams;
};

const fetch = (uri, query = {}, options = {}) => {
  const { mutateLocation, useDev, failSilently, force, rawResponse } = options;
  const { cancelToken, ...q } = query;
  const axiosInstance = useDev ? http_dev : http;

  let axiosOptions = {
    cancelToken,
    params: q,
    paramsSerializer: p => paramsSerializer(p, mutateLocation)
  };

  if (force) {
    axiosOptions.cache = { ignoreCache: true };
  }

  return axiosInstance
    .get(uri, axiosOptions)
    .then(r => mutateResponse(r, rawResponse))
    .catch(e => handleErrorResponse(e, failSilently));
};

const del = (uri, query = {}, options = {}) => {
  const { mutateLocation, useDev, failSilently, force, rawResponse } = options;

  const { cancelToken, ...q } = query;
  const axiosInstance = useDev ? http_dev : http;

  let axiosOptions = {
    cancelToken,
    params: q,
    paramsSerializer: p => paramsSerializer(p, mutateLocation)
  };

  if (force) {
    axiosOptions.cache = { ignoreCache: true };
  }

  return axiosInstance
    .delete(uri, axiosOptions)
    .then(r => mutateResponse(r, rawResponse))
    .catch(e => handleErrorResponse(e, failSilently));
};

const post = (uri, query = {}, options = {}, axios = {}) => {
  const { mutateLocation, useDev, failSilently, force, rawResponse, logoutOnError } = options;
  const axiosInstance = useDev ? http_dev : http;
  const stringiFiedParams = Qs.stringify(query, { arrayFormat: "brackets" });

  if (mutateLocation) {
    var sp = new URLSearchParams(stringiFiedParams);
    const route = location.hash.split("?")[0];
    window.history.replaceState(null, null, `${route}?${sp.toString()}`);
  }

  let axiosOptions = axios;

  if (force) {
    axiosOptions.cache = { ignoreCache: true };
  }

  return axiosInstance
    .post(uri, query, axiosOptions)
    .then(r => mutateResponse(r, rawResponse))
    .catch(e => handleErrorResponse(e, failSilently, logoutOnError));
};

const upload = (uri, file, options = {}, axios = {}) => {
  const { useDev, failSilently, force, rawResponse } = options;
  const axiosInstance = useDev ? http_dev : http;

  let axiosOptions = axios;

  if (force) {
    axiosOptions.cache = { ignoreCache: true };
  }

  return axiosInstance
    .post(uri, file, axiosOptions)
    .then(r => mutateResponse(r, rawResponse))
    .catch(e => handleErrorResponse(e, failSilently));
};

const put = (uri, query = {}, options = {}) => {
  const { useDev, failSilently, force, rawResponse } = options;
  const axiosInstance = useDev ? http_dev : http;

  let axiosOptions = {};

  if (force) {
    axiosOptions.cache = { ignoreCache: true };
  }

  return axiosInstance
    .put(uri, query, axiosOptions)
    .then(r => mutateResponse(r, rawResponse))
    .catch(e => handleErrorResponse(e, failSilently));
};

const patch = (uri, query = {}, options = {}) => {
  const { useDev, failSilently, force, rawResponse } = options;
  const axiosInstance = useDev ? http_dev : http;

  let axiosOptions = {};

  if (force) {
    axiosOptions.cache = { ignoreCache: true };
  }

  return axiosInstance
    .patch(uri, query, axiosOptions)
    .then(r => mutateResponse(r, rawResponse))
    .catch(e => handleErrorResponse(e, failSilently));
};

const readFile = file => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error("Problem parsing file"));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(file);
  });
};

const readJsonFromBlob = file => {
  return readFile(file).then(r => {
    return Promise.resolve(JSON.parse(r));
  });
};

export default {
  http,
  http_dev,
  fetch,
  post,
  patch,
  upload,
  put,
  del,
  handleErrorResponse,
  mutateResponse,
  readJsonFromBlob
};
