<template>
  <div class="text">
    <template v-if="value.length">
      <el-row
        type="flex"
        align="middle"
        v-for="(val, index) in value"
        v-bind:key="index"
        :gutter="20"
      >
        <el-col :span="22">
          <core-input
            v-bind:key="'input_' + index"
            :label="title"
            :value="getValue(val)"
            readonly
            @click="$emit('edit-filter', index)"
          />
        </el-col>
        <el-col :span="2">
          <core-filter-modal-filter-clear
            @click="$emit('remove-filter', index)"
          />
        </el-col>
      </el-row>
      <core-button @click="$emit('add-new')">
        {{ $t("add_new") }}
      </core-button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleInput: function(val, index) {
      if (this.value.length === 1) {
        if (val) this.$emit("update-filter", val, index);
        else this.$emit("remove-filter", index);
      } else this.$emit("update-filter", val, index);
    },
    getValue: function(val) {
      if (val.value.type === "custom-range") {
        const end = val.value.end || "0";
        const start = val.value.start || "0";

        return `${start} - ${end} ${this.$t("days_since").toLowerCase()}`;
      } else {
        const to = val.value.end ? this.$moment(val.value.end) : this.$moment();
        if (this.$_.isObject(val.value)) {
          return (
            this.$moment(val.value.start, "YYYY-MM-DD").format("Do MMMM YYYY") +
            " - " +
            to.format("Do MMMM YYYY")
          );
        }
        return this.filter.choices[val.value];
      }
    }
  }
};
</script>
