<template>
  <el-collapse
    v-model="expanded"
    v-bind:key="item.id"
    class="core-list-accordion__row shadow-sm rounded transition-shadow	"
    :class="[{ 'shadow-md': expanded.length }]"
    ref="collapse"
  >
    <el-collapse-item class="core-list-accordion__row-content" :name="item.id">
      <template slot="title">
        <slot name="title" v-bind:item="item" />
      </template>
      <slot name="expanded-content" v-bind:item="item" />
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  props: {
    index: {
      type: Number
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    expandDefault: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      expanded: []
    };
  },
  mounted: function() {
    if (this.expandDefault) this.expanded.push(this.item.id);
  }
};
</script>
