<template>
  <div class="p-5">
    <div>
      <row>
        <column :xs="12" :xl="3" style="margin: auto">
          <core-carrier-logo :carrier="value.carrier" />
        </column>
        <column :xs="12">
          <row :gutter="12">
            <column :xs="12">
              <core-card style="height: 100%;">
                <div class="w-full">
                  <h3>{{ $t("order_summary") }}</h3>
                  <p>
                    <b>{{ $tc("order") }}: </b>
                    <i>{{ $t("pending") }}</i>
                  </p>
                  <p>
                    <b>{{ $t("order_date") }}:</b>
                    {{ convertUTCToLocal(undefined, "datetime") }}
                  </p>
                  <p>
                    <b>{{ $t("product") }}:</b>
                    <span @click="$emit('set:step', 'products')">
                      {{ value.price.title }}
                      <font-awesome-icon :icon="['far', 'edit']" />
                    </span>
                  </p>
                  <p>
                    <b>{{ $t("price") }}:</b>
                    <span>
                      {{ $n(salesPrice, "currency", "da-DK") }}
                    </span>
                  </p>
                  <span v-if="value.insurance">
                    <p>
                      <b>{{ $t("insurance") }} : </b>
                      {{ $n(insurancePrice, "currency", "da-DK") }}
                    </p>
                  </span>
                  <p>
                    <b>{{ $t("price_total") }}:</b>
                    <span>
                      {{ $n(salesPrice + insurancePrice, "currency", "da-DK") }}
                    </span>
                  </p>
                  <div style="text-align: left;">
                    <core-checkbox
                      :value="termsAccepted"
                      @change="setTermsAccepted"
                    >
                      <i18n path="accept_terms" tag="p">
                        <template v-slot:terms>
                          <a v-if="$store.state.account.business"
                            href="https://homerunner.com/handelsbetingelser/"
                            target="_blank"
                            >{{ $t("sales_terms").toLowerCase() }}</a
                          >
                          <a v-if="!$store.state.account.business"
                            href="https://coolrunner.dk/handelsbetingelser/"
                            target="_blank"
                            >{{ $t("sales_terms").toLowerCase() }}</a
                          >
                        </template>
                      </i18n>
                    </core-checkbox>
                  </div>
                </div>
              </core-card>
            </column>

            <column :xs="12">
              <core-card style="height: 100%;">
                <div class="w-full">
                  <h3>{{ $t("label_information") }}</h3>
                  <p>
                    <b>{{ $t("package_number") }}: </b>
                    <i>{{ $t("pending") }}</i>
                  </p>
                  <p>
                    <b>{{ $t("dimensions") }}: </b>

                    {{ value.length }} x {{ value.height }} x
                    {{ value.width }} cm
                  </p>
                  <p>
                    <b>{{ $t("weight") }}: </b> {{ value.weight }}g
                  </p>
                  <br />

                  <core-input
                    :label="$t('reference')"
                    v-model="value.reference"
                    :value="value.reference"
                    @input="updateReference"
                  />
                </div>
              </core-card>
            </column>
            <column :xs="6">
              <core-card style="height: 100%;">
                <div class="w-full">
                  <h3 class="text-lg">
                    {{ $t("entity_address", { entity: $t("sender") }) }}
                  </h3>
                  <p>{{ value.sender.name }}</p>
                  <p>{{ value.sender.street1 }}</p>
                  <p v-if="value.sender.street2">
                    {{ value.sender.street2 }}
                  </p>
                  <p>
                    {{ value.sender.zip_code }}
                    {{ value.sender.city }}
                  </p>
                  <p>{{ value.sender.email }}</p>
                  <p>{{ value.sender.phone }}</p>
                </div>
              </core-card>
            </column>
            <column :xs="6">
              <core-card style="height: 100%;">
                <div class="w-full">
                  <h3 class="text-lg">
                    {{ $t("entity_address", { entity: $t("receiver") }) }}
                  </h3>
                  <p>{{ value.receiver.name }}</p>
                  <p>{{ value.receiver.street1 }}</p>
                  <p v-if="value.receiver.street2">
                    {{ value.receiver.street2 }}
                  </p>
                  <p>
                    {{ value.receiver.zip_code }}
                    {{ value.receiver.city }}
                  </p>
                  <p>{{ value.receiver.email }}</p>
                  <p>{{ value.receiver.phone }}</p>
                </div>
              </core-card>
            </column>
          </row>
        </column>
      </row>
    </div>
  </div>
</template>

<script>
import { convertUTCToLocal } from "@/utils/date";
export default {
  name: "CreateShipmentReview",
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    termsAccepted: false
  }),
  computed: {
    salesPrice: function() {
      let price = 0;

      const price_key = this.$store.state.account.business
        ? "excl_tax"
        : "incl_tax";

      price += this.value.price[price_key];

      return price;
    },
    insurancePrice: function() {
      if (!this.value.insurance) return 0;
      let product = {}
      this.$store.state.insurance_products.forEach(productIn => {
        if (productIn.id == this.value.insurance) {
          product = productIn
        }
      });
      const price = this.$store.state.account.business
        ? (product.price_incl_tax / 100) * 80
        : product.price_incl_tax;
      return price;
    }
  },
  methods: {
    convertUTCToLocal: function(val, type) {
      return convertUTCToLocal(val, type);
    },
    updateReference: function(val) {
      this.$emit("update:value", { ...this.value, reference: val });
    },
    setTermsAccepted: function(val) {
      this.termsAccepted = val;
      this.$emit("terms:accepted", val);
    }
  }
};
</script>

<style scoped></style>
