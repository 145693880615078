<template>
  <core-view>
    <div v-if="!canView">
      {{$t('page_restricted')}}
    </div>
    <div v-if="canView">
    <core-view-header
      :name="'documents'"
      :count="pageData.total"
      :description="'page_descriptions.documents'"
      :routeName="$route.name"
      route="documents"
      showViewName
    >
    </core-view-header>
    <div class="bg-white px-7 pb-4 grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-5">
      <div class="col-span-1">
        <p class="leading-tight">{{ $t('select_sender_country') }}</p>
        <core-select-country
                class="w-64"
                v-model="sender_country"
                :value="sender_country"
                :dataOverride="countries"
              />
      </div>
      <div class="col-span-1">
        <p class="leading-tight">{{ $t('select_receiver_country') }}</p>
      <core-select-country
                class="w-64"
                v-model="receiver_country"
                :value="receiver_country"
                :dataOverride="countries"
              />
      </div>
      <core-date-picker-tailwind
        class="mb-4 col-span-1"
        label="select_date_from"
        :modelValue="$moment(new Date()).add(-7, 'days')._d"
        @update:modelValue="setDateFrom"/>
        <core-date-picker-tailwind
        class="mb-4 col-span-1"
        label="select_date_to"
        @update:modelValue="setDateTo"/>
      <div class="col-span-1 pt-7 grid">
        <div class="justify-self-end flex flex-wrap">
          <div class="w-10 h-10 pt-1 text-2xl rounded-l cursor-pointer transition duration-300"
                :class="mode == 'cards' ? 'bg-coolrunner-500' : 'bg-gray-300'"
                @click="setMode('cards', 18)">
            <font-awesome-icon
              class="text-white"
              :icon="['fas', 'th-large']"
            />
          </div>
          <div class="w-10 h-10 pt-1 text-2xl rounded-r cursor-pointer transition duration-300"
               :class="mode == 'list' ? 'bg-coolrunner-500' : 'bg-gray-300'"
                @click="setMode('list', 30)">
            <font-awesome-icon class="text-white" :icon="['far', 'bars']" />
          </div>
        </div>
      </div>
      <div class="flex col-span-2">
        <div v-bind:key="document_type.slug" v-for="document_type in document_types">
           <input
          type="checkbox"
          :id="document_type.slug"
          class="sr-only peer"
          :checked="type == document_type.slug"
        />
        <div @click="type = document_type.slug "
        class="cursor-pointer mt-2 mr-2 border border-gray-300 bg-gray-100 px-2 py-2 rounded transition duration-300 peer-checked:border-coolrunner-300 peer-checked:text-coolrunner-300 peer-checked:bg-gray-50 min-w-max">
          {{document_type.name}}
          </div>
        </div>
      </div>
    </div>
    <div id='cards'>
    <transition name="document-animation" mode="out-in" >
      <div v-if="totalItems == 0 && mode == 'cards' && !loading" class="pt-6">
      {{ $t('no_matches') }}
    </div>
    <div v-if="mode == 'cards' && !loading && totalItems !== 0" >
    <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 mt-4 gap-6"
       >
      <div
        v-for="(file, index) in files"
        v-bind:key="'file' + index">
        <transition appear name="return-animation" mode="out-in">
      <div
        class="
          shadow-md
          rounded
          bg-white
          p-4
          hover:shadow-lg
          cursor-pointer
          transition
          duration-300
          ease-in-out
          h-64
          relative
        "
        :style="{ '--i': index, '--length': files.length }"
        @click="showFile(file)"
      >
        <core-list-actions
          trigger="hover"
          :actions="cardActions"
          :entity="file"
          class="absolute top-1 right-2 rounded-full"
        >
        </core-list-actions>
        <div class="relative mt-12">
          <div class="text-5xl ralative">
            <font-awesome-icon class="text-gray-300" :icon="['fal', 'file']" />
            <p
              class="absolute top-7 left-2/4 -ml-3 text-xs font-medium"
              :class="checkColor(file.file_type)"
            >
              {{ file.file_type }}
            </p>
          </div>
        </div>
        <div class="h-32 flex flex-wrap content-end overflow-hidden">
          <h3 class="text-base w-full mb-6">{{ $t(file.type) }}</h3>
          <div class="grid grid-cols-1 xl:grid-cols-2 w-full">
            <div v-if="file.receiver_country.length <= 2">
            <core-list-cell-templates-receiver-with-flag v-bind:key="country" v-for=" country in file.receiver_country"
          :name="country"
          :countryCode="country"
            /></div>
            <div v-if="file.receiver_country.length > 2">
            <core-list-cell-templates-receiver-with-flag :class="{ 'last' : index == 1} " v-bind:key="country" v-for=" country, index in splicedCountries(file.receiver_country)"
          :name="country"
          :countryCode="country"
            /></div>
            <p class="text-sm col-span-1 text-right text-gray-500">
              {{ $moment(file.created_at, "YYYY-MM-DD").format("LL") }}
            </p>
          </div>
        </div>
      </div>
      </transition>
      </div>
    </div>
    <el-pagination
        class="core-list__pagination mt-4"
        :current-page="currentPage"
        :total="pageData.total"
        :page-size="limit"
        :disabled="loading"
        style="background-color:white;
      border-radius: 9px;
      padding:7px 0;"
        @size-change="s => $emit('update:pageSize', s)"
        @current-change="c => changePage(c)"
      />
    </div>
    </transition>
    </div>
    <transition name="document-animation" mode="out-in">
        <div class="mt-4"
        v-if="mode == 'list'">
      <core-list-new-table
      :items="computedfiles"
      :headers="headers"
      :loading="loading"
      :currentPage.sync="currentPage"
      :totalItems="totalItems"
      :rowActions="cardActions"
      :pageSize="limit"
      :selection="false"
      :filterable="false"
      @rowclicked="val => showFile(val)"
    >
     <template v-slot:[`column.type`]="{ item }">
        <div>
          {{$t(item.type)}}
        </div>
      </template>
      <template v-slot:[`column.receiver_country`]="{ item }">
        <div v-if="item.receiver_country.length <= 2">
            <core-list-cell-templates-receiver-with-flag v-bind:key="country" v-for=" country in item.receiver_country"
          :name="country"
          :countryCode="country"
            /></div>
            <div v-if="item.receiver_country.length > 2">
            <core-list-cell-templates-receiver-with-flag :class="{ 'last' : index == 1} " v-bind:key="country" v-for=" country, index in splicedCountries(item.receiver_country)"
          :name="country"
          :countryCode="country"
            /></div>
      </template>
      <template v-slot:[`column.created_at`]="{ item }">
        <div>
          {{$moment(item.created_at, "YYYY-MM-DD").format("LL")}}
        </div>
      </template>
    </core-list-new-table>
    </div>

    </transition>
    <core-document-modal
      :visible.sync="modals.documentViewer.visible"
      :entity="modals.documentViewer.entity"
    />
  </div>
  </core-view>
</template>

<script>
import { URL_PREFIX } from "@/utils/constants";
import Loader from "@/mixins/Loader"
import pageAccess from "@/mixins/PageAccess";

export default {
  mixins: [Loader, pageAccess],
  name: "Documents",
  data: () => ({
    defaultLoaderTarget: "#cards",
    endpoint: "documents",
    loaderOptions: {
      lock: true,
      fullscreen: false,
      text: "loading",
      customClass: null
    },
    countries: [
      {
        active: true,
        address_format: "{street_name} {street_number}, {zip_code} {city}, {state}, {country_name}",
        created_at: "2018-07-19T12:20:09.000000Z",
        deleted_at: "",
        full_name: "Austria",
        id: 241,
        is_zone: 0,
        iso: "AT",
        iso3: "AUT",
        numeric: "040",
        phone_format: ".+",
        phone_prefix: "43",
        region: "Central Europe",
        trade_union: "EU",
        updated_at: "2021-08-01T00:00:20.000000Z",
        zip_format: "^\\d{4}$"
      }
      ],
    document_types: null,
    pageData : {},
    totalItems: 0,
    loading: false,
      sender_country: "DK",
      receiver_country: "",
      dateTo: "",
      dateFrom:"",
      currentPage: 1,
      limit: 18,
      type: "",
    files: [],
    modals:{
        documentViewer: {
          visible: false,
          entity: {}
        }
    },
    mode: 'cards',
    headers: [
        {
        label: "type",
        key: "type",
      },
      {
        label: "created_at",
        key: "created_at",
      },
      {
        label: "receiver_country",
        key: "receiver_country",
      },
      {
        label: "file_type",
        key: "file_type",
      },
    ]
  }),
  mounted() {
    let date = this.$moment(new Date())
    let AWaakAgo = this.$moment(new Date()).add(-7, 'days')._d
    this.dateFrom = this.$moment(AWaakAgo).format('YYYY-MM-DD')
    this.dateTo = this.$moment(date).format('YYYY-MM-DD')
    this.getDocuments()
  },
  watch: {
    dateFrom: function (n, o) {
      this.currentPage = 1
      this.getDocuments()
    },
    sender_country: function (n, o) {
      this.currentPage = 1
      this.getDocuments()
    },
    receiver_country: function (n, o) {
      this.currentPage = 1
      this.getDocuments()
    },
    dateTo: function (n, o) {
      this.currentPage = 1
      this.getDocuments()
    },
    currentPage: function (n, o) {
      this.getDocuments()
    },
    type: function (n, o) {
      this.currentPage = 1
      this.getDocuments()
    },
  },
  computed: {
    computedfiles: function() {
      return this.files
    },
    cardActions: function () {
      return [
        {
          label: this.$t("download_entity", {
            entity: this.$t("").toLowerCase(),
          }),
          icon: ["far", "file-download"],
          link: item =>
            `${URL_PREFIX}/business-panel/documents/${item.id}?access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`,
        },
      ];
    },
    bulkActions: function () {
      return [
        {
          label: this.$t("download_entity", {
            entity: this.$t("pdf").toLowerCase(),
          }),
          icon: ["far", "file-download"],
          link: (item) =>
            item.url + "?access_token=" + this.$store.state.auth.access_token + '&account_id=' + this.$store.state.account_id,
        },
        {
          label: this.$t("download_entity", {
            entity: this.$t("specification").toLowerCase(),
          }),
          icon: ["far", "file-download"],
          link: (item) =>
            item.specification_url +
            "?access_token=" +
            this.$store.state.auth.access_token + '&account_id=' + this.$store.state.account_id,
        },
      ];
    },
  },
  methods: {
    splicedCountries: function (val) {
     return val.slice(0, 2)
     },
    changePage: function (val) {
      this.currentPage = val
      this.getDocuments()
    },
    getDocuments: function () {
      this.loading = true
       this.$get(`documents?page=${this.currentPage}&limit=${this.limit}&sender_country=${this.sender_country}&receiver_country=${this.receiver_country}&from_date=${this.dateFrom}&to_date=${this.dateTo}&type=${this.type}`, {
        failSilently: true
      })
        .then(r => {
          this.pageData = r;
          this.files = r.data
          this.totalItems = r.total
          this.document_types = this.$store.state.document_types
          this.countries = this.$store.state.document_countries
        })
        .catch(e => {})
        .finally(() => {
          this.loading = false;
        });
    },
    handleSelection: function (val) {
       this.filter = val
    },
    checkColor: function (key) {
      switch (key) {
        case "pdf":
          return "text-red-600";
        case "xls":
          return "text-green-600";
        default:
          return "text-gray-600";
      }
    },
    setMode: function (key, limit) {
        this.mode = key
        this.limit = limit
    },
    showFile: function (val) {
      switch (val.file_type) {
        case 'pdf':
          this.modals.documentViewer.entity.url = `${URL_PREFIX}/business-panel/documents/${val.id}?access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}&framed`;
          this.modals.documentViewer.entity.type = 'PDF';
          this.modals.documentViewer.entity.name = this.$t(val.type)
          this.modals.documentViewer.entity.date = this.$moment(val.created_at).format('YYYY-MM-DD')
          this.modals.documentViewer.entity.countries = val.receiver_country
          break;
          case 'xml':
            return
         // this.modals.documentViewer.entity.url = `${URL_PREFIX}/business-panel/documents/${val.id}?access_token=${this.$store.state.auth.access_token}`;
         // this.modals.documentViewer.entity.type = 'XML';
         // this.modals.documentViewer.entity.name = this.$t(val.type)
         // this.modals.documentViewer.entity.date = this.$moment(val.created_at).format('YYYY-MM-DD')
         // this.modals.documentViewer.entity.countries = val.receiver_country
          case 'xls':
          this.modals.documentViewer.entity.url = `${URL_PREFIX}/business-panel/documents/${val.id}?access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`;
          this.modals.documentViewer.entity.type = 'XLS';
          this.modals.documentViewer.entity.name = this.$t(val.type)
          this.modals.documentViewer.entity.date = this.$moment(val.created_at).format('YYYY-MM-DD')
          this.modals.documentViewer.entity.countries = val.receiver_country
          break;
        default:
          return
      }
        this.modals.documentViewer.visible = true
    },
    setDateTo(val) {
       this.dateTo = this.$moment(val).format('YYYY-MM-DD')
    },
    setDateFrom(val) {
       this.dateFrom = this.$moment(val).format('YYYY-MM-DD')
    }
  },
};
</script>

<style lang="scss" scoped>
.last {
  display: flex;
  margin-right: 5px;
  &::after {
        content: '...';
  }
}
</style>