<template>
  <core-view :style="{ '--delay': delay}">
    <core-view-header :name="name" :count="totalItems" />
    <core-list-new-table
    :items="items"
    :headers="headers"
    :pageSize="options.itemsPerPage"
    :currentPage.sync="options.page"
    :totalItems="totalItems"
    :loading="loading"
    :orderBy.sync="options.orderBy"
    :orderDir.sync="options.orderDir"
    :query.sync="search"
    @create-new-clicked="editItem()"
    :rowActions="rowActions"
    :entityName="name"
    :emptyListIcon="icon"
    :selection="false"
    showCreateNew
    />
    <el-dialog
      class="filter abow_dialog"
      :visible.sync="dialog"
      @update:visible="$emit('confirm')"
      append-to-body
      width="730px"
      :title="
        $t('update_entity', {
          entity: `${$tc(name).toLowerCase()}`
        })
      "
      :close-on-click-modal="false"
    >
      <template>
        <div class="wrapper form">
          <div class="grid grid-cols-12 gap-5">
            <div class="col-span-12">
              <el-tabs v-model="editedItem.type" stretch>
                <el-tab-pane :label="$t('private')" name="private" />
                <el-tab-pane :label="$t('business')" name="business" />
              </el-tabs>
            </div>
            <div class="col-span-12" v-if="editedItem.type === 'business'">
              <core-text
                :label="$t('company_name')"
                v-model="editedItem.company_name"
              />
            </div>

            <div class="col-span-12">
              <core-text
                :label="
                  editedItem.type === 'business' ? $t('attention') : $t('name')
                "
                v-model="editedItem.name"
              />
            </div>
            <div class="col-span-12">
              <core-text
                :label="`${$t('adress_line')} 1`"
                v-model="editedItem.street1"
              />
            </div>
            <div
              class="col-span-12"
              v-if="
                editedItem.type === 'business' || editedItem.country !== 'DK'
              "
            >
              <core-text
                :label="`${$t('adress_line')} 2`"
                v-model="editedItem.street2"
              />
            </div>
            <div class="col-span-4">
              <core-text
                :label="$t('zip_code')"
                v-model="editedItem.zip_code"
              />
            </div>
            <div class="col-span-8">
              <core-text :label="$t('city')" v-model="editedItem.city" />
            </div>
            <div class="col-span-12">
              <core-text :label="$t('email')" v-model="editedItem.email" />
            </div>
            <div class="col-span-12">
              <core-select-country
                :label="$t('country')"
                v-model="editedItem.country"
              />
            </div>

            <div class="col-span-12">
              <core-phone-input
                :phone_prefix.sync="editedItem.phone_prefix"
                :phone.sync="editedItem.phone"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer class="flex">
        <core-button type="default_outline" @click="close"
          >{{ $t("cancel") }}
        </core-button>
        <core-button
          class="ml-auto"
          type="coolrunner"
          @click="save"
          :loading="saving"
          >{{ $t("save") }}
        </core-button>
      </template>
    </el-dialog>
  </core-view>
</template>

<script>
import List from "@/mixins/List";
import { convertUTCToLocal } from "@/utils/date";
export default {
  mixins: [List],
  data: () => ({
    endpoint: "contacts",
    name: "contact",
    icon: ["far", "address-book"],
    headers: [
      {
        label: "name",
        key: "name"
      },
      { label: "email", key: "email" },
      {
        label: "phone",
        key: "phone"
      }
    ],
    defaultItem: {
      type: "private",
      name: "",
      company_name: "",
      street1: "",
      street2: "",
      zip_code: "",
      country: "DK",
      phone_prefix: "45",
      phone: "",
      email: ""
    }
  }),
  mounted: function() {
    this.getDataFromApi();
  },
  computed: {
    rowActions: function() {
      return [
        {
          label: this.$t("delete"),
          icon: ["far", "trash"],
          action: this.deleteItem
        },
        {
          label: this.$t("edit"),
          icon: ["far", "edit"],
          action: this.editItem
        }
      ];
    },
    delay: function() {
        return this.items.length * 0.050
    }
  },
  methods: {
    convertUTCToLocal: function(val) {
      return convertUTCToLocal(val, "date");
    }
  }
};
</script>
