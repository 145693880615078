<template>
  <core-view id="returns__container">
    <div class="overflow-hidden rounded-md bg-white">
      <core-view-header :name="'returns'" :count="totalItems" :description="'page_descriptions.returns'" />
      <div class="flex justify-between bg-white p-4 pt-0 shadow-sm md:px-8 md:pb-5">
        <div>
          <core-search size="small" :query="query" @update:query="(val) => $emit('update:query', val)" />
        </div>
        <core-button type="coolrunner" @click="newPage">{{ $t("create_new") }}</core-button>
      </div>
    </div>
    <div id="returns_container" class="grid grid-cols-12 gap-5" style="margin-top: 1em">
      <div v-for="(route, index) in routes" :key="`route-${route['name']}-${route['country']}`" class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <transition appear name="return-animation" mode="out-in">
          <core-product-card
            type="return"
            :data="route"
            :style="{ '--i': index, '--length': routes.length }"
            :headline="route.name"
            :sub-line="route.country_name"
            :description="route.description"
            :price="route.price"
            :currency="route.currency"
            :private-portal="route.settings.private"
            @openshare="openModal"
            @openedit="edit"
            @opendelete="deletePage"
          />
        </transition>
      </div>
    </div>
    <core-list-empty v-if="!loading && !totalItems" class="w-full rounded-md bg-white pt-10" style="height: 500px" />

    <share-dialog
      v-if="modalOpen"
      :route="selected_route"
      :show.sync="modalOpen"
      @dialog:close="
        () => {
          modalOpen = false;
          selected_route = null;
        }
      "
    />
  </core-view>
</template>

<script>
import Loader from "@/mixins/Loader";
import ShareDialog from "./components/ShareDialog";
import I18n from "@/plugins/i18n";
export default {
  name: "Returns",
  components: { ShareDialog },
  mixins: [Loader],
  data: () => ({
    routes: [],
    query: null,
    modalOpen: false,
    breakpoint: null,
    modal_width: null,
    defaultSelectedRoute: {
      name: null,
      uid: null,
      country_name: null,
      endpoint: null,
      private_key: null,
      settings: {
        private: false,
      },
    },
    selected_route: null,
  }),
  computed: {
    totalItems() {
      return this.routes.length;
    },
    cardActions() {
      return [
        {
          label: I18n.t("edit"),
          icon: ["far", "edit"],
          action: (entity) => {
            this.edit(entity);
          },
        },
        {
          label: I18n.t("share"),
          icon: ["far", "share"],
          action: (entity) => {
            this.openModal(entity);
          },
        },
        {
          label: I18n.t("delete"),
          icon: ["far", "trash"],
          action: (entity) => {
            this.deletePage(entity);
          },
        },
      ];
    },
  },
  watch: {
    query: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.loaderOptions = {
      ...this.loaderOptions,
      target: "#returns__container",
    };

    this.$on("update:query", function (val) {
      this.query = val;
    });
  },
  methods: {
    openModal(route) {
      this.selected_route = route;

      this.modalOpen = true;
    },
    loadData() {
      this.loading = true;
      this.$get("returns", {
        params: {
          name: this.query,
        },
      })
        .then((response) => {
          this.routes = [...response];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.routes = [];
        });
    },
    newPage: function () {
      this.$router.push({ path: `/returns/new` });
    },
    edit: function (route) {
      this.$router.push({ path: `/returns/${route.uid}` });
    },
    deletePage: function (route) {
      let self = this;
      this.$confirm(
        route.name,
        self.$t("delete_entity", {
          entity: self.$t("returns").toLocaleLowerCase(),
        })
      )
        .then(function () {
          self.$axios.delete(`returns/${route.uid}`).then(function () {
            self.$notification({
              title: self.$t("entity_deleted_correctly", {
                entity: self.$t("returns"),
              }),
              type: "warning",
            });
            self.loadData();
          });
        })
        .catch(function () {});
    },
  },
};
</script>

<style lang="scss">
.route_container {
  margin-bottom: 22px;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px;
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0 8px 16px;
  }
  .actions {
    padding: 10px 14px;
  }

  .route_container__header {
    height: 175px;

    .flag-header {
      height: 100%;
      width: 100%;
      background-size: cover;
      &.flag-icon-background {
        &::before {
          content: "";
          display: block;
          position: relative;
          height: 100%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .route_container__body {
    background-color: #ffffff;
    padding: 16px;
    cursor: pointer;
  }

  .actions {
    color: white;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .el-icon-border {
    font-size: 16px;
    border: 3px solid white;
    border-radius: 2em;
    padding: 3px;
  }

  #returns_container {
    min-height: 295px;
  }
}

.actions__locked {
  position: relative;

  > span:not(.locked) {
    opacity: 0.4;
  }
}

.returns__card__action {
  position: absolute;
  margin: unset;
  top: 0;
}
</style>
