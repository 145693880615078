<template>
  <core-dialog
    id="gallery-dialog"
    :visible="visible"
    :title="$tc('image', images.length)"
    width="60"
    @close="$emit('close')"
  >
    <div
      class="wrapper form"
      style="display: flex; flex-direction: column; width: 100%;"
    >
        <div v-for="image in images" :key="image">
          <el-image
          class="mb-6"
            v-if="image.includes('amazonaws.com')"
            fit="contain"
            :src="`${image}`"
          >
          </el-image>
          <el-image
          class="mb-6"
            v-else
            fit="contain"
            :src="`${image}?access_token=${$store.state.auth.access_token}&account_id=${$store.state.account_id}`"
          >
          </el-image>
        </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="display: flex; width: 100%;"
    >
      <core-button type="default_outline" @click="$emit('close')">{{
        $t("close")
      }}</core-button>
    </div>
  </core-dialog>
</template>

<script>
import Loader from "@/mixins/Loader";

export default {
  mixins: [Loader],
  name: "GalleryDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  computed: {}
};
</script>

<style lang="scss">
#gallery-dialog {
  .el-dialog {
    height: 100%;
    &__body {
      height: inherit;
    }
  }
  .el-image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: inherit;
    }
  }

  .el-carousel {
    height: 100%;
    &__container {
      height: inherit;
    }
  }
}
</style>
