<template>
  <div id="return_shipment_order">
    <div class="wrapper p-5">
      <row>
        <column
          :xs="12"
          :xl="12"
          style="margin: auto"
          class="flex flex-col mb-4 text-center justify-center"
        >
          <core-animated-checkmark class="mb-4"></core-animated-checkmark>
          <core-button
            type="coolrunner"
            class="w-full mx-auto mb-4"
            @click="downloadLabel"
            >{{ $t("download_label") }}</core-button
          >
          <core-button
            type="coolrunner"
            class="w-full mx-auto mb-4"
            @click="printLabel"
            >{{ $t("print_entity", { entity: $tc('label', 1).toLowerCase() }) }}</core-button
          >
        </column>
        <column :xs="12">
          <row :gutter="12">
            <column :xs="12">
              <core-card style="height: 100%;" class="hover:cursor-default">
                <div class="w-full">
                  <h3>{{ $t("order_summary") }}</h3>
                  <p>
                    <b>{{ $tc("order") }}: </b>
                    <router-link
                      :to="{ name: 'orders', query: { q: formData.order.id } }"
                      target="_blank"
                      >#{{ formData.order.id }}
                      <font-awesome-icon
                        :icon="['fas', 'external-link-alt']"
                        size="sm"
                    /></router-link>
                  </p>
                  <p>
                    <b>{{ $t("order_date") }}:</b>
                    {{ convertUTCToLocal(undefined, "datetime") }}
                  </p>
                  <p>
                    <b>{{ $t("product") }}:</b> {{ formData.title }}
                  </p>
                  <p>
                    <b>{{ $t("price") }}: </b
                    >{{ $n(salesPrice, "currency", "da-DK") }}
                  </p>
                  <p v-if="insurancePrice">
                    <b>{{ $t("insurance") }}: </b
                    >{{ $n(insurancePrice, "currency", "da-DK") }}
                  </p>
                  <p>
                    <b>{{ $t("price_total") }}: </b
                    >{{ $n(salesPriceTotal, "currency", "da-DK") }}
                  </p>
                </div>
              </core-card>
            </column>
            <column :xs="12">
              <core-card style="height: 100%;" class="hover:cursor-default">
                <div class="w-full">
                  <h3>{{ $t("label_information") }}</h3>
                  <p>
                    <b>{{ $t("package_number") }}: </b>
                    <router-link
                      style="font-size:13px"
                      class=""
                      :to="{
                        name: 'labels',
                        query: {
                          filter: {
                            package_number: formData.shipment.package_number
                          }
                        }
                      }"
                      target="_blank"
                      >{{ formData.shipment.package_number }}
                      <font-awesome-icon
                        :icon="['fas', 'external-link-alt']"
                        size="sm"
                    /></router-link>
                  </p>
                  <p v-if="formData.shipment.labelless_code">
                    <b>{{ $t("labelless_code") }}: </b>
                    {{ formData.shipment.labelless_code }}
                  </p>
                  <p>
                    <b>{{ $t("dimensions") }}: </b>

                    {{ formData.shipment.length }} x
                    {{ formData.shipment.height }} x
                    {{ formData.shipment.width }} cm
                  </p>
                  <p>
                    <b>{{ $t("weight") }}: </b> {{ formData.shipment.weight }}g
                  </p>
                  <p v-if="formData.shipment.reference">
                    <b>{{ $t("reference") }}: </b>
                    {{ formData.shipment.reference }}
                  </p>
                </div>
              </core-card>
            </column>

            <column :xs="6">
              <core-card style="height: 100%;" class="hover:cursor-default">
                <div class="w-full">
                  <h3 class="text-lg">
                    {{ $t("entity_address", { entity: $t("sender") }) }}
                  </h3>
                  <p>{{ formData.shipment.sender.name }}</p>
                  <p>{{ formData.shipment.sender.street1 }}</p>
                  <p v-if="formData.shipment.sender.street2">
                    {{ formData.shipment.sender.street2 }}
                  </p>

                  <p>
                    {{ formData.shipment.sender.zip_code }}
                    {{ formData.shipment.sender.city }}
                  </p>
                  <p>{{ formData.shipment.sender.email }}</p>
                  <p>{{ formData.shipment.sender.phone }}</p>
                </div>
              </core-card>
            </column>
            <column :xs="6">
              <core-card style="height: 100%;" class="hover:cursor-default">
                <div class="w-full">
                  <h3 class="text-lg">
                    {{ $t("entity_address", { entity: $t("receiver") }) }}
                  </h3>
                  <p>{{ formData.shipment.receiver.name }}</p>
                  <p>{{ formData.shipment.receiver.street1 }}</p>
                  <p v-if="formData.shipment.receiver.street2">
                    {{ formData.shipment.receiver.street2 }}
                  </p>

                  <p>
                    {{ formData.shipment.receiver.zip_code }}
                    {{ formData.shipment.receiver.city }}
                  </p>
                  <p>{{ formData.shipment.receiver.email }}</p>
                  <p>{{ formData.shipment.receiver.phone }}</p>
                </div>
              </core-card>
            </column>
          </row>
        </column>
      </row>
    </div>
  </div>
</template>

<script>
import { API_PREFIX } from "@/utils/constants";
import { convertUTCToLocal } from "@/utils/date";
import Step from "@/mixins/Step";
import EventBus from "@/plugins/eventbus";
export default {
  name: "CreateShipmentOrderReceipt",
  mixins: [Step],
  data: () => ({
    store_key: "order"
  }),

  computed: {
    salesPrice: function() {
      let price = 0;

      const price_key = this.$store.state.account.business
        ? "excl_tax"
        : "incl_tax";

      price += this.formData.shipment.price[price_key];

      return price;
    },
    insurancePrice: function() {
      return this.formData.insurance?.price;
    },
    salesPriceTotal: function() {
      return this.salesPrice + (this.insurancePrice || 0);
    }
  },
  methods: {
    printLabel: function () {
      EventBus.$emit("SHOW_PRINT_DIALOG", printer =>
      this.$get(`shipments/${this.formData.shipment.package_number}/print/${printer}`)
        .then(() => {
          this.$notification({
            title: this.$t("request send"),
            text: this.$t("print_should_start"),
            type: "success"
          });
        })
        .catch(({ response }) => {
          this.$notification({
            title: this.$t("print_failed"),
            type: "error"
          });
        })
      );
    },
    convertUTCToLocal: function(val, type) {
      return convertUTCToLocal(val, type);
    },
    downloadLabel: function() {
      window.open(
        `${API_PREFIX}/business-panel/shipments/${this.formData.shipment.package_number}/label?access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`
      );
    }
  }
};
</script>

<style scoped></style>
