import moment from "moment";

export const convertUTCToLocal = function(ds, mode) {
  var date = moment(ds).local();

  switch (mode) {
    case "date":
      return date.format("L");
    case "time":
      return date.format("LTS");
    default:
      return date.format("L") + " " + date.format("LT");
  }
};
